<template>
  <v-container fluid style="max-width: 1400px; margin-left: 0;"
		:style="'height:'+$store.getters['general/availableHeight']+'px'">
		<v-row v-if="!loading" class="fill-height pb-0">
			<v-col ref="colContractContainer" :cols="contract && selectedCompany && contractPreviewing || loadingContractPreviewing ? '12':'9'" class="d-flex flex-column fill-height pb-0" style="overflow-y: scroll;overflow-x: hidden;">
				<div class="d-flex justify-space-between align-start w-100">
					<div class="flex-grow-1">
						<div class="caption" v-if="contract">
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 130px">Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract?.morphable?.name}}</b></div>
								<div class="action-container">

									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="warning"
												x-small
												icon
												v-bind="attrs"
												v-on="on"
											>
												<v-icon small>mdi-eye-outline</v-icon>
											</v-btn>
										</template>
										<v-list dense class="py-0">
											<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
												<p class="caption mb-0 font-weight-bold white--text">Asociaciones</p>
											</v-list-item>
											<v-list-item-group color="#524D4D">
												<v-list-item
													v-for="(association, index) in contract.morphable?.associations"
													:key="'ass-'+index"
													dense
													@click="handleGoTo(association)"
													style="min-width: 200px"
												>
													<v-list-item-title dense>
														<div class="d-flex justify-space-between align-center item-background-hover">
															<div class="d-flex justify-space-between caption" style="width: 100%">
																<div style="width: 90px">{{ association.morphable_type == 'App\\Models\\PreProject' ? 'Pre Proyecto':'Visita Técnica'}}</div>
																<span>:</span>
																<b class="text-end" style="flex: 1;">#{{association.morphable_id}}</b>
															</div>
														</div>
													</v-list-item-title>
												</v-list-item>
											</v-list-item-group>
										</v-list>
									</v-menu>
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 130px">Zona Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable?.info?.zone?.name}}</b></div>
								<div class="action-container">
									<v-menu v-model="zoneMenu" offset-y z-index="9999" :close-on-content-click="false" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
										<template v-slot:activator="{ on, attrs }">
											<div class="action-container">
												<v-btn
													icon
													color="warning"
													x-small
													v-bind="attrs"
													v-on="on"
												>
													<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
												</v-btn>
											</div>
										</template>
										<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
											<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
												<p class="caption mb-0 font-weight-bold white--text">Cambiar Zona Proyecto</p>
											</v-list-item>
											<v-list-item-group color="#524D4D">
												<v-list-item
													v-for="(zone, index) in zones"
													:key="index"
													dense
													@click="changeIptZone(zone.id)"
												>
													<v-list-item-title dense>
														{{ zone.name }}
													</v-list-item-title>
												</v-list-item>
											</v-list-item-group>
										</v-list>
									</v-menu>
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 130px">Tipo Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable?.info?.project_type?.name}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 130px">Dirección Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable?.info?.address?.address}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 130px">Municipio</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable?.info?.address?.district?.name}}</b></div>
								<div class="action-container">
								</div>
							</div>
						</div>
					</div>
					<v-divider vertical class="mx-4"></v-divider>
					<div class="flex-grow-1 client-column">
						<div class="caption" v-if="contract">
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Cliente</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable?.client?.profile?.name}} {{contract.morphable?.client?.profile?.lastname}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Rut</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable?.client?.profile?.rut | formatRut}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Teléfono</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable?.client?.profile?.phone}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Email</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable?.client?.email}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Valor Contrato</div><span>:</span>
									<b class="text-end" style="flex: 1;" v-if="contractValue && contractValue != 0">${{contractValue | toThousandFilter}}</b>
									<b class="text-end" style="flex: 1;" v-else>Sin valor asignado</b>
								</div>
								<div class="action-container justify-center">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="#FF120B"
												icon
												class="mb-0"
												small
												v-bind="attrs"
												v-on="on"
												@click="changePaymentRequired"
											>
												<v-icon small>{{contractPaymentRequired ? 'mdi-square-rounded':'mdi-square-rounded-outline'}}</v-icon>
											</v-btn>
										</template>
										<span>Contrato Requiere Pago</span>
									</v-tooltip>
									<div v-if="contract.last_status?.status == contractStatuses.CREADO && contractPaymentRequired">
										<v-menu
											v-model="contractValueMenu"
											:close-on-content-click="false"
											offset-y
										>
											<template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
												<v-tooltip bottom>
													<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
														<v-btn
															depressed
															color="#959595"
															class="mb-0 ml-0"
															x-small
															icon
															v-bind="{ ...tooltipAttrs, ...menuAttrs }"
															v-on="{ ...tooltipOn, ...menuOn }"
														>
															<v-icon small>mdi-email-arrow-right</v-icon>
														</v-btn>
													</template>
													<span>Enviar correo para pago</span>
												</v-tooltip>
											</template>
											<v-card style="min-width: 150px;">
												<v-card-text class="pa-0">
													<div class="px-2 py-1" style="background: #959595;">
														<p class="caption mb-0 font-weight-bold white--text">Enviar Correo para Pago - Valor de Contrato</p>
													</div>
													<div class="pa-2">
														<div>
															<p class="caption mb-3 font-weight-bold" v-if="contract.contract_info && contract.contract_info.contract_value">El contrato tiene un valor asociado, puedes cambiarlo si lo deseas.</p>
															<p class="caption mb-3 font-weight-bold" v-else>El contrato no tiene un valor asociado, puedes asignarle un nuevo valor a continuación.</p>
															<v-text-field
																label="Valor del Contrato"
																v-model="contractValue"
																class="custom-height-search custom-height-calendar mb-2"
																hide-details
																dense
																outlined
																type="number"
																@keypress="isNumberKey"
																:rules="requiredRule"
															></v-text-field>
															<v-btn
																depressed
																color="green"
																:dark="contractValue"
																small
																block
																:disabled="!contractValue"
																@click="sendPaymentRequestEmail"
															>
																<v-icon small>mdi-check</v-icon>
																<p class="mb-0 ml-2 caption">Confirmar</p>
															</v-btn>
															<v-btn
																depressed
																color="#959595"
																text
																x-small
																block
																class="mt-2"
																@click="contractValueMenu = false"
															>
																<p class="mb-0">Cancelar</p>
															</v-btn>
														</div>
													</div>
												</v-card-text>
											</v-card>
										</v-menu>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<v-divider class="my-2"></v-divider>
				<div class="d-flex align-start" v-if="contract">
					<div class="d-flex flex-column flex-grow-1 align-start justify-start" style="flex: 1">
						<div class="d-flex align-center" v-if="contractPreviewing">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										color="#959595"
										outlined
										dark
										class="mb-2 mr-2"
										small
										@click="previewContract"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon small>mdi-briefcase-edit-outline</v-icon>
										<p class="mb-0 ml-2 caption">Volver a Editor de Cláusulas</p>
									</v-btn>
								</template>
								<span>Vuelve a editar las claúsulas del contrato</span>
							</v-tooltip>
						</div>
						<div class="d-flex align-center" v-else>
							<div class="mr-2" v-if="showPreviewButton && !contractPreviewing">
								<v-menu
									v-model="menuTemplate"
									:close-on-content-click="false"
									offset-y
								>
									<template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
										<v-tooltip bottom>
											<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
												<v-btn
													depressed
													color="#959595"
													outlined
													class="mb-2"
													small
													v-bind="{ ...tooltipAttrs, ...menuAttrs }"
													v-on="{ ...tooltipOn, ...menuOn }"
												>
													<v-icon size="20">mdi-text-box-multiple-outline</v-icon>
												</v-btn>
											</template>
											<span>Plantillas</span>
										</v-tooltip>
									</template>
									<v-card style="min-width: 300px;">
										<v-card-text class="pa-0">
											<div class="px-2 py-1" style="background: #959595;">
												<p class="caption mb-0 font-weight-bold white--text">Plantillas</p>
											</div>
											<div class="pa-2">
												<div class="d-flex align-center" v-if="!showAddNewTemplate">
													<v-menu
														ref="menu"
														v-model="menuTemplates"
														transition="scale-transition"
														offset-y
														left
														nudge-top="-10"
														eager
													>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																depressed
																color="#524D4D"
																outlined
																dark
																small
																class="flex-grow-1"
																v-bind="attrs"
																v-on="on"
															>
																<div class="d-flex align-center justify-space-between py-2 w-100">
																	<p class="mb-0 mr-2">Seleccione Plantilla</p>
																	<v-icon>mdi-chevron-down</v-icon>
																</div>
															</v-btn>
														</template>
														<div class="white d-flex flex-column">
															<div>
																<v-list class="template-list white" max-height="300px" dense>
																	<v-list-item
																		class="template-list-item white"
																		v-for="(template, i) in $store.state.contracts.templateContracts" :key="'t-' + i"
																		@click="onChangeTemplate(template)">
																		<v-list-item-content>
																			<v-list-item-title>{{template.title}}</v-list-item-title>
																		</v-list-item-content>
																		<v-list-item-action>
																			<div class="d-flex align-center">
																				<v-tooltip bottom>
																					<template v-slot:activator="{ on, attrs }">
																						<v-btn
																							fab
																							dark
																							x-small
																							icon
																							color="error"
																							@click="deleteTemplate(template)"
																							v-bind="attrs"
																							v-on="on"
																						>
																							<v-icon small dark>mdi-delete-outline</v-icon>
																						</v-btn>
																					</template>
																					<span>Eliminar</span>
																				</v-tooltip>
																			</div>
																		</v-list-item-action>
																	</v-list-item>
																</v-list>
															</div>
														</div>
													</v-menu>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																small
																icon
																class="ml-2"
																color="green"
																@click="showAddNewTemplate = !showAddNewTemplate"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon dark>mdi-plus</v-icon>
															</v-btn>
														</template>
														<span>Guardar Contrato Actual Como Plantilla</span>
													</v-tooltip>
												</div>
												<div v-if="showAddNewTemplate">
													<p class="caption mb-1 font-weight-bold">Guardar Actual Contrato Como Plantilla</p>
													<v-text-field
														label="Nombre de la Plantilla"
														v-model="templateName"
														class="custom-height-search custom-height-calendar mb-2"
														hide-details
														dense
														outlined
														:rules="requiredRule"
													></v-text-field>
													<v-btn
														depressed
														color="green"
														:dark="templateName"
														small
														block
														:disabled="!templateName"
														@click="saveTemplate"
													>
														<v-icon small>mdi-check</v-icon>
														<p class="mb-0 ml-2 caption">Confirmar</p>
													</v-btn>
													<v-btn
														depressed
														color="#959595"
														text
														x-small
														block
														class="mt-2"
														@click="showAddNewTemplate = false"
													>
														<p class="mb-0">Cancelar</p>
													</v-btn>
												</div>
											</div>
										</v-card-text>
									</v-card>
								</v-menu>
							</div>
							<div>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											color="#959595"
											dark
											class="mb-2 mr-2"
											small
											@click="$router.push({ name: 'ProjectCostBreakdown', params: { id: contract.morphable?.id } })"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon>mdi-currency-usd</v-icon>
										</v-btn>
									</template>
									<span>Ver Tabla de Costo del Proyecto</span>
								</v-tooltip>
							</div>
							<!-- <div>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											color="#959595"
											dark
											outlined
											class="mb-2 mr-2"
											small
											v-if="showPreviewButton"
											@click="previewContract"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon>{{contractPreviewing ? 'mdi-briefcase-edit-outline':'mdi-file-pdf-box'}}</v-icon>
										</v-btn>
									</template>
									<span>{{ contractPreviewing ? 'Volver a Editor de Cláusulas':'Previsualizar y Enviar Contrato'}}</span>
								</v-tooltip>
							</div> -->
							<!-- <div class="d-flex align-center" v-if="showActionsButtons">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											color="#959595"
											dark
											outlined
											class="mb-2 mr-2"
											small
											v-bind="attrs"
											v-on="on"
											:loading="loadingPdf"
											@click="contractToPdf"
										>
											<v-icon>mdi-file-pdf-box</v-icon>
										</v-btn>
									</template>
									<span>Se generará el pdf del contrato con la información actualizada y podrás enviarlo al cliente</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											color="#959595"
											outlined
											class="mb-2"
											small
											v-bind="attrs"
											v-on="on"
											dark
											:style="contract.pdf ? '':'opacity: 0.7'"
											:loading="loadingPdf"
											@click="sendEmail"
										>
											<v-icon>mdi-email-outline</v-icon>
										</v-btn>
									</template>
									<span>{{contract.pdf ? 'Se enviará correo al usuario con una copia del contrato':'Primero debes convertir a pdf el contrato'}}</span>
								</v-tooltip>
							</div> -->
						</div>

						<div>
							<v-tooltip bottom v-if="canSignCompany">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										color="green"
										dark
										class="mb-2 mr-2"
										small
										@click="companyRepSign"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon small>mdi-signature-freehand</v-icon>
										<p class="mb-0 ml-2 caption">Firmar Contrato</p>
									</v-btn>
								</template>
								<span>El contrato ya ha sido firmado por el cliente, sólo falta la firma de la empresa / profesional</span>
							</v-tooltip>
							<v-tooltip bottom v-if="showPreviewButton && !contractPreviewing">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										color="green"
										dark
										class="mb-2 mr-2"
										small
										@click="previewContract"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon small>mdi-file-pdf-box</v-icon>
										<p class="mb-0 ml-2 caption">Previsualizar y Enviar Contrato</p>
									</v-btn>
								</template>
								<span>{{contract.last_status?.status == contractStatuses.OBSERVADO_CLIENTE ? 'Luego de revisar las observaciones del cliente previsualiza y envía nuevamente el contrato para su revisión':'Previsualiza y envía el contrato al cliente para su revisión'}}</span>
							</v-tooltip>
							<v-tooltip bottom v-else-if="showPreviewButton && contractPreviewing">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										color="green"
										dark
										class="mb-2 mr-2"
										small
										:loading="loadingPdf"
										@click="contractToPdf"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon small>mdi-file-pdf-box</v-icon>
										<p class="mb-0 ml-2 caption">Enviar Contrato</p>
									</v-btn>
								</template>
								<span>Visualiza el formato final en PDF y envia al cliente</span>
							</v-tooltip>
						</div>
					</div>
					<div class="d-flex flex-column flex-grow-1 align-center justify-start" style="flex: 1" v-if="contract">
						<div class="d-flex align-center">
							<p class="mb-0 mr-2 font-weight-bold caption">Estado:</p>
							<v-chip
								:color="contract.last_status?.status | contractStatusColor"
								label
								outlined
								x-small
								class="px-1"
							>
								<b>{{ contract.last_status?.status | contractStatus }}</b>
							</v-chip>

							<v-menu offset-y>
								<template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
									<v-tooltip bottom>
										<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
											<v-btn
												depressed
												color="#959595"
												dark
												small
												icon
												v-bind="{ ...tooltipAttrs, ...menuAttrs }"
												v-on="{ ...tooltipOn, ...menuOn }"
											>
												<v-icon small>mdi-briefcase-clock-outline</v-icon>
											</v-btn>
										</template>
										<span>Historial del contrato</span>
									</v-tooltip>
								</template>
								<v-list dense class="py-0 white">
									<v-list-item dense class="mb-0" style="min-height: 25px; background: #959595;">
										<div>
											<p class="caption mb-0 font-weight-bold white--text">Historial del Contrato</p>
										</div>
									</v-list-item>
									<v-list-item dense class="my-0 py-0" style="min-height: 25px;">
										<div class="d-flex justify-space-between align-center flex-grow-1">
											<p class="caption mb-0 text--disabled">Estado</p>
											<p class="caption mb-0 text--disabled" style="width: 90px;">Fecha</p>
										</div>
									</v-list-item>
									<v-divider></v-divider>
									<v-list-item-group color="#524D4D" style="max-height: 350px;overflow-y:scroll;">
										<v-list-item
											v-for="(contractStatus, index) in contract.statuses"
											:key="'ctstatus-'+index"
											dense
											style="min-width: 350px;"
											class="white"
										>
											<v-list-item-title dense>
												<div class="d-flex justify-space-between align-center item-background-hover">
													<div class="d-flex align-center justify-space-between caption mr-6">
														<v-icon class="mr-1" x-small :color="contractStatusColor(contractStatus.status)">mdi-circle</v-icon>
														<b>{{contractStatus.status | contractStatus}}</b>
													</div>
													<div class="d-flex justify-end caption" style="width: 90px;">
														<p class="mb-0 caption text-end">{{contractStatus.created_at | toDateTime}}</p>
													</div>
												</div>
											</v-list-item-title>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-menu>
						</div>
						<div class="d-flex align-center justify-end flex-grow-1 mt-1">
							<v-alert
								outlined
								type="warning"
								dense
								max-width="500"
								min-width="400"
								border="left"
							>
								<p class="mb-0 caption">{{contract.last_status?.status | contractEstatusNotice }}</p>
								<div v-if="contract.last_status?.status == contractStatuses.FIRMADO_CLIENTE">
									<p class="mb-0 caption font-weight-bold" v-if="canSignCompany">Considera: Sólo el representante legal tiene la facultad de firmar el contrato</p>
									<p class="mb-0 caption font-weight-bold" v-else>Considera: Sólo el representante legal tiene la facultad de firmar el contrato, con tu perfil de usuario no podrás realizar esta acción</p>
								</div>
							</v-alert>
						</div>
					</div>
					<div class="d-flex flex-column flex-grow-1 align-end justify-start" style="flex: 1">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="#959595"
									dark
									outlined
									class="mb-2 mr-2"
									small
									v-if="contract && contract.pdf"
									@click="openContractPDF"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon small>mdi-file-pdf-box</v-icon>
									<p class="mb-0 caption">{{viewPdfText}}</p>
								</v-btn>
							</template>
							<span>Visualizar la última versión del contrato que fue convertida a PDF</span>
						</v-tooltip>
					</div>
				</div>
				<v-divider class="my-2"></v-divider>
				<div v-if="contract && selectedCompany && loadingContractPreviewing">
					<p class="text-center caption">Cargando Contrato</p>
					<v-skeleton-loader
						type="heading"
						class="my-2 mx-6"
					></v-skeleton-loader>
					<v-skeleton-loader
						type="paragraph"
						class="my-2 mx-6"
					></v-skeleton-loader>
					<v-skeleton-loader
						type="text"
						class="my-2 mx-6"
					></v-skeleton-loader>
					<v-skeleton-loader
						type="paragraph"
						class="my-2 mx-6"
					></v-skeleton-loader>
				</div>
				<TextEditor v-else-if="contract && selectedCompany && contractPreviewing" :contract="contract"/>
				<NonEditableContract v-else-if="(contract && contract.last_status?.status >= contractStatuses.ACEPTADO) || (contract && selectedCompany && showNonEditable)" :contract_info="contract" :fromCompany="true"/>
				<div class="contract-container flex-grow-1 pb-0" v-else-if="contract && selectedCompany">
					<!-- <div class="py-2 px-4 d-flex justify-space-between">
						<div class="d-flex justify-space-between">
							<img :src="selectedCompany.company.logo" style="width: 120px;"/>
							<div class="px-2">
								<p class="font-weight-bold mb-0" style="color: #606060;font-size: 19.6px !important">{{$store.state.companies.selectedCompany.company.name}}</p>
								<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.description}}</p>
								<p class="caption mb-0" style="color: #606060;">Rut: {{$store.state.companies.selectedCompany.company.rut | formatRut}}</p>
								<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.phone}}</p>
								<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.email}}</p>
							</div>
						</div>
						<div>
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
								<div style="width: 120px">Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">{{ contract.code }} - {{ contract.created_at | toYear }}</b>
							</div>
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
								<div style="width: 120px">Fecha Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">{{ contract.created_at | toDate }}</b>
							</div>
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
								<div style="width: 120px">Fecha Inicio</div><span>:</span>
								<b class="text-end ml-3" style="flex: 1;" v-if="contract.start_date">{{ contract.start_date | toDate }}</b>
								<b class="text-end ml-3" style="flex: 1;" v-else>A Convenir</b>
							</div>
						</div>
					</div> -->
					<div class="py-2 px-4" style="flex-wrap: wrap;">
						<div class="d-flex justify-space-between align-stretch">
							<div class="d-flex flex-column justify-center align-items-center flex-grow-1"
								:style="`background-image: url(${selectedCompany.company.logo});`"
								style="background-size: contain;background-repeat: no-repeat;">
							</div>
							<div class="text-end flex-grow-2 mr-1">
								<p class="font-weight-bold mb-0" style="color: #606060; font-size: 19.6px !important;">
									{{$store.state.companies.selectedCompany.company.name}}
								</p>
								<p class="caption mb-0" style="color: #606060;line-height: 11pt;">{{$store.state.companies.selectedCompany.company.description}}</p>
								<p class="caption mb-0" style="color: #606060;line-height: 11pt;">Rut: {{$store.state.companies.selectedCompany.company.rut | formatRut}}</p>
								<p class="caption mb-0" style="color: #606060;line-height: 11pt;">{{$store.state.companies.selectedCompany.company.phone}}</p>
								<p class="caption mb-3" style="color: #606060;line-height: 11pt;">{{$store.state.companies.selectedCompany.company.email}}</p>
								<p class="caption mb-0" style="color: #606060;line-height: 11pt;">Cotización {{ contract.contract_info?.prefix?.prefix }} Nº {{ contract.serial?.serial }} - {{ contract.created_at | toYear }}</p>
								<p class="caption mb-0" style="color: #606060;line-height: 11pt;">{{ contract.created_at | toDate }}</p>
								<p class="caption mb-0" style="color: #606060;line-height: 11pt;">
									Inicio Proyecto:
									<b v-if="contract.start_date">{{ contract.start_date | toDate }}</b>
									<b v-else>A Convenir</b>
								</p>
							</div>
						</div>
					</div>
					<draggable
						v-if="contract"
						v-model="contract.sections"
						group="project_contract"
						id="contracts"
						item-key="id"
						:move="onMove"
						filter=".exclude"
						@end="startEndDragEventCategory"
					>
						<div class="py-2 px-4" v-for="(section, index) in contract.sections" :key="index"
							:class="section.section_type == 1 ? 'exclude':''">
							<div class="section-container rounded" v-if="!selectedSectionToWork || (selectedSectionToWork && selectedSectionToWork == section.id)">
								<div class="pa-4">
									<div class="d-flex align-center mb-3 justify-space-between">
										<div class="pa-2 w-100" v-if="newSection.contract_section_id == section.id">
											<v-menu
												ref="menu"
												v-if="section.section_type == 1"
												v-model="menuTitles"
												transition="scale-transition"
												offset-y
												left
												nudge-top="-10"
												eager
											>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														depressed
														color="#524D4D"
														outlined
														dark
														small
														block
														v-bind="attrs"
														v-on="on"
													>
														<div class="d-flex align-center justify-space-between py-2 w-100">
															<p class="mb-0 mr-2">{{newSection.title ? newSection.title:'Seleccione Título'}}</p>
															<v-icon>mdi-chevron-down</v-icon>
														</div>
													</v-btn>
												</template>
												<div class="white d-flex flex-column">
													<div>
														<v-list class="template-list white" max-height="300px" dense>
															<v-list-item
																class="template-list-item white"
																v-for="(title, i) in $store.state.contracts.titleContracts" :key="'ct-' + i"
																@click="onChangeTitle(title)">
																<v-list-item-content>
																	<v-list-item-title>{{title.title}}</v-list-item-title>
																</v-list-item-content>
																<v-list-item-action>
																	<div class="d-flex align-center">
																		<v-tooltip bottom>
																			<template v-slot:activator="{ on, attrs }">
																				<v-btn
																					fab
																					dark
																					x-small
																					icon
																					color="error"
																					@click="deleteTitle(title.id)"
																					v-bind="attrs"
																					v-on="on"
																				>
																					<v-icon small dark>mdi-delete-outline</v-icon>
																				</v-btn>
																			</template>
																			<span>Eliminar</span>
																		</v-tooltip>
																	</div>
																</v-list-item-action>
															</v-list-item>
														</v-list>
													</div>
												</div>
											</v-menu>

											<div class="d-flex justify-start align-center">
												<v-btn
													depressed
													color="#FF120B"
													class="my-2 mx-2"
													small
													outlined
													@click="closeNewSection()"
												>
													<v-icon>mdi-window-close</v-icon>
													<p class="mb-0 ml-2 caption">Cancelar</p>
												</v-btn>
												<v-btn
													depressed
													color="green"
													dark
													class="my-2"
													small
													@click="confirmUpdateSection()"
												>
													<v-icon>mdi-check</v-icon>
													<p class="mb-0 ml-2 caption">Confirmar Cambios</p>
												</v-btn>
											</div>
										</div>
										<div class="d-flex flex-grow-1 justify-space-between" v-else>
											<div v-if="section.section_type == 1"  class="d-flex align-center text-center flex-grow-1">
												<v-menu
													v-model="menuAddTitle"
													:close-on-content-click="false"
													offset-y
												>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="#959595"
															dark
															class="mb-0"
															x-small
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small>mdi-cog</v-icon>
														</v-btn>
													</template>
													<v-card style="min-width: 300px;">
														<v-card-text>
															<v-text-field
																label="Ingrese Título"
																v-model="contractTitle"
																class="custom-height-search custom-height-calendar mb-2"
																hide-details
																dense
																outlined
																:rules="requiredRule"
															></v-text-field>
															<v-btn
																depressed
																color="green"
																:dark="contractTitle"
																small
																block
																:disabled="!contractTitle"
																@click="saveTitle"
															>
																<v-icon small>mdi-check</v-icon>
																<p class="mb-0 ml-2 caption">Confirmar</p>
															</v-btn>
															<v-btn
																depressed
																color="#959595"
																text
																x-small
																block
																class="mt-2"
																@click="closeTitleMenu"
															>
																<p class="mb-0">Cancelar</p>
															</v-btn>
														</v-card-text>
													</v-card>
												</v-menu>
												<p class="mb-0 font-weight-bold text-center flex-grow-1">{{section.title}}</p>
											</div>
											<p class="mb-0 font-weight-bold" v-else>{{ sectionIndex(index) }} {{section.title}}</p>
											<div class="d-flex align-center">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="warning"
															small
															icon
															v-bind="attrs"
															v-on="on"
															@click="editNewSection(section)"
														>
															<v-icon small>mdi-pencil-outline</v-icon>
														</v-btn>
													</template>
													<span>Editar Sección</span>
												</v-tooltip>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="#FF120B"
															small
															icon
															v-bind="attrs"
															v-on="on"
															@click="deleteSection(section)"
														>
															<v-icon small>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Sección</span>
												</v-tooltip>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="green"
															small
															icon
															v-bind="attrs"
															v-on="on"
															v-show="addingContent !== section.id"
															@click="addNewContent(section)"
														>
															<v-icon>mdi-plus</v-icon>
														</v-btn>
													</template>
													<span>Agregar Contenido a Sección</span>
												</v-tooltip>
											</div>
										</div>
									</div>
									<draggable
										v-model="section.contents"
										group="project_contract_sections"
										:id="'contracts_sections_' + index"
										item-key="id"
										@end="(ev) => startEndDragEventSection(ev)"
									>
										<div v-for="(section_content, indexB) in section.contents" :key="'a'+indexB" :id="'section_content_id_' + section_content.id">
											<div class="mb-4">
												<v-hover v-slot="{ hover }">
													<div class="section-content-container">
														<div class="pa-2" v-if="newContent.contract_section_content_id == section_content.id">
															<v-text-field
																v-model="newContent.title"
																label="Título"
																placeholder="Ingrese título identificador"
																outlined
																required
																dense
																hide-details
																:rules="requiredRule"
															></v-text-field>
															<v-checkbox v-model="newContent.show_title" class="mb-2" color="black" label="Mostrar Título en Contrato" hide-details dense></v-checkbox>
															<vue2-tinymce-editor
																:inline="true"
																v-model="newContent.content"
																:options="{ menubar: false }" ref="tinymceEditor"></vue2-tinymce-editor>
															<div class="d-flex justify-end align-center">
																<v-btn
																	depressed
																	color="#FF120B"
																	class="my-2 mx-2"
																	small
																	outlined
																	@click="closeNewContent()"
																>
																	<v-icon>mdi-window-close</v-icon>
																	<p class="mb-0 ml-2 caption">Cancelar</p>
																</v-btn>
																<v-btn
																	depressed
																	color="green"
																	dark
																	class="my-2"
																	small
																	@click="confirmUpdateContent()"
																>
																	<v-icon>mdi-check</v-icon>
																	<p class="mb-0 ml-2 caption">Confirmar Cambios</p>
																</v-btn>
															</div>
														</div>
														<div class="px-2" v-else>
															<div v-if="section_content.show_title" class="font-weight-bold">{{ (indexB + 1) + '.- ' }} {{ section_content.title }}</div>
															<!-- <div class="section-content pa-2" v-html="transformPlaceholdersToFriendlyView(section_content.content)"></div> -->
															<div class="section-content pa-2" v-html="transformPlaceholdersToContentView(section_content.content)"></div>
															<div class="d-flex justify-end py-2">
																<v-tooltip left>
																	<template v-slot:activator="{ on, attrs }">
																		<v-badge
																			:value="countContractsByContentId(section_content.id)"
																			:content="countContractsByContentId(section_content.id)"
																			color="red"
																			overlap
																		>
																			<v-btn
																				v-show="section_content.observation"
																				:ref="'commentActivatorButton_' + section_content.id"
																				depressed
																				color="warning"
																				outlined
																				small
																				rounded
																				@click="menuComment = section_content.id"
																				v-bind="attrs"
																				v-on="on"
																			>
																				<v-icon class="mr-4" :class="{'pulse-animation': section_content.observation && !hover}">mdi-comment-plus-outline</v-icon>
																				<p class="mb-0">Hay Observaciones / Comentarios en esta sección</p>
																			</v-btn>
																		</v-badge>
																	</template>
																	<span>Ver / Agregar Comentarios en Sección</span>
																</v-tooltip>
															</div>
														</div>
														<div class="hover-section-content rounded" v-show="hover && newContent.contract_section_content_id != section_content.id">
															<div class="d-flex flex-column w-100 h-100 fill-height">
																<div class="d-flex justify-center align-center flex-grow-1">
																	<v-menu
																		ref="menu"
																		v-model="menuCopyContentOpen[section_content.id]"
																		offset-y
																		auto
																		eager
																		origin="center center"
																	>
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn
																				color="green"
																				class="mx-2"
																				icon
																				v-bind="attrs"
																				v-on="on"
																			>
																				<v-icon>mdi-content-copy</v-icon>
																			</v-btn>
																		</template>
																		<div class="white d-flex flex-column" style="max-width: 400px;">
																			<div>
																				<v-list class="template-list white py-0" max-height="300px" dense>
																					<v-list-item dense class="mb-1 d-flex" style="min-height: 25px; background: #959595;">
																						<p class="caption flex-grow-1 mb-0 font-weight-bold white--text">Copiar Contenido a Nueva Sección:</p>
																						<v-btn
																							color="white"
																							class="mx-2"
																							icon
																							@click="menuCopyContentOpen[section_content.id] = false"
																						>
																							<v-icon>mdi-close</v-icon>
																						</v-btn>
																					</v-list-item>
																					<v-list-item
																						class="template-list-item white"
																						v-for="(section, indexSelect) in contract.sections" :key="'tselect-' + index + '_' + indexSelect"
																						@click="copyContentToSection(section_content, indexSelect)">
																						<v-list-item-content>
																							<v-list-item-title>{{indexSelect > 0 ? sectionIndex(indexSelect):''}} {{section.title}}</v-list-item-title>
																						</v-list-item-content>
																					</v-list-item>
																				</v-list>
																			</div>
																		</div>
																	</v-menu>
																	<v-menu
																		ref="menu"
																		v-model="menuMoveContentOpen[section_content.id]"
																		offset-y
																		auto
																		eager
																		origin="center center"
																	>
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn
																				color="#524D4D"
																				class="mx-2"
																				icon
																				v-bind="attrs"
																				v-on="on"
																			>
																				<v-icon>mdi-swap-horizontal</v-icon>
																			</v-btn>
																		</template>
																		<div class="white d-flex flex-column" style="max-width: 400px;">
																			<div>
																				<v-list class="template-list white py-0" max-height="300px" dense>
																					<v-list-item dense class="mb-1 d-flex" style="min-height: 25px; background: #959595;">
																						<p class="caption flex-grow-1 mb-0 font-weight-bold white--text">Mover Contenido a Nueva Sección:</p>
																						<v-btn
																							color="white"
																							class="mx-2"
																							icon
																							@click="menuMoveContentOpen[section_content.id] = false"
																						>
																							<v-icon>mdi-close</v-icon>
																						</v-btn>
																					</v-list-item>
																					<v-list-item
																						class="template-list-item white"
																						v-for="(section, indexSelect) in contract.sections" :key="'tselect-' + index + '_' + indexSelect"
																						@click="moveContentToSection(section_content, index, indexSelect)">
																						<v-list-item-content>
																							<v-list-item-title>{{indexSelect > 0 ? sectionIndex(indexSelect):''}} {{section.title}}</v-list-item-title>
																						</v-list-item-content>
																					</v-list-item>
																				</v-list>
																			</div>
																		</div>
																	</v-menu>
																	<v-btn
																		depressed
																		color="warning"
																		class="mx-2"
																		icon
																		@click="editNewContent(section_content)"
																	>
																		<v-icon>mdi-pencil-outline</v-icon>
																	</v-btn>
																	<v-btn
																		depressed
																		color="#FF120B"
																		class="mx-2"
																		icon
																		@click="deleteContent(section_content)"
																	>
																		<v-icon>mdi-delete-outline</v-icon>
																	</v-btn>
																</div>
																<div class="d-flex justify-end mb-2 px-2">
																	<v-tooltip left>
																		<template v-slot:activator="{ on, attrs }">
																			<v-badge
																				:value="countContractsByContentId(section_content.id)"
																				:content="countContractsByContentId(section_content.id)"
																				color="red"
																				overlap
																			>
																				<v-btn
																					:ref="'commentActivatorButtonHover_' + section_content.id"
																					v-show="hover && section_content.observation"
																					depressed
																					color="warning"
																					outlined
																					small
																					rounded
																					@click="openCommentSection(section_content.id)"
																					v-bind="attrs"
																					v-on="on"
																				>
																					<v-icon class="mr-4" :class="{'pulse-animation': section_content.observation && !hover}">mdi-comment-plus-outline</v-icon>
																					<p class="mb-0">Hay Observaciones / Comentarios en esta sección</p>
																				</v-btn>
																			</v-badge>
																		</template>
																		<span>Ver / Agregar Comentarios en Sección</span>
																	</v-tooltip>
																</div>
															</div>
														</div>
														<v-overlay
															:opacity="0.2"
															:value="menuComment == section_content.id"
															z-index="9"
															@click="menuComment = null"
														>
															<v-card
																v-if="menuComment == section_content.id"
																class="card-comment-container"
																light
																color="white"
																width="400"
																:style="{
																	position: 'fixed',
																	top: `${commentPosition.top}px`,
																	left: `${commentPosition.left}px`
																}"
																@click.stop
																:ripple="false"
																>
																<v-card-text class="pa-0" style="background: white;width:400px;">
																	<div class="py-1 px-4" style="background: #959595;">
																		<p class="caption mb-0 font-weight-bold white--text">Observaciones en Sección</p>
																	</div>
																	<v-list dense class="py-0" max-height="300" style="overflow-y: auto;" ref="scrollListComment">
																		<v-list-item-group color="#524D4D" v-if="section_content.observation">
																			<v-list-item
																				v-for="(comment, index) in sectionComments"
																				:key="'comments-' + index"
																				dense
																			>
																				<v-list-item-title dense>
																					<div class="d-flex justify-space-between item-background-hover py-2">
																						<div class="d-flex">
																							<v-icon :color="comment.side == 1 ? 'warning':''">mdi-comment-plus-outline</v-icon>
																							<div class="ml-2">
																								<div class="font-weight-bold caption">{{ comment.user.profile.name }} {{ comment.user.profile.lastname }} <small>({{ comment.side == 1 ? 'Cliente':'Empresa' }})</small></div>
																								<div class="caption">{{ comment.comment }}</div>
																							</div>
																						</div>
																						<v-tooltip bottom>
																							<template v-slot:activator="{ on, attrs }">
																								<v-btn
																									fab
																									dark
																									x-small
																									icon
																									color="error"
																									@click="deleteComment(comment)"
																									v-bind="attrs"
																									v-on="on"
																									v-if="userInfo && userInfo.id == comment.user_id"
																								>
																									<v-icon small dark>mdi-delete-outline</v-icon>
																								</v-btn>
																							</template>
																							<span>Eliminar Comentario</span>
																						</v-tooltip>
																					</div>
																				</v-list-item-title>
																			</v-list-item>
																		</v-list-item-group>
																	</v-list>
																	<v-divider class="my-2"></v-divider>
																	<div class="px-4 pb-4">
																		<v-text-field
																			label="Ingrese Comentario"
																			v-model="sectionComment"
																			class="custom-height-search custom-height-calendar mb-2"
																			hide-details
																			dense
																			outlined
																			validate-on-blur
																			:rules="requiredRule"
																			@keyup.enter="saveComment"
																		></v-text-field>
																		<v-btn
																			depressed
																			color="green"
																			:dark="!!sectionComment"
																			small
																			block
																			:disabled="!sectionComment"
																			:loading="loadingComment"
																			@click="saveComment"
																		>
																			<v-icon small>mdi-check</v-icon>
																			<p class="mb-0 ml-2 caption">Confirmar</p>
																		</v-btn>
																		<v-btn
																			depressed
																			color="#959595"
																			text
																			x-small
																			block
																			class="mt-2"
																			@click="closeCommentForm"
																		>
																			<p class="mb-0">Cerrar</p>
																		</v-btn>
																	</div>
																</v-card-text>
															</v-card>
														</v-overlay>
													</div>
												</v-hover>
											</div>
										</div>
									</draggable>
								</div>
								<div class="pa-4">
									<div v-if="addingContent == section.id">
										<v-divider class="mb-4"></v-divider>
										<div class="mb-3 d-flex justify-space-between align-center">
											<div>
												<p class="mb-0 caption font-weight-bold">Agregar Contenido a Sección</p>
												<p class="mb-0 caption text--secondary">Puedes seleccionar una sección y un contenido preestablecido</p>
											</div>
											<div style="width: 30%">
												<v-select
													v-model="selectedCategory"
													:items="$store.state.contracts.companyContractCategories"
													:loading="$store.state.contracts.loadingCompanyContractCategories"
													:menu-props="{ top: true, offsetY: true }"
													item-text="title"
													item-value="id"
													label="Seleccione Sección"
													class="custom-height-search custom-height-calendar mb-2 w-100"
													outlined
													dense
													return-object
													required
													v-if="!blockCategorySelect"
												></v-select>
												<v-select
													v-model="selectedContent"
													:items="$store.state.contracts.companyContracts"
													:loading="$store.state.contracts.loadingCompanyContracts"
													:menu-props="{ top: true, offsetY: true }"
													item-text="title"
													item-value="id"
													label="Seleccione Contenido"
													class="custom-height-search custom-height-calendar w-100"
													outlined
													dense
													return-object
													required
													clearable
												></v-select>
											</div>
										</div>
										<v-text-field
											v-model="newContent.title"
											label="Título"
											placeholder="Ingrese título identificador"
											outlined
											required
											dense
											hide-details
											:rules="requiredRule"
										></v-text-field>
										<v-checkbox v-model="newContent.show_title" class="mb-2" color="black" label="Mostrar Título en Contrato" hide-details dense></v-checkbox>
										<vue2-tinymce-editor
											:inline="true"
											v-model="newContent.content"
											:options="{ menubar: false }" ref="tinymceEditor"></vue2-tinymce-editor>
										<div class="d-flex">
											<v-btn
												depressed
												color="#FF120B"
												class="my-2 mx-2"
												small
												outlined
												@click="closeNewContent()"
											>
												<v-icon>mdi-close-circle-outline</v-icon>
												<p class="mb-0 ml-2 caption">Cancelar</p>
											</v-btn>
											<v-btn
												depressed
												color="green"
												class="my-2 mx-2 flex-grow-1"
												small
												dark
												@click="confirmContent()"
											>
												<v-icon>mdi-check-circle-outline</v-icon>
												<p class="mb-0 ml-2 caption">Confirmar</p>
											</v-btn>
										</div>
									</div>
								</div>
							</div>
						</div>
					</draggable>
					<div class="py-2 px-4">
						<div class="section-container rounded">
							<div class="pa-4">
								<v-btn
									depressed
									color="#959595"
									class="my-2 flex-grow-1"
									small
									block
									outlined
									v-if="!addingSection"
									@click="addNewSection()"
								>
									<v-icon>mdi-plus</v-icon>
									<p class="mb-0 ml-2 caption">Agregar Sección</p>
								</v-btn>
								<div v-else>
									<div class="mb-3 d-flex justify-space-between align-center">
										<div>
											<p class="mb-0 caption font-weight-bold">Agregar una Sección</p>
											<p class="mb-0 caption text--secondary">Puedes seleccionar una sección preestablecida</p>
										</div>
										<div style="width: 30%">
											<v-select
												v-model="selectedCategory"
												:items="$store.state.contracts.companyContractCategories"
												:loading="$store.state.contracts.loadingCompanyContractCategories"
												:menu-props="{ top: true, offsetY: true }"
												item-text="title"
												item-value="id"
												label="Seleccione Sección"
												class="custom-height-search custom-height-calendar mb-2 w-100"
												outlined
												dense
												return-object
												required
												clearable
												@change="onChangeCategory"
											></v-select>
										</div>
									</div>
									<v-text-field
										v-model="newSection.title"
										label="Título"
										placeholder="Ingrese título de la sección"
										outlined
										required
										dense
										:rules="requiredRule"
									></v-text-field>
									<div class="d-flex">
										<v-btn
											depressed
											color="green"
											class="my-2 mx-2 flex-grow-1"
											small
											dark
											@click="confirmSection()"
										>
											<v-icon>mdi-check-circle-outline</v-icon>
											<p class="mb-0 ml-2 caption">Confirmar</p>
										</v-btn>
										<v-btn
											depressed
											color="#FF120B"
											class="my-2 mx-2"
											small
											outlined
											@click="closeNewSection()"
										>
											<v-icon>mdi-close-circle-outline</v-icon>
											<p class="mb-0 ml-2 caption">Cancelar</p>
										</v-btn>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <draggable v-model="contractSections" @end="startEndDragEvent">
					<div>
						<v-btn
							depressed
							color="#959595"
							class="my-2 flex-grow-1 mx-1"
							small
							block
							outlined
							@click="addNewSection"
						>
							<v-icon>mdi-plus</v-icon>
							<p class="mb-0 ml-2 caption">Agregar Sección</p>
						</v-btn>
					</div>
				</draggable> -->
			</v-col>
			<!-- <v-col cols="3" v-if="!contractPreviewing && contract.last_status?.status >= contractStatuses.ACEPTADO" class="d-flex flex-column fill-height pb-0" style="overflow: scroll;">
			</v-col> -->
			<v-col cols="3" v-if="!(contract && selectedCompany && contractPreviewing)" class="d-flex flex-column fill-height pb-0" style="overflow: scroll;">
				<div class="mb-4">
					<p class="mb-0 caption font-weight-bold">Secciones Empresa</p>
					<draggable
						v-model="$store.state.contracts.companyContractCategories"
						:group="{ name: 'project_contract', pull: 'clone', put: false }"
						id="contracts"
						item-key="id"
						@end="startEndDragNewCategory"
					>
						<div class="custom-list-drag pa-2 rounded my-2"
							:class="item.id == selectedCategory?.id ? 'selected':''"
							v-for="(item) in $store.state.contracts.companyContractCategories"
							:key="'cats-'+item.id"
							@click="onClickCategory(item)">
							<p class="mb-0 caption">{{ item.title }}</p>
						</div>
					</draggable>
				</div>
				<div>
					<p class="mb-0 caption font-weight-bold">Contenido Sección Seleccionada Empresa</p>
					<div v-if="selectedCategory">
						<draggable
							v-model="$store.state.contracts.companyContracts"
							:group="{ name: 'project_contract_sections', pull: 'clone', put: false }"
							id="contracts"
							item-key="id"
							class="draggable-contents"
							@end="startEndDragNewContent"
						>
							<div v-for="(item) in $store.state.contracts.companyContracts" :key="'compc-'+item.id">
								<div class="custom-list-drag pa-2 rounded my-2" v-if="isCompanyFirstSection(item)">
									<p class="mb-0 font-weight-bold caption">{{ item.title }}</p>
									<div class="caption pt-1" v-html="transformPlaceholdersToFriendlyView(item.content)"></div>
								</div>
							</div>
						</draggable>
					</div>
					<p v-else class="mb-0 caption">Selecciona una sección del listado superior para ver los contenidos que la empresa ha configurado</p>
				</div>
			</v-col>
		</v-row>
		<div v-else>
			<p class="text-center caption">Cargando Contrato</p>
			<v-skeleton-loader
				type="heading"
				class="my-2 mx-6"
			></v-skeleton-loader>
			<v-skeleton-loader
				type="paragraph"
				class="my-2 mx-6"
			></v-skeleton-loader>
			<v-skeleton-loader
				type="text"
				class="my-2 mx-6"
			></v-skeleton-loader>
			<v-skeleton-loader
				type="paragraph"
				class="my-2 mx-6"
			></v-skeleton-loader>
		</div>
  </v-container>
</template>

<script>
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import draggable from "vuedraggable"
import _debounce from 'lodash/debounce'
import { getContract, updateContract, createContractSectionProject, createContractSectionContent, updateContractSectionContent, updateContractSectionProject, deleteContractSectionProject, deleteContractSectionContent, updateContractSectionProjectOrders, updateContractSectionContentOrders, setTemplateToContract, createContractTemplate, deleteContractTemplate, createContractTitle, deleteContractTitle, deleteContractContentComment, createContractContentComment, createContractPDF, sendContractPdfEmail, createContractPdfFromPreview, sendContractPaymentRequest } from '@/helpers/api/contracts'
import {markAsReaded} from '@/helpers/api/notifications'
import {updateProjectInfo} from '@/helpers/api/projects'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'
import NonEditableContract from "./NonEditableContract.vue"
import TextEditor from '@/components/Helpers/Editor/TextEditor'
import contractStatuses from '@/components/Helpers/Constants/contract.js'

export default {
	name: 'ContractDetail',
  components: { Vue2TinymceEditor, NonEditableContract, draggable, TextEditor },
  data() {
    return {
			contractStatuses,
			zoneMenu: false,
			showAddNewTemplate: false,
			loadingComment: false,
			menuCopyContentOpen: {},
			menuMoveContentOpen: {},
			selectedSectionToWork: null,
			sectionComment: null,
			commentPosition: { top: 0, left: 0, width: 400 },
			menuComment: null,
			menuChangeSection: false,
			showNonEditable: false,
			testing: [],
			selectedTemplate: null,
			loadingPdf: false,
			loading: false,
			search: '',
			menuTitles: false,
			menuAddTitle: false,
			contractTitle: null,
			menuTemplates: false,
			menuTemplate: false,
			contractValueMenu: false,
			menuStatuses: false,
			templateName: null,
			contractValue: null,
			selectedCategory: null,
			selectedContent: null,
			editIndex: null,
			blockCategorySelect: false,
			addingSection: false,
			contractPaymentRequired: false,
			newSection: {
				contract_id: null,
				section_type: 2,
				title: null,
				order: 1
			},
			addingContent: false,
			newContent: {
				title: null,
				order: 1,
				content: null,
				contract_section_id: null,
				show_title: false
			},
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			contract: null,
			contractSections: [
				{
					id: 1,
					title: 'Objeto del Contrato',
					parent_section_id: 1,
					section_contents: [{
						title: 'Section 1',
						parent_content_id: 1,
						content: '<p>Los servicios de arquitectura y especialidades del presente contrato contemplan el proyecto de dise&ntilde;o de una ampliaci&oacute;n de 80m&sup2; en un segundo piso de una vivienda existente de un piso de 60m&sup2; m&aacute;s la redistribuci&oacute;n de su planta original y la alteraci&oacute;n de esta (modificaciones a realizar o ya realizadas en la vivienda original), haciendo un total de 140m&sup2; m&aacute;ximos y sus gestiones pertinentes con la Direcci&oacute;n de Obras Municipales con el fin de obtener el permiso de edificaci&oacute;n por Art. 5.1.4 de la OGUC y Art. 166&deg; de la LGUC y luego obtener la recepci&oacute;n definitiva una vez sean terminadas las obras constructivas y certificadas las especialidades de agua potable, alcantarillado, electricidad, gas y otras. Esto se encuentra ubicado en calle 3 Poniente N&deg;2849, comuna de Macul, enmarc&aacute;ndose en las normativas pertinentes como Ordenanza General de Urbanismo y Construcci&oacute;n en adelante O.G.U.C., Ley General de Urbanismo y Construcci&oacute;n en adelante L.G.U.C., Plan Regulador Metropolitano de Santiago en adelante P.R.M.S. y Plan Regulador Comunal de Macul en adelante PRCM.</p>'
					}]
				}
			],
			loadingContractPreviewing: false,
			contractPreviewing: false,
			placeholderMapping: {
				'{contract_start_date}': 'Fecha Inicio',
				'{contract_date}': 'Fecha del Contrato',
				'{address}': 'Dirección del Proyecto',
				'{district}': 'Municipio del Proyecto',
				'{client_name}': 'Nombre Cliente',
				'{client_dni}': 'Rut Cliente',
				'{client_address}': 'Dirección Cliente',
				'{client_district}': 'Comuna Cliente',
				'{company_name}': 'Nombre Empresa',
				'{company_rut}': 'Rut Empresa',
				'{company_responsible}': 'Nombre Representante',
				'{company_responsible_rut}': 'Rut Representante',
				'{company_address}': 'Dirección Empresa',
				'{client_company_name}': 'Nombre Empresa (Cliente)',
				'{client_company_dni}': 'Rut Empresa (Cliente)',
				'{client_company_address}': 'Dirección Empresa (Cliente)',
				'{client_company_district}': 'Comuna Empresa (Cliente)',
				'{client_rep_name}': 'Nombre Representante (Cliente)',
				'{client_rep_dni}': 'Rut Representante (Cliente)',
				'{client_rep_address}': 'Dirección Representante (Cliente)',
				'{client_rep_district}': 'Comuna Representante (Cliente)',
			}
    };
  },
  computed: {
    concepts() {
      return this.$store.state.companies.concepts
    },
		zones(){
			return this.$store.state.projects.zoneIpt
		},
		sectionTitles(){
			return this.contract.sections.map((section) => {
				return section.title
			})
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		userInfo(){
			return this.$store.state.user.userInfo
		},
		contractEstatusNotice(){
			if(!this.contract) return ''
			else if(this.contract.last_status?.status < contractStatuses.ENVIADO_CLIENTE){
				return '<b>Importante:</b> El cliente aún no puede visualizar el contrato'
			}
			else if(this.contract.last_status?.status == contractStatuses.ENVIADO_CLIENTE){
				return '<b>Importante:</b> El contrato ya puede ser visualizado por el cliente'
			}
			else if(this.contract.last_status?.status == contractStatuses.OBSERVADO_CLIENTE){
				return '<b>Importante:</b> El cliente ha realizado observaciones, resuélvelas y envíale de nuevo el contrato'
			}
			else if(this.contract.last_status?.status == contractStatuses.ACEPTADO){
				return '<b>Importante:</b> El cliente ha aceptado el contrato pero aún no lo ha firmado'
			}
			else if(this.contract.last_status?.status == contractStatuses.RECHAZADO){
				return '<b>Importante:</b> El cliente ha rechazado el contrato'
			}
			else if(this.contract.last_status?.status == contractStatuses.FIRMADO_CLIENTE){
				return '<b>Importante:</b> El cliente ha firmado el contrato, ahora debe ser firmado por la Empresa / Profesional'
			}
			else if(this.contract.last_status?.status == contractStatuses.FIRMADO_AMBAS_PARTES){
				return '<b>Importante:</b> El contrato ha sido firmado por ambas partes'
			}
			return ''
		},
		showActionsButtons(){
			return this.contractPreviewing && this.contract && this.contract.last_status?.status != contractStatuses.ENVIADO_CLIENTE
		},
		showPreviewButton(){
			if(!this.contract) return false
			return this.contract.last_status?.status < contractStatuses.ENVIADO_CLIENTE || this.contract.last_status?.status == contractStatuses.OBSERVADO_CLIENTE
		},
		sectionComments(){
			for (const section of this.contract.sections) {
				for (const content of section.contents) {
					if (content.id == this.menuComment && content.observation) {
						if (!Array.isArray(content.observation.comments)) {
							this.$set(content.observation, 'comments', []);
						}
						return content.observation.comments
					}
				}
			}
			return []
		},
		canSignCompany(){
			// Validar que usuario sea rep legal empresa y que está firmado por cliente
			if(!this.contract) return false
			else if(this.userInfo && this.selectedCompany && this.selectedCompany.company_id == this.contract.company_id){
				const is_company_resp = this.selectedCompany.company?.responsible == this.userInfo.id
				return this.contract.last_status?.status == contractStatuses.FIRMADO_CLIENTE && is_company_resp
			}
			return false
		},
		viewPdfText(){
			if(this.contract.last_status?.status == contractStatuses.FIRMADO_CLIENTE){
				return 'Ver PDF Firmado Por Cliente'
			}
			else if(this.contract.last_status?.status == contractStatuses.FIRMADO_AMBAS_PARTES){
				return 'Ver PDF Firmado Por Ambas Partes'
			}
			return 'Ver PDF del Contrato'
		},
		queryRoute(){
			return this.$route.query
		},
		contractVariablesValue(){
			if(this.contract){
				const content = {
					'{contract_date}': this.$options.filters.toDate(this.contract.created_at),
					'{contract_start_date}': this.contract.start_date ? this.$options.filters.toDate(this.contract.start_date):'A Convenir',
					'{client_name}': `${this.contract.morphable?.client?.profile.name} ${this.contract.morphable?.client?.profile.lastname}`,
					'{client_dni}': this.$options.filters.formatRut(this.contract.morphable?.client?.profile.rut),
					'{client_address}': this.contract.morphable?.client?.profile.address,
					'{client_district}': this.contract.morphable?.client?.profile.city?.name ?? 'N/A',
					'{address}': this.contract.morphable?.info.address.address,
					'{district}': this.contract.morphable?.info.address.district.name,
					'{company_name}': this.contract.morphable?.company.name,
					'{company_rut}': this.$options.filters.formatRut(this.contract.morphable?.company.rut),
					'{company_responsible}': `${this.contract.morphable?.company.user_responsible.profile.name} ${this.contract.morphable?.company.user_responsible.profile.lastname}`,
					'{company_responsible_rut}': this.$options.filters.formatRut(this.contract.morphable?.company.user_responsible.profile.rut),
					'{company_address}': this.contract.morphable?.company.address,
					'{client_company_name}': this.contract.morphable?.client?.company_profile?.name ?? 'N/A',
					'{client_company_dni}': this.$options.filters.formatRut(this.contract.morphable?.client?.company_profile?.rut ?? ''),
					'{client_company_address}': this.contract.morphable?.client?.company_profile?.address ?? 'N/A',
					'{client_company_district}': this.contract.morphable?.client?.company_profile?.city?.name ?? 'N/A',
					'{client_rep_name}': `${this.contract.morphable?.client?.company_profile?.rep_name} ${this.contract.morphable?.client?.company_profile?.rep_first_lastname} ${this.contract.morphable?.client?.company_profile?.rep_second_lastname}`,
					'{client_rep_dni}': this.$options.filters.formatRut(this.contract.morphable?.client?.company_profile?.rep_rut),
					'{client_rep_address}': this.contract.morphable?.client?.company_profile?.rep_address,
					'{client_rep_district}': this.contract.morphable?.client?.company_profile?.rep_city?.name ?? 'N/A',
				}
				return content
			}
			return null
		}
  },
	watch: {
		selectedCategory(newValue){
			if(newValue){
				this.$store.dispatch('contracts/getCompanyContractsConfig', {category_id: this.selectedCategory.id, start: 0, limit: 100})
			}
		},
		selectedContent(newValue){
			this.newContent.title = newValue ? newValue.title:null
			this.newContent.content = newValue ? newValue.content:''
			this.newContent.company_contract_section_id = newValue ? newValue.id:null
		},
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
				this.$store.dispatch('contracts/getContractTemplates', {start: 0, limit: 100})
				this.$store.dispatch('contracts/getContractTitles', {start: 0, limit: 100})
			}
		},
		menuTemplate(){
			this.$nextTick(() => {
				this.showAddNewTemplate = false
			})
		},
		queryRoute(){
			const contract_id = this.$route.query.id
			if(this.contract && this.contract.id != contract_id){
				this.contract = null
				this.getContractInfo(true)
			}
		},
		'$route.hash': function () {
			this.scrollToSection();
		}
	},
	mounted(){
		this.scrollToSection()
		if(this.selectedCompany){
			this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
			this.$store.dispatch('contracts/getContractTemplates', {start: 0, limit: 100})
      this.$store.dispatch('contracts/getContractTitles', {start: 0, limit: 100})
			this.$store.dispatch('projects/getAllZoneIpts')
		}
		this.getContractInfo(true)
    eventBus.$on('reloadContract', (data) => {
			if(this.contract && data.contract_id == this.contract.id){
				this.contractPreviewing = false
				this.loadingContractPreviewing = false
				this.getContractInfo()
				if(this.menuComment && data.contract_content_id){
					this.markAsReadNotification(data.contract_content_id)
				}
			}
    })
	},
  methods: {
		isCompanyFirstSection(item) {
			const billingInfo = this.contract?.morphable?.client?.company_profile;
			if (!billingInfo && item.content.includes('client_company_name')) {
				return false;
			}
			const billingInfoType = billingInfo?.type;

			if (this.selectedCategory.section_type !== 1) {
				return true;
			}
			const isClientCompanyNameIncluded = item.content.includes('client_company_name');
			if (billingInfoType === 2) {
				return isClientCompanyNameIncluded;
			}
			if (billingInfoType === 1) {
				return !isClientCompanyNameIncluded;
			}
			return true;
		},
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
		contractStatusColor(contractStatus){
			return this.$options.filters.contractStatusColor(contractStatus)
		},
		openCommentSection(section_content_id){
			this.menuComment = section_content_id
			this.$nextTick(() => {
        const buttonRefHover = this.$refs[`commentActivatorButtonHover_${section_content_id}`]
				let buttonR = buttonRefHover
        if (buttonR) {
					let buttonEl = Array.isArray(buttonR) ? buttonR[0] : buttonR
					const style = window.getComputedStyle(buttonEl.$el)
					
					if (style.display === 'none' || style.visibility === 'hidden' || style.opacity === '0') {
						const buttonRef = this.$refs[`commentActivatorButton_${section_content_id}`]
						buttonR = buttonRef
						buttonEl = Array.isArray(buttonR) ? buttonR[0] : buttonR
					}

					const rect = buttonEl.$el.getBoundingClientRect()

					const cardWidth = 400;
					const cardHeight = 500;
					let top = rect.bottom + window.scrollY + 5;
					let left = rect.left + window.scrollX;

					if (left + cardWidth > window.innerWidth) {
						left = window.innerWidth - cardWidth - 10;
					}
					
					if (top + cardHeight > window.innerHeight + window.scrollY) {
						top = window.innerHeight + window.scrollY - cardHeight - 10;
					}
					this.commentPosition = {
						top,
						left,
						width: rect.width
					}
        }
				if(this.$refs.scrollListComment && this.$refs.scrollListComment.length > 0){
					const container = this.$refs.scrollListComment[0]?.$el
					if(container){
						container.scrollTop = container.scrollHeight
					}
				}
			})
			this.markAsReadNotification(section_content_id)
		},
		markAsReadNotification(section_content_id){
			this.markAsReadRTDBNotifications(section_content_id)

			const ids = this.$store.state.notifications.contractNotifications
        .filter((item) => {
					return !item.readed && item.data?.contract_content_id == section_content_id
				})
        .map(item => item.id)
			ids.forEach(notification_id => {
				this.markNotificationAsRead(notification_id)
			});
		},
		markAsReadRTDBNotifications(section_content_id){
			const keys_to_remove = this.getContractKeysByContentId(section_content_id)
      eventBus.$emit('deleteRTDBNotifications', { type: 'contracts', keys_to_remove});
		},
		async markNotificationAsRead(notification_id){
				const tasks = await markAsReaded({id: notification_id})
				if(tasks.code == 200){
					this.$store.dispatch('notifications/getNotifications', {take: 10, types: [4]})
				}
		},
		countContractsByContentId(contractContentId) {
			const obj = this.$store.state.notifications.rtdbNotifications
			if (!obj.contracts) return 0;

			return Object.values(obj.contracts).reduce((count, contract) => {
				return contract.data?.contract_content_id == contractContentId ? count + 1 : count;
			}, 0);
		},
		getContractKeysByContentId(contractContentId) {
			const obj = this.$store.state.notifications.rtdbNotifications
			if (!obj.contracts) return [];

			return Object.keys(obj.contracts).filter(key => 
					obj.contracts[key].data?.contract_content_id == contractContentId
			);
		},
		openContractPDF(){
			this.$store.commit('contracts/setContractPdfModal', this.contract)
		},
		previewContract(){
			if(this.contractPreviewing){
				this.contractPreviewing = false
				return
			}
			this.loadingContractPreviewing = true
			setTimeout(() => {
				this.contractPreviewing = !this.contractPreviewing
				this.loadingContractPreviewing = false
			}, 100)
		},
    scrollToSection() {
      this.$nextTick(() => {
        if (this.$route.hash) {
					this.contractPreviewing = false
					this.$nextTick(() => {
						const section = document.querySelector(this.$route.hash)
						const scrollContainer = this.$refs.colContractContainer
						window.history.replaceState(null, null, window.location.pathname + window.location.search)

						if (section && scrollContainer) {
							const offset = 100
							const sectionTop = section.offsetTop - scrollContainer.offsetTop
							scrollContainer.scrollTo({
								top: sectionTop - offset,
								behavior: 'smooth'
							})
							setTimeout(() => {
								this.checkForHash()
								this.$router.replace({  query: {id: this.contract.id | this.$route.query.id} })
							}, 500)
						}
					})
        }
      });
    },
		checkForHash(){
			if (this.$route.hash.startsWith('#section_content_id_')) {
				const section_content_id = this.$route.hash.replace('#section_content_id_', '');
				if (section_content_id) {
					this.openCommentSection(section_content_id);
				}
			}
		},
		async sendEmail(){
			if(this.contract.pdf){
				try {
					await sendContractPdfEmail({contract_id: this.contract.id})
					this.getContractInfo()
					const messageData = {
						message: 'Se ha enviado correctamente el correo al cliente',
						title: 'Correo enviado',
						type: 'success',
						icon: 'mdi-check-circle',
						color: '#3EBFA3',
					}
					this.setMessage(messageData)
				} catch (error) {
					const messageData = {
						message: 'Hubo un error en la operación, por favor intenta nuevamente',
						title: 'Ha ocurrido un error',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#FF1209',
					}
					this.setMessage(messageData)
				}
			}
		},
		async downloadPDF(){
			this.loadingPdf = true
			const pdf = await createContractPDF({contract_id: this.contract.id})
			if(pdf.code == 200){
				window.open(pdf.url);
			}
			this.loadingPdf = false
		},
		async deleteComment(comment){
			await deleteContractContentComment({contract_content_comment_id: comment.id})
			this.getContractInfo()
			this.sectionComment = null
		},
		closeCommentForm(){
			this.menuComment = null
		},
		setNewCommentBeforeInsert(data) {
			const newContract = JSON.parse(JSON.stringify(this.contract));

			for (const section of newContract.sections) {
				for (const content of section.contents) {
					if (content.id == data.contract_content_id) {
						if(!content.observation){
							content.observation = {contract_content_id: data.contract_content_id}
						}
						if (!Array.isArray(content.observation.comments)) {
							this.$set(content.observation, 'comments', []);
						}

						content.observation.comments.push({
							user: this.userInfo,
							comment: data.comment,
							side: data.side
						});
					}
				}
			}
			this.$set(this, 'contract', newContract);
			this.$nextTick(() => {
				if(this.$refs.scrollListComment && this.$refs.scrollListComment.length > 0){
					const container = this.$refs.scrollListComment[0]?.$el
					if(container){
						container.scrollTop = container.scrollHeight
					}
				}
			})
		},
		async saveComment(){
			if(!this.sectionComment || this.sectionComment.length == 0 || this.sectionComment == '') return
			this.loadingComment = false
			const data = {contract_id: this.contract.id, contract_content_id: this.menuComment, comment: this.sectionComment, side: 2}
			this.setNewCommentBeforeInsert(data)
			this.sectionComment = null
			await createContractContentComment(data)
			this.getContractInfo()
			this.loadingComment = false
		},
		async deleteTitle(contract_title_id){
      await deleteContractTitle({contract_title_id})
      this.$store.dispatch('contracts/getContractTitles', {start: 0, limit: 100})
    },
    onChangeTitle(title){
      this.newSection.title = title.title
    },
    async saveTitle(){
      await createContractTitle({contract_title: this.contractTitle})
      this.closeTitleMenu()
      this.$store.dispatch('contracts/getContractTitles', {start: 0, limit: 100})
    },
    closeTitleMenu(){
      this.menuAddTitle = false
      this.contractTitle = null
    },
		handleGoTo(association){
			if(association.morphable_type == 'App\\Models\\PreProject'){
				this.$router.push({ name: 'PreProject', params: { id: association.morphable_id } })
			}
			else if(association.morphable_type == 'App\\Models\\BotConversation'){
				this.$router.push({ name: 'Conversation', params: { id: association.morphable_id } })
			}
		},
		async deleteTemplate(template){
			await deleteContractTemplate({contract_template_id: template.id})
			this.$store.dispatch('contracts/getContractTemplates', {start: 0, limit: 100})
		},
		closeTemplateMenu(){
			this.menuTemplate = false
			this.templateName = null
		},
		transformPlaceholdersToFriendlyView(content) {
			let result = content;
			for (const [placeholder, label] of Object.entries(this.placeholderMapping)) {
				const regex = new RegExp(placeholder, 'g');
				result = result.replace(regex, `<span class="friendly-placeholder" style="font-size: 12px;padding: 1px 6px;border: 1px solid grey;border-radius: 5px;background: white;color:orange;" contenteditable="false">${label}</span>`);
			}
			return result;
		},
		transformPlaceholdersToContentView(content) {
			let result = content;
			for (const [placeholder, label] of Object.entries(this.contractVariablesValue)) {
				const regex = new RegExp(placeholder, 'g');
				result = result.replace(regex, `<span style="font-weight:bold;text-transform: uppercase;">${label}</span>`);
			}
			return result;
		},
		async getContractInfo(showLoader = false){
			if(this.$route.query.id){
				if(showLoader) this.loading = true
				const contract = await getContract({contract_id: this.$route.query.id})
				if(contract.code == 200){
					// this.contract = null

					setTimeout(() => {
						this.scrollToSection()
					}, 500)
					this.$nextTick(() => {
						this.contract = contract.contract
						if(this.contract.contract_info && this.contract.contract_info){
							this.contractValue = this.contract.contract_info.contract_value
							this.contractPaymentRequired = this.contract.contract_info.payment_required
						}
						this.$nextTick(() => {
							if(this.$refs.scrollListComment && this.$refs.scrollListComment.length > 0){
								const container = this.$refs.scrollListComment[0]?.$el
								if(container){
									container.scrollTop = container.scrollHeight
								}
							}
						})
					})
				}
				this.loading = false
			}
		},
		onChangeCategory(category){
			this.newSection.title = category ? category.title:null
			this.newSection.section_type = category ? category.section_type:2
			this.newSection.company_planning_cat_id = category ? category.id:null
		},
		sectionIndex(index){
			return '' +  this.$store.state.general.romanNumerals[index] + '. ' + this.$store.state.general.ordinalsNumbers[index] + ':'
		},
		closeNewSection(){
			this.newSection = {
				contract_id: null,
				section_type: 2,
				title: null,
				order: 1
			}
			this.addingSection = false
		},
		addNewSection(){
			this.selectedCategory = null
			this.selectedContent = null
			this.newSection = {
				contract_id: this.contract.id,
				title: null,
				order: this.contract.sections.length + 1
			}
			this.addingSection = true
		},
		editNewSection(section){
			this.newSection = {
				title: section.title,
				contract_section_id: section.id
			}
		},
		async confirmUpdateSection(){
			await updateContractSectionProject(this.newSection)
			this.getContractInfo()
			this.closeNewSection()
		},
		closeNewContent(){
			this.newContent = {
				contract_section_id: null,
				title: null,
				order: 1,
				content: null,
				show_title: false
			}
			this.addingContent = false
		},
		addNewContent(section){
			this.selectedCategory = null
			this.selectedContent = null
			if(section.company_planning_cat_id){
				this.selectedCategory = {id: section.company_planning_cat_id, title: section.title}
				this.blockCategorySelect = true
			}
			const subsections_count = section.contents.length + 1
			this.newContent = {
				title: null,
				contract_section_id: section.id,
				order: subsections_count,
				content: null,
				show_title: false
			}
			this.addingContent = section.id
		},
		editNewContent(content){
			this.newContent = {
				title: content.title,
				content: content.content,
				contract_section_content_id: content.id,
				show_title: content.show_title
			}
		},
		async confirmSection(){
			await createContractSectionProject(this.newSection)
			this.getContractInfo()
			this.closeNewSection()
		},
		async deleteSection(section){
			const data = {contract_section_id: section.id}
			await deleteContractSectionProject(data)
			this.getContractInfo()
		},
		async confirmContent(){
			await createContractSectionContent(this.newContent)
			this.getContractInfo()
			this.closeNewContent()
		},
		async confirmUpdateContent(){
			await updateContractSectionContent(this.newContent)
			this.getContractInfo()
			this.closeNewContent()
		},
		async deleteContent(section_content){
			const data = {contract_section_content_id: section_content.id}
			await deleteContractSectionContent(data)
			this.getContractInfo()
		},
		async moveContentToSection(section_content, from_index, to_index){
			const new_section = this.contract.sections[to_index]
			const old_section = this.contract.sections[from_index]

			let new_contents = new_section.contents.map((sections,index ) => {
					return {
						id: sections.id,
						order: index + 1,
						contract_section_id: new_section.id,
					}
				})
			new_contents = [
				...new_contents,
				...[{
					id: section_content.id,
					order: new_contents.length + 1,
					contract_section_id: new_section.id,
				}]
			]
			let old_contents = old_section.contents.map((sections,index ) => {
				if(section_content.id != sections.id){
					return {
						id: sections.id,
						order: index + 1,
						contract_section_id: old_section.id,
					}
				}
			})
			.filter(Boolean)
			const contents = [...new_contents, ...old_contents]
			const contents_r = await updateContractSectionContentOrders({contents});
			if(contents_r.code == 200){
				this.getContractInfo()
			}
		},
		async copyContentToSection(section_content, to_index){
			const new_section = this.contract.sections[to_index]

			const newContent = {
				title: section_content.title,
				contract_section_id: new_section.id,
				order: new_section.contents ? new_section.contents.length + 1:1,
				content: section_content.content,
				show_title: section_content.show_title
			}

			const contents_r = await createContractSectionContent(newContent)
			if(contents_r.code == 200){
				this.getContractInfo()
			}
		},
		startEndDragEventCategory: _debounce(function(ev){ this.endDragEventCategory(ev) }, 800),
		async endDragEventCategory(ev){
			if (typeof ev.newIndex === 'number') {
				const sections = this.contract.sections.map((sections, index) => {
					return {
						id: sections.id,
						order: index + 1
					}
				})
				const sections_r = await updateContractSectionProjectOrders({sections});
				if(sections_r.code == 200){
					this.getContractInfo()
				}
			}
		},
		startEndDragEventSection: _debounce(function(ev){ this.endDragEventSection(ev) }, 800),
		async endDragEventSection(ev){
			if (typeof ev.newIndex === 'number') {
				const new_section_index = ev.to.id.replace('contracts_sections_', '')
				const new_section = this.contract.sections[new_section_index]
				const old_section_index = ev.from.id.replace('contracts_sections_', '')
				const old_section = this.contract.sections[old_section_index]

				const new_contents = new_section.contents.map((sections,index ) => {
					return {
						id: sections.id,
						order: index + 1,
						contract_section_id: new_section.id,
					}
				})
				const old_contents = old_section.contents.map((sections,index ) => {
					return {
						id: sections.id,
						order: index + 1,
						contract_section_id: old_section.id,
					}
				})
				const contents = [...new_contents, ...old_contents]
				const contents_r = await updateContractSectionContentOrders({contents});
				if(contents_r.code == 200){
					this.getContractInfo()
				}
			}
		},
    onMove(event) {
      if (event.draggedContext.element.section_type == 1 || event.relatedContext.element.section_type == 1) {
        return false;
      }
      return true;
    },
		startEndDragNewCategory: _debounce(function(ev){ this.endDragNewCategory(ev) }, 800),
		async endDragNewCategory(ev){
			const section = this.$store.state.contracts.companyContractCategories[ev.oldIndex]
			const newSection = {
				contract_id: this.contract.id,
				title: section.title,
				section_type: section.section_type,
				order: ev.newIndex + 1,
				company_planning_cat_id: section.id
			}
			await createContractSectionProject(newSection)
			this.getContractInfo()
		},
		startEndDragNewContent: _debounce(function(ev){ this.endDragNewContent(ev) }, 800),
		async endDragNewContent(ev){
			const section_index = ev.to.id.replace('contracts_sections_', '')
			const section = this.contract.sections[section_index]
			const content = this.$store.state.contracts.companyContracts[ev.oldIndex]
			const newContent = {
				title: content.title,
				content: content.content,
				contract_section_id: section.id,
				company_contract_section_id: content.id,
				order: ev.newIndex + 1
			}
			await createContractSectionContent(newContent)
			this.getContractInfo()
		},
		onClickCategory(data){
			this.selectedCategory = data
		},
		async onChangeTemplate(template){
			this.loading = true
			this.menuTemplate = false
			this.menuTemplates = false
			await setTemplateToContract({contract_id: this.contract.id, contract_template_id: template.id})
			this.getContractInfo()
			this.loading = false
		},
		async saveTemplate(){
			const data = {
				contract_id: this.contract.id,
				title: this.templateName
			}
			await createContractTemplate(data)
			this.$store.dispatch('contracts/getContractTemplates', {start: 0, limit: 100})
			this.closeTemplateMenu()
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
		reverseContentViewToPlaceholders(content) {
			let result = content;
			result = result.replace(/<span id="(.*?)"[^>]*>(.*?)<\/span>/g, (match, placeholder) => {
					return `${placeholder}`;
			})
			return result;
		},
		async contractToPdf(){
			const contentEditorRef = this.$store.state.contracts.contractEditorRef
			const data = {
				contract_id: this.contract.id,
				contents: JSON.stringify( contentEditorRef.pages.map(page => this.reverseContentViewToPlaceholders(page.prev_innerHTML) ) )
			}
			const pdf = await createContractPdfFromPreview(data)
			if(pdf.code == 200){
				this.getContractInfo()
				const contract = {...this.contract, ...{pdf: pdf.contract_pdf}}
				this.$store.commit('contracts/setContractPdfModal', contract)
			}
		},
		async companyRepSign(){
			this.$store.commit('contracts/setContractSignModal', {...this.contract, ...{companySign: true}})
		},
		async changeIptZone(zone_id){
			const data = {
				project_id: this.contract?.morphable?.id,
				zone_id
			}
			const project_info = await updateProjectInfo(data)
			if(project_info.code == 200){
				this.getContractInfo()
			}
		},
		debouncedPersistPaymentRequired: _debounce(function () { this.persistPaymentRequired() }, 800),
		async changePaymentRequired(){
			this.contractPaymentRequired = !this.contractPaymentRequired
			this.contractValueMenu = false
			this.debouncedPersistPaymentRequired()
		},
		async persistPaymentRequired(){
			const data = {
				contract_id: this.contract.id,
				payment_required: this.contractPaymentRequired
			}
			updateContract(data)
		},
		async sendPaymentRequestEmail(){
			try {
				this.contractValueMenu = false
				await sendContractPaymentRequest({contract_id: this.contract.id, contract_value: this.contractValue})
				this.getContractInfo()
				const messageData = {
					message: 'Se ha enviado correctamente el correo al cliente',
					title: 'Correo enviado',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			} catch (error) {
				const messageData = {
					message: 'Hubo un error en la operación, por favor intenta nuevamente',
					title: 'Ha ocurrido un error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		}
  },
};
</script>

<style scoped>
.contract-container{
	background-color: white;
	text-transform: none;
	/* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}
.section-container{
	border: 1px solid  #d3d3d3;
}
.hover-section-content{
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.9);
	border: 1px solid #d3d3d3;
}
.section-content-container{
	position: relative;
	text-transform: none;
}
/deep/ .section-content-container p,
/deep/ .section-content-container div,
/deep/ .section-content-container ul,
/deep/ .section-content-container span,
/deep/ .section-content-container{
	text-transform: none;
}
/deep/ .section-content-container img{
	max-width: 100%;
	object-fit: contain;
}
.custom-list-drag{
	background: white;
	border: 1px solid #d3d3d3;
	cursor: pointer;
}
.custom-list-drag.selected{
	background: #d3d3d3;
}
.draggable-contents{
	max-height: 800px;
	overflow: scroll;
}
.action-container{
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.client-column .action-container{
	width: 60px;
}
.add-comment-icon{
	position: absolute;
	bottom: 0;
	right: 0;
	margin: auto;
	background: white;
	z-index: 9999;
}
.card-comment-container{
	position: absolute;
	top: 0;
	right: 60px;
	background: white;
	z-index: 9999;
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

.pulse-animation {
  animation: pulse 1.5s infinite;
}
/deep/ .section-content p{
	margin-bottom: 0;
}
</style>