const endpoints = {
	createCostCategory: '/cost/createCostCategory',
	createCostSubcategory: '/cost/createCostSubcategory',
	createCostItem: '/cost/createCostItem',
	getCostCategories: '/cost/getCostCategories',
	getCostCategory: '/cost/getCostCategory',
	saveAllCostCategory: '/cost/saveAllCostCategory',
	deleteCostCategory: '/cost/deleteCostCategory',
	deleteCostSubcategory: '/cost/deleteCostSubcategory',
	deleteCostItem: '/cost/deleteCostItem',

	//Config
	getCostCategoriesCompany: '/cost/config/getCostCategories',
	createCostCategoryCompany: '/cost/config/createCostCategory',
	updateCostCategory: '/cost/config/updateCostCategory',
	deleteCostCategoryCompany: '/cost/config/deleteCostCategory',
	createCostSubCategoryCompany: '/cost/config/createCostSubcategory',
	updateCostSubCategory: '/cost/config/updateCostSubcategory',
	deleteCostSubCategoryCompany: '/cost/config/deleteCostSubcategory',
	createCostItemCompany: '/cost/config/createCostItem',
	updateCostItem: '/cost/config/updateCostItem',
	deleteCostItemCompany: '/cost/config/deleteCostItem',
	deleteCostCenter: '/cost/config/deleteCostCenter',
	createCostCenter: '/cost/config/createCostCenter',
	updateCostCenter: '/cost/config/updateCostCenter',
	getCompanyCostTemplates: '/cost/config/getCompanyCostTemplates',
	createCostTemplate: '/cost/config/createCostTemplate',
	deleteCostTemplate: '/cost/config/deleteCostTemplate',
}
export {endpoints}