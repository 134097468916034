<template>
  <v-container fluid>
		<v-row v-if="loading">
			<v-col col="12">
				
			</v-col>
		</v-row>
		<div v-else>
			<div>
				<div class="d-flex align-center justify-space-between">
					<p class="caption mb-0">Valor UF: <b>${{ ufValue | toThousandFilter }}</b> <small>(al día de hoy)</small></p>
					<div class="d-flex justify-end">
						<!-- <v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="green"
									large
									icon
									v-bind="attrs"
									v-on="on"
									@click="saveAsTemplate"
								>
									<v-icon large>mdi-plus</v-icon>
								</v-btn>
							</template>
							<span>Guardar Como Plantilla</span>
						</v-tooltip> -->
						<div>
							<div class="d-flex align-center">
								<v-btn
									depressed
									color="green"
									small
									outlined
									@click="addCategory"
								>
									<v-icon>mdi-plus</v-icon>
									<p class="mb-0">Agregar Proyecto a Tabla de Costo</p>
								</v-btn>
								<v-divider vertical class="mx-2"></v-divider>
								<div>
									<v-menu
										ref="menu"
										v-model="menuTemplates"
										transition="scale-transition"
										offset-y
										left
										nudge-top="-10"
										eager
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="#524D4D"
												outlined
												dark
												small
												class="flex-grow-1"
												v-bind="attrs"
												v-on="on"
											>
												<div class="d-flex align-center justify-space-between py-2 w-100">
													<p class="mb-0 mr-2">Seleccione Plantilla</p>
													<v-icon>mdi-chevron-down</v-icon>
												</div>
											</v-btn>
										</template>
										<div class="white d-flex flex-column">
											<div>
												<v-list class="template-list white" max-height="300px" dense>
													<v-list-item
														class="template-list-item white"
														v-for="(template, i) in $store.state.costbreakdown.companiesCostTemplates" :key="'t-' + i"
														@click="onChangeTemplate(template)">
														<v-list-item-content>
															<v-list-item-title>{{template.name}}</v-list-item-title>
														</v-list-item-content>
														<v-list-item-action>
															<div class="d-flex align-center">
																<v-tooltip bottom>
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn
																			fab
																			dark
																			x-small
																			icon
																			color="error"
																			@click="removeCostTemplate(template.id)"
																			v-bind="attrs"
																			v-on="on"
																		>
																			<v-icon small dark>mdi-delete-outline</v-icon>
																		</v-btn>
																	</template>
																	<span>Eliminar</span>
																</v-tooltip>
															</div>
														</v-list-item-action>
													</v-list-item>
												</v-list>
											</div>
										</div>
									</v-menu>
									<v-menu
										ref="menuNewTemplate"
										v-model="menuNewTemplate"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
										left
										nudge-top="-10"
										eager
									>
										<template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
											<v-tooltip bottom>
												<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
													<v-btn
														small
														icon
														class="ml-2"
														color="green"
														v-bind="{ ...menuAttrs, ...tooltipAttrs }"
														v-on="{ ...menuOn, ...tooltipOn }"
													>
														<v-icon dark>mdi-plus</v-icon>
													</v-btn>
												</template>
												<span>Guardar Configuración Como Plantilla</span>
											</v-tooltip>
										</template>
										<div class="white d-flex flex-column">
											<div class="pa-2">
												<p class="caption mb-1 font-weight-bold">Guardar Configuración Como Plantilla</p>
												<v-text-field
													label="Nombre de la Plantilla"
													v-model="templateName"
													class="custom-height-search custom-height-calendar mb-2"
													hide-details
													dense
													outlined
													:rules="requiredRule"
												></v-text-field>
												<v-btn
													depressed
													color="green"
													:dark="templateName"
													small
													block
													:disabled="!templateName"
													@click="saveAsTemplate"
												>
													<v-icon small>mdi-check</v-icon>
													<p class="mb-0 ml-2 caption">Confirmar</p>
												</v-btn>
												<v-btn
													depressed
													color="#959595"
													text
													x-small
													block
													class="mt-2"
													@click="menuNewTemplate = false"
												>
													<p class="mb-0">Cancelar</p>
												</v-btn>
											</div>
										</div>
									</v-menu>
								</div>
							</div>
						</div>
						<v-divider vertical class="mx-2"></v-divider>
						<CostBreakdownSettings />
					</div>
				</div>
				<v-divider class="my-2 mb-4"></v-divider>
			</div>
			<v-row v-if="cost_categories.length == 0">
				<v-col col="12">
					<div class="d-flex  flex-column align-center">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="green"
									large
									icon
									v-bind="attrs"
									v-on="on"
									@click="addCategory"
								>
									<v-icon large>mdi-plus</v-icon>
								</v-btn>
							</template>
							<span>Agregar Proyecto</span>
						</v-tooltip>
						<p class="mb-0 caption font-weight-bold mr-2">No se ha creado nada en esta tabla de costo, crea tu primera categoría con el botón +</p>
					</div>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12">
					<v-form ref="form" v-model="validform">
						<!-- <v-row>
							<v-col cols="2">
								<p class="font-weight-bold caption mb-0">Subcategoría</p>
							</v-col>
							<v-col cols="3">
								<p class="font-weight-bold caption mb-0">Detalle</p>
							</v-col>
							<v-col cols="1">
								<p class="font-weight-bold caption mb-0">Valor Unitario (UF)</p>
							</v-col>
							<v-col cols="1">
								<p class="font-weight-bold caption mb-0">Cantidad</p>
							</v-col>
							<v-col cols="2">
								<p class="font-weight-bold caption mb-0 text-center">Total UF</p>
							</v-col>
							<v-col cols="2">
								<p class="font-weight-bold caption mb-0 text-center">Total Pesos</p>
							</v-col>
							<v-col cols="1">
							</v-col>
						</v-row>
						<v-divider class="mb-2 mt-4"></v-divider> -->
						<div v-for="(category, i1) in cost_categories" :key="i1">
							<div class="d-flex align-center pt-1 pb-0 mb-0">
								<table width="100%" v-if="category.specialty">
									<tr width="100%">
										<td width="91%"><p class="mb-0 caption font-weight-bold">Especialidades</p></td>
										<td width="9%"></td>
									</tr>
								</table>
								<table width="100%" v-else>
									<tr width="100%">
										<td width="91%">
											<v-select
												v-model="category.company_cost_category_id"
												:items="companyCostCategories"
												:loading="$store.state.costbreakdown.loadingCompaniesCostCategories"
												:menu-props="{ top: true, offsetY: true }"
												item-text="name"
												item-value="id"
												label="Proyecto"
												class="custom-height-search custom-height-calendar"
												outlined
												dense
												style="max-width: 1000px;"
												required
												@input="updateCategory(i1)"
											></v-select>
										</td>
										<td width="9%">
											<div class="d-flex justify-center align-center">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="#FF120B"
															small
															icon
															v-bind="attrs"
															v-on="on"
															@click="deleteCategory(category.id, i1)"
														>
															<v-icon small>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Proyecto</span>
												</v-tooltip>
											</div>
										</td>
									</tr>
								</table>
							</div>
							<div v-if="!category.items || (category.items && category.items.length == 0)" class="d-flex justify-center">
								<v-btn
									depressed
									color="#524D4D"
									small
									outlined
									@click="addSubcategory(i1)"
								>
									<v-icon>mdi-plus</v-icon>
									<p class="mb-0 ml-2 caption">Agregar Detalle</p>
								</v-btn>
							</div>
							<div class="my-2" v-for="(subcategory, i2) in category.items" :key="i2">
								<table class="costbreakdown-detail-table" v-if="(category.specialty && subcategory.specialties.length == 0) && (!category.specialty && subcategory.items.length == 0)">
									<tr>
										<td width="20%">
											<div v-if="category.specialty">
												<p class="mb-0 caption">{{ subcategory.detail }}</p>
											</div>
											<div class="d-flex align-center" v-else>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="#FF120B"
															small
															icon
															class="mr-2"
															v-bind="attrs"
															v-on="on"
															@click="deleteSubcategory(subcategory.id, i1, i2)"
														>
															<v-icon small>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Detalle</span>
												</v-tooltip>
												<v-select
													v-model="subcategory.company_cost_subcategory_id"
													:items="companyCostSubCategories(category)"
													:loading="$store.state.costbreakdown.loadingCompaniesCostCategories"
													:menu-props="{ top: true, offsetY: true }"
													item-text="name"
													item-value="id"
													label="Detalle"
													class="custom-height-search custom-height-calendar"
													outlined
													dense
													style="flex: 1; max-width: 100%; min-width: 0;"
													required
													@input="updateSubcategory(i1, i2)"
												></v-select>
												<div class="d-flex justify-center align-center">
												</div>
											</div>
										</td>
										<td colspan="6"></td>
									</tr>
								</table>
								<table class="costbreakdown-detail-table" v-else-if="showDetail.includes(subcategory.id)">
									<tr>
										<td style="vertical-align: middle;" width="20%"><p class="mb-0" style="font-size: 10px;"></p></td>
										<td style="vertical-align: middle;" width="25%"><p class="mb-0" style="font-size: 10px;"></p></td>
										<td style="vertical-align: middle;" width="10%"><p class="text-center mb-0" style="font-size: 10px;">Valor U. (UF)</p></td>
										<td style="vertical-align: middle;" width="10%"><p class="text-center mb-0" style="font-size: 10px;">Valor U. (CLP)</p></td>
										<td style="vertical-align: middle;" width="6%"><p class="text-center mb-0" style="font-size: 10px;">Cantidad</p></td>
										<td style="vertical-align: middle;" width="10%"><p class="text-center mb-0" style="font-size: 10px;">Total UF</p></td>
										<td style="vertical-align: middle;" width="10%"><p class="text-center mb-0" style="font-size: 10px;">TOTAL CLP</p></td>
										<td width="9%">
											<div class="d-flex justify-center">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															small
															text
															v-bind="attrs"
															v-on="on"
															@click="changeVisualization(subcategory.id, 2)"
														>
															<p class="text-center mb-0" style="font-size: 10px;">Ocultar Detalle</p>
														</v-btn>
													</template>
													<span>Oculta el detalle de esta sección</span>
												</v-tooltip>
											</div>
										</td>
									</tr>
									<tr v-for="(cost_item, i3) in getSubcategoryItems(subcategory, category)" :key="i3">
										<td width="20%" style="max-width:20%;min-width: 20%;" :rowspan="subcategory.items.length" v-if="i3 == 0">
											<div v-if="category.specialty">
												<p class="mb-0 caption">{{ subcategory.detail }}</p>
											</div>
											<div class="d-flex align-center" v-else>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="#FF120B"
															small
															icon
															class="mr-2"
															v-bind="attrs"
															v-on="on"
															@click="deleteSubcategory(subcategory.id, i1, i2)"
														>
															<v-icon small>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Detalle</span>
												</v-tooltip>
												<v-select
													v-model="subcategory.company_cost_subcategory_id"
													:items="companyCostSubCategories(category)"
													:loading="$store.state.costbreakdown.loadingCompaniesCostCategories"
													:menu-props="{ top: true, offsetY: true }"
													item-text="name"
													item-value="id"
													label="Detalle"
													class="custom-height-search custom-height-calendar"
													outlined
													dense
													style="flex: 1; max-width: 100%; min-width: 0;"
													required
													@input="updateSubcategory(i1, i2)"
												></v-select>
											</div>
										</td>
										<td width="25%">
											<v-select
												v-if="category.specialty"
												v-model="cost_item.company_specialty_id"
												:items="companySpecialties"
												:loading="$store.state.costbreakdown.loadingCompaniesSpecialties"
												:menu-props="{ top: true, offsetY: true }"
												item-text="name"
												item-value="id"
												label="Especialidad"
												class="custom-height-search custom-height-calendar"
												outlined
												dense
												style="flex: 1; max-width: 100%; min-width: 0;"
												required
												@input="(select) => handleCostItemChange(i1, i2, i3, select, companyCostItem(category, subcategory))"
											></v-select>
											<v-select
												v-else
												v-model="cost_item.company_cost_item_id"
												:items="companyCostItem(category, subcategory)"
												:loading="$store.state.costbreakdown.loadingCompaniesCostCategories"
												:menu-props="{ top: true, offsetY: true }"
												item-text="concept"
												item-value="id"
												label="Concepto"
												class="custom-height-search custom-height-calendar"
												outlined
												dense
												style="flex: 1; max-width: 100%; min-width: 0;"
												required
												@input="(select) => handleCostItemChange(i1, i2, i3, select, companyCostItem(category, subcategory))"
											></v-select>
										</td>
										<td width="10%">
											<v-text-field
												v-model="cost_item.unit_cost"
												placeholder="Valor Unitario (UF)"
												outlined
												required
												dense
												hide-details
												:loading="loadingBudget"
												class="custom-height-search custom-height-calendar"
												@keypress="isNumberKey"
												@input="updateItem(i1, i2, i3)"
												:rules="requiredRule"
											></v-text-field>
										</td>
										<td width="10%">
											<p class="font-weight-bold caption mb-0 text-center">${{ cost_item.unit_cost * ufValue | toThousandFilter }}</p>
										</td>
										<td width="6%">
											<v-text-field
												v-model="cost_item.quantity"
												placeholder="Cantidad"
												outlined
												required
												dense
												hide-details
												:loading="loadingBudget"
												class="custom-height-search custom-height-calendar"
												@keypress="isNumberKey"
												@input="updateItem(i1, i2, i3)"
												:rules="requiredRule"
											></v-text-field>
										</td>
										<td width="10%">
											<p class="font-weight-bold caption mb-0 text-center">{{ cost_item.total_uf | toThousandFilter }}</p>
										</td>
										<td width="10%">
											<p class="font-weight-bold caption mb-0 text-center">${{ cost_item.total_clp | toThousandFilter }}</p>
										</td>
										<td width="9%">
											<div class="d-flex justify-center">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="#FF120B"
															small
															icon
															v-bind="attrs"
															v-on="on"
															@click="deleteItem(cost_item.id, i1, i2, i3)"
														>
															<v-icon small>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Concepto</span>
												</v-tooltip>
											</div>
										</td>
									</tr>
								</table>
								<SubcategorySummaryTable :category="category" :subcategory="subcategory" :changeVisualization="changeVisualization" v-else />
								<div class="d-flex justify-space-between my-4">
									<v-btn
										depressed
										color="#524D4D"
										small
										outlined
										v-if="(i2 + 1) == category.items.length"
										@click="addSubcategory(i1)"
									>
										<v-icon>mdi-plus</v-icon>
										<p class="mb-0 ml-2 caption">Agregar Detalle A Proyecto</p>
									</v-btn>
									<div v-else></div>
									<v-btn
										depressed
										color="#524D4D"
										small
										outlined
										@click="addItem(i1, i2)"
									>
										<v-icon>mdi-plus</v-icon>
										<p class="mb-0 ml-2 caption">Agregar Concepto</p>
									</v-btn>
								</div>
							</div>
							<!-- <div class="my-2" v-for="(subcategory, i2) in category.items" :key="i2">
								<div class="py-4" v-for="(cost_item, i3) in subcategory.items" :key="i3">
									<v-row>
										<v-col cols="2">
											<div v-if="i3 == 0">
												<v-select
													v-model="subcategory.company_cost_subcategory_id"
													:items="companyCostSubCategories(category)"
													:loading="$store.state.costbreakdown.loadingCompaniesCostCategories"
													:menu-props="{ top: true, offsetY: true }"
													item-text="name"
													item-value="id"
													label="Subcategoría"
													class="custom-height-search custom-height-calendar"
													outlined
													dense
													style="max-width: 400px;"
													required
													@input="updateSubcategory(i1, i2)"
												></v-select>
												<div class="d-flex align-center">
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																depressed
																color="#FF120B"
																small
																icon
																v-bind="attrs"
																v-on="on"
																@click="deleteSubcategory(subcategory.id, i1, i2)"
															>
																<v-icon small>mdi-delete-outline</v-icon>
															</v-btn>
														</template>
														<span>Eliminar Subcategoría</span>
													</v-tooltip>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																depressed
																color="green"
																small
																icon
																v-bind="attrs"
																v-on="on"
																@click="addSubcategory(i1)"
															>
																<v-icon>mdi-plus</v-icon>
															</v-btn>
														</template>
														<span>Agregar Subcategoría</span>
													</v-tooltip>
												</div>
											</div>
										</v-col>
										<v-col cols="3">
											<v-select
												v-model="cost_item.company_cost_item_id"
												:items="companyCostItem(category, subcategory)"
												:loading="$store.state.costbreakdown.loadingCompaniesCostCategories"
												:menu-props="{ top: true, offsetY: true }"
												item-text="concept"
												item-value="id"
												label="Concepto"
												class="custom-height-search custom-height-calendar"
												outlined
												dense
												style="max-width: 400px;"
												required
												@input="updateItem(i1, i2, i3)"
											></v-select>
										</v-col>
										<v-col cols="1">
											<v-text-field
												v-model="cost_item.unit_cost"
												label="Valor Unitario (UF)"
												outlined
												required
												dense
												hide-details
												:loading="loadingBudget"
												class="custom-height-search custom-height-calendar"
												@keypress="isNumberKey"
												@input="updateItem(i1, i2, i3)"
												:rules="requiredRule"
											></v-text-field>
										</v-col>
										<v-col cols="1">
											<v-text-field
												v-model="cost_item.quantity"
												label="Cantidad"
												outlined
												required
												dense
												hide-details
												:loading="loadingBudget"
												class="custom-height-search custom-height-calendar"
												@keypress="isNumberKey"
												@input="updateItem(i1, i2, i3)"
												:rules="requiredRule"
											></v-text-field>
										</v-col>
										<v-col cols="2" align-self="center">
											<p class="font-weight-bold caption mb-0 text-center">{{ cost_item.total_uf | toThousandFilter }}</p>
										</v-col>
										<v-col cols="2" align-self="center">
											<p class="font-weight-bold caption mb-0 text-center">${{ cost_item.total_clp | toThousandFilter }}</p>
										</v-col>
										<v-col cols="1" align-self="center">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														depressed
														color="#FF120B"
														small
														icon
														v-bind="attrs"
														v-on="on"
														@click="deleteItem(cost_item.id, i1, i2, i3)"
													>
														<v-icon small>mdi-delete-outline</v-icon>
													</v-btn>
												</template>
												<span>Eliminar Item de Costo</span>
											</v-tooltip>
										</v-col>
									</v-row>
								</div>
								<div class="d-flex justify-center my-4">
									<v-btn
										depressed
										color="#524D4D"
										small
										outlined
										@click="addItem(i1, i2)"
									>
										<v-icon>mdi-plus</v-icon>
										<p class="mb-0 ml-2 caption">Agregar Item de Costo a Subcategoría</p>
									</v-btn>
								</div>
							</div> -->
							<table width="100%" style="background-color: #d3d3d3;">
								<tr>
									<td width="71%">
										<p class="font-weight-bold caption text-right mb-0">Totales Proyecto {{ category.name }}</p>
									</td>
									<td width="10%">
										<p class="font-weight-bold caption mb-0 text-center">{{ totalUf(category) | toThousandFilter }}</p>
									</td>
									<td width="10%">
										<p class="font-weight-bold caption mb-0 text-center">${{ totalClp(category) | toThousandFilter }}</p>
									</td>
									<td width="9%"></td>
								</tr>
							</table>
						</div>
					</v-form>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
import CostBreakdownSettings from '@/components/Views/Projects/CostBreakdownSettings'
import SubcategorySummaryTable from '@/components/Views/Projects/Parts/SubcategorySummaryTable'
import {getCostCategory, saveAllCostCategory, deleteCostCategory, deleteCostSubcategory, deleteCostItem, createCostTemplate, deleteCostTemplate} from '@/helpers/api/costs'

export default {
	components: {CostBreakdownSettings, SubcategorySummaryTable},
  data() {
    return {
			menuTemplates: false,
			menuNewTemplate: false,
			showAddNewTemplate: false,
			templateName: null,
			validform: true,
			editingCategory: null,
			editingSubcategory: null,
			cost_categories: [],
			showDetail: [],
			loading: true,
      loadingBudget: false,
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			limit: 10,
			start: 0,
    };
  },
	computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		ufValue(){
			return this.$store.state.general.dayUfValue
		},
		companyCostCategories(){
			return this.$store.state.costbreakdown.companiesCostCategories
		},
		companySpecialties(){
			return this.$store.state.costbreakdown.companiesSpecialties
		},
		costGeneralSubCategories(){
			return this.$store.state.costbreakdown.companiesCostGeneralSubCategories
		},
	},
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.getCosts()
				this.$store.dispatch('costbreakdown/getCompanyCostCategories')
				this.$store.dispatch('costbreakdown/getCompanyCostTemplates')
			}
		},
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('costbreakdown/getCompanyCostCategories')
			this.$store.dispatch('costbreakdown/getCompanyCostTemplates')
			this.getCosts()
		}
	},
  methods: {
		getSubcategoryItems(subcategory, category){
			console.log('category', category)
			if(category.specialty){
				console.log('SI', subcategory)
				return subcategory.specialties
			}
			return subcategory.items
		},
		companyCostSubCategories(category){
			let subcategories = this.companyCostCategories.find(cat => cat.id == category.company_cost_category_id)?.subcategories || []
			subcategories = [...subcategories, ...this.costGeneralSubCategories]
			return subcategories
		},
		companyCostItem(category, subcategory){
			if(category.specialty){
				const items = this.companyCostSubCategories(category).find(subcat => subcat.id == subcategory.company_cost_subcategory_id)?.specialties || []
				return items
			}
			const items = this.companyCostSubCategories(category).find(subcat => subcat.id == subcategory.company_cost_subcategory_id)?.items || []
			return items
		},
		async getCosts(){
			const project_id = this.$route.params.id
			const costs = await getCostCategory({project_id})
			if(costs.code == 200){
				this.cost_categories = costs.cost_category
			}
			this.loading = false
		},
		addCategory(){
			const new_category = {
				detail: null,
				order: this.cost_categories.length + 1,
				items: []
			}
			this.cost_categories.push(new_category)
		},
		addSubcategory(category_index){
			const category = this.cost_categories[category_index]
			const new_subcategory = {
				detail: null,
				order: category.items ? category.items.length + 1:0,
				cost_category_id: category.id,
				items: []
			}
			category.items.push(new_subcategory)
		},
		addItem(category_index, subcategory_index){
			const subcategory = this.cost_categories[category_index].items[subcategory_index]
			const new_item = {
				detail: null,
				unit_cost: null,
				quantity: 1,
				total_uf: null,
				total_clp: null,
				order: subcategory.items ? subcategory.items.length + 1:0,
				cost_subcategory_id: subcategory.id
			}
			subcategory.items.push(new_item)
			this.changeVisualization(subcategory.id, 1)
		},
		updateCategory(){
			this.startSaveCost()
		},
		updateSubcategory(){
			this.startSaveCost()
		},
		handleCostItemChange(i1, i2, i3, select, elements){
			const selected = elements.find(el => el.id == select)
			this.updateItem(i1, i2, i3, 'concept', selected)
		},
		updateItem(category_index, subcategory_index, item_index, type = null, parent = null){
			const category = this.cost_categories[category_index]
			const item = category.specialty ? category.items[subcategory_index].specialties[item_index]:category.items[subcategory_index].items[item_index]
			this.$set(item, 'total_uf', item.quantity * item.unit_cost)
			this.$set(item, 'total_clp', item.quantity * item.unit_cost * this.ufValue)
			if(type && type == 'concept' && parent){
				this.$set(item, 'unit_cost', parent.default_value)
			}
			this.startSaveCost()
		},
		startSaveCost: _debounce(function() { this.saveCost() }, 1500),
		async saveCost(){
      if(this.$refs.form.validate()){
				const data = {
					project_id : this.$route.params.id,
					cost_categories: this.cost_categories
				}
				await saveAllCostCategory(data)
				this.getCosts()
			}
		},
		totalUf(category){
			if(category.items && category.items.length > 0){
				const totalUF = category.items.reduce((sum, cat) => {
					const items = category.specialty ? cat.specialties : cat.items
					return sum + items.reduce((subSum, item) => subSum + (item.total_uf || 0), 0);
				}, 0).toFixed(2)
				return totalUF
			}
			return 0
		},
		totalClp(category){
			if(category.items && category.items.length > 0){
				const totalUF = category.items.reduce((sum, cat) => {
					const items = category.specialty ? cat.specialties : cat.items
					return sum + items.reduce((subSum, item) => subSum + (item.total_clp || 0), 0);
				}, 0).toFixed(2)
				return totalUF
			}
			return 0
		},
		async deleteCategory(cost_category_id, index){
			if(!cost_category_id){
				this.cost_categories.splice(index, 1)
				return
			}
			await deleteCostCategory({cost_category_id})
			this.getCosts()
		},
		async deleteSubcategory(cost_subcategory_id, category_index, subcategory_index){
			if(!cost_subcategory_id){
				this.cost_categories[category_index].items.splice(subcategory_index, 1)
				return
			}
			await deleteCostSubcategory({cost_subcategory_id})
			this.getCosts()
		},
		async deleteItem(cost_item_id, category_index, subcategory_index, item_index){
			if(!cost_item_id){
				this.cost_categories[category_index].items[subcategory_index].items.splice(item_index, 1)
				return
			}
			await deleteCostItem({cost_item_id})
			this.getCosts()
		},
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			const input = event.target.value;
			if (charCode >= 48 && charCode <= 57) {
				return;
			}
			if (charCode === 46) {
				if (input.includes('.') || input.length === 0) {
					event.preventDefault();
				}
				return;
			}
			event.preventDefault()
		},
		onChangeTemplate(template){
			this.cost_categories = template.detail
			console.log(template)
		},
		async removeCostTemplate(company_cost_template_id){
			await deleteCostTemplate({company_cost_template_id})
			this.$store.dispatch('costbreakdown/getCompanyCostTemplates')
		},
		async saveAsTemplate(){
			const detail = this.cost_categories.map((category,index1) => {
				return {
					order: index1 + 1,
					company_cost_category_id: category.company_cost_category_id,
					items: category.items.map((subcategory, index2) => {
						let subItems = category.specialty ? subcategory.specialties : subcategory.items
						return {
							order: index2 + 1,
							company_cost_subcategory_id: subcategory.company_cost_subcategory_id,
							items: subItems.map((item, index3) => {
								return {
									order: index3 + 1,
									company_cost_item_id: item.company_cost_item_id
								}
							})
						}
					})
				}
			})
			
			await createCostTemplate({
				name: this.templateName,
				detail
			})
		},
		changeVisualization(item_id, action = 1) {
			if (action == 1) {
				if (!this.showDetail.includes(item_id)) {
					this.showDetail.push(item_id);
				}
			} else {
				this.showDetail = this.showDetail.filter(id => id !== item_id);
			}
		}
  },
};
</script>

<style scoped>
.costbreakdown-detail-table{
	table-layout: fixed;
	width: 100%;
}
/deep/.costbreakdown-detail-table,
/deep/.costbreakdown-detail-table td{
	vertical-align: top;
}
/deep/.costbreakdown-detail-table:hover {
  background-color: rgb(235, 235, 235);
}
</style>