<template>
	<div class="d-flex justify-end align-center" v-if="['PreProject', 'Client', 'ClientDetail'].includes($route.name) && $store.state.preprojects.preProject && !$store.state.preprojects.preProject.deleted_at">
		<div
			v-if="$store.state.preprojects.preProject.public_profile">
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-speed-dial
						v-model="fab"
						direction="bottom"
					>
						<template v-slot:activator>
							<v-btn
								fab
								dark
								x-small
								color="#FF120B"
								class="mx-1"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon small dark>mdi-share-all-outline</v-icon>
							</v-btn>
						</template>

						<div>
							<ShareNetwork
								network="facebook"
								:url='"https://apiarq.insomnia.cl/item-profile/" + $store.state.preprojects.preProject.public_profile.id'
								:title="'Conoce mi proyecto: ' + $store.state.preprojects.preProject.public_profile.title"
								:description="$store.state.preprojects.preProject.public_profile.short_description"
								:quote="$store.state.preprojects.preProject.public_profile.title"
								class="text-decoration-none"
							>
								<v-btn
									class="social-buttonn mx-0"
									style="background-color: #3b5998; color: white;"
									block
								>
									<v-icon>mdi-facebook</v-icon> Facebook
								</v-btn>
							</ShareNetwork>

							<ShareNetwork
								network="whatsapp"
								:url='"https://apiarq.insomnia.cl/item-profile/" + $store.state.preprojects.preProject.public_profile.id'
								:title="'Conoce mi proyecto: ' + $store.state.preprojects.preProject.public_profile.title"
								:description="$store.state.preprojects.preProject.public_profile.short_description"
								class="text-decoration-none"
							>
								<v-btn
									class="social-buttonn mx-0"
									style="background-color: #25D366; color: white;"
									block
								>
									<v-icon>mdi-whatsapp</v-icon> WhatsApp
								</v-btn>
							</ShareNetwork>

							<ShareNetwork
								network="twitter"
								:url='"https://apiarq.insomnia.cl/item-profile/" + $store.state.preprojects.preProject.public_profile.id'
								:title="'Conoce mi proyecto: ' + $store.state.preprojects.preProject.public_profile.title"
								:description="$store.state.preprojects.preProject.public_profile.short_description"
								class="text-decoration-none"
							>
								<v-btn
									class="social-buttonn mx-0"
									style="background-color: #1DA1F2; color: white;"
									block
								>
									<v-icon>mdi-twitter</v-icon> Twitter
								</v-btn>
							</ShareNetwork>

							<ShareNetwork
								network="linkedin"
								:url='"https://apiarq.insomnia.cl/item-profile/" + $store.state.preprojects.preProject.public_profile.id'
								:title="'Conoce mi proyecto: ' + $store.state.preprojects.preProject.public_profile.title"
								:description="$store.state.preprojects.preProject.public_profile.short_description"
								class="text-decoration-none"
							>
								<v-btn
									class="social-buttonn mx-0"
									style="background-color: #0077b5; color: white;"
									block
								>
									<v-icon>mdi-linkedin</v-icon> LinkedIn
								</v-btn>
							</ShareNetwork>

							<ShareNetwork
								network="email"
								:url='"https://apiarq.insomnia.cl/item-profile/" + $store.state.preprojects.preProject.public_profile.id'
								:title="'Conoce mi proyecto: ' + $store.state.preprojects.preProject.public_profile.title"
								:description="$store.state.preprojects.preProject.public_profile.short_description"
								class="text-decoration-none"
							>
								<v-btn
									class="social-button mx-0"
									style="background-color: #D44638; color: white;"
									block
								>
									<v-icon>mdi-email</v-icon> Email
								</v-btn>
							</ShareNetwork>
						</div>
					</v-speed-dial>
				</template>
				<span>Compartir Perfil Público</span>
			</v-tooltip>
		</div>
		<v-tooltip bottom v-if="editPermission">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					fab
					dark
					x-small
					color="#FF120B"
					class="mx-1"
					@click="toggleEditSocialPreproject()"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon small dark>mdi-pencil-box-outline</v-icon>
				</v-btn>
			</template>
			<span>Editar Perfil Público</span>
		</v-tooltip>
		<!-- <v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-badge
					:value="!!totalNewMessages"
					:content="totalNewMessages"
					color="green"
					overlap
				>
					<v-btn
						fab
						dark
						x-small
						color="#FF120B"
						class="mx-1"
						@click="openChatModal"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon small dark>mdi-chat-outline</v-icon>
					</v-btn>
				</v-badge>
			</template>
			<span>Ver Chat</span>
		</v-tooltip> -->
		<v-tooltip bottom v-if="editPermission">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					fab
					dark
					x-small
					color="#FF120B"
					class="mx-1"
					@click="$store.commit('preprojects/setOpenBudgetModal', !$store.state.preprojects.openBudgetModal)"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon small dark>mdi-table-cog</v-icon>
				</v-btn>
			</template>
			<span>Ver Presupuesto</span>
		</v-tooltip>
		<v-tooltip bottom v-if="preProject.project?.contract">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					fab
					dark
					x-small
					color="green"
					class="mx-1"
					@click="$router.push({ name: 'ContractDetail', query: { id: preProject.project?.contract.id } })"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon small dark>mdi-briefcase-eye</v-icon>
				</v-btn>
			</template>
			<span>Ir al Contrato</span>
		</v-tooltip>
		<v-tooltip bottom v-else>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					fab
					dark
					x-small
					color="#FF120B"
					class="mx-1"
					@click="openNewProjectModal"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon small dark>mdi-briefcase-outline</v-icon>
				</v-btn>
			</template>
			<span>Pasar a Contrato</span>
		</v-tooltip>
	</div>
	<!-- <div
		class="d-flex justify-end align-center"
		v-else-if="$checkPermissions(['client_capabilities'])">
		<v-menu
			transition="scale-transition"
			offset-y
			left
			nudge-top="-10"
			eager
		>
			<template #activator="{ on: onMenu }">
				<v-tooltip bottom>
					<template #activator="{ on: onTooltip }">
						<div v-on="onTooltip">
							<v-badge
								:value="!!totalNewMessages"
								:content="totalNewMessages"
								color="green"
								overlap
							>
								<v-btn
									fab
									dark
									x-small
									color="#FF120B"
									class="mx-1"
									v-on="onMenu"
								>
									<v-icon small dark>mdi-chat-outline</v-icon>
								</v-btn>
							</v-badge>
						</div>
					</template>
					<span>Ver Chats</span>
				</v-tooltip>
			</template>
			<v-card scrollable class="pa-0">
				<v-card-text class="pa-0" style="height: 200px; width: 350px; overflow-y: scroll;">
					<v-list dense class="py-0">
						<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
							<p class="caption mb-0 font-weight-bold white--text">Salas de Chat</p>
						</v-list-item>
						<v-list-item-group color="#524D4D">
							<v-list-item
								v-for="(chat, index) in chatsRooms"
								:key="index"
								dense
								@click="openChatModalFromList(chat)"
							>
								<v-list-item-title dense>
									<div class="d-flex align-center">
										<div class="d-flex justify-space-between align-center" style="width: 100%">
											<p class="mb-0">{{ chat.name }}</p>
											<v-icon
												small
												:color="newMessages(chat) ? '#FF120B':'grey lighten-2'"
												>mdi-circle</v-icon>
										</div>
									</div>
								</v-list-item-title>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-card-text>
			</v-card>
		</v-menu>
	</div> -->
</template>

<script>
import {markAsReaded} from '@/helpers/api/notifications'
export default {
	name: 'PreProjectButtonsHeader',
	components: {  },
	computed: {
		preProject(){
			return this.$store.state.preprojects.preProject
		},
		chatsRooms(){
			return this.$store.state.chats.chats
		},
		totalNewMessages() {
			if (this.$store.state.notifications.notifications && this.$store.state.notifications.notifications.length > 0) {
					const notifications = this.$store.state.notifications.notifications.filter(notification => {
							return notification.item_type == 3 && notification.item_id && !notification.readed
					});
					return notifications.length;
			}
			return null;
		},
		notificationChat(){
			if(this.$store.state.notifications.notifications && this.$store.state.notifications.notifications.length > 0){
				const notification = this.$store.state.notifications.notifications.find((notification) => {
					return notification.item_id == this.$store.state.preprojects.preProject.id && notification.item_type == 3 && !notification.readed
				})
				return notification
			}
			return null
		},
		editPermission(){
			return this.$checkPermissions(['edit_preprojects'])
		}
	},
	data: () => ({
		fab: false
	}),
	mounted(){
		this.$store.dispatch('chats/getUserChats')
	},
	methods: {
		newMessages(chat_room){
			if(this.$store.state.notifications.notifications && this.$store.state.notifications.notifications.length > 0){
				const notification = this.$store.state.notifications.notifications.find((notification) => {
					if(notification.item_type == 3 && chat_room.morphable_type == 'App\\Models\\PreProject'){
						return notification.item_id == chat_room.morphable_id && !notification.readed
					}
				})
				return !!notification
			}
			return null
		},
		openChatModalFromList(chat_room){
			if(chat_room.morphable_type == 'App\\Models\\PreProject'){
				this.$router.push({ name: 'ClientDetail', query: { p: chat_room.morphable_id } })
				this.$store.commit('preprojects/setOpenChatModal', true)

				if(this.$store.state.notifications.notifications && this.$store.state.notifications.notifications.length > 0){
					const notification = this.$store.state.notifications.notifications.find((notification) => {
						if(notification.item_type == 3 && chat_room.morphable_type == 'App\\Models\\PreProject'){
							return notification.item_id == chat_room.morphable_id
						}
					})
					if(notification){
						this.markNotificationAsRead(notification.id)
					}
				}
			}
		},
		openChatModal(){
			if(this.notificationChat){
				this.markNotificationAsRead(this.notificationChat.id)
			}
			this.$store.commit('preprojects/setOpenChatModal', !this.$store.state.preprojects.openChatModal)
		},
		async markNotificationAsRead(notification_id){
				const tasks = await markAsReaded({id: notification_id})
				if(tasks.code == 200){
					this.$store.dispatch('notifications/getNotifications', 10)
				}
		},
		toggleEditSocialPreproject(){
			if(this.$store.state.general.publicProfileModal){
				this.$store.commit('general/setPublicProfileModalEdditing', null)
			}
			else{
				let data = {
					morphable_type: 'preproject',
					morphable_id: this.$store.state.preprojects.preProject.id,
				};

				const preProject = this.$store.state.preprojects.preProject;
				if (preProject && preProject.public_profile) {
					data = {
						...data,
						...preProject.public_profile,
					};
				}

				this.$store.commit('general/setPublicProfileModalEdditing', data);
			}
			this.$store.commit('general/setPublicProfileModal', !this.$store.state.general.publicProfileModal)
		},
		openNewProjectModal(){
			const pre_project = {...this.$store.state.preprojects.preProject, ...{doc_type: 1}}
			this.$store.commit('projects/setProjectModalInfo', pre_project)
			this.$store.commit('projects/setProjectModal', true)
		},
	}
};
</script>