import moment from 'moment'
import {GENDER_MALE} from '@/consts'
import { format, validate } from '@/helpers/rut'
import contractStatuses from '@/components/Helpers/Constants/contract.js'
import budgetStatuses from '@/components/Helpers/Constants/budget.js'

const filters = {
	toThousandFilter(num, decimal = 0) {
    const [integer, decimalPart] = ((+num || 0).toString()).split('.');
    let formatted = integer.replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, '.'));

    if (decimal !== 0) {
        const formattedDecimal = decimalPart ? decimalPart.slice(0, decimal) : '';
        formatted += ',' + formattedDecimal.padEnd(decimal, '0');
    }

    return formatted;
	},
	toDateTime(date){
		return moment(date).format('DD-MM-YYYY | HH:mm')
	},
	toDateFormat(date){
		return moment(date).format('DD-MM-YYYY')
	},
	toDate(date, fromFormat = null, format = 'DD-MM-YYYY'){
		if(fromFormat){
			return moment(date, fromFormat).format(format)
		}
		return moment(date).format(format)
	},
	toHour(date){
		return moment(date).format('HH:mm')
	},
	toYear(date){
		return moment(date).format('YYYY')
	},
	oneDecimalsNumber(number){
		return Math.round(number * 10) / 10
	},
	formatRut(rut){
		return validate(rut) ? format(rut):rut
	},
	gender(gender){
		return gender == GENDER_MALE ? 'Masculino':'Femenino'
	},
	genderIcon(gender){
		return gender == GENDER_MALE ? 'mdi-gender-male':'mdi-gender-female'
	},
	addTargetsToLinksMessages(message) {
		const container = document.createElement('div');
		container.innerHTML = message;
		const links = container.getElementsByTagName('a');
		for (let i = 0; i < links.length; i++) {
			links[i].setAttribute('target', '_blank');
		}
		return container.innerHTML;
	},
	budgetStatus(status) {
		const statuses = ['Creado','Enviado','Observado','Aceptado','Rechazado']
    if (Number.isInteger(status) && status >= 1 && status <= statuses.length) {
        return statuses[status - 1];
    }
		return '--'
	},
	budgetStatusColor(status){
		const statuses = ['#524D4D','warning','warning','green','red']
    if (Number.isInteger(status) && status >= 1 && status <= statuses.length) {
        return statuses[status - 1];
    }
		return '#524D4D'
	},
	contractStatus(status) {
		const statuses = ['Creado', 'Esperando Pago Contrato', 'Contrato Pagado', 'Esperando Visto Bueno Empresa / Profesional', 'Visto Bueno Empresa / Profesional', 'Enviado a Cliente', 'Observado por Cliente', 'Aceptado', 'Rechazado', 'Firmado Cliente', 'Firmado Ambas Partes']
    if (Number.isInteger(status) && status >= 1 && status <= statuses.length) {
        return statuses[status - 1];
    }
		return '--'
	},
	contractStatusColor(status){
		const statuses = ['#524D4D','warning','green','#524D4D','#524D4D','green','warning','green','red','green','green']
    if (Number.isInteger(status) && status >= 1 && status <= statuses.length) {
        return statuses[status - 1];
    }
		return '#524D4D'
	},
	preprojectStatus(status) {
		const statuses = ['En recopilación de antecedentes','Pasó a presupuesto','Pasó a contrato','Rechazado']
    if (Number.isInteger(status) && status >= 1 && status <= statuses.length) {
        return statuses[status - 1];
    }
		return '--'
	},
	preprojectStatusColor(status){
		const statuses = ['#524D4D','warning','green','red']
    if (Number.isInteger(status) && status >= 1 && status <= statuses.length) {
        return statuses[status - 1];
    }
		return '#524D4D'
	},
	contractEstatusNotice(status){
		if(!status) return ''
		else if(status < contractStatuses.ENVIADO_CLIENTE){
			return 'El cliente aún no puede visualizar el contrato'
		}
		else if(status == contractStatuses.ENVIADO_CLIENTE){
			return 'El contrato ya puede ser visualizado por el cliente'
		}
		else if(status == contractStatuses.OBSERVADO_CLIENTE){
			return 'El cliente ha realizado observaciones, resuélvelas y envíale de nuevo el contrato'
		}
		else if(status == contractStatuses.ACEPTADO){
			return 'El cliente ha aceptado el contrato pero aún no lo ha firmado'
		}
		else if(status == contractStatuses.RECHAZADO){
			return 'El cliente ha rechazado el contrato'
		}
		else if(status == contractStatuses.FIRMADO_CLIENTE){
			return 'El cliente ha firmado el contrato, ahora debe ser firmado por la Empresa / Profesional'
		}
		else if(status == contractStatuses.FIRMADO_AMBAS_PARTES){
			return 'El contrato ha sido firmado por ambas partes'
		}
		return ''
	},
	contractEstatusNoticeClient(status){
		if(!status) return ''
		else if(status < contractStatuses.ENVIADO_CLIENTE){
			return 'El cliente aún no puede visualizar el contrato'
		}
		else if(status == contractStatuses.ENVIADO_CLIENTE){
			return 'El contrato está listo para ser revisado'
		}
		else if(status == contractStatuses.OBSERVADO_CLIENTE){
			return 'La empresa está revisando las observaciones'
		}
		else if(status == contractStatuses.ACEPTADO){
			return 'El contrato ha sido aceptado, ahora deberás firmarlo'
		}
		else if(status == contractStatuses.RECHAZADO){
			return 'El contrato ha sido rechazado, podrán enviarte una nueva revisión'
		}
		else if(status == contractStatuses.FIRMADO_CLIENTE){
			return 'Firmado por cliente, en espera de ser firmado por la empresa / profesional'
		}
		else if(status == contractStatuses.FIRMADO_AMBAS_PARTES){
			return 'El contrato ha sido firmado por ambas partes'
		}
		return ''
	},
	budgetEstatusNotice(status){
		if(!status) return ''
		else if(status < budgetStatuses.ENVIADO_CLIENTE){
			return 'El cliente aún no puede visualizar el presupuesto'
		}
		else if(status == budgetStatuses.ENVIADO_CLIENTE){
			return 'El presupuesto ya puede ser visualizado por el cliente'
		}
		else if(status == budgetStatuses.OBSERVADO_CLIENTE){
			return 'El cliente ha hecho una observación al presupuesto'
		}
		else if(status == budgetStatuses.ACEPTADO){
			return 'El cliente ha aceptado el presupuesto'
		}
		else if(status == budgetStatuses.RECHAZADO){
			return 'El cliente ha aceptado el presupuesto'
		}
		return ''
	},
	budgetEstatusNoticeClient(status){
		if(!status) return ''
		else if(status < budgetStatuses.ENVIADO_CLIENTE){
			return 'El cliente aún no puede visualizar el presupuesto'
		}
		else if(status == budgetStatuses.ENVIADO_CLIENTE){
			return 'El presupuesto está listo para ser revisado'
		}
		else if(status == budgetStatuses.OBSERVADO_CLIENTE){
			return 'El cliente ha hecho una observación al presupuesto'
		}
		else if(status == budgetStatuses.ACEPTADO){
			return 'El presupuesto ha sido aceptado'
		}
		else if(status == budgetStatuses.RECHAZADO){
			return 'El presupuesto ha sido rechazado, podrán enviarte una nueva revisión'
		}
		return ''
	},
}

export default filters