import { getUserFromCompany } from '@/helpers/api/company'
import { getCompanyClients, getCompanyClient } from '@/helpers/api/users'

const usersStore = {
  namespaced: true,
  state: {
		client: null,
		loadingClient: true,
		companyClients: [],
		loadingCompanyClients: true,
    loadingUserList: true,
    usersList: [],
    newUserModal: false,
    newUserModalEditing: null,
    editUserModal: false,
    usersListForItems: {
      project_id: null,
      users: []
    },
		userSignatureModal: false,
		editPermisosUserModal: false,
		editPermissionsUser: null,
    loadingUserListForItems: false
  },
  mutations: {
    setUsersList(state, users) {
      state.usersList = users
    },
    setLoadingUserList(state, status) {
      state.loadingUserList = status
    },
    setCompanyClients(state, users) {
      state.companyClients = users
    },
    setLoadingCompanyClients(state, status) {
      state.loadingCompanyClients = status
    },
    setClient(state, user) {
      state.client = user
    },
    setLoadingClient(state, status) {
      state.loadingClient = status
    },
    setNewUserModal(state, status) {
      state.newUserModal = status
    },
    setNewUserModalEditing(state, status) {
      state.newUserModalEditing = status
    },
    setEditUserModal(state, status) {
      state.editUserModal = status
    },
    setUsersListForItems(state, users) {
      state.usersListForItems = users
    },
    setLoadingUserListForItems(state, status) {
      state.loadingUserListForItems = status
    },
    setEditPermisosUserModal(state, editPermisosUserModal) {
      state.editPermisosUserModal = editPermisosUserModal
    },
    setEditPermissionsUser(state, editPermissionsUser) {
      state.editPermissionsUser = editPermissionsUser
    },
    setUserSignatureModal(state, status) {
      state.userSignatureModal = status
    }
  },
  actions: {
    async getUserFromCompany({ commit }) {
      commit('setLoadingUserList', true)
      const data = {
        start: 0,
        limit: 50
      }
      const users = await getUserFromCompany(data)
      if (users.code === 200) {
        commit('setUsersList', users.users)
      }
      commit('setLoadingUserList', false)
    },
    async getCompanyClients({ commit }, {search = null, start = 0, limit = 20} = {}) {
			start = isNaN(start) ? 0 : start
      commit('setLoadingCompanyClients', true)
      const data = {
				search,
        start,
        limit
      }
      const users = await getCompanyClients(data)
      if (users.code === 200) {
        commit('setCompanyClients', users.users)
      }
      commit('setLoadingCompanyClients', false)
    },
    async getCompanyClient({ commit }, user_id) {
      commit('setLoadingClient', true)
      const user = await getCompanyClient({user_id})
      if (user.code === 200) {
        commit('setClient', user.user)
      }
      commit('setLoadingClient', false)
    }
  }
}

export default usersStore
