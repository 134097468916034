<template>
	<div>
		<v-list-item link class="menu-item-inner d-flex align-center" :class="isActive(item) || showSubItem ? 'active':''"
			@click="toRouteItem(item)"
			:style="!item.routeName && !item.subMenu ? 'opacity: 0.4':''" :inactive="!item.routeName && !item.subMenu">
			<v-icon class="menu-item-icon">{{item.icon}}</v-icon>
			<div class="w-100">
				<p class="mb-0 ml-2 menu-item-text" style="font-size:11px">{{item.name}}</p>
				<p class="mb-0 ml-2 menu-item-text text-right" style="font-size:10px" v-if="item.subtitle">{{item.subtitle}}</p>
			</div>
		</v-list-item>
		<div v-if="showSubItem || showSubItems">
			<v-list-item v-for="(subitem, index) in item.subMenu" :key="index" link class="menu-item-inner submenu d-flex align-center" :class="isActive(subitem) ? 'active':''"	
				@click="toRouteItem(subitem)"
				:style="!subitem.routeName ? 'opacity: 0.4':''" :inactive="!subitem.routeName">
				<div class="blank-space"></div>
				<div class="d-flex flex-grow-1 justify-end">
					<v-icon class="menu-item-icon">{{subitem.icon}}</v-icon>
					<p class="mb-0 ml-2 menu-item-text">{{subitem.name}}</p>
				</div>
			</v-list-item>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MenuItem',
	props: ['item'],
	data: () => ({
		showSubItems: false
	}),
	computed: {
    currentRouteName() {
			return this.$route.name;
    },
		showSubItem(){
			const parent = this.currentRouteName === this.item.routeName
			const sub = this.item.subMenu && this.item.subMenu.some((menu) => {
				return this.currentRouteName === menu.routeName
			})
			const child = this.item.childRoutes && this.item.childRoutes.some((child) => {
				return this.currentRouteName === child
			})
			return parent || sub || child
		}
	},
	watch: {
    showSubItem() {
			this.showSubItems = this.showSubItem
    },
	},
	methods: {
		isActive(item){
			return this.currentRouteName === item.routeName || ( item.childRoutes && item.childRoutes.includes(this.currentRouteName) )
		},
		toRouteItem(item){
			if(item.subMenu){
				this.showSubItems = !this.showSubItems
				if(!item.routeName || !this.showSubItems) return null
			}
			this.$router.push({ name: item.routeName })
		}
	}
};
</script>

<style scoped>
	.menu-item-inner{
		position: relative;
		padding-left: 5px;
		width: 100%;
	}
	.menu-item-inner:hover, .menu-item-inner.active{
		background-color: rgb(82, 77, 77, 0.15)!important;
		/* background: 5px solid #524D4D; */
	}
	.menu-item-inner.submenu{
		background-color: white;
	}
	/* .menu-item-inner:hover:before, .menu-item-inner.active:before{
		content: '';
		width: 5px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background-color: #524D4D;
		opacity: 1!important;
	} */
	.menu-item-inner{
		padding-left: 10%;
	}
	.menu-item-inner.submenu{
		display: flex;
		justify-content: space-evenly;
		text-align: right;
	}
	.menu-item-inner.submenu .blank-space{
		min-width: 30px;
		text-align: center;
	}
	.menu-item-inner.submenu .menu-item-text{
    text-align: right;
    flex-grow: 1;
		font-size: 10px;
	}
	.menu-item-inner.submenu .menu-item-icon{
		font-size: 18px;
	}
	.menu-item-inner .menu-item-text{
		color: #959595;
	}
	.menu-item-inner:hover .menu-item-icon,
	.menu-item-inner:hover .menu-item-text,
	.menu-item-inner.active .menu-item-icon,
	.menu-item-inner.active .menu-item-text{
		color: #524D4D;
	}
	.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before{display: none;}
</style>
