<template>
	<div class="d-flex align-center mr-4">
		<NotificationHeaderChat
			v-if="$store.state.user.isAuthenticated"
			:clearTray="() => clearTray('chat')"
			size="x-small"
			:class="notificationClass" />
		<NotificationHeaderObservations
			v-if="$store.state.user.isAuthenticated"
			:clearTray="() => clearTray('contracts')"
			size="x-small"
			:class="notificationClass" />
		<NotificationsHeader
			v-if="$store.state.user.isAuthenticated"
			:clearTray="() => clearTray('regular')"
			:class="notificationClass" />
	</div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, off, set } from "firebase/database";

import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'
import NotificationsHeader from '@/components/Helpers/Notifications/NotificationHeader'
import NotificationHeaderObservations from '@/components/Helpers/Notifications/NotificationHeaderObservations'
import NotificationHeaderChat from '@/components/Helpers/Notifications/NotificationHeaderChat'

export default {
	name: 'RTDBNotifications',
	components: { NotificationHeaderObservations, NotificationHeaderChat, NotificationsHeader },
	filters: {
	},
	computed: {
		notificationClass() {
			return this.$vuetify.breakpoint.mobile ? '':'mr-2'
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		userInfo() {
			return this.$store.state.user.userInfo
		}
	},
	watch: {
		userInfo(newData, oldData){
			if(newData && newData != oldData){
				this.startRTDB()
			}
		},
		selectedCompany(newData, oldData){
			if(newData && newData != oldData){
				this.startRTDB()
			}
		}
	},
	data: () => ({
		app: null,
		database: null,
		notificationsRef: null,
		firebaseConfig: {
			apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
			authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
			databaseURL: process.env.VUE_APP_FIREBASE_RTDB_URL,
			projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
			storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
			appId: process.env.VUE_APP_FIREBASE_APP_ID
		}
	}),
	mounted(){
    eventBus.$on('deleteRTDBNotifications', (data) => {
			data.keys_to_remove.forEach((notificationKey) => {
				this.clearSingleNotification(data.type, notificationKey)
			})
    })
		this.init()
	},
	beforeDestroy() {
    this.stopNotificationsRTDBListener()
	},
	methods: {
		init(){
			if (!this.app) {
				console.log(this.firebaseConfig)
				this.app = initializeApp(this.firebaseConfig)
				this.database = getDatabase(this.app)
				this.startRTDB()
			}
		},
		startRTDB(){
			if(this.userInfo && this.selectedCompany){
				this.startNotificationsRTDBListenner()
			}
		},
		startNotificationsRTDBListenner(){
			if(this.notificationsRef){
				this.stopNotificationsRTDBListener()
			}
			this.notificationsRef = ref(this.database, `notifications/${this.selectedCompany.company_id}/${this.userInfo.id}`)
			onValue(this.notificationsRef, (snapshot) => {
				this.resetStore()
				if (snapshot.exists()) {
					const notifications = snapshot.val()
					this.$store.commit('notifications/setRTDBNotifications', notifications)
					Object.entries(notifications).forEach(([key, value]) => {
						const count = value && Object.values(value) ? Object.values(value).length:null
						this.setHasNotifications(key, count)
						Object.values(value).forEach(notification => {
							this.setMessage(notification)
							if(key == 'contracts'){
								this.getUserInfo(notification)
								eventBus.$emit('reloadContract', {contract_id: notification.data.contract_id, contract_content_id: notification.data.contract_content_id})
							}
							if(key == 'regular' && notification.data?.budget_id){
								eventBus.$emit('reloadBudget', {budget_id: notification.data.budget_id})
							}
						});
					})
				}
			},
			(error) => console.error('Error RTDB Listener:', error))
		},
		stopNotificationsRTDBListener() {
			if (this.notificationsRef) {
				off(this.notificationsRef)
				this.notificationsRef = null
			}
		},
		clearTray(path){
			const contractsRef = ref(this.database, `notifications/${this.selectedCompany.company_id}/${this.userInfo.id}/${path}`);
			set(contractsRef, null)
		},
		clearSingleNotification(path, notificationKey){
			const contractsRef = ref(this.database, `notifications/${this.selectedCompany.company_id}/${this.userInfo.id}/${path}/${notificationKey}`)
			set(contractsRef, null)
		},
		resetStore(){
			this.$store.commit('notifications/setRTDBNotifications', [])
			this.$store.commit('notifications/setHasChatNotifications', false)
			this.$store.commit('notifications/setHasContractNotifications', false)
			this.$store.commit('notifications/setHasRegularNotifications', false)
			this.$store.commit('notifications/setBadgeText', '--')
			this.$store.commit('notifications/setBadgeTextContract', '--')
			this.$store.commit('notifications/setBadgeTextChat', '--')
		},
		setHasNotifications(key, count){
			switch (key) {
				case 'chat':
					this.$store.commit('notifications/setHasChatNotifications', true)
					this.$store.commit('notifications/setBadgeTextChat', count || '--')
					this.$store.dispatch('notifications/getChatNotifications', {take: 10, types: [3]})
					break
				case 'contracts':
					this.$store.commit('notifications/setHasContractNotifications', true)
					this.$store.commit('notifications/setBadgeTextContract', count || '--')
					this.$store.dispatch('notifications/getContractNotifications', {take: 10, types: [4]})
					break
				case 'regular':
					this.$store.commit('notifications/setHasRegularNotifications', true)
					this.$store.commit('notifications/setBadgeText', count || '--')
					this.$store.dispatch('notifications/getNotifications', {take: 10, types: [1,2,5]})
					break
				default:
					break
			}
		},
		getUserInfo(notification_info){
			if(notification_info.data?.contract_revision){
				this.$store.dispatch('notifications/getContractNotifications', {take: 10, types: [4]})
			}
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    }
	}
};
</script>