import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import {getStates, getCities, getCountryCities, getPolicyAcceptance} from '@/helpers/api/general'

const messagesStore = {
  namespaced: true,
  state: {
		ordinalsNumbers: [
			"Cero", "Primero", "Segundo", "Tercero", "Cuarto", "Quinto", "Sexto", "Séptimo", "Octavo", 
			"Noveno", "Décimo", "Undécimo", "Duodécimo", "Décimotercero", "Décimocuarto", 
			"Décimoquinto", "Décimosexto", "Décimoséptimo", "Décimooctavo", "Décimonoveno", "Vigésimo", 
			"Vigésimo primero", "Vigésimo segundo", "Vigésimo tercero", "Vigésimo cuarto", 
			"Vigésimo quinto", "Vigésimo sexto", "Vigésimo séptimo", "Vigésimo octavo", 
			"Vigésimo noveno", "Trigésimo"
		],
		romanNumerals: [
			"0", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", 
			"XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX", 
			"XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", 
			"XXIX", "XXX"
		],
		importantNotificationModal: true,
		importantNotificationModalData: null,
		newCreatedElement: null,
    messagesToMessageCenter: [],
		headerHeight: 0,
		viewerHeight: window.innerHeight,
		loadingStates: false,
		states: [],
		loadingCities: false,
		cities: [],
		loadingCountryCities: false,
		countryCities: [],
		holidayDays: [],
		policiesLists: [],
		loadingPolicies: false,
		userGeo: null,
		newBudgetModal: null,
		userTypePromptModal: false,
		addressModal: null,
		dayUfValue: 40000,
		selectedFile: null,
		publicProfileModal: false,
		publicProfileModalEdditing: null
  },
  mutations: {
		setHeaderHeight(state, height) {
			state.headerHeight = height
		},
		setNewCreatedElement(state, element) {
			state.newCreatedElement = element
		},
		setPublicProfileModal(state, publicProfileModal) {
			state.publicProfileModal = publicProfileModal
		},
		setPublicProfileModalEdditing(state, publicProfileModalEdditing) {
			state.publicProfileModalEdditing = publicProfileModalEdditing
		},
    setMessagesToMessageCenter(state, message) {
      let messages = [...state.messagesToMessageCenter]
      messages.push(message)
      state.messagesToMessageCenter = messages
    },
    removeMessagesToMessageCenter(state, message_uuid) {
      let messages = [...state.messagesToMessageCenter]

      const founded = messages.findIndex((item) => {
        return item.uuid === message_uuid
      })
      if (founded !== -1) {
        messages.splice(founded, 1)
        state.messagesToMessageCenter = messages
      }
    },
		setLoadingStates(state, value){
			state.loadingStates = value
		},
		setStates(state, value){
			state.states = value
		},
		setLoadingCities(state, value){
			state.loadingCities = value
		},
		setCities(state, value){
			state.cities = value
		},
		setLoadingCountryCities(state, value){
			state.loadingCountryCities = value
		},
		setCountryCities(state, value){
			state.countryCities = value
		},
		setLoadingPolicies(state, value){
			state.loadingPolicies = value
		},
		setPolicies(state, value){
			state.policiesLists = value
		},
		setHolidayDays(state, value){
			state.holidayDays = value
		},
		setUserGeo(state, value){
			state.userGeo = value
		},
		setNewBudgetModal(state, value){
			state.newBudgetModal = value
		},
		setNewBudgetModalStatus(state, value){
			state.newBudgetModal.status = value
		},
		setDayUfValue(state, value){
			state.dayUfValue = value
		},
		setUserTypePromptModal(state, value){
			state.userTypePromptModal = value
		},
		setAddressModal(state, value){
			state.addressModal = value
		},
		setSelectedFile(state, value){
			state.selectedFile = value
		},
		setImportantNotificationModal(state, status){
			state.importantNotificationModal = status
		},
		setImportantNotificationModalData(state, data){
			state.importantNotificationModalData = data
		},
  },
  getters: {
    availableHeight: state => state.viewerHeight - state.headerHeight,
    getHeaderHeight: state => state.headerHeight
  },
  actions: {
    addMessage({ commit }, message) {
      message.uuid = uuidv4()
      commit('setMessagesToMessageCenter', message)
      setTimeout(() => {
        commit('removeMessagesToMessageCenter', message.uuid)
      }, message.close ? message.close : 5000)
    },
    async getStates({ commit }) {
      commit('setLoadingStates', true)
      const data = {
        country_id: 44
      }
      const states = await getStates(data)
      if (states.code === 200) {
        commit('setStates', states.states)
      }
      commit('setLoadingStates', false)
    },
    async getCities({ commit }, state_id) {
      commit('setLoadingCities', true)
      const cities = await getCities({state_id})
      if (cities.code === 200) {
        commit('setCities', cities.cities)
      }
      commit('setLoadingCities', false)
    },
    async getCountryCities({ commit }, country_id) {
      commit('setLoadingCountryCities', true)
      const cities = await getCountryCities({country_id})
      if (cities.code === 200) {
        commit('setCountryCities', cities.cities)
      }
      commit('setLoadingCountryCities', false)
    },
    async getPolicies({ commit }, params) {
      commit('setLoadingPolicies', true)
      const policies = await getPolicyAcceptance(params)
      if (policies.code === 200) {
        commit('setPolicies', policies.policies)
      }
      commit('setLoadingPolicies', false)
    },
    async getHolidayDays({ commit }) {
			const url = `https://api.boostr.cl/holidays.json`;
			const response = await fetch(url);
			if (response.ok) {
				const data = await response.json();
				const holidayDays = data.data.map(f => moment(f.date).format('YYYY-MM-DD'))

				commit('setHolidayDays', holidayDays)
			}
    },
    async getDayUf({ commit }) {
			// const url = `https://mindicador.cl/api`;
			const cmf_url = 'https://api.cmfchile.cl/api-sbifv3/recursos_api/uf?apikey=' + process.env.VUE_APP_CMF_API_KEY + '&formato=json';
			const response = await fetch(cmf_url);
			if (response.ok) {
				const data = await response.json();
				let formattedString = data.UFs[0].Valor.replace(/\./g, "").replace(",", ".");
				let ufValue = parseFloat(formattedString);
				commit('setDayUfValue', ufValue)
			}
    }
  }
}

export default messagesStore
