<template>
	<v-container fluid pa-0 mx-2 style="width: auto;"
		:style="menuOpen ? 'z-index: 999;':''">
		<v-menu
			v-model="menuOpen"
			bottom
			offset-y
			left
			min-width="500"
			max-width="500"
			content-class="container-notification-menu-header"
			:close-on-content-click="false"
			@input="changeMenu"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-badge
					:color="$store.state.notifications.badgeColor"
					:content="$store.state.notifications.badgeText"
					:value="showBadge"
					overlap
				>
					<v-btn
						fab
						dark
						class="button-activator-notifications"
						:small="size === 'small'"
						:x-small="size === 'x-small'"
						:loading="$store.state.notifications.loadingNotifications"
						color="#524D4D"
						v-bind="attrs"
						v-on="on"
						@click="clearTray"
					>
						<v-icon dark>mdi-bell-outline</v-icon>
					</v-btn>
				</v-badge>
			</template>
			<div class="d-flex flex-row align-center justify-space-between">
				<div class="d-flex flex-row px-4" style="border-left: 2px solid #524D4D;">
					<v-icon dark color="#524D4D" class="mr-5">mdi-bell-outline</v-icon>
					<p class="mb-0 font-weight-bold" style="color: #524D4D">Notificaciones</p>
				</div>
				<div>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon color="grey" class="mr-3"
										v-bind="attrs"
										v-on="on"
										@click="markAllNotificationsAsRead">
								<v-icon>mdi-email-open-multiple-outline</v-icon>
							</v-btn>
						</template>
						<span>Marcar todo como visto</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon color="grey" class="mr-3"
										v-bind="attrs"
										v-on="on"
										@click="reloadNotifications()">
								<v-icon>mdi-cached</v-icon>
							</v-btn>
						</template>
						<span>Recargar notifications</span>
					</v-tooltip>
				</div>
			</div>
			<v-divider class="mx-2"></v-divider>
			<div v-if="$store.state.notifications.notifications.length > 0">
				<v-list style="max-height: 75vh;overflow: scroll;">
					<v-list-item
						v-for="(item, i) in $store.state.notifications.notifications"
						:key="i"
						class="px-0 py-0"
						@click="viewItem(item, i)"
					>
						<div style="width: 100%">
							<div class="d-flex flex-row px-4 pt-2 justify-space-between">
								<div class="d-flex flex-row">
									<img v-if="item.image && item.image !== ''"
										:src="item.image" class="notification-img"/>
									<v-icon v-else class="notification-img icon" color="#524D4D" >mdi-account</v-icon>
									<div class="px-4 caption">
										<small class="mb-0" style="color: #707070;">{{ item.project_name }}</small>
										<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.title }}</p>
										<div class="mb-0 caption notification-description" style="color: #707070;" v-html="item.description">{{ item.description }}</div>
									</div>
								</div>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon
											@click.stop="markNotificationAsRead(item.id, i)"
											v-bind="attrs"
											v-on="on">
											<v-icon
												small
												:color="item.readed || loadingIndexes.includes(i) ? 'grey lighten-2':'#FF120B'"
												>mdi-circle</v-icon>
										</v-btn>
									</template>
									<span>Marcar como vista</span>
								</v-tooltip>
							</div>
							<v-divider class="mt-2 mx-2"></v-divider>
						</div>
					</v-list-item>
					<div class="mt-3 mx-4">
						<v-btn
							depressed
							color="#524D4D"
							:dark="$store.state.notifications.notifications.length >= 10"
							block
							small
							:loading="$store.state.notifications.loadingNotifications"
							:disabled="$store.state.notifications.notifications.length < 10"
							@click="reloadNotifications(10)"
						>
							<v-icon small>mdi-email-outline</v-icon>
							<p class="mb-0 ml-2 caption">Ver antiguas</p>
						</v-btn>
					</div>
				</v-list>
			</div>
			<div v-else class="d-flex flex-column align-center justify-center">
				<v-icon class="mt-3 mb-2" large>mdi-check-circle-outline</v-icon>
				<p class="body-2">No hay notificaciones nuevas</p>
			</div>
		</v-menu>
	</v-container>
</template>

<script>
import {markAsReaded} from '@/helpers/api/notifications'

export default {
	name: 'NotificationHeader',
	props: {
		size: {
			type: String,
			default: 'small'
		},
		clearTray: {
			type: Function,
			required: true
		}
	},
	components: {
	},
  computed: {
		showBadge(){
			return this.$store.state.notifications.hasRegularNotifications
			// return this.$store.state.notifications.badgeText !== '0' && this.$store.state.notifications.badgeText !== '--' && this.$store.state.notifications.hasRegularNotifications
		},
    loadingNotifications() {
      return this.$store.state.notifications.loadingNotifications
    },
		userInfo() {
			return this.$store.state.user.userInfo
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
  watch: {
    loadingNotifications(newData) {
      if(newData){
				this.reloading = true
      }
			else if(this.reloading){
				this.reloading = false
				this.loadingIndexes = []
				this.loadingTaskIndexes = []
				this.loadingRdiIndexes = []
			}
    }
  },
	data: () => ({
		reloading: false,
		loadingIndexes: [],
		loadingTaskIndexes: [],
		loadingRdiIndexes: [],
		menuOpen: false,
		maxNotifications: 10
	}),
	mounted(){
	},
	methods: {
		reloadNotifications(add = null){
			if(add){
				this.maxNotifications += add
			}
			else{
				this.maxNotifications = 10
			}
			this.$store.dispatch('notifications/getNotifications', {take: this.maxNotifications, types: [1,2,5]})
		},
		async viewItem(item, index){
			this.markNotificationAsRead(item.id, index)
			if(item.item_type == 1){
				// Nueva conversación
				this.$router.push({ name: 'Conversation', params: { id: item.item_id } })
			}
			if(item.item_type == 2){
				// Agenda confirmada
				this.$router.push({ name: 'Conversation', params: { id: item.item_id } })
			}
			if(item.item_type == 3){
				// Chat mensaje desde preproject
				if(this.selectedCompany.capabilities.view_preprojects){
					this.$router.push({ name: 'PreProject', params: { id: item.item_id } })
				}
				else{
					this.$router.push({ name: 'ClientDetail', query: { p: item.item_id } })
				}
				this.$store.commit('preprojects/setOpenChatModal', true)	
			}
			if(item.item_type == 4){
				// Nueva observación en contrato
				if(item.data && item.data.panel_redirect_path){
					this.$router.push(item.data.panel_redirect_path)
				}
				else{
					this.$router.push({ name: 'ContractDetail', query: { id: item.item_id } })
				}
			}
			if(item.item_type == 5){
				// Cambio en presupuesto
				if(item.data && item.data.panel_redirect_path){
					this.$router.push(item.data.panel_redirect_path)
				}
				else{
					this.$router.push({ name: 'Budgets', query: { b: item.item_id } })
				}
			}
			this.changeMenu(false)
		},
		async markNotificationAsRead(notification_id, index){
				this.loadingIndexes.push(index)
				const tasks = await markAsReaded({id: notification_id})
				if(tasks.code == 200){
					this.reloadNotifications()
				}
		},
		async markAllNotificationsAsRead(){
			const notification_ids = this.$store.state.notifications.notifications.map((item) => {
				return item.id
			})
			if(notification_ids.length > 0){
				const tasks = await markAsReaded({ids: notification_ids})
				if(tasks.code == 200){
					this.reloadNotifications()
				}
			}
		},
		changeMenu(value){
			this.menuOpen = value
		}
	}
};
</script>

<style scoped>
	.container-notification-menu-header{
		background: white;
		margin-top: 10px;
		border-radius: 8px;
	}
	.notification-img{
		width: 40px;
		height: 40px;
		border-radius: 100%;
	}
	.notification-img.icon{
		background-color: #F0F2F8;
	}
	/deep/ .notification-description p{margin: 0;}
</style>
