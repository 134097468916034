<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.general.userTypePromptModal"
      max-width="600"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-start">
							<v-icon color="white" class="info-icon mr-2">mdi-information-symbol</v-icon>
              <div class="d-flex flex-column align-start flex-grow-1">
								<p class="mb-0 font-weight-bold text-primary title">Datos de Facturación</p>
								<p class="mb-0 text-primary caption">Por favor ingresa los datos con los que quieres que las empresas te facturen los proyectos.</p>
              </div>
            </div>

            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4 mt-2">
								<v-col cols="12" class="py-0">
									<div class="d-flex justify-space-between align-center flex-1 pa-2 mb-4" style="border: 1px solid #959595;border-radius: 5px;">
										<p class="mb-0">Tipo de Facturación: <b>{{newBillingInfo.type ? 'Empresa':'Persona Natural'}}</b></p>
										<v-switch
											class="mt-0"
											color="#FF120B"
											dense
											hide-details
											v-model="newBillingInfo.type"
										></v-switch>
									</div>
								</v-col>
								<v-col cols="12" class="py-0">
									<v-text-field
										v-model="newBillingInfo.name"
										label="Nombre Legal (SII)"
										:placeholder="newBillingInfo.type ? 'Ingrese el nombre legal de la empresa':'Ingrese su nombre'"
										outlined
										required
										dense
										:rules="requiredRule"
									></v-text-field>
								</v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newBillingInfo.rut"
                    label="Rut"
                    placeholder="Ingrese el rut"
                    outlined
                    required
                    dense
										hint="Ingresa rut sin puntos ni guión"
                    :rules="rutRule"
										@blur="onBlurRut"
										@focus="onFocusRut"
										maxlength="9"
                  ></v-text-field>
								</v-col>
								<v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newBillingInfo.address"
                    label="Dirección"
                    placeholder="Ingrese direccción completa"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
								<v-col cols="12" class="py-0">
									<v-select
										v-model="newBillingInfo.state_id"
										:items="$store.state.general.states"
										:loading="$store.state.general.loadingStates"
										:menu-props="{ top: true, offsetY: true }"
										item-text="name"
										item-value="id"
										label="Seleccione Región"
										outlined
										dense
										required
										:rules="requiredRule"
										@change="onChangeState"
									></v-select>
                </v-col>
								<v-col cols="12" class="py-0">
									<v-select
										v-model="newBillingInfo.district_id"
										:items="$store.state.general.cities"
										:loading="$store.state.general.loadingCities"
										item-text="name"
										item-value="id"
										label="Seleccione Comuna"
										outlined
										dense
										required
										:disabled="!newBillingInfo.state_id"
										:rules="requiredRule"
									></v-select>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>

						<!-- <v-row align="center">
							<v-col cols="12">
								<div class="d-flex flex-row justify-center">
									<v-btn
										depressed
										color="#524D4D"
										small
										outlined
										class="ml-2"
										style="height: 40px"
										@click="setUserType(1)"
									>
										<v-icon small>mdi-account-outline</v-icon>
										<p class="mb-0 ml-2">Soy Persona Natural</p>
									</v-btn>
									<v-btn
										depressed
										color="#524D4D"
										small
										outlined
										class="ml-2"
										style="height: 40px"
										@click="setUserType(2)"
									>
										<v-icon small>mdi-domain</v-icon>
										<p class="mb-0 ml-2">Soy Empresa</p>
									</v-btn>
								</div>
							</v-col>
						</v-row> -->
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {setUserBillingInfo} from '@/helpers/api/user'
import {validate, format, clean} from '@/helpers/rut.js'

export default {
  name: 'UserBillingInfoModal',
  data: () => ({
    validform: true,
		loadingData: false,
		newBillingInfo: {
			type: true,
			name: null,
			rut: null,
			address: null,
			state_id: null,
			district_id: null
		},
		rutRule: [
			v => validate(v) || 'Rut no válido'
		],
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
	},
  mounted(){
		Object.assign(this.newBillingInfo, this.$store.state.user.userInfo.billing_info)
		if(this.newBillingInfo.state_id){
			this.onChangeState(this.newBillingInfo.state_id)
		}
  },
  methods: {
		onBlurRut(){
			if(validate(this.newCompany.rut)){
				this.newCompany.rut = format(this.newCompany.rut)
			}
		},
		onFocusRut(){
			if(validate(this.newCompany.rut)){
				this.newCompany.rut = clean(this.newCompany.rut)
			}
		},
		onChangeState(state){
			this.$store.dispatch('general/getCities', state)
		},
		async sendForm(){
			this.loadingData = true
			await setUserBillingInfo(this.newBillingInfo)
			this.$store.dispatch('user/getUserInfo')
			this.closeModal()
			this.loadingData = false
		},
    closeModal(){
      this.$store.commit('general/setUserTypePromptModal', false)
    }
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>