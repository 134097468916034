<template>
	<v-container fluid fill-height class="align-start justify-start">
		<v-row style="height:100%">
			<v-col cols="12" md="9">
				<GmapMap
					:center="mapCenter"
					:zoom="zoom"
					style="width: 100%; height: 100%;min-height: 400px;"
					:options="getMapOptions()"
					@click="addPoint"
					@zoom_changed="updateZoom"
					@mousemove="onMouseMove"
          ref="mapRef"
					:class="getMapClass()"
				>
					<GmapMarker
						v-if="searchedCoordinates"
						:position="searchedCoordinates"
						:icon="{url: require('@/assets/marker_schedule.png'), scaledSize: { width: 45, height: 47 }}"
					/>
					<div v-if="newPath.path.length > 0 && drawingPolyline && !isClosedNewPolyline && !hoveringRemoveButton">
						<GmapCircle
							draggable
							:center="cursorGeo"
							:radius="dynamicRadius"
							:options="{
								fillColor: newPath.color,
								fillOpacity: 0.6,
								strokeColor: newPath.color,
								strokeWeight: dynamicRadius,
								strokeOpacity: 1,
								zIndex: 999
							}"
							@click="addPoint"
						/>
						<GmapPolyline
							:path="cursorGeoWithLastPath"
							:options="{
								strokeColor: newPath.color,
								strokeOpacity: 0.6,
								strokeWeight: 3
							}" />
					</div>
          <GmapCircle
            v-for="(point, index) in newPath.path"
            :key="'circles-'+index"
						draggable
            :center="point"
            :radius="dynamicRadius"
            :options="{
              fillColor: newPath.color,
              fillOpacity: 1,
              strokeColor: newPath.color,
              strokeWeight: dynamicRadius,
              strokeOpacity: 1,
							zIndex: 999
            }"
						@drag="updatePoint(index, $event.latLng)"
						@dragend="updatePoint(index, $event.latLng)"
						@click="onClickCircle(index)"
						@mouseover="cursorGeo = newPath.path[0]"
          />
					<GmapMarker
						v-if="!isClosedNewPolyline"
						:position="newPath.path[newPath.path.length - 1]"
						:clickable="true"
						:opacity="!hoveringRemoveButton ? 0.5:1"
						:icon="{
							url: require('@/assets/delete_point.png'),
							anchor: { x: -7, y: 12 },
							scaledSize: { width: 24, height: 24 }
						}"
						:zIndex="999"
						@click="removeLastDrawingPoint"
						@mouseover="onMouseOverRemoveLastPoint"
						@mouseout="onMouseOutRemoveLastPoint"/>
					<GmapPolygon
						v-if="isClosedNewPolyline"
						:paths="newPath.path"
						:options="{
							strokeColor: newPath.color,
							strokeOpacity: 0.4,
							strokeWeight: 3,
							fillColor: newPath.color,
							fillOpacity: 0.2,
						}"
						draggable
						ref="myPolygon"
						@drag="onPolygonDrag"
					/>
					<GmapPolyline
						v-else
						:path="newPath.path"
						:options="{
							strokeColor: newPath.color,
							strokeOpacity: 0.6,
							strokeWeight: 3
						}" />
					<GmapPolygon
						v-for="(path, i) in filteredPolygons"
						:key="'polygons-' + i"
						:paths="parsedPath(path.vt_polygon.coordinates)"
						:options="{
							...getPolygonOptions(path),
							zIndex: i
						}"
						@click="onClickPolygon(path, true)"
						@mouseover="onMouseOverPolygon(path)"
						@mouseout="onMouseOutPolygon(path)"/>
				</GmapMap>
			</v-col>
			<v-col cols="12" md="3">
				<v-autocomplete
					v-model="address"
					:items="[...items,...[this.address],...[this.search]]"
					:search-input.sync="search"
					prepend-inner-icon="mdi-map-marker"
					label="Buscar Dirección"
					placeholder="Buscar Dirección"
					autocomplete="off"
					outlined
					dense
					clearable
					hide-details
					:loading="loading"
					color="secondary"
					item-color="secondary"
					return-object
					class="mb-2"
					@change="change"
				></v-autocomplete>
				<div
					v-if="$checkPermissions(['edit_vt_maps'])"
					:style="'height: ' + ($store.getters['general/availableHeight'] - 24) + 'px;width: 100%;overflow:scroll;'">
					<v-btn
						depressed
						color="#524D4D"
						dark
						block
						small
						class="mb-3"
						@click="newPolyline()"
					>
						<v-icon small>mdi-map</v-icon>
						<p class="mb-0 ml-2 caption">Generar nueva área</p>
					</v-btn>
					<div v-show="setNewPolyline">
						<v-text-field
							v-model="newPath.name"
							label="Nombre área"
							flat
							hide-details
							dense
							outlined
							class="mb-4"
						></v-text-field>
						<v-text-field
							v-model="newPath.price"
							label="Valor visita en área"
							flat
							hide-details
							dense
							type="number"
							outlined
							class="mb-4"
						></v-text-field>
						<v-checkbox
							v-model="newPath.allowed"
							label="Área permitida para VT"
							dense
							class="mb-1"
						></v-checkbox>
						<v-menu v-model="selectColorMenu" offset-y :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									color="#524D4D"
									dark
									x-small
									block
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-palette</v-icon>
									<p class="mb-0">Seleccionar Color</p>
								</v-btn>
							</template>
							<v-card class="pa-2">
								<v-color-picker
									mode="hexa"
									v-model="newPath.color"
									:swatches="swatches"
									show-swatches></v-color-picker>
								<v-btn
									depressed
									color="#524D4D"
									dark
									block
									x-small
									class="mb-3"
									@click="selectColorMenu = false"
								>
									<p class="mb-0 ml-2 caption">Confirmar</p>
								</v-btn>
							</v-card>
						</v-menu>
						<v-divider class="my-3"></v-divider>
						<v-btn
							depressed
							color="#524D4D"
							:dark="isClosedNewPolyline"
							block
							small
							class="mb-1"
							:disabled="!isClosedNewPolyline"
							@click="createPolylineMap"
						>
							<p class="mb-0 ml-2 caption">Guardar Área</p>
						</v-btn>
						<v-btn
							depressed
							color="#524D4D"
							block
							small
							class="mb-3"
							outlined
							@click="cancelDrawing"
						>
							<p class="mb-0 ml-2 caption">Cancelar</p>
						</v-btn>
					</div>
					<v-divider class="my-4"></v-divider>
					<v-list dense color="transparent">
						<v-subheader>Áreas</v-subheader>
						<v-list-item-group>
							<draggable v-model="$store.state.vt.vtPolygons" handle=".handle_drag" @end="startEndDragEvent">
								<!-- <div v-for="element in myArray" :key="element.id">{{element.name}}</div> -->
								<v-list-item
									v-for="(item, i) in $store.state.vt.vtPolygons"
									:key="i"
									:class="{'hovering': polygonHovering && polygonHovering.id == item.id}"
									@mouseover="onMouseOverPolygon(item)"
									@mouseout="onMouseOutPolygon(item)"
								>
									<v-list-item-icon class="align-self-center handle_drag">
										<v-icon :color="item.color">mdi-unfold-more-horizontal</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>
											{{ item.name }}
										</v-list-item-title>
										<v-list-item-subtitle>${{ item.price | toThousandFilter }}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<div class="d-flex">
											<v-btn
												color="warning"
												dark
												icon
												v-if="$checkPermissions(['edit_vt_maps'])"
												@click="editPolygon(item)"
											>
												<v-icon>mdi-pencil-box</v-icon>
											</v-btn>
											<v-btn
												color="error"
												dark
												icon
												v-if="$checkPermissions(['delete_vt_maps'])"
												@click="removePolygon(item)"
											>
												<v-icon>mdi-trash-can-outline</v-icon>
											</v-btn>
										</div>
									</v-list-item-action>
								</v-list-item>
							</draggable>
						</v-list-item-group>
					</v-list>
				</div>
			</v-col>
		</v-row>
		
	</v-container>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import draggable from 'vuedraggable'
import _debounce from 'lodash/debounce'
import { createVtPolygonMap, updateVtPolygonMap, updatePriorityVtPolygonMap, deleteVtPolygonMap } from "@/helpers/api/vts"

export default {
	components: {draggable},
  data() {
    return {
			items: [],
			search: '',
			address: null,
			autocompleteService: null,
			searchedCoordinates: null,
			mapCenter: {lat: -33.4311109, lng: -70.6381756},
			loading: false,
			selectColorMenu: false,
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
      ],
			newPath: {
				polygon_id: null,
				color: '#000000',
				name: 'Área',
				price: 50000,
				allowed: true,
				path: []
			},
			cursorGeo: null,
			zoom: 11,
      map: null,
			hoveringRemoveButton: false,
			setNewPolyline: false,
			drawingPolyline: false,
      toleranceDistance: 150,
			polygonHovering: null,
			dragging: false
    };
  },
  computed: {
		google: gmapApi,
    dynamicRadius() {
      const baseRadius = 100;
      return baseRadius / this.zoom;
    },
    userGeo() {
			return this.$store.state.general.userGeo
    },
		dynamicTolerance() {
      return this.toleranceDistance * this.zoom / 8
		},
		isClosedNewPolyline() {
			if (this.newPath.path.length > 1) {
				const firstPoint = this.newPath.path[0];
				const lastPoint = this.newPath.path[this.newPath.path.length - 1];
				return firstPoint.lat === lastPoint.lat && firstPoint.lng === lastPoint.lng;
			}
			return false;
		},
		filteredPolygons(){
			return this.$store.state.vt.vtPolygons.filter((item) => {
				return item.id != this.newPath.polygon_id
			})
			.sort((a, b) => {
				return b.priority - a.priority;
			})
		},
		cursorGeoWithLastPath(){
			if(this.cursorGeo){
				const path = [...[this.newPath.path[this.newPath.path.length - 1]], ...[this.cursorGeo]]
				return path
			}
			return [this.newPath.path[this.newPath.path.length - 1]]
		},
  },
	watch: {
    search(newSearch) {
      if (newSearch) {
        this.googleSearch(newSearch);
      } else {
        this.items = [];
      }
    },
    userGeo(newValue) {
      if (newValue) {
				this.mapCenter = this.userGeo
      }
    },
	},
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown)
  },
	mounted(){
		this.$store.dispatch('vt/getVtPolygons')
    window.addEventListener("keydown", this.handleKeydown)
		if(this.userGeo){
			this.mapCenter = this.userGeo
		}

		this.$nextTick(() => {
			this.$gmapApiPromiseLazy().then(() => {
				this.autocompleteService = new this.google.maps.places.AutocompleteService()
			})
		});
	},
  methods: {
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.items = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.items = []; // Limpiar los resultados en caso de error
        }
      });
    },
		async getPlaceDetails(placeId) {
			if (typeof this.google !== 'undefined' && this.google.maps) {
				const service = new this.google.maps.places.PlacesService(document.createElement('div'));
				this.searchedCoordinates = null
				service.getDetails({ placeId }, (place, status) => {
					if (status === this.google.maps.places.PlacesServiceStatus.OK) {
						const { geometry } = place;
						this.searchedCoordinates = this.mapCenter = {
							lat: geometry.location.lat(),
							lng: geometry.location.lng()
						}
					}
				});
			} else {
				console.error('Google Maps API no está cargada');
			}
		},
    change(value) {
			if(!value){
				this.searchedCoordinates = null
				this.mapCenter = {lat: -33.3807616, lng: -70.647808}
				this.address = null
				return null
			}
			this.getPlaceDetails(value.value.place_id)
			console.log('Value: ', value)
      this.address = this.search = value.text;
    },
		startEndDragEvent: _debounce(function(){ this.endDragEvent() }, 2000),
		async endDragEvent(){
			const polygons = this.$store.state.vt.vtPolygons.map((polygon, index) => {
				return {id: polygon.id, priority: index + 1}
			})
			await updatePriorityVtPolygonMap({polygons})
			this.$store.dispatch('vt/getVtPolygons')
		},
    handleKeydown(event) {
      switch (event.key) {
        case 'Escape':
					this.cancelDrawing();
          break;
        case 'ArrowUp':
          this.panMap('up');
          break;
        case 'ArrowDown':
          this.panMap('down');
          break;
        case 'ArrowLeft':
          this.panMap('left');
          break;
        case 'ArrowRight':
          this.panMap('right');
          break;
      }
    },
    panMap(direction) {
      const currentCenter = this.mapRef.getCenter();
      let newLat = currentCenter.lat();
      let newLng = currentCenter.lng();

      switch (direction) {
        case 'up':
          newLat += this.moveStep;
          break;
        case 'down':
          newLat -= this.moveStep;
          break;
        case 'left':
          newLng -= this.moveStep;
          break;
        case 'right':
          newLng += this.moveStep;
          break;
      }
      this.mapRef.setCenter({ lat: newLat, lng: newLng });
    },
		cancelDrawing(){
			this.newPath = {
				color: '#000000',
				name: '',
				price: 50000,
				allowed: true,
				path: []
			}
			this.setNewPolyline = false
			this.drawingPolyline = false
			this.hoveringRemoveButton = false
		},
		onMouseOverRemoveLastPoint(){
			this.hoveringRemoveButton = true
		},
		onMouseOutRemoveLastPoint(){
			this.hoveringRemoveButton = false
		},
		removeLastDrawingPoint(){
			this.newPath.path.pop()
		},
		onClickPolygon(path){
			if(this.polygonHovering && this.polygonHovering.fixed && this.polygonHovering.polygon_id === path.polygon_id){
				this.polygonHovering = null
				return null
			}
			this.polygonHovering = {...path, ...{ fixed: true }}
		},
		onMouseOverPolygon(path){
			if(this.polygonHovering && this.polygonHovering.fixed){
				return null
			}
			this.polygonHovering = path
		},
		onMouseOutPolygon(){
			if(this.polygonHovering.fixed){
				return null
			}
			this.polygonHovering = null
		},
		getMapOptions(){
			return {
						// disableDefaultUI: true,
						clickableIcons: false,
						draggableCursor: this.drawingPolyline ? 'crosshair':''
					}
		},
		getPolygonOptions(path){
			const hovering = (this.newPath.polygon_id && this.newPath.polygon_id == path.id) || (this.polygonHovering && this.polygonHovering.id == path.id)
			return {
				strokeColor: path.color,
				strokeOpacity: 0.8,
				strokeWeight: 3,
				fillColor: path.color,
				fillOpacity: hovering ? 0.6:0.2,
				clickable: !this.drawingPolyline,
				editable: false
			}
		},
		getMapClass(){
			return { 'cursor-drawing': this.drawingPolyline }
		},
		onMouseMove(event){
			this.cursorGeo = event.latLng
			if(this.newPath.path.length > 0){
				const distance = this.calculateDistance(
					this.cursorGeo.lat(),
					this.cursorGeo.lng(),
					this.newPath.path[0].lat(),
					this.newPath.path[0].lng()
				);
				if (distance < this.dynamicTolerance) {
					this.cursorGeo = this.newPath.path[0]
				}
			}
		},
		editPolygon(polygon){
			this.$set(this, 'newPath', {
				polygon_id: polygon.id,
				color: polygon.color,
				name: polygon.name,
				price: polygon.price,
				allowed: polygon.allowed,
				path: this.parsedPath(polygon.vt_polygon.coordinates)
			})
			this.setNewPolyline = true
			this.drawingPolyline = true
		},
		async removePolygon(polygon){
			await deleteVtPolygonMap({polygon_id: polygon.id})
			this.$store.dispatch('vt/getVtPolygons')
		},
    updateZoom(zoom) {
      this.zoom = zoom
    },
		onClickCircle(index){
			if(index == 0){
        const firstPoint = this.newPath.path[0]
				this.newPath.path.push(firstPoint)
				this.drawingPolyline = false
			}
		},
    updatePoint(index, newLatLng) {
			if(index == 0 || index == this.newPath.path.length - 1){
				this.$set(this.newPath.path, 0, newLatLng);
				this.$set(this.newPath.path, this.newPath.path.length -1, newLatLng);
			}
			else{
				this.$set(this.newPath.path, index, newLatLng);
			}
    },
    onPolygonDrag() {
			const polygon = this.$refs.myPolygon.$polygonObject;
      this.newPath.path = polygon.getPath().getArray().map(latLng => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      }));
    },
		parsedPath(polygon_coords){
			const paths = polygon_coords[0].map((coords) => {
				return {lat: coords[1], lng: coords[0]}
			})
			return paths
		},
		parsedPathFunctions(polygon_coords){
			const paths = polygon_coords[0].map((coords) => {
				return {lat: () => coords[1], lng: () =>  coords[0]}
			})
			return paths
		},
		async createPolylineMap(){
			this.drawingPolyline = false
			const data = {
				...this.newPath,
				...{vt_polygon: this.newPath.path, company_id: 1}
			}
			const polygon = data.polygon_id ? await updateVtPolygonMap(data):await createVtPolygonMap(data)
			if(polygon.code == 200){
				this.newPath= {
					color: '#000000',
					name: '',
					price: 50000,
					allowed: true,
					path: []
				}
				const data = {
					message: 'Se ha realizado el proceso correctamente.',
					title: 'Acción completada correctamente.',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.setNewPolyline = false
				this.$store.dispatch('general/addMessage', data)
				this.$store.dispatch('vt/getVtPolygons')
			}
			else{
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Ha ocurrido rror',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.$store.dispatch('general/addMessage', data)
			}
		},
		newPolyline(){
			this.newPath= {
				color: '#000000',
				name: '',
				price: 50000,
				allowed: true,
				path: []
			}
			this.setNewPolyline = true
			this.drawingPolyline = true
		},
    addPoint(event) {
			if(!this.drawingPolyline){ return null }

      const latLng = event.latLng;
      if (this.newPath.path.length > 1) {
        let firstPoint = this.newPath.path[0];
				if(typeof firstPoint.lat !== 'function'){
					firstPoint = new this.google.maps.LatLng(firstPoint.lat, firstPoint.lng)
				}
        const distance = this.calculateDistance(
          firstPoint.lat(),
          firstPoint.lng(),
          latLng.lat(),
          latLng.lng()
        );
        if (distance < this.dynamicTolerance) {
          this.newPath.path.push(firstPoint);
					this.drawingPolyline = false
          return;
        }
      }
			const lastPoint = this.newPath.path[this.newPath.path.length - 1];
			if (this.newPath.path.length > 0 && this.calculateDistance(lastPoint.lat(), lastPoint.lng(), latLng.lat(), latLng.lng()) > this.toleranceDistance) {
				const lastLat = lastPoint.lat();
				const lastLng = lastPoint.lng();

				const midpoint = this.calculateMidpoint(lastLat, lastLng, latLng.lat(), latLng.lng());
				this.newPath.path.push(midpoint);
			}
      this.newPath.path.push(latLng);
    },
		calculateMidpoint(lat1, lng1, lat2, lng2) {
			const midLat = (lat1 + lat2) / 2;
			const midLng = (lng1 + lng2) / 2;
			return new this.google.maps.LatLng(midLat, midLng);
		},
    calculateDistance(lat1, lng1, lat2, lng2) {
      const R = 6371e3;
      const φ1 = (lat1 * Math.PI) / 180;
      const φ2 = (lat2 * Math.PI) / 180;
      const Δφ = ((lat2 - lat1) * Math.PI) / 180;
      const Δλ = ((lng2 - lng1) * Math.PI) / 180;

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c;
    }
  }
};
</script>

<style scoped>
.cursor-drawing {
  cursor: crosshair;
}
.hovering{
	background-color: #d9d9d9;
}
.handle_drag{
	cursor: move;
}
</style>