import {endpoints} from './endpoints'
import request from '../request'

export function addCrmEntry (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addCrmEntry,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function editCrmValue (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.editCrmValue,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function editCrmProjectDetail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.editCrmProjectDetail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function editCrmProjectType (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.editCrmProjectType,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function editCrmZoneIpt (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.editCrmZoneIpt,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function editContractValue (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.editContractValue,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function assignScheduleToUser (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.assignScheduleToUser,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCrmEntry (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCrmEntry,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function restoreCrmEntry (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.restoreCrmEntry,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function archiveCrmEntry (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.archiveCrmEntry,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}