<template>
	<v-menu offset-y>
		<template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
					<v-btn
						icon
						dark
						small
						color="#959595"
						class="mr-2"
						v-bind="{ ...tooltipAttrs, ...menuAttrs }"
						v-on="{ ...tooltipOn, ...menuOn }"
					>
						<v-icon dark>mdi-book-clock-outline</v-icon>
					</v-btn>
				</template>
				<span>Historial del presupuesto</span>
			</v-tooltip>
		</template>
		<v-list dense class="py-0 white">
			<v-list-item dense class="mb-0" style="min-height: 25px; background: #959595;">
				<div>
					<p class="caption mb-0 font-weight-bold white--text">Historial del Presupuesto Nº {{ budget.serial_id }} - {{ budget.created_at | toYear }}</p>
				</div>
			</v-list-item>
			<v-list-item dense class="my-0 py-0" style="min-height: 25px;">
				<div class="d-flex justify-space-between align-center flex-grow-1">
					<p class="caption mb-0 text--disabled">Estado</p>
					<p class="caption mb-0 text--disabled" style="width: 90px;">Fecha</p>
				</div>
			</v-list-item>
			<v-divider></v-divider>
			<v-list-item-group color="#524D4D" style="max-height: 350px;overflow-y:scroll;">
				<v-list-item
					v-for="(budgetStatus, index) in budget.statuses"
					:key="'ctstatus-'+index"
					dense
					style="min-width: 350px;"
					class="white"
				>
					<v-list-item-title dense>
						<div class="d-flex justify-space-between align-center item-background-hover">
							<div class="d-flex align-center justify-space-between caption mr-6">
								<v-icon class="mr-1" x-small :color="budgetStatusColor(budgetStatus.status)">mdi-circle</v-icon>
								<b>{{budgetStatus.status | budgetStatus}}</b>
							</div>
							<div class="d-flex justify-end caption" style="width: 90px;">
								<p class="mb-0 caption text-end">{{budgetStatus.created_at | toDateTime}}</p>
							</div>
						</div>
					</v-list-item-title>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-menu>
</template>

<script>
export default {
  name: 'BudgetStatusHistory',
  components: { },
	props: {
		budget: {
			type: Object,
			required: true
		}
	},
  data: () => ({
    validform: true,
		loadingData: false,
    newBudgetCategory: {
			category_id: null,
			name: null
    },
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
  mounted(){
  },
  methods: {
		budgetStatusColor(budgetStatus){
			return this.$options.filters.budgetStatusColor(budgetStatus)
		},
  }
};
</script>