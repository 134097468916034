<template>
  <v-container fluid>
		<div class="d-flex align-center justify-end">
			<div class="d-flex align-center mb-2" style="flex: 1;max-width: 400px;">
				<v-icon color="#524D4D" @click="searchForString">mdi-magnify</v-icon>
				<v-text-field
					v-model="searchInput"
					:loading="$store.state.contracts.loadingCompanyContractsList"
					outlined
					dense
					hide-details
					item-text="name"
					item-value="id"
					return-object
					clearable
					label="Búsqueda"
					class="mb-0 mr-2 custom-height-search custom-height-calendar"
					color="#524D4D"
					@input="sendToSearch"
				></v-text-field>
			</div>
		</div>
    <v-data-table
      :headers="headers"
      :items="$store.state.contracts.companyContractsList"
			:server-items-length="$store.state.contracts.companyContractsListCount"
      item-key="id"
			class="contract-table"
      :loading="$store.state.contracts.loadingCompanyContractsList"
			:options.sync="options"
      loading-text="Cargando datos..."
			:footer-props="{
        itemsPerPageText: 'Filas por página',
				'items-per-page-options': [10, 30, 50, 100]
			}"
    >
			<template v-slot:item.serial="{ item }">
				<div class="d-flex align-center">
					<div style="width:75px;"><b>{{ item.contract_info?.prefix?.prefix }}  {{ item.serial?.serial }} - {{ item.created_at | toYear}}</b></div>
				</div>
			</template>
			<template v-slot:item.client="{ item }">
				<b>{{ getUserName(item.morphable) }}</b>
				<div v-if="item.morphable">{{ item.morphable.client ? item.morphable.client.email:'No identificado'}}</div>
			</template>
      <template v-slot:item.details="{ item }">
				<div>
					<div class="d-flex">
						<div class="d-flex align-center flex-grow-1">
							<v-icon small class="mr-2">mdi-ruler-square</v-icon> <p class="mb-0 font-weight-bold">{{ item.morphable?.name }}</p>
						</div>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									color="warning"
									x-small
									v-bind="attrs"
									v-on="on"
									disabled
									@click="gotToAssociation(item)"
								>
									<v-icon dark small>mdi-eye-outline</v-icon>
								</v-btn>
							</template>
							<span>Ir al detalle</span>
						</v-tooltip>
					</div>
					<div class="d-flex align-center">
						<div class="d-flex align-center flex-grow-1" v-if="item.morphable">
							<div class="d-flex align-center flex-grow-1" v-if="item.morphable.responsible">
								<v-icon small class="mr-2" color="green">mdi-account-check-outline</v-icon>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<p
											class="mb-0 text-truncate font-weight-bold"
											v-bind="attrs"
											v-on="on"
										>
											{{ item.morphable.responsible.profile.name + ' ' + item.morphable.responsible.profile.lastname }}
										</p>
									</template>
									<span>{{ item.morphable.responsible.profile.name + ' ' + item.morphable.responsible.profile.lastname }}</span>
								</v-tooltip>
							</div>
							<div class="d-flex align-center flex-grow-1" v-else>
								<v-icon small class="mr-2" color="warning">mdi-account-cancel-outline</v-icon>
								<b>Sin Responsable</b>
							</div>
						</div>
						<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
							<template v-slot:activator="{ on, attrs }">
								<v-tooltip bottom>
									<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
										<v-btn
											icon
											color="warning"
											x-small
											v-bind="{ ...attrs, ...tooltipAttrs }"
											v-on="{ ...on, ...tooltipOn }"
										>
											<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
										</v-btn>
									</template>
									<span>Cambiar Responsable</span>
								</v-tooltip>
							</template>
							<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
								<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
									<p class="caption mb-0 font-weight-bold white--text">Cambiar Responsable</p>
								</v-list-item>
								<v-list-item-group color="#524D4D">
									<v-list-item
										v-for="(responsible, index) in $store.state.users.usersList"
										:key="index"
										dense
										@click="changeResponsible(item, responsible.id)"
									>
										<v-list-item-title dense>
											{{ responsible.profile?.name }} {{ responsible.profile?.lastname }}
										</v-list-item-title>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
					</div>
				</div>
      </template>
      <template v-slot:item.address="{ item }">
				<div>
					<p class="mb-0 font-weight-bold">{{ item.morphable?.info?.address?.address }}</p>
					<p class="mb-0">{{ item.morphable?.info?.address?.district?.name }}</p>
				</div>
			</template>
      <template v-slot:item.status="{ item }">
				<div class="d-flex align-center">
					<div>
						<v-chip
							:color="item.last_status?.status | contractStatusColor"
							label
							outlined
							x-small
							class="px-1"
						>
							<b>{{ item.last_status?.status | contractStatus }}</b>
						</v-chip>
						<p class="ml-2 mb-0 caption">{{ item.last_status?.description }}</p>
					</div>

					<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								dark
								small
								icon
								color="#524D4D"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
							</v-btn>
						</template>
						<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
							<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
								<p class="caption mb-0 font-weight-bold white--text">Cambiar Estado</p>
							</v-list-item>
							<v-list-item-group color="#524D4D">
								<v-list-item
									v-for="(status, index) in contractStatuses"
									:key="index"
									dense
									@click="changeContractStatus(item.id, status.value)"
								>
									<v-list-item-title dense>
										{{ status.title }}
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</div>
			</template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom v-if="$store.state.user.userInfo">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="warning"
							class="mx-1"
							@click="toContractDetail(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'
import {addNewStatus} from '@/helpers/api/contracts'

export default {
  data() {
    return {
      search: '',
			searchInput: null,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
			contracts: [],
			loading: true,
			contractStatuses: [
				{value: 1, title: 'Creado'},
				{value: 2, title: 'Esperando Pago Contrato'},
				{value: 3, title: 'Contrato Pagado'},
				{value: 4, title: 'Esperando Visto Bueno Empresa / Profesional'},
				{value: 5, title: 'Visto Bueno Empresa / Profesional'},
				{value: 6, title: 'Enviado a Cliente'},
				{value: 7, title: 'Observado por Cliente'},
				{value: 8, title: 'Aceptado'},
				{value: 9, title: 'Rechazado'},
				{value: 10, title: 'Firmado Cliente'},
				{value: 11, title: 'Firmado Ambas Partes'},
			],
      headers: [
				{ text: 'Nº', value: 'serial', class: 'with-divider', cellClass: 'with-divider', sortable: false },
				{ text: 'Cliente', value: 'client', width: '15%' },
        { text: 'Detalle', value: 'details', width: '20%' },
        { text: 'Dirección Proyecto', value: 'address', width: '25%' },
        { text: 'Estado', value: 'status', width: '15%' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
    options: {
      handler() {
				this.reloadInfo()
      },
      deep: true,
    },
		selectedCompany(newValue){
			if(newValue){
				this.reloadInfo()
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.reloadInfo()
		}
    eventBus.$on('reloadContract', () => {
			this.reloadInfo()
    })
	},
  methods: {
		sendToSearch: _debounce(function() { this.searchForString() }, 800),
		searchForString(){
			this.options = {...this.options, ...{page: 1}}
			this.reloadInfo()
		},
		async changeContractStatus(contract_id, status){
			const resp = await addNewStatus({contract_id, status})
			if(resp.code == 200){
				this.reloadInfo()
			}
		},
		reloadInfo(){
			let data = {
				...this.options,
				start: (this.options.page - 1) * this.options.itemsPerPage,
				limit: this.options.itemsPerPage,
			}
			if(this.searchInput){
				data = {...data, ...{search: this.searchInput}}
			}
			this.$store.dispatch('contracts/getContracts', data)
		},
		getUserName(item){
			let name = ''
			if(item && item.client && item.client.profile){
				name = item.client.profile.name + ' ' + item.client.profile.lastname
			}
			else{
				name = 'No identificado'
			}
			return name
		},
		getCompanyProjects(){
			this.$store.dispatch('projects/getProjects')
		},
		toContractDetail(contract){
			this.$router.push({ name: 'ContractDetail', query: { id: contract.id } })
		},
		getTypeIcon(type){
			switch (type) {
				case "App\\Models\\BotConversation":
					return 'mdi-contacts-outline'
				case "App\\Models\\PreProject":
					return 'mdi-information-variant-circle-outline'
				case "App\\Models\\Project":
					return 'mdi-ruler-square'
				default:
					break;
			}
			return 'Otro'
		},
		getTypeName(type){
			switch (type) {
				case "App\\Models\\BotConversation":
					return 'CRM'
				case "App\\Models\\PreProject":
					return 'PreProyecto'
				case "App\\Models\\Project":
					return 'Proyecto'
				default:
					break;
			}
			return 'Otro'
		},
		getType(type){
			switch (type) {
				case "App\\Models\\BotConversation":
					return 'crm'
				case "App\\Models\\PreProject":
					return 'preproject'
				case "App\\Models\\Project":
					return 'project'
				default:
					break;
			}
			return 'Otro'
		},
  },
};
</script>

<style>
.contract-index .message-container{
	margin-bottom: 0;
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.contract-index .message-container p{
	margin-bottom: 0;
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.contract-table table tr td{
	font-size: 11px!important;
}
.contract-table.resizable .v-data-table__wrapper th,
.contract-table.resizable .v-data-table__wrapper td {
	border-right: 1px solid #cacaca;
}
</style>