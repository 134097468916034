<template>
	<v-menu
		v-model="menuOpen"
		bottom
		offset-y
		left
		min-width="700"
		max-width="700"
		content-class="container-notification-menu-header"
		:close-on-click="false"
		:close-on-content-click="false"
		@input="changeMenu"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				outlined
				dark
				small
				color="#959595"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon dark>mdi-cog-outline</v-icon>
				<p class="mb-0">Configuración</p>
			</v-btn>
		</template>
		<div class="white" style="position: relative;">
			<v-btn
				icon
				dark
				x-small
				color="white"
				style="position:absolute;top: 5px; right: 5px;z-index: 9999;"
				@click="changeMenu(false)"
			>
				<v-icon dark>mdi-close</v-icon>
			</v-btn>
			<v-tabs
				v-model="tab"
				background-color="#524D4D"
				grow
				dark
				height="40"
			>
				<v-tab><p class="mb-0 caption">Especialidades</p></v-tab>
				<v-tab><p class="mb-0 caption">Proyectos (Costo)</p></v-tab>
				<v-tab><p class="mb-0 caption">Detalles</p></v-tab>
				<v-tab><p class="mb-0 caption">Detalles Generales</p></v-tab>
				<v-tab :disabled="!selectedCostSubCategoryId"><p class="mb-0 caption">Concepto</p></v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<div>
						<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-4">
							<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Especialidades Empresa</p>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="grey" class="mr-3"
												v-bind="attrs"
												v-on="on"
												small
												@click="setCostCategory(null)">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Nueva Especialidad</span>
							</v-tooltip>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
							<v-list-item
								v-for="(item, i) in companySpecialties"
								:key="i"
								class="px-0 py-0"
							>
								<div style="width: 100%">
									<div class="d-flex flex-row px-4 justify-space-between">
										<div class="px-4 caption">
											<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.name }}</p>
										</div>
										<div>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="warning"
														@click="setCostCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-pencil</v-icon>
													</v-btn>
												</template>
												<span>Editar Especialidad</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="error"
														@click="deleteCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-delete-outline</v-icon>
													</v-btn>
												</template>
												<span>Eliminar Especialidad</span>
											</v-tooltip>
										</div>
									</div>
								</div>
							</v-list-item>
						</v-list>
					</div>
				</v-tab-item>
				<v-tab-item>
					<div>
						<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-4">
							<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Proyectos (Costos)</p>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="grey" class="mr-3"
												v-bind="attrs"
												v-on="on"
												small
												@click="setCostCategory(null)">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Nuevo Proyecto (Costo)</span>
							</v-tooltip>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
							<v-list-item
								v-for="(item, i) in costCategories"
								:key="i"
								class="px-0 py-0"
								:class="{ 'selected': item.id == selectedCostCategoryId }"
							>
								<div style="width: 100%">
									<div class="d-flex flex-row px-4 justify-space-between">
										<div class="px-4 caption">
											<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.name }}</p>
										</div>
										<div>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="green"
														@click="setSelectedCostCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-chevron-right</v-icon>
													</v-btn>
												</template>
												<span>Ver Detalles</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="warning"
														@click="setCostCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-pencil</v-icon>
													</v-btn>
												</template>
												<span>Editar Proyecto</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="error"
														@click="deleteCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-delete-outline</v-icon>
													</v-btn>
												</template>
												<span>Eliminar Proyecto (Costo)</span>
											</v-tooltip>
										</div>
									</div>
								</div>
							</v-list-item>
						</v-list>
					</div>
				</v-tab-item>
				<v-tab-item>
					<div>
						<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-4">
							<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Detalles</p>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="grey" class="mr-3"
												v-bind="attrs"
												v-on="on"
												small
												@click="setCostSubCategory({type: 1})">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Nuevo Detalle</span>
							</v-tooltip>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-list class="pt-0" style="max-height: 300px;overflow: scroll;" v-if="selectedCostCategoryId && costSubCategories.length > 0">
							<v-list-item
								v-for="(item, i) in costSubCategories"
								:key="i"
								class="px-0 py-0"
								:class="{ 'selected': item.id == selectedCostSubCategoryId }"
							>
								<div style="width: 100%">
									<div class="d-flex flex-row px-4 justify-space-between">
										<div class="px-4 caption">
											<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.name }}</p>
										</div>
										<div>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="green"
														@click="setSelectedCostSubCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-chevron-right</v-icon>
													</v-btn>
												</template>
												<span>Ver y Editar Conceptos</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="warning"
														@click="setCostSubCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-pencil</v-icon>
													</v-btn>
												</template>
												<span>Editar Detalle</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="error"
														@click="deleteSubcategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-delete-outline</v-icon>
													</v-btn>
												</template>
												<span>Eliminar Detalle</span>
											</v-tooltip>
										</div>
									</div>
								</div>
							</v-list-item>
						</v-list>
						<div class="px-4 pb-4" v-else>
							<p class="caption" v-if="selectedCostCategoryId && costSubCategories.length == 0">Este proyecto no tiene detalles, por favor crea un detalle o selecciona otro proyecto</p>
							<p class="caption" v-else>Selecciona un Proyecto para ver los detalles de este</p>
							<v-select
								v-model="selectedCostCategoryId"
								:items="costCategories"
								:loading="$store.state.costbreakdown.loadingCompaniesCostCategories"
								:menu-props="{ top: true, offsetY: true }"
								item-text="name"
								item-value="id"
								label="Proyecto"
								class="custom-height-search custom-height-calendar"
								outlined
								dense
								required
							></v-select>
						</div>
					</div>
				</v-tab-item>
				<v-tab-item>
					<div>
						<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-4">
							<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Detalles Generales: Gastos Fijos y/o Variables</p>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="grey" class="mr-3"
												v-bind="attrs"
												v-on="on"
												small
												@click="setCostSubCategory({type: 2})">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Nuevo Detalle</span>
							</v-tooltip>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
							<v-list-item
								v-for="(item, i) in costGeneralSubCategories"
								:key="i"
								class="px-0 py-0"
								:class="{ 'selected': item.id == selectedCostSubCategoryId }"
							>
								<div style="width: 100%">
									<div class="d-flex flex-row px-4 justify-space-between">
										<div class="px-4 caption">
											<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.name }}</p>
										</div>
										<div>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="green"
														@click="setSelectedCostSubCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-chevron-right</v-icon>
													</v-btn>
												</template>
												<span>Ver Conceptos</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="warning"
														@click="setCostSubCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-pencil</v-icon>
													</v-btn>
												</template>
												<span>Editar Detalle</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="error"
														@click="deleteSubcategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-delete-outline</v-icon>
													</v-btn>
												</template>
												<span>Eliminar Detalle</span>
											</v-tooltip>
										</div>
									</div>
								</div>
							</v-list-item>
						</v-list>
					</div>
				</v-tab-item>
				<v-tab-item>
					<div>
						<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-4">
							<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Conceptos</p>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="grey" class="mr-3"
												v-bind="attrs"
												v-on="on"
												small
												@click="setCostConcept(null)">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Nuevo concepto de costo</span>
							</v-tooltip>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
							<v-list-item
								v-for="(item, i) in costItems"
								:key="i"
								class="px-0 py-0"
							>
								<div style="width: 100%">
									<div class="d-flex flex-row px-4 justify-space-between">
										<div class="d-flex flex-row align-center caption">
											<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.concept }}</p>
										</div>
										<div>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="warning"
														@click="setCostConcept(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-pencil</v-icon>
													</v-btn>
												</template>
												<span>Editar Concepto</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="error"
														@click="deleteConcept(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-delete-outline</v-icon>
													</v-btn>
												</template>
												<span>Eliminar Concepto</span>
											</v-tooltip>
										</div>
									</div>
								</div>
							</v-list-item>
						</v-list>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</div>
		<v-divider class="mx-2"></v-divider>
	</v-menu>
</template>

<script>
// import _debounce from 'lodash/debounce'
import {deleteCostCategoryCompany, deleteCostSubCategoryCompany, deleteCostItemCompany} from '@/helpers/api/costs'
// import {createSpecialty, updateSpecialty, deleteSpecialty, getSpecialties} from '@/helpers/api/company'

export default {
  name: 'CostBreakdownSettings',
  data: () => ({
		menuOpen: false,
		selectedCostCategoryId: null,
		selectedCostSubCategoryId: null,
		tab: null,
    validform: true,
		loadingData: false,
    newBudgetConcept: {
			budget_concept_id: null,
			name: null
    },
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		companySpecialties(){
			return this.$store.state.costbreakdown.companiesSpecialties
		},
		costCategories(){
			return this.$store.state.costbreakdown.companiesCostCategories
		},
		costSubCategories(){
			if(this.selectedCostCategoryId){
				const subcategories = this.costCategories.find(item => item.id === this.selectedCostCategoryId)
				return subcategories ? subcategories.subcategories : []
			}
			return []
		},
		costGeneralSubCategories(){
			return this.$store.state.costbreakdown.companiesCostGeneralSubCategories
		},
		costItems(){
			if(this.costSubCategories && this.selectedCostSubCategoryId){
				let costs = this.costSubCategories.find(item => item.id === this.selectedCostSubCategoryId)
				if(!costs){
					costs = this.costGeneralSubCategories.find(item => item.id === this.selectedCostSubCategoryId)
				}
				return costs ? costs.items : []
			}
			return []
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('costbreakdown/getCompanySpecialties')
				this.$store.dispatch('costbreakdown/getCompanyCostCategories')
			}
		},
	},
  mounted(){
		if(this.$store.state.budget.newBudgetConceptModalEditing){
			this.newBudgetConcept = this.$store.state.budget.newBudgetConceptModalEditing
		}
		if(this.selectedCompany){
			this.$store.dispatch('costbreakdown/getCompanySpecialties')
			this.$store.dispatch('costbreakdown/getCompanyCostCategories')
		}
  },
  methods: {
		setSelectedCostCategory(item){
			this.selectedCostCategoryId = item.id
			this.tab = 1
		},
		setSelectedCostSubCategory(item){
			this.selectedCostSubCategoryId = item.id
			this.tab = 2
		},
		changeMenu(value){
			this.menuOpen = value
		},
		setCostCategory(item){
			this.$store.commit('costbreakdown/setNewCostBreakdownCategoryModal', true)
			if(item){
				this.$store.commit('costbreakdown/setNewCostBreakdownCategoryModalEditing', {...item, ...{company_cost_category_id: item.id}})
			}
		},
		setCostSubCategory(item){
			this.$store.commit('costbreakdown/setNewCostBreakdownSubCategoryModal', true)
			let data = {company_cost_category_id: this.selectedCostCategoryId}
			if(item){
				data = {...data, ...item, ...{company_cost_subcategory_id: item.id}}
			}
			this.$store.commit('costbreakdown/setNewCostBreakdownSubCategoryModalEditing', data)
		},
		setCostConcept(item){
			this.$store.commit('costbreakdown/setNewCostBreakdownItemModal', true)
			let data = {company_cost_subcategory_id: this.selectedCostSubCategoryId}
			if(item){
				data = {...data, ...item, ...{company_cost_item_id: item.id}}
			}
			this.$store.commit('costbreakdown/setNewCostBreakdownItemModalEditing', data)
		},
		async deleteCategory(item){
			await deleteCostCategoryCompany({company_cost_category_id: item.id})
			this.$store.dispatch('costbreakdown/getCompanyCostCategories')
		},
		async deleteSubcategory(item){
			await deleteCostSubCategoryCompany({company_cost_subcategory_id: item.id})
			this.$store.dispatch('costbreakdown/getCompanyCostCategories')
		},
		async deleteConcept(item){
			await deleteCostItemCompany({company_cost_item_id: item.id})
			this.$store.dispatch('costbreakdown/getCompanyCostCategories')
		},
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
	.handle_drag{
		cursor: move;
	}
	.selected{background-color: #ececec;}
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>