import { getCompanyPreProjects, getArchivedCompanyPreProjects, getPreProject } from "@/helpers/api/preprojects"
import {getClientPreProjects} from '@/helpers/api/clients'
import router from '@/router'
const listingColumns = localStorage.getItem('preproject_columns')

const PreProjectsStore = {
  namespaced: true,
  state: {
		loadingPreProjects: false,
    preProjects: [],
    preProjectsCount: 0,
    archivedPreProjects: [],
    archivedPreProjectsCount: 0,
		preProject: null,
		loadingPreProject: false,
		newPreProjectModal: false,
		listingColumns: listingColumns ? JSON.parse(listingColumns):{
			id: true,
			client: false,
			address: false,
			rol: true,
			project_name: false,
			entity: true,
			unarchive: true,
			cbr: true,
			sii: true,
			responsible: true,
			observation: true,
			actions: true,
		},
		openChatModal: false,
		openBudgetModal: false,
		loadingClientPreprojects: false,
		clienPreprojects: []
  },
  mutations: {
    setPreProjects(state, preProjects) {
      state.preProjects = preProjects
    },
    setArchivedPreProjects(state, archivedPreProjects) {
      state.archivedPreProjects = archivedPreProjects
    },
    setPreProjectsCount(state, count) {
      state.preProjectsCount = count
    },
    setArchivedPreProjectsCount(state, count) {
      state.archivedPreProjectsCount = count
    },
    setLoadingPreProjects(state, status) {
      state.loadingPreProjects = status
    },
    setPreProject(state, preProject) {
      state.preProject = preProject
    },
    setLoadingPreProject(state, status) {
      state.loadingPreProject = status
    },
    setNewPreProjectModal(state, status) {
      state.newPreProjectModal = status
    },
    setListingColumns(state, listingColumns) {
      state.listingColumns = listingColumns
			localStorage.setItem('preproject_columns', JSON.stringify(listingColumns))
    },
    setOpenChatModal(state, status) {
      state.openChatModal = status
    },
    setOpenBudgetModal(state, status) {
      state.openBudgetModal = status
    },
    setLoadingClientPreprojects(state, status) {
      state.loadingClientPreprojects = status
    },
    setClienPreprojects(state, status) {
      state.clienPreprojects = status
    },
  },
  actions: {
    async getPreProjects({ commit }, {search = null, start = 0, limit = 20} = {}) {
			start = isNaN(start) ? 0 : start
      commit('setLoadingPreProjects', true)
      const data = {
				search,
        start,
        limit
      }
      const preProjects = await getCompanyPreProjects(data)
      if (preProjects.code === 200) {
        commit('setPreProjects', [])
				setTimeout(() => {
					commit('setPreProjects', preProjects.preprojects)
					commit('setPreProjectsCount', preProjects.preprojects_count)
				}, 50);
      }
      commit('setLoadingPreProjects', false)
    },
    async getClientPreProjects({ commit }, {search = null, client_id = null, start = 0, limit = 20} = {}) {
			start = isNaN(start) ? 0 : start
      commit('setLoadingClientPreprojects', true)
      const data = {
				client_id,
				search,
        start,
        limit
      }
      const preProjects = await getClientPreProjects(data)
      if (preProjects.code === 200) {
				commit('setClienPreprojects', preProjects.client_preprojects)
      }
      commit('setLoadingClientPreprojects', false)
    },
    async getArchivedPreProjects({ commit }) {
      commit('setLoadingPreProjects', true)
      const data = {
        start: 0,
        limit: 20
      }
      const preProjects = await getArchivedCompanyPreProjects(data)
      if (preProjects.code === 200) {
        commit('setArchivedPreProjects', [])
				setTimeout(() => {
					commit('setArchivedPreProjects', preProjects.preprojects)
					commit('setArchivedPreProjectsCount', preProjects.preprojects_count)
				}, 50);
      }
      commit('setLoadingPreProjects', false)
    },
    getPreProject({ commit }, { preproject_id = null, showLoader = true } = {}) {
			if(showLoader){
				commit('setLoadingPreProject', true)
				commit('setPreProject', null)
			}
      const data = {
        preproject_id
      }
      getPreProject(data)
				.then((preproject) => {
					if (preproject.code === 200) {
						commit('setPreProject', preproject.preproject)
					}
					commit('setLoadingPreProject', false)
				})
				.catch(() => {
					commit('setPreProject', null)
					commit('setLoadingPreProject', false)
					router.push({name: 'PreProjects'})
				})
    }
  }
}

export default PreProjectsStore
