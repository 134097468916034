<template>
	<div>
    <v-data-table
      :headers="headers"
      :items="client_contracts"
      item-key="id"
      :loading="loadingList"
			height="200px"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
			:item-class="getRowClass"
    >
      <template v-slot:item.name="{ item }">
				<p class="caption mb-0">{{ item.name }}</p>
      </template>
      <template v-slot:item.company="{ item }">
				<p class="mb-0 caption">{{ item.company.name }}</p>
      </template>
			<template v-slot:item.name="{ item }">
				<div>
					<p class="caption mb-0">{{item.morphable?.name ?? '--'}}</p>
				</div>
			</template>
			<template v-slot:item.address="{ item }">
				<div>
					<p class="caption mb-0">{{item.morphable?.info?.address?.address ?? '--'}}</p>
					<p class="caption mb-0">{{item.morphable?.info?.address?.district?.name ?? '--'}}</p>
				</div>
			</template>
			<template v-slot:item.contract_date="{ item }">
				<div>
					<p class="caption mb-0">{{item.created_at | toDate}}</p>
				</div>
			</template>
      <template v-slot:item.status="{ item }">
				<div>
					<v-chip
						:color="item.last_status?.status | contractStatusColor"
						label
						outlined
						x-small
						class="px-1"
					>
						<b>{{ item.last_status?.status | contractStatus }}</b>
					</v-chip>
					<p class="ml-2 mb-0 caption">{{ item.last_status?.description }}</p>
				</div>
			</template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="openContractDetail(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
      </template>
    </v-data-table>
	</div>
</template>

<script>
// import moment from 'moment'
import {getClientContracts} from '@/helpers/api/clients'
export default {
	name: 'ClientsContractList',
	props: {
		clientId: {
      type: Number,
      required: true
    },
		openContractDetail: {
      type: Function,
      required: true
    }
	},
	computed: {
		selected_contract() {
			return this.$route.query?.ct
		}
	},
	data: () => ({
		loadingList: true,
		client_contracts: [],
		headers: [
        { text: '#', value: 'id', align: 'center', sortable: false },
        { text: 'Empresa', value: 'company', align: 'center', sortable: false },
        { text: 'Nombre Proyecto', value: 'name', align: 'center', sortable: false },
        { text: 'Dirección', value: 'address', align: 'center', sortable: false },
        { text: 'Fecha Creación Contrato', value: 'contract_date', align: 'center', sortable: false },
        { text: 'Estado', value: 'status', align: 'center', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
	}),
	mounted(){
		this.getClientContracts()
		const contract_id = this.$route.query.ct
		if(contract_id){
			this.openContractDetail(contract_id)
		}
	},
	methods: {
		getRowClass(item) {
			return item.id == this.selected_contract ? 'custom-client-highlight-row' : '';
		},
		async getClientContracts(){
			this.loadingList = true
			try {
				const client_contract = await getClientContracts({
					client_id: this.clientId,
					start: 1,
					limit: 100
				})
				if(client_contract.code == 200){
					this.client_contracts = client_contract.client_contracts
				}
			} catch (error) {
				console.log(error)
			}
			this.loadingList = false
		},
	}
}
</script>

<style>
.custom-client-highlight-row {
  background-color: #e8e8e8 !important;
}
</style>