<template>
	<v-container
		ref="header"
		fluid
		class="header-app-custom d-flex flex-row justify-space-between"
		:style="$store.state.menu.menuSidebarStatus ? 'padding-left: 240px;':''">
		<div class="d-flex" style="width: 100%;">
			<v-icon v-show="$vuetify.breakpoint.mobile" class="pa-1 mr-2" large @click="toggleMenu">mdi-menu</v-icon>
			<div class="d-flex justify-space-between align-center" style="width: 100%;">
				<div class="d-flex align-center">
					<div>
						<div class="d-flex align-center">
							<h3 class="mb-0 font-weight-bold">{{this.currentRoute?.label}}</h3>
							<p class="ml-2 mb-0 caption text--secondary" v-if="this.currentRoute?.subtitle">{{this.currentRoute?.subtitle}}</p>
						</div>
						<!-- <div v-if="$route.name == 'Dashboard'" class="caption">Panel de control</div> -->
						<div class="d-flex align-center justify-center mt-1">
							<ActionButtons class="mr-4"/>
							<div class="d-flex align-center" v-if="!$vuetify.breakpoint.mobile">
								<v-btn
									depressed
									color="#959595"
									x-small
									dark
									outlined
									class="mr-2"
									@click="$router.go(-1)"
								>
									<v-icon small>mdi-chevron-left</v-icon>
									<p class="mb-0 ml-2 caption">Atrás</p>
								</v-btn>
								<v-btn
									depressed
									color="#959595"
									x-small
									dark
									outlined
									@click="$router.go(1)"
								>
									<v-icon small>mdi-chevron-right</v-icon>
									<p class="mb-0 ml-2 caption">Avanzar</p>
								</v-btn>
								<div style="width: 220px;height: 10px;"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<PreProjectButtons />
		<CRMButtons />
		<v-divider vertical class="mx-2" v-if="['PreProject', 'Client'].includes($route.name) && $store.state.preprojects.preProject"></v-divider>
		<div class="d-flex justify-center align-center" :class="this.$vuetify.breakpoint.mobile ? 'flex-column':''">
			<RTDBNotifications />
			<AccountHeader v-if="$store.state.user.isAuthenticated" />
		</div>
	</v-container>
</template>

<script>

import AccountHeader from '@/components/Helpers/Account/AccountHeader'
import ActionButtons from '@/components/Layout/Header/ActionButtons'
import PreProjectButtons from '@/components/Layout/Header/Parts/PreProjectButtons'
import CRMButtons from '@/components/Layout/Header/Parts/CRMButtons'
import RTDBNotifications from '@/components/Helpers/Firebase/RTDBNotifications'
import {redirectToGoogle, getEvents} from '@/helpers/api/schedule'

export default {
	name: 'Header',
	components: { ActionButtons, AccountHeader, PreProjectButtons, CRMButtons, RTDBNotifications },
	computed: {
		editPermission(){
			console.log('ppppp: ', this.$checkPermissions(['edit_preprojects']))
			return this.$checkPermissions(['edit_preprojects'])
		},
    canGoBack() {
      return window.history.length > 1;
    },
    currentRoute() {
			if(this.$route.name == 'Dashboard'){
				return 'Bienvenido, ' + this.$store.state.user?.userInfo?.profile?.name
			}
			return this.$route.meta;
    }
	},
	data: () => ({
		fab: false,
    resizeObserver: null
	}),
	beforeUnmount() {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	},
	mounted(){
		this.$nextTick(() => {
			const header = this.$refs.header;
			if (!header) return;
			this.resizeObserver = new ResizeObserver(entries => {
				for (let entry of entries) {
					const newHeight = entry.target.offsetHeight;
					this.$store.commit('general/setHeaderHeight', newHeight);
				}
			});
			this.resizeObserver.observe(header);
		});
	},
	methods: {
		toggleEditSocialPreproject(){
			if(this.$store.state.general.publicProfileModal){
				this.$store.commit('general/setPublicProfileModalEdditing', null)
			}
			else{
				let data = {
					morphable_type: 'preproject',
					morphable_id: this.$store.state.preprojects.preProject.id,
				};

				const preProject = this.$store.state.preprojects.preProject;
				if (preProject && preProject.public_profile) {
					data = {
						...data,
						...preProject.public_profile,
					};
				}

				this.$store.commit('general/setPublicProfileModalEdditing', data);
			}
			this.$store.commit('general/setPublicProfileModal', !this.$store.state.general.publicProfileModal)
		},
		async syncCalendarAgenda(){
			const events = await getEvents()
			if(events.code == 200){
				const messageData = {
					message: 'Sincronización realizada correctamente',
					title: 'Sincronización Completa',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			}
			else{
				const messageData = {
					message: 'Hubo un error en la sincronización por favor intenta nuevamente',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		},
		async toAsociateCalendar(){
			const redirect = await redirectToGoogle()
			if(redirect.code == 200){
				window.location.href = redirect.url
			}
		},
		toggleMenu(){
			if(this.$vuetify.breakpoint.mobile){
				this.$store.commit('menu/setMenuSidebarStatus', !this.$store.state.menu.menuSidebarStatus)
				return null
			}
			this.$store.commit('menu/toggleSidebarMini')
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    }
	}
};
</script>
<style scoped>
	.header-app-custom{
		border-bottom: 1px solid rgb(202, 202, 202);
		position: fixed;
		padding-left: 240px;
		z-index: 1;
		background: white;
	}
</style>