<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.users.userSignatureModal"
      max-width="600"
			persistent
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row align-center justify-start">
								<v-icon color="warning" class="mr-2">mdi-draw-pen</v-icon>
								<p class="mb-0 font-weight-bold text-primary body-1">Definir Firma Simple</p>
							</div>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon
										dark
										small
										color="#959595"
										@click="$store.commit('users/setUserSignatureModal', false)"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon dark>mdi-close</v-icon>
									</v-btn>
								</template>
								<span>Cerrar</span>
							</v-tooltip>
						</div>
						<div>
							<p>Por favor dibuja tu firma dentro el recuadro a continuación. Cuando estés conforme presiona el botón Guardar.</p>
							<div>
								<div class="custom-signature-pad-container d-flex" v-if="userSignature && !forceNew">
									<img :src="userSignature.file.file_link" style="width: 100%;height:100%;object-fit:contain;"/>
								</div>
								<div class="custom-signature-pad-container" :class="!userSignature || (userSignature && forceNew) ? 'd-flex':'d-none'">
									<img :src="base64Signature" v-if="base64Signature" style="width: 100%;height:100%;object-fit:contain;"/>
									<VueSignaturePad v-if="showSignaturePad && !base64Signature" width="100%" height="100%" ref="signaturePad" />
								</div>
								<v-divider class="my-2"></v-divider>
								<div class="d-flex justify-space-between">
									<v-btn
										depressed
										dark
										outlined
										small
										color="#959595"
										@click="clearSignature"
									>
										<v-icon dark>mdi-backspace-outline</v-icon>
										<p class="ml-2 mb-0">Limpiar</p>
									</v-btn>
									<div class="d-flex">
										<v-btn
											depressed
											dark
											outlined
											small
											color="#959595"
											class="mr-2"
											v-if="forceNew && userSignature"
											@click="forceNew = false"
										>
											<v-icon dark>mdi-close</v-icon>
											<p class="ml-2 mb-0">Cancelar</p>
										</v-btn>
										<v-btn
											depressed
											dark
											outlined
											small
											color="#959595"
											class="mr-2"
											@click="triggerFileInput"
										>
											<v-icon dark>mdi-image-plus-outline</v-icon>
											<p class="ml-2 mb-0">Subir Imagen</p>
										</v-btn>
										<v-btn
											depressed
											dark
											outlined
											small
											color="#959595"
											@click="saveSignature"
										>
											<v-icon dark>mdi-check</v-icon>
											<p class="ml-2 mb-0">Guardar</p>
										</v-btn>
										<v-file-input
											ref="fileInput"
											v-model="fileSignature"
											show-size
											outlined
											dense
											accept=".png,.jpg,.jpeg"
											placeholder="Seleccione imagen con su firma"
											prepend-inner-icon="mdi-image"
											prepend-icon=""
											style="display: none;"
											@change="onFileSelected"
										></v-file-input>
									</div>
								</div>
							</div>
						</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {setSimpleSignature} from '@/helpers/api/user'

export default {
  name: 'UserSignatureModal',
	components: {},
  data: () => ({
		showSignaturePad: false,
		fileSignature: null,
		base64Signature: null,
		forceNew: false,
		fromFile: false
  }),
  computed: {
		userSignature(){
			return this.$store.state.user.userInfo?.signature
		},
	},
	watch: {
	},
  mounted(){
		this.$nextTick(() => {
			this.showSignaturePad = true
		})
  },
  methods: {
		clearSignature(){
      this.$refs.signaturePad.clearSignature()
			this.fileSignature = null
			this.base64Signature = null
			this.forceNew = true
			this.showSignaturePad = false
			this.$nextTick(() => {
				this.showSignaturePad = true
			})
			// setTimeout(() => {
			// 	this.showSignaturePad = true
			// }, 500)
		},
		saveSignature(){
			if(this.fromFile && this.fileSignature){
				this.uploadNewSignature()
				return
			}
      const save = this.$refs.signaturePad.saveSignature()
			if(save.isEmpty){
				const messageData = {
					message: 'Por favor dibuja la firma o selecciona una imagen para subir',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
				return
			}
			this.fileSignature = this.base64ToFile(save.data, 'firma.png')
			this.base64Signature = save.data
			this.uploadNewSignature()
    },
		async uploadNewSignature(){
			const formData = new FormData()
			formData.append('signature', this.fileSignature)
			const signature = await setSimpleSignature(formData)
			if(signature.code == 200){
				this.$store.dispatch('user/getUserInfo')
				const messageData = {
					message: 'Tu firma simple ha sido actualizada correctamente',
					title: 'Firma actualizada',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
				this.$store.commit('users/setUserSignatureModal', false)
			}
			else{
				const messageData = {
					message: 'Ha ocurrido un error, por favor intenta nuevamente',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		},
    triggerFileInput() {
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    onFileSelected(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
					this.fileSignature = this.base64ToFile(e.target.result, 'firma.png')
          this.base64Signature = e.target.result
					this.fromFile = true
        };
        reader.readAsDataURL(file)
      }
    },
		base64ToFile(base64, filename) {
			let arr = base64.split(',')
			let mime = arr[0].match(/:(.*?);/)[1]
			let bstr = atob(arr[1])
			let n = bstr.length
			let u8arr = new Uint8Array(n)

			while (n--) {
				u8arr[n] = bstr.charCodeAt(n)
			}

			return new File([u8arr], filename, { type: mime })
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
	.custom-signature-pad-container{
		border: 1px solid #959595;
		border-radius: 10px;
		min-height: 200px;
		min-width: 250px;
		max-height: 400px;
		margin: auto;
	}
	/deep/ .custom-signature-pad-container > div > canvas,
	/deep/ .custom-signature-pad-container > img{
		min-height: 200px;
		max-height: 400px;
	}
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>