<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.vt.newReportTemplateModal"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-plus-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Editor de Plantillas</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#FF1209" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4">
								<v-col cols="12" class="py-0">
									<v-text-field
										v-model="newReportTemplate.name"
										:rules="requiredRule"
										label="Nombre plantilla"
										solo
										flat
										hide-details
										dense
										outlined
									></v-text-field>
                </v-col>
								<v-col cols="12">
									<div v-if="loadingMce" class="d-flex flex-column justify-center align-center" style="min-height: 200px;">
										<v-skeleton-loader
											style="width: 250px;"
											class="mb-1"
											type="paragraph"
										></v-skeleton-loader>
										<p>Cargando editor...</p>
									</div>
									<vue2-tinymce-editor
										v-else
										:inline="true"
										v-model="newReportTemplate.content"
										:options="{ menubar: false }" ref="tinymceEditor"></vue2-tinymce-editor>
								</v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
										<v-btn
											depressed
											color="#524D4D"
											:dark="!(!newReportTemplate.name || newReportTemplate.name.length == 0)"
											small
											outlined
											:loading="loading"
											class="ml-2"
											style="height: 40px"
											:disabled="!newReportTemplate.name || newReportTemplate.name.length == 0"
											@click="saveReportTemplate()"
										>
											<v-icon small>mdi-send</v-icon>
											<p class="mb-0 ml-2 caption">Guardar Plantilla</p>
										</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import {createVTReportTemplate, updateVTReportTemplate} from '@/helpers/api/vts'

export default {
  name: 'NewUserModal',
  components: {
    Vue2TinymceEditor
  },
  data: () => ({
    validform: true,
		loadingMce: true,
		newReportTemplate: {
			id: null,
			content: '',
			name: null
		},
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
		loading: false,
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ]
  }),
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
	},
  mounted(){
		if(this.$store.state.vt.newReportTemplateEditing){
			this.newReportTemplate = this.$store.state.vt.newReportTemplateEditing
		}
		this.$nextTick(() => {
				this.loadingMce = false
		});
  },
  methods: {
		async saveReportTemplate(){
      if(this.$refs.form.validate()){
				const data = { ...this.newReportTemplate, ...{template_id: this.newReportTemplate.id}}
				const report = this.newReportTemplate.id ? await updateVTReportTemplate(data):await createVTReportTemplate(data)
				if(report.code == 200){
					this.$store.dispatch('vt/getVtReportTemplates')
					const data = {
						message: this.newReportTemplate.id  ? 'Actualización realizada correctamente.':'Creación realizada correctamente',
						title: 'Ok',
						created: new Date(),
						type: 'success',
						icon: 'mdi-check-circle',
						color: '#3EBFA3'
					}
					this.$store.dispatch('general/addMessage', data)
					this.closeModal()
				}
				else{
					const data = {
						message: 'Por favor intenta nuevamente.',
						title: 'Error',
						created: new Date(),
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#FF1209',
					}
					this.$store.dispatch('general/addMessage', data)
				}
			}
		},
    closeModal(){
      this.$store.commit('vt/setNewReportTemplateModal', false)
			this.$store.commit('vt/setNewReportTemplateEditing', null)
      this.newReportTemplate = {
				id: null,
				content: '',
				name: null
			}
			this.loadingMce = true
      this.validform = true
      this.loadingData = false
    }
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>