<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.contracts.modalContract"
      max-width="700"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="#524D4D" class="mr-2">mdi-plus-circle-outline</v-icon>
								<p class="mb-0 font-weight-bold text-primary title">Nuevo Contrato</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#FF1209" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4">
								<v-col cols="12" class="py-0">
									<v-autocomplete
										v-model="selectedProject"
										:items="companyProjects"
										:loading="$store.state.projects.loadingCompanyProjects"
										:menu-props="{ bottom: true, offsetY: true }"
										:search-input.sync="searchTerm"
										item-text="name"
										item-value="id"
										label="Busca un Proyecto"
										class="custom-height-search custom-height-calendar flex-grow-0 mb-4"
										outlined
										dense
										required
										no-filter
										return-object
										@input="resetExistence"
									>
										<template v-slot:item="data">
											<v-list-item-content>
												<v-list-item-title>
													<div class="d-flex">
														<p class="mb-0" style="width: 200px">
															<span class="font-weight-bold">{{data.item.name}}</span><br/>
															<span class="light-blue-text">{{data.item.info?.address?.district?.name}}</span>
														</p>
														<span>-</span>
														<p class="flex-grow-1 text-right mb-0">
															<span class="font-weight-bold">{{data.item.client?.profile?.name}} {{data.item.client?.profile?.lastname}}</span><br/>
															<span class="light-blue-text">{{data.item.client?.email}}</span>
														</p>
													</div>
												</v-list-item-title>
											</v-list-item-content>
										</template>
									</v-autocomplete>
                </v-col>
								<!-- <v-col cols="12" class="py-0">
									<v-select
										v-model="selectedPrefix"
										:items="$store.state.budget.companiesBudgetPrefixes"
										:loading="$store.state.budget.loadingBudgetPrefixes"
										:menu-props="{ top: true, offsetY: true }"
										item-text="name"
										item-value="id"
										label="Tipo de Presupuesto"
										class="custom-height-search custom-height-calendar"
										outlined
										dense
										required
									></v-select>
                </v-col> -->
              </v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row align-center justify-end">
										<div class="d-flex align-center" v-if="existentContract">
											<v-chip
												color="warning"
												label
												outlined
												x-small
												
											>
												<b>El contrato para este proyecto ya existe</b>
											</v-chip>
											<v-btn
												class="mx-2 my-1"
												color="green"
												raised
												dark
												@click="goToContract"
											>
												<p class="mb-0">Ir al Contrato</p>
											</v-btn>
										</div>
										<div class="d-flex align-center" v-else>
											<v-btn
												class="mx-2 my-1"
												color="white"
												raised
												@click="closeModal"
											>
												<p class="mb-0 text-primary">Cancelar</p>
											</v-btn>
											<v-btn
												class="mx-2 my-1"
												color="#524D4D"
												raised
												dark
												:loading="loadingData"
												@click="sendForm"
											>
												<p class="mb-0">Confirmar</p>
											</v-btn>
										</div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {createContract} from '@/helpers/api/contracts'

export default {
  name: 'CreateContractModal',
  components: { },
  data: () => ({
    validform: true,
		searchTerm: null,
		loadingData: false,
		selectedProject: null,
		selectedPrefix: null,
		existentContract: false,
		contractInfo: null,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		companyProjects(){
			if(this.searchTerm){
				const projects = this.$store.state.projects.companyProjects.filter((companyProject) => {
					
					return companyProject.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
								companyProject.info?.address?.district?.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
								companyProject.info?.address?.district?.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
								companyProject.client?.profile?.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
								companyProject.client?.profile?.lastname.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
								companyProject.client?.email.toLowerCase().includes(this.searchTerm.toLowerCase())
				})
				return projects
			}
			return this.$store.state.projects.companyProjects
		},
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.getCompanyProjects()
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.getCompanyProjects()
		}
	},
  methods: {
		filterProjects(search){
			console.log('search', search)
			return this.companyProjects
		},
		getCompanyProjects(){
			this.$store.dispatch('projects/getProjects')
		},
    closeModal(){
      this.$store.commit('contracts/setModalContract', false)
			this.selectedProject = null
      this.validform = true
      this.loadingData = false
    },
		resetExistence(){
			this.existentContract = false
			this.contractInfo = null
		},
		goToContract(){
			this.$router.push({ name: 'ContractDetail', query: { id: this.contractInfo.id } })
			this.closeModal()
		},
    async sendForm(){
      if(this.$refs.form.validate()){
				this.loadingData = true
				const data = {
					item_type: 'project',
					item_id: this.selectedProject.id
				}
        const contract = await createContract(data)
				if(contract.code === 200){
					if(contract.is_new){
						const messageData = {
							message: 'Se ha creado correctamente el contrato',
							title: 'Contrato creado',
							type: 'success',
							icon: 'mdi-check-circle',
							color: '#3EBFA3',
						}
						this.$router.push({ name: 'ContractDetail', query: { id: contract.contract.id } })
						this.setMessage(messageData)
						this.closeModal()
					}
					else{
						const messageData = {
							message: 'Ya existe un contrato para el proyecto seleccionado',
							title: 'Contrato Existente',
							type: 'warning',
							icon: 'mdi-alert-outline',
							color: 'orange',
						}
						this.contractInfo = contract.contract
						this.setMessage(messageData)
						this.existentContract = true
					}
        }
        else{
          const messageData = {
            message: 'Hubo un error en la operación, por favor intenta nuevamente',
            title: 'Ha ocurrido un error',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#FF1209',
          }
          this.setMessage(messageData)
        }
				this.loadingData = false
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>