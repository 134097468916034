<template>
	<v-container fluid pa-0 style="width: auto;"
		:style="menuOpen ? 'z-index: 999;':''">
		<v-menu
			v-model="menuOpen"
			bottom
			offset-y
			left
			min-width="200"
			max-width="200"
			:close-on-click="false"
			:close-on-content-click="false"
			content-class="container-notification-menu-header"
			@input="changeMenu"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					fab
					dark
					outlined
					class="button-activator-notifications"
					small
					color="#959595"
					v-bind="attrs"
					v-on="on"
				>
					<img :src="$store.state.user.userInfo.profile.avatar" class="user-avatar" v-if="$store.state.user.userInfo?.profile?.avatar"/>
					<v-icon v-else class="user-avatar icon" color="#524D4D">mdi-account</v-icon>
				</v-btn>
			</template>
			<div>
				<p class="caption mb-0 pa-2">{{$store.state.user.userInfo?.profile?.name}} {{$store.state.user.userInfo?.profile?.lastname}}</p>
				<v-divider></v-divider>
				<v-list dense>
					<v-list-item
						class="px-0 py-0 my-0 caption compact-item"
						@click="editUser($store.state.user.userInfo)"
						link
					>
						<v-list-item-title class="pa-2">Mis Datos</v-list-item-title>
					</v-list-item>
					<v-list-item
						class="px-0 py-0 my-0 caption compact-item"
						@click="openMySignatureModal"
						link
					>
						<v-list-item-title class="pa-2">Mi Firma</v-list-item-title>
					</v-list-item>
					<v-list-item
						class="px-0 py-0 my-0 caption compact-item"
						@click="handleItemClick"
						link
					>
						<v-list-item-title class="pa-2">Datos Facturación</v-list-item-title>
					</v-list-item>
				</v-list>
			</div>
		</v-menu>
	</v-container>
</template>

<script>
export default {
	name: 'AccountHeader',
	components: {},
	data: () => ({
		menuOpen: false
	}),
	mounted(){
	},
	methods: {
		changeMenu(value){
			this.menuOpen = value
		},
		handleItemClick(){
			this.menuOpen = false
      this.$store.commit('general/setUserTypePromptModal', true)
		},
		openMySignatureModal(){
			this.menuOpen = false
			this.$store.commit('users/setUserSignatureModal', true)
		},
    editUser(user) {
			this.menuOpen = false
			const data = {...user, ...{user_id: user.id}}
			this.$store.commit('user/setMyUserDataModal', data)
			this.$store.commit('user/setEditMyUserDataModal', true)
    },
	}
};
</script>

<style scoped>
	.user-avatar:not(.icon){
		border: 1px solid #959595;
	}
	.container-notification-menu-header{
		background: white;
		margin-top: 10px;
		border-radius: 8px;
	}
	.notification-img{
		width: 40px;
		height: 40px;
		border-radius: 100%;
	}
	.notification-img.icon{
		background-color: #F0F2F8;
	}
	/deep/ .notification-description p{margin: 0;}
	/* Clase personalizada para reducir altura */
	.compact-item {
		line-height: 1.2rem; /* Reduce la altura del texto */
		padding: 2px 8px; /* Ajusta el padding para más compacidad */
		cursor: pointer; /* Indica que es clickeable */
	}

	.compact-item:hover {
		background-color: #f5f5f5; /* Cambia el fondo al pasar el mouse */
	}
</style>
