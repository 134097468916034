<template>
  <div class="text-center">
    <v-dialog
      v-model="modalPdfStatus"
      max-width="1000px"
			persistent
      scrollable
    >
      <v-card min-height="90vh">
        <v-card-title class="pa-0">
            <div class="d-flex flex-row flex-grow-1 align-center justify-space-between py-2 px-8">
							<div class="d-flex flex-row align-center justify-start">
								<v-icon color="#959595" class="mr-2">mdi-file-pdf-box</v-icon>
								<p class="mb-0 font-weight-bold text-primary body-1">Presupuesto</p>
							</div>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon
										dark
										small
										color="#959595"
										@click="$store.commit('budget/setBudgetPdfModal', null)"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon dark>mdi-close</v-icon>
									</v-btn>
								</template>
								<span>Cerrar</span>
							</v-tooltip>
						</div>
				</v-card-title>
				<v-card-text class="pa-0">
					<iframe :src="budgetPdf" width="100%" class="fill-height d-flex flex-grow-1"></iframe>
        </v-card-text>
        <v-card-actions class="pa-0">
					<div class="d-flex flex-grow-1 justify-end py-2 px-8">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="green"
									class="mb-0"
									small
									v-bind="attrs"
									v-on="on"
									dark
									v-if="canSendEmail"
									@click="sendEmail"
								>
									<v-icon small>mdi-email-outline</v-icon>
									<p class="mb-0 ml-2 caption">Enviar por Email a Cliente</p>
								</v-btn>
							</template>
							<span>Se enviará correo al usuario con una copia del presupuesto</span>
						</v-tooltip>
					</div>
				</v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { sendBudgetPdfEmail } from '@/helpers/api/budgets'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'

export default {
  name: 'BudgetPdfModal',
  data: () => ({
  }),
  computed: {
		modalPdfStatus(){
			return !!this.$store.state.budget.budgetPdfModal
		},
		budgetPdf(){
			return this.$store.state.budget.budgetPdfModal.pdf.file.file_link
		},
		canSendEmail(){
			return true
			// return this.$store.state.contracts.contractPdfModal?.last_status?.status <= contractStatuses.OBSERVADO_CLIENTE ||  this.$store.state.contracts.contractPdfModal?.last_status?.status <= contractStatuses.RECHAZADO   
		},
		budget(){
			return this.$store.state.budget.budgetPdfModal
		}
	},
	watch: {
	},
  mounted(){
		console.log('asdasd: ', this.modalPdfStatus)
  },
  methods: {
		async sendEmail(){
			if(this.budget.pdf){
				try {
					await sendBudgetPdfEmail({budget_id: this.budget.id})
					const data = {budget_id: this.budget.id}
					eventBus.$emit('reloadBudget', data)
					const messageData = {
						message: 'Se ha enviado correctamente el correo al cliente',
						title: 'Correo enviado',
						type: 'success',
						icon: 'mdi-check-circle',
						color: '#3EBFA3',
					}
					this.$store.dispatch('general/addMessage', messageData)
					this.$store.commit('budget/setBudgetPdfModal', null)
				} catch (error) {
					const messageData = {
						message: 'Hubo un error en la operación, por favor intenta nuevamente',
						title: 'Ha ocurrido un error',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#FF1209',
					}
					this.$store.dispatch('general/addMessage', messageData)
				}
			}
		}
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>