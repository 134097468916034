export const contractStatuses = {
	CREADO: 1,
	ESPERANDO_PAGO: 2,
	PAGADO: 3,
	ESPERANDO_EMPRESA: 4,
	VISTO_BUENO_EMPRESA: 5,
	ENVIADO_CLIENTE: 6,
	OBSERVADO_CLIENTE: 7,
	ACEPTADO: 8,
	RECHAZADO: 9,
	FIRMADO_CLIENTE: 10,
	FIRMADO_AMBAS_PARTES: 11
};

export const placeholderMapping = {
	'{contract_start_date}': 'Fecha Inicio',
	'{contract_date}': 'Fecha del Contrato',
	'{address}': 'Dirección del Proyecto',
	'{district}': 'Municipio del Proyecto',
	'{client_name}': 'Nombre Cliente',
	'{client_dni}': 'Rut Cliente',
	'{client_address}': 'Dirección Cliente',
	'{company_name}': 'Nombre Empresa',
	'{company_rut}': 'Rut Empresa',
	'{company_responsible}': 'Nombre Representante',
	'{company_responsible_rut}': 'Rut Representante',
	'{company_address}': 'Dirección Empresa',
	'{client_company_name}': 'Nombre Empresa (Cliente)',
	'{client_company_dni}': 'Rut Empresa (Cliente)',
	'{client_company_address}': 'Dirección Empresa (Cliente)',
	'{client_company_district}': 'Comuna Empresa (Cliente)',
	'{client_rep_name}': 'Nombre Representante (Cliente)',
	'{client_rep_dni}': 'Rut Representante (Cliente)',
	'{client_rep_address}': 'Dirección Representante (Cliente)',
	'{client_rep_district}': 'Comuna Representante (Cliente)',
}

export default contractStatuses;
