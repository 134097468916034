import Vue from 'vue'
import Vuex from 'vuex'
import {getUserNotifications} from '@/helpers/api/notifications'

Vue.use(Vuex)

const NotificationsStore = {
  namespaced: true,
	state: {
		loadingNotifications: true,
		badgeText: '--',
		badgeColor: '#cacaca',
		notifications: [],
		loadingContractNotifications: true,
		badgeTextContract: '--',
		badgeColorContract: '#cacaca',
		contractNotifications: [],
		loadingChatNotifications: true,
		badgeTextChat: '--',
		badgeColorChat: '#cacaca',
		chatNotifications: [],
		hasChatNotifications: false,
		hasContractNotifications: false,
		hasRegularNotifications: false,
		rtdbNotifications: {}
	},
	mutations: {
		setNotifications(state, notifications){
			state.notifications = notifications
		},
		setLoadingNotifications(state, status){
			state.loadingNotifications = status
		},
		setBadgeText(state, text){
			state.badgeText = text
		},
		setBadgeColor(state, color){
			state.badgeColor = color
		},
		setContractNotifications(state, notifications){
			state.contractNotifications = notifications
		},
		setLoadingContractNotifications(state, status){
			state.loadingContractNotifications = status
		},
		setBadgeTextContract(state, text){
			state.badgeTextContract = text
		},
		setBadgeColorContract(state, color){
			state.badgeColorContract = color
		},
		setChatNotifications(state, notifications){
			state.chatNotifications = notifications
		},
		setLoadingChatNotifications(state, status){
			state.loadingChatNotifications = status
		},
		setBadgeTextChat(state, text){
			state.badgeTextChat = text
		},
		setBadgeColorChat(state, color){
			state.badgeColorChat = color
		},
		setHasChatNotifications(state, value){
			state.hasChatNotifications = value
		},
		setHasContractNotifications(state, value){
			state.hasContractNotifications = value
		},
		setHasRegularNotifications(state, value){
			state.hasRegularNotifications = value
		},
		setRTDBNotifications(state, value){
			state.rtdbNotifications = value
		},
	},
	actions: {
		async getNotifications({commit}, {take = 10, types = [1,2,5]} = {}){
			commit('setLoadingNotifications', true)
			const notifications = await getUserNotifications({
				take,
				types
			})
			if(notifications.code === 200){
				commit('setNotifications', notifications.notifications)
				commit('setLoadingNotifications', false)
				// commit('setBadgeText', notifications.unread_count.toString())

        let badge_color = 'success'
        if(notifications.unread_count >= 5){
            badge_color = '#fb8c00'
        }
        if(notifications.unread_count >= 10){
            badge_color = '#FF1209'
        }
				badge_color = '#FF1209'

				commit('setBadgeColor', badge_color)
			}
		},
		async getContractNotifications({commit}, {take = 10, types = [4]} = {}){
			commit('setLoadingContractNotifications', true)
			const notifications = await getUserNotifications({
				take,
				types
			})
			if(notifications.code === 200){
				commit('setContractNotifications', notifications.notifications)
				commit('setLoadingContractNotifications', false)
				// commit('setBadgeTextContract', notifications.unread_count.toString())

        let badge_color = 'success'
        if(notifications.unread_count >= 5){
            badge_color = '#fb8c00'
        }
        if(notifications.unread_count >= 10){
            badge_color = '#FF1209'
        }
				badge_color = '#FF1209'

				commit('setBadgeColorContract', badge_color)
				const { contract_notification, budget_notification } = notifications;

				let modalData = contract_notification
					? { ...contract_notification, contract_id: contract_notification.id }
					: budget_notification
					? { ...budget_notification, budget_id: budget_notification.id }
					: null;
				if (modalData) {
					commit('general/setImportantNotificationModalData', modalData, { root: true });
					commit('general/setImportantNotificationModal', true, { root: true });
				}
			}
		},
		async getChatNotifications({commit}, {take = 10, types = [3]} = {}){
			commit('setLoadingChatNotifications', true)
			const notifications = await getUserNotifications({
				take,
				types
			})
			if(notifications.code === 200){
				commit('setChatNotifications', notifications.notifications)
				commit('setLoadingChatNotifications', false)
				// commit('setBadgeTextChat', notifications.unread_count.toString())

        let badge_color = 'success'
        if(notifications.unread_count >= 5){
            badge_color = '#fb8c00'
        }
        if(notifications.unread_count >= 10){
            badge_color = '#FF1209'
        }
				badge_color = '#FF1209'

				commit('setBadgeColorChat', badge_color)
			}
		}
	}
}

export default NotificationsStore