import {endpoints} from './endpoints'
import request from '../request'

export function createProjectPlanning(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createProjectPlanning,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setProjectPlanningCategory(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setProjectPlanningCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeProjectPlanning(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeProjectPlanning,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createProjectPlanningCategory(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createProjectPlanningCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateProjectPlanningCategory(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateProjectPlanningCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createProjectPlanningStage(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createProjectPlanningStage,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateProjectPlanningStage(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateProjectPlanningStage,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateProjectPlanningStageOrder(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateProjectPlanningStageOrder,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteProjectPlanningCategory(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteProjectPlanningCategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteProjectPlanningStage(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteProjectPlanningStage,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getAllProjectPlanningCategories(params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getAllProjectPlanningCategories,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getAllProjectPlanningStages() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getAllProjectPlanningStages,
			method: 'get'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function copyProjectPlaningStage(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.copyProjectPlaningStage,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

// ZONE IPT

export function createZoneIpt(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createZoneIpt,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateZoneIpt(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateZoneIpt,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteZoneIpt(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteZoneIpt,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getAllZoneIpts() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getAllZoneIpts,
			method: 'get'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createProjectPlanningPrj(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createProjectPlanningPrj,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setProjectPlanningCategoryPrj(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setProjectPlanningCategoryPrj,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateProjectPlanningCategoryPrj(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateProjectPlanningCategoryPrj,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createProjectPlanningStagePrj(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createProjectPlanningStagePrj,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateProjectPlanningStagePrj(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateProjectPlanningStagePrj,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateProjectPlanningStageOrderPrj(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateProjectPlanningStageOrderPrj,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getProjectPlanningPrj(params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getProjectPlanningPrj,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeProjectPlanningPrj(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeProjectPlanningPrj,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeProjectPlanningCategoryPrj(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeProjectPlanningCategoryPrj,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeProjectPlanningStage(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeProjectPlanningStage,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

// Projects

export function createProject(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateProjectInfo(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateProjectInfo,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyProjects() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyProjects,
			method: 'get'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getUserProjects(params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getUserProjects,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setResponsible(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setResponsible,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getProjectsSerials(params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getProjectsSerials,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}