<template>
	<div class="d-flex justify-end align-center" v-if="['Conversation', 'Client', 'ClientDetail'].includes($route.name) && $store.state.crm.conversation">
		<div>
			<v-tooltip bottom v-if="editPermission">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						fab
						dark
						x-small
						color="#FF120B"
						class="mx-1"
						@click="setReportModal"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon small dark>mdi-table-cog</v-icon>
					</v-btn>
				</template>
				<span>Preparar Nuevo Informe</span>
			</v-tooltip>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CRMButtonsHeader',
	components: {  },
	computed: {
		editPermission(){
			return this.$checkPermissions(['edit_crm'])
		}
	},
	data: () => ({
		fab: false
	}),
	mounted(){
	},
	methods: {
		setReportModal(){
			if(this.$store.state.crm.conversation.schedule){
				this.$store.commit('crm/setOpenBudgetModal', !this.$store.state.crm.openBudgetModal)
			}
			else{
				const messageData = {
					message: 'No se puede preparar un informe sin haber creado una agenda previamente',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.$store.dispatch('general/addMessage', messageData)
			}
		}
	}
};
</script>