<template>
  <div class="text-center">
    <v-dialog
      v-model="canShowModal"
      max-width="600"
			persistent
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row align-center justify-start">
								<v-icon color="warning" class="mr-2">mdi-information</v-icon>
								<p class="mb-0 font-weight-bold text-primary body-1">Notificación Importante</p>
							</div>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon
										dark
										small
										color="#959595"
										@click="$store.commit('general/setImportantNotificationModal', false)"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon dark>mdi-close</v-icon>
									</v-btn>
								</template>
								<span>Cerrar</span>
							</v-tooltip>
						</div>
						<div class="mt-6" v-if="modalData?.contract_id">
							<!-- Contrato -->
							<p class="mb-0">Has recibido un contrato para tu proyecto <b>{{ modalData?.morphable?.name }}</b>.</p>
							<p>Por favor revisa este contrato desde tu sección de cliente</p>
							<v-btn
								depressed
								dark
								color="#959595"
								@click="navigateToItem"
							>
								<v-icon dark>mdi-briefcase-outline</v-icon>
								<p class="ml-2 mb-0">Ir al Contrato</p>
							</v-btn>
							<p class="mt-2 mb-0 caption"><small>Este mensaje seguirá apareciendo hasta que hayas realizado una acción sobre el contrato pendiente</small></p>
						</div>
						<div class="mt-6" v-else-if="modalData?.budget_id">
							<!-- Contrato -->
							<p class="mb-0">Has recibido un presupuesto para tu proyecto <b>{{ modalData?.morphable?.name }}</b>.</p>
							<p>Por favor revísalo desde tu sección de cliente</p>
							<v-btn
								depressed
								dark
								color="#959595"
								@click="navigateToItem"
							>
								<v-icon dark>mdi-book-outline</v-icon>
								<p class="ml-2 mb-0">Ir al Presupuesto</p>
							</v-btn>
							<p class="mt-2 mb-0 caption"><small>Este mensaje seguirá apareciendo hasta que hayas realizado una acción sobre el presupuesto pendiente</small></p>
						</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'ImportantNotificationModal',
  data: () => ({
  }),
  computed: {
		modalData() {
			return this.$store.state.general.importantNotificationModalData
		},
		canShowModal() {
			const { importantNotificationModal } = this.$store.state.general;
			if (!importantNotificationModal) return false;

			if (this.modalData) {
				const { contract_id, budget_id } = this.modalData;
				const { ct, b } = this.$route.query;

				if (contract_id) return contract_id != ct;
				if (budget_id) return budget_id != b;
			}

			return false;
		}
	},
	watch: {
	},
  mounted(){
		console.log('asdasd: ', this.$route.query.ct)
  },
  methods: {
		navigateToItem() {
			const query = this.modalData.contract_id 
				? { ct: this.modalData.contract_id } 
				: this.modalData.budget_id 
				? { b: this.modalData.budget_id }
				: {};

			this.$router.push({ path: '/client', query });
			this.$store.commit('general/setImportantNotificationModal', false);
		}
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>