<template>
	<v-container fluid class="crm-index">
		<div class="d-flex align-center justify-end">
			<div class="d-flex align-center mb-2" style="flex: 1;max-width: 400px;">
				<v-icon color="#524D4D" @click="searchForString">mdi-magnify</v-icon>
				<v-text-field
					v-model="searchInput"
					:loading="loading"
					outlined
					dense
					hide-details
					item-text="name"
					item-value="id"
					return-object
					clearable
					label="Búsqueda"
					class="mb-0 mr-2 custom-height-search custom-height-calendar"
					color="#524D4D"
					@input="sendToSearch"
				></v-text-field>
			</div>
		</div>
		<v-data-table
			ref="table"
			:headers="headers"
			:items="records"
			item-key="id"
			:loading="loading"
			:server-items-length="records_count"
			:options.sync="options"
			loading-text="Cargando datos..."
			class="crm-table"
			style="overflow-x: scroll;"
			:class="$vuetify.breakpoint.mobile ? '':'resizable'"
			disable-sort
			:footer-props="{
        itemsPerPageText: 'Filas por página',
				'items-per-page-options': [20, 50, 100]
			}"
		>
			<template v-slot:header.budgets="{ header }">
				<div class="pt-2">
					<b>{{ header.text }}</b>
					<v-divider></v-divider>
					<table style="width: 100%;border-collapse: collapse;">
						<tr>
							<th class="pa-0">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<div class="pa-1" v-bind="attrs" v-on="on">PRE <v-icon x-small>mdi-information-slab-circle-outline</v-icon></div>
									</template>
									<span>Presupuesto Preproyecto (Desarchivo)</span>
								</v-tooltip>
								<v-divider></v-divider>
								<table style="width: 100%;border-collapse: collapse;">
									<tr>
										<th style="width: 100px;">Nº</th>
										<th style="width: 100px;border-right-width:0;">Fecha</th>
									</tr>
								</table>
							</th>
							<th :style="index + 1 == budget_prefixes.length ? 'border-right-width: 0;':''" v-for="(budget_prefix,index) in budget_prefixes" :key="'bp_' + budget_prefix.id" class="pa-0">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<div class="pa-1" v-bind="attrs" v-on="on">{{ budget_prefix.prefix }} <v-icon x-small>mdi-information-slab-circle-outline</v-icon></div>
									</template>
									<span>{{ budget_prefix.name }}</span>
								</v-tooltip>
								<v-divider></v-divider>
								<table style="width: 100%;border-collapse: collapse;">
									<tr>
										<th style="width: 100px;">Nº</th>
										<th style="width: 100px;border-right-width:0;">Fecha</th>
									</tr>
								</table>
							</th>
						</tr>
					</table>
				</div>
			</template>
			<template v-slot:header.contracts="{ header }">
				<div class="d-flex flex-column flex-grow-1 align-center justify-center fill-height pt-2">
					<div class="flex-grow-1 d-flex fill-height">
						<b>{{ header.text }}</b>
					</div>
					<v-divider></v-divider>
					<table style="width: 100%;border-collapse: collapse;">
						<tr>
							<th style="border-right-width: 0;" class="pa-0">
								<!-- <div class="pa-0 red">asdas</div> -->
								<v-divider></v-divider>
								<table style="width: 100%;border-collapse: collapse;">
									<tr>
										<th style="width: 100px;">Nº</th>
										<th style="width: 100px;border-right-width: 0;">Fecha</th>
									</tr>
								</table>
							</th>
						</tr>
					</table>
				</div>
			</template>
			<template v-slot:item.client="{ item }">
				<b>{{ item.client?.profile.name }} {{ item.client?.profile.lastname }}</b>
				<div>{{ item.client ? item.client.email:'No identificado'}}</div>
			</template>
			<template v-slot:item.project="{ item }">
				<p class="mb-0 font-weight-bold">{{ item.name }}</p>
				<div class="d-flex align-start justify-start">
					<p class="mb-0 mr-2">Asociaciones</p>

					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="warning"
								x-small
								icon
								v-bind="attrs"
								v-on="on"
							>
								<v-icon small class="mb-1">mdi-eye-outline</v-icon>
							</v-btn>
						</template>
						<v-list dense class="py-0">
							<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
								<p class="caption mb-0 font-weight-bold white--text">Asociaciones</p>
							</v-list-item>
							<v-list-item-group color="#524D4D">
								<v-list-item
									v-for="(association, index) in item.associations"
									:key="'ass-'+index"
									dense
									@click="handleGoTo(association)"
									style="min-width: 200px"
								>
									<v-list-item-title dense>
										<div class="d-flex justify-space-between align-center item-background-hover">
											<div class="d-flex justify-space-between caption" style="width: 100%">
												<div style="width: 90px">{{ association.morphable_type == 'App\\Models\\PreProject' ? 'Pre Proyecto':'Visita Técnica'}}</div>
												<span>:</span>
												<b class="text-end" style="flex: 1;">#{{association.morphable_id}}</b>
											</div>
										</div>
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</div>
			</template>
			<template v-slot:item.budgets="{ item }">
				<table class="fill-height" style="width: 100%;border-collapse: collapse;">
					<tr>
						<td style="border-right-width: 0;" class="px-0 py-0 fill-height">
							<table class="fill-height custom-table-serials-content" style="width: 100%;border-collapse: collapse;">
								<tr>
									<td>
										--
									</td>
									<td>
										--
									</td>
								</tr>
							</table>
						</td>
						<td style="border-right-width: 0;" class="px-0 py-0 fill-height" v-for="(budget_prefix, ind) in budgetPrefixes" :key="'bp2_' + budget_prefix.id">
							<table class="fill-height custom-table-serials-content" style="width: 100%;border-collapse: collapse;">
								<tr>
									<td>
										<div v-if="item.budgets.length == 0">--</div>
										<div v-for="budget in item.budgets" :key="'b_' + budget.id">
											<v-btn
												text
												x-small
												color="#FF120B"
												class="mb-0 caption font-weight-bold" 
												@click="toBudget(budget)"
												v-if="budget.prefix?.id === budget_prefix.id">
													{{ budget.prefix?.prefix }} {{ budget.serial_id }} - {{ budget.created_at | toYear }}
												</v-btn>
											<p class="mb-0 caption" v-else>--</p>
										</div>
									</td>
									<td :style="ind + 1 == budget_prefixes.length ? 'border-right-width:0':''">
										<div v-if="item.budgets.length == 0">--</div>
										<div v-for="budget in item.budgets" :key="'b2_' + budget.id">
											<p class="mb-0 caption" v-if="budget.prefix?.id === budget_prefix.id">{{ budget.created_at | toDate }}</p>
											<p class="mb-0 caption" v-else>--</p>
										</div>
									</td>
								</tr>
							</table>
						</td>
					</tr>
				</table>
			</template>
			<template v-slot:item.contracts="{ item }">
				<table class="fill-height" style="width: 100%;border-collapse: collapse;">
					<tr>
						<td style="border-right-width: 0;" class="py-0 fill-height">
							<table class="fill-height custom-table-serials-content" style="width: 100%;border-collapse: collapse;">
								<tr v-if="item.contracts?.length >= 0">
									<td>
										<div v-for="contract in item.contracts" :key="'ct_' + contract.id">
											<v-btn
												text
												x-small
												color="#FF120B"
												class="mb-0 caption font-weight-bold" 
												@click="toContractDetail(contract)">
													{{ contract.prefix?.prefix }} {{ contract.serial_id }} - {{ contract.created_at | toYear }}
												</v-btn>
										</div>
									</td>
									<td style="border-right-width: 0;">
										<div v-for="contract in item.contracts" :key="'ct2_' + contract.id">
											<p class="mb-0 caption">{{ contract.created_at | toDate }}</p>
										</div>
									</td>
								</tr>
								<tr v-else>
									<td><p class="mb-0 caption">--</p></td>
									<td style="border-right-width: 0;"><p class="mb-0 caption">--</p></td>
								</tr>
							</table>
						</td>
					</tr>
				</table>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
import { getSerials } from '@/helpers/api/budgets'

export default {
	name: 'ProjectsSerialsTable',
	data() {
		return {
			loading: false,
			searchInput: null,
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
			headers: [
				{ text: 'Cliente', value: 'client', align: 'start', width: '20%', sortable: false },
				{ text: 'Proyecto', value: 'project', align: 'start', width: '20%', sortable: false },
				{ text: 'Presupuestos', value: 'budgets', align: 'center', sortable: false, class: 'custom-project-header-class', cellClass: 'custom-project-header-class' },
				{ text: 'Contratos', value: 'contracts', align: 'center', sortable: false, class: 'custom-project-header-class', cellClass: 'custom-project-header-class' }
			],
			records: [],
			records_count: 0,
			budget_prefixes: []
		}
	},
	computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		budgetPrefixes(){
			return this.budget_prefixes
		}
	},
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.reloadInfo()
			}
		},
    options: {
      handler() {
				this.reloadInfo()
      },
      deep: true,
    },
	},
	mounted() {
		if(this.selectedCompany){
			this.reloadInfo()
		}
	},
	methods: {
		sendToSearch: _debounce(function() { this.searchForString() }, 800),
		searchForString(){
			this.options = {...this.options, ...{page: 1}}
			this.reloadInfo()
		},
		async reloadInfo() {
			this.loading = true
			let data = {
				...this.options,
				start: (this.options.page - 1) * this.options.itemsPerPage,
				limit: this.options.itemsPerPage,
			}
			if(this.searchInput){
				data = {...data, ...{search: this.searchInput}}
			}
			const records = await getSerials(data)
			if(records.code === 200){
				this.records = records.records
				this.budget_prefixes = records.budget_prefixes
				this.records_count = records.records_count
			}
			this.loading = false
		},
		toBudget(budget){
			localStorage.setItem('last_working_budget', JSON.stringify(budget))
			this.$router.push({ name: 'BudgetDetail', query: { id: budget.id } })
		},
		toContractDetail(contract){
			this.$router.push({ name: 'ContractDetail', query: { id: contract.id } })
		},
		handleGoTo(association){
			if(association.morphable_type == 'App\\Models\\PreProject'){
				this.$router.push({ name: 'PreProject', params: { id: association.morphable_id } })
			}
			else if(association.morphable_type == 'App\\Models\\BotConversation'){
				this.$router.push({ name: 'Conversation', params: { id: association.morphable_id } })
			}
		},
		getTypeIcon(type){
			switch (type) {
				case "crm":
					return 'mdi-contacts-outline'
				case "preproject":
					return 'mdi-information-variant-circle-outline'
				case "project":
					return 'mdi-ruler-square'
				default:
					break;
			}
			return 'Otro'
		},
		getTypeName(type){
			switch (type) {
				case "crm":
					return 'CRM'
				case "preproject":
					return 'PreProyecto'
				case "project":
					return 'Proyecto'
				default:
					break;
			}
			return 'Otro'
		},
		getType(type){
			switch (type) {
				case "crm":
					return 'crm'
				case "preproject":
					return 'preproject'
				case "project":
					return 'project'
				default:
					break;
			}
			return 'Otro'
		},
	},
}
</script>

<style>
	.custom-project-header-class{
		padding: 0 !important;
	}
	table.custom-table-serials-content {
		table-layout: fixed;
		width: 100%;
	}

	table.custom-table-serials-content td {
		width: 100px;
		border-right: none;
	}
	.crm-table table .custom-project-header-class{
		border-right-width: 0;
		border-collapse: collapse;
	}
</style>