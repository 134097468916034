<template>
	<div ref="clientContractContainer" class="fill-height pa-2 d-flex flex-column">
		<NonEditableContract
			class="mx-4"
			:contract_info="contract"
			:contract_id="contract_id"
			:openCrmDetail="handleOpenCrmDetail"
			:condensed="condensed"
			isVertical />
	</div>
</template>

<script>
import { getContract } from '@/helpers/api/contracts'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'
import contractStatuses from '@/components/Helpers/Constants/contract.js'
import NonEditableContract from '@/components/Views/Contracts/NonEditableContract'

export default {
	name: 'ClientContract',
	components: {NonEditableContract},
	props: {
		contract_id: {
			type: [Number, String],
			default: null
		},
		handleOpenCrmDetail: {
      type: Function
    },
		condensed: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		contractStatuses,
		contract: null
	}),
	mounted(){
		this.contract = this.contract_info
		if(!this.contract){
			this.getContractInfo()
		}
    eventBus.$on('reloadContract', (data) => {
			if(this.contract && data.contract_id == this.contract.id){
				this.getContractInfo()
			}
    })
		this.$nextTick(() => {
      const element = this.$refs.clientContractContainer
      if (element) {
        this.componentWidth = element.offsetWidth
      }
		})
	},
	methods: {
		async getContractInfo(){
			if(this.contract_id){
				this.loading = true
				const contract = await getContract({contract_id: this.contract_id})
				if(contract.code == 200){
					this.contract = null
					this.$nextTick(() => {
						this.contract = contract.contract
					})
				}
				this.loading = false
			}
		},
	}
}
</script>

<style>

</style>