import {endpoints} from './endpoints'
import request from '../request'

export function createContractSectionCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractSectionCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionCategoryOrders (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionCategoryOrders,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteContractSectionCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteContractSectionCategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyContractSectionCategories (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyContractSectionCategories,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function createContractSection (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractSection,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSection (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSection,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionOrders (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionOrders,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteContractSection (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteContractSection,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyContractSections (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyContractSections,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContract (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContract,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContract (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContract,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContractSectionProject (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractSectionProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContractSectionContent (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractSectionContent,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getContract (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getContract,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyContracts (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyContracts,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionProject (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionContent (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionContent,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionProjectOrders (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionProjectOrders,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionContentOrders (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionContentOrders,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteContractSectionProject (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteContractSectionProject,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteContractSectionContent (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteContractSectionContent,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContractTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractTemplate,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setTemplateToContract (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setTemplateToContract,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getContractTemplates (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getContractTemplates,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteContractTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteContractTemplate,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContractTitle (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractTitle,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractTitle (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractTitle,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getContractTitles (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getContractTitles,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteContractTitle (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteContractTitle,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContractContentComment (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractContentComment,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractContentComment (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractContentComment,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteContractContentComment (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteContractContentComment,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContractPDF (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractPDF,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContractPdfFromPreview (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractPdfFromPreview,
			method: 'post',
			headers: {
					'Content-Type': 'application/json; charset=UTF-8'
			},
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function sendContractPdfEmail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendContractPdfEmail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addNewStatus (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addNewStatus,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function signContract (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.signContract,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function companySignContract (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.companySignContract,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function sendContractPaymentRequest (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendContractPaymentRequest,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}