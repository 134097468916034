import {endpoints} from './endpoints'
import request from '../request'

export function getAdminCompanies (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getAdminCompanies,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.create,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.update,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.delete,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getUserFromCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getUserFromCompany,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function searchUsersFromCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.searchUsersFromCompany,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createUserForCompany(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.createUserForCompany,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateUserForCompany(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateUserForCompany,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function removeUserFromCompany(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.removeUserFromCompany,
			method: 'delete',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateUserCompanyPermissions(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateUserCompanyPermissions,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateUserCompanyStatus(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateUserCompanyStatus,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getUserCompanyStatus(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getUserCompanyStatus,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function createConcept(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.createConcept,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateConcept(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateConcept,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function deleteConcept(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.deleteConcept,
			method: 'delete',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getConcepts(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getConcepts,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function createRole(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.createRole,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateRole(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateRole,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateRolesOrders(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateRolesOrders,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function deleteRole(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.deleteRole,
			method: 'delete',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getRoles(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getRoles,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getProjectTypes(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getProjectTypes,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function createProjectType(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.createProjectType,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateProjectType(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateProjectType,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateProjectTypeOrders(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateProjectTypeOrders,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function deleteProjectType(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.deleteProjectType,
			method: 'delete',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function createSpecialty(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.createSpecialty,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateSpecialty(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateSpecialty,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function deleteSpecialty(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.deleteSpecialty,
			method: 'delete',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getSpecialties(params) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getSpecialties,
			method: 'get',
			params
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateCompanyConfig(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateCompanyConfig,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function getCompanyConfig(params) {
	return new Promise((resolve) => {
		request({
			url: endpoints.getCompanyConfig,
			method: 'get',
			params
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}