import {endpoints} from './endpoints'
import request from '../request'

export function createBudgetPrefix (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetPrefix,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudgetPrefix (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudgetPrefix,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getBudgetPrefixes (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getBudgetPrefixes,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeBudgetPrefix (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeBudgetPrefix,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createBudgetConcept (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetConcept,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudgetConcept (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudgetConcept,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getBudgetConcepts (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getBudgetConcepts,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeBudgetConcept (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeBudgetConcept,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createBudgetCompanyCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetCompanyCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudgetCompanyCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudgetCompanyCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getBudgetCompanyCategories (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getBudgetCompanyCategories,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeBudgetCompanyCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeBudgetCompanyCategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createBudgetCompanyDetail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetCompanyDetail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudgetCompanyDetail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudgetCompanyDetail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getBudgetCompanyDetails (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getBudgetCompanyDetails,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeBudgetCompanyDetail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeBudgetCompanyDetail,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateOrderBudgetDetail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateOrderBudgetDetail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setBudgetsPartCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setBudgetsPartCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeBudgetPartCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeBudgetPartCategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setItemBudgetPart (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setItemBudgetPart,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addPreProjectBudgetsPart (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addPreProjectBudgetsPart,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeItemBudgetPart (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeItemBudgetPart,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function saveUpdateBudgetNoteTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.saveUpdateBudgetNoteTemplate,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyBudgetNoteTemplates (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyBudgetNoteTemplates,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteBudgetNoteTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteBudgetNoteTemplate,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateOrderBudgetNoteTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateOrderBudgetNoteTemplate,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createBudgetNote (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetNote,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudgetNote (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudgetNote,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteBudgetNote (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteBudgetNote,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createBudget (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudget,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudget (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudget,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function setBudgetInfo (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.setBudgetInfo,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyBudgets (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyBudgets,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getBudgetById (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getBudgetById,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateOrderBudgetNote (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateOrderBudgetNote,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addNewStatus (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addNewStatus,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function sendBudgetPdfEmail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendBudgetPdfEmail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createBudgetPdfFromPreview (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetPdfFromPreview,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getSerials (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getSerials,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function newBudgetObservation (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.newBudgetObservation,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createBudgetTitle (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createBudgetTitle,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateBudgetTitle (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateBudgetTitle,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getBudgetTitles (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getBudgetTitles,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteBudgetTitle (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteBudgetTitle,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}