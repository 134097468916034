<template>
	<v-menu v-model="menuStatus" offset-y z-index="9999" :close-on-content-click="false" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				dark
				small
				icon
				color="#524D4D"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
			</v-btn>
		</template>
		<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;min-width: 200px;">
			<v-list-item dense class="mb-2" style="min-height: 25px; background: #959595;">
				<p class="caption mb-0 font-weight-bold white--text">Cambiar Cliente {{ $store.state.users.loadingClient }}</p>
			</v-list-item>
			<v-list-item dense class="mb-1" style="min-height: 25px;">
				<div class="d-flex align-center" style="flex: 1;max-width: 400px;">
					<v-text-field
						v-model="searchInput"
						:loading="$store.state.users.loadingCompanyClients"
						outlined
						dense
						hide-details
						item-text="name"
						item-value="id"
						return-object
						clearable
						label="Búsqueda"
						class="custom-height-search custom-height-calendar"
						color="#524D4D"
						@input="sendToSearch"
					></v-text-field>
				</div>
			</v-list-item>
			<v-list-item-group color="#524D4D">
				<v-list-item
					v-for="(user, index) in companyClients"
					:key="index"
					dense
					@click="changePreprojectStatus(user.id)"
				>
					<v-list-item-title dense>
						{{ user.profile.name }} {{ user.profile.lastname }}
					</v-list-item-title>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-menu>
</template>

<script>
import _debounce from 'lodash/debounce'
import {updatePreProjectInfo} from '@/helpers/api/preprojects'

export default {
	name: 'ChangeProjectClient',
	props: {
		itemType: {
			type: String,
			required: true
		},
		itemId: {
			type: Number,
			required: true
		},
		clientChanged:{
			type: Function
		}
	},
	computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		companyClients(){
			return this.$store.state.users.companyClients
		}
	},
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.getCompanyClients()
			}
		}
	},
	data() {
		return {
			menuStatus: false,
			searchInput: null,
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.getCompanyClients()
		}
	},
	methods:{
		sendToSearch: _debounce(function() { this.searchForString() }, 800),
		searchForString(){
			this.getCompanyClients()
		},
		getCompanyClients(){
			let data = {
				start: 0,
				limit: 20
			}
			if(this.searchInput){
				data = {...data, ...{search: this.searchInput}}
			}
			this.$store.dispatch('users/getCompanyClients', data)
		},
		async changePreprojectStatus(client_id){
			this.menuStatus = false
			switch (this.itemType) {
				case 'crm':
					await updatePreProjectInfo({preproject_id: this.itemId, client_id})
					this.clientChanged && this.clientChanged()
					break;
				case 'preproject':
					await updatePreProjectInfo({preproject_id: this.itemId, client_id})
					this.clientChanged && this.clientChanged()
					break;
			
				default:
					break;
			}
		}
	}
}
</script>