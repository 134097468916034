<template>
	<v-menu
		ref="menuConfig"
		bottom
		offset-y
		right
		min-width="400"
		max-width="400"
		content-class="container-notification-menu-header"
		:close-on-content-click="false"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				icon
				dark
				small
				color="#959595"
				class="mr-2"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon dark>mdi-cog-outline</v-icon>
			</v-btn>
		</template>
		<div class="white" style="position: relative;">
			<div dense class="px-2 py-1" style="background: #959595;">
				<p class="caption mb-0 font-weight-bold white--text">Configuración Presupuestos</p>
			</div>
			<div class="pa-0">
				<v-tabs
					v-model="tab"
					background-color="#524D4D"
					grow
					dark
					height="40"
				>
					<v-tab>General Presupuestos</v-tab>
					<v-tab>Prefijos</v-tab>
					<v-tab>Títulos</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<div class="pa-4">
							<div class="d-flex align-center">
								<v-text-field
									v-model="baseSerial"
									:loading="showLoading"
									outlined
									dense
									hide-details
									item-text="name"
									item-value="id"
									label="Número Base Para Códigos"
									class="custom-height-search custom-height-calendar mt-2"
									color="#524D4D"
									@keypress="isNumberKey"
								></v-text-field>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item>
						<div class="pa-4">
							<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-2">
								<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Prefijos para Presupuesto</p>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="grey" class="mr-3"
													v-bind="attrs"
													v-on="on"
													small
													@click="setBudgetPrefix(null)">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</template>
									<span>Nuevo prefijo para Presupuesto</span>
								</v-tooltip>
							</div>
							<v-divider class="my-2"></v-divider>
							<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
								<v-list-item
									v-for="(item, i) in $store.state.budget.companiesBudgetPrefixes"
									:key="i"
									class="px-0 py-0"
								>
									<div style="width: 100%">
										<div class="d-flex flex-row px-2 pt-2 justify-space-between">
											<div class="px-4 caption">
												<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.name }}</p>
											</div>
											<div>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															dark
															x-small
															color="warning"
															@click="setBudgetPrefix(item)"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small dark>mdi-pencil</v-icon>
														</v-btn>
													</template>
													<span>Editar Prefijo</span>
												</v-tooltip>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															dark
															x-small
															color="error"
															@click="deleteBugdetPrefix(item)"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small dark>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Prefijo</span>
												</v-tooltip>
											</div>
										</div>
									</div>
								</v-list-item>
							</v-list>
						</div>
					</v-tab-item>
					<v-tab-item>
						<div class="pa-4">
							<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-2">
								<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Títulos para Presupuesto</p>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="grey" class="mr-3"
													v-bind="attrs"
													v-on="on"
													small
													@click="setBudgetTitle(null)">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</template>
									<span>Nuevo título para Presupuestos</span>
								</v-tooltip>
							</div>
							<v-divider class="my-2"></v-divider>
							<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
								<v-list-item
									v-for="(item, i) in $store.state.budget.companiesBudgetTitles"
									:key="i"
									class="px-0 py-0"
								>
									<div style="width: 100%">
										<div class="d-flex flex-row px-2 pt-2 justify-space-between">
											<div class="px-4 caption">
												<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.title }}</p>
											</div>
											<div>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															dark
															x-small
															color="warning"
															@click="setBudgetTitle(item)"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small dark>mdi-pencil</v-icon>
														</v-btn>
													</template>
													<span>Editar Título</span>
												</v-tooltip>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															dark
															x-small
															color="error"
															@click="deleteBugdetTitle(item)"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small dark>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Título</span>
												</v-tooltip>
											</div>
										</div>
									</div>
								</v-list-item>
							</v-list>
						</div>
					</v-tab-item>
				</v-tabs-items>
			</div>
		</div>
	</v-menu>
</template>

<script>
import _debounce from 'lodash/debounce'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'
import {updateCompanyConfig, getCompanyConfig} from '@/helpers/api/company'
import {removeBudgetPrefix, deleteBudgetTitle} from '@/helpers/api/budgets'

export default {
	name: 'BudgetLeftButtons',
	computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		showLoading(){
			return this.loading
		}
	},
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.getConfigs()
			}
		},
		baseSerial(newValue) {
			if(newValue && newValue > 0 && !this.loading){
				this.onChangeBaseSerial()
			}
		}
	},
	data: () => ({
		tab: 0,
		loading: false,
		baseSerial: null
	}),
	mounted(){
		if(this.selectedCompany){
			this.getConfigs()
		}
	},
	methods:{
		async getConfigs(){
			this.loading = true
			const config = await getCompanyConfig()
			if(config.code == 200){
				this.baseSerial = config.company_config?.base_serial
			}
			this.loading = false
		},
		onChangeBaseSerial: _debounce(function() { this.changeBaseSerial() }, 800),
		async changeBaseSerial(){
			if(this.loading) return

			this.loading = true
			const config = await updateCompanyConfig({
				base_serial: this.baseSerial
			})
			if(config.code == 200){
				eventBus.$emit('reloadBudget', {})
				this.$refs.menuConfig.save()
			}
			this.loading = false
		},
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
		setBudgetPrefix(prefix = null){
			this.$store.commit('budget/setNewBudgetPrefixModalEditing', prefix ? {...prefix, ...{budget_prefix_id: prefix.id}}:null)
      this.$store.commit('budget/setNewBudgetPrefixModal', true)
		},
		setBudgetTitle(title = null){
			this.$store.commit('budget/setNewBudgetTitleModalEditing', title ? {...title, ...{budget_title_id: title.id}}:null)
      this.$store.commit('budget/setNewBudgetTitleModal', true)
		},
		async deleteBugdetPrefix(prefix){
			await removeBudgetPrefix({budget_prefix_id: prefix.id})
			this.$store.dispatch('budget/getBudgetPrefixes')
		},
		async deleteBugdetTitle(title){
			await deleteBudgetTitle({budget_title_id: title.id})
			this.$store.dispatch('budget/getBudgetTitles')
		},
	}
}
</script>