<template>
  <v-container fluid>
		<v-row>
			<v-col cols="5">
				<div class="mb-3 d-flex justify-space-between align-center">
					<p class="mb-0 caption font-weight-bold">Categorías / Cláusulas para Contrato</p>
					<v-btn
						depressed
						color="#959595"
						x-small
						outlined
						class="my-0"
						v-if="$checkPermissions(['create_company_project_types'])"
						@click="newContractCategory"
					>
						<v-icon small>mdi-plus</v-icon>
						<p class="mb-0 ml-1 caption">Nueva Categoría</p>
					</v-btn>
				</div>
				<v-data-table
					:headers="headersCat"
					:items="$store.state.contracts.companyContractCategories"
					:search="search"
					item-key="id"
					:loading="$store.state.contracts.loadingCompanyContractCategories"
					loading-text="Cargando datos..."
					:footer-props="{
						itemsPerPageText: 'Filas por página'
					}"
					:items-per-page="-1"
					hide-default-footer
				>
					<template v-slot:body="{ items }">
						<draggable
							v-model="$store.state.contracts.companyContractCategories"
							tag="tbody"
							item-key="id"
							@end="startEndDragEventCategory"
						>
							<tr v-for="(item) in items" :key="item.id" style="cursor: pointer;" @click="selectedCategory = item">
								<td style="cursor: pointer;">{{ item.title }} ({{ item.sections_count }})</td>
								<td>
									<div>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													icon
													x-small
													color="#959595"
													class="mx-1"
													v-if="$checkPermissions(['edit_company_roles'])"
													@click="selectedCategory = item"
													v-bind="attrs"
													v-on="on"
												>
													<v-icon small dark>mdi-chevron-right</v-icon>
												</v-btn>
											</template>
											<span>Visualizar Secciones</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													icon
													x-small
													color="warning"
													class="mx-1"
													v-if="$checkPermissions(['edit_company_roles'])"
													@click="editCategory(item)"
													v-bind="attrs"
													v-on="on"
												>
													<v-icon small dark>mdi-pencil-outline</v-icon>
												</v-btn>
											</template>
											<span>Editar</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													icon
													x-small
													color="error"
													class="mx-1"
													v-if="$checkPermissions(['delete_company_roles'])"
													@click="deleteSectionCategory(item.id)"
													v-bind="attrs"
													v-on="on"
												>
													<v-icon small dark>mdi-delete-outline</v-icon>
												</v-btn>
											</template>
											<span>Eliminar</span>
										</v-tooltip>
									</div>
								</td>
							</tr>
						</draggable>
					</template>
				</v-data-table>
			</v-col>
			<v-col cols="7">
				<div class="mb-3 d-flex justify-space-between align-center">
					<p class="mb-0 caption font-weight-bold">Secciones para Contrato</p>
					<v-btn
						depressed
						color="#959595"
						x-small
						outlined
						class="my-0"
						v-if="$checkPermissions(['create_company_project_types'])"
						:disabled="!selectedCategory"
						@click="newContractSection"
					>
						<v-icon small>mdi-plus</v-icon>
						<p class="mb-0 ml-1 caption">Nueva Sección en Categoría</p>
					</v-btn>
				</div>
				<v-data-table
					:headers="headers"
					:items="$store.state.contracts.companyContracts"
					:search="search"
					item-key="id"
					:loading="$store.state.contracts.loadingCompanyContracts"
					loading-text="Cargando datos..."
					:footer-props="{
						itemsPerPageText: 'Filas por página'
					}"
					:items-per-page="-1"
					hide-default-footer
				>
					<template v-slot:body="{ items }">
						<draggable
							v-model="$store.state.contracts.companyContracts"
							tag="tbody"
							item-key="id"
							@end="startEndDragEvent"
						>
							<tr v-for="(item) in items" :key="item.id" style="cursor: pointer;">
								<td style="cursor: pointer;">{{ item.title }}</td>
								<td style="cursor: pointer;max-width: 400px;">
									<div class="custom-html-content" v-html="transformPlaceholdersToFriendlyView(item.content)"></div>
								</td>
								<td>
									<div>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													icon
													x-small
													color="warning"
													class="mx-1"
													v-if="$checkPermissions(['edit_company_roles'])"
													@click="editSection(item)"
													v-bind="attrs"
													v-on="on"
												>
													<v-icon small dark>mdi-pencil-outline</v-icon>
												</v-btn>
											</template>
											<span>Editar</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													icon
													x-small
													color="error"
													class="mx-1"
													v-if="$checkPermissions(['delete_company_roles'])"
													@click="deleteSection(item.id)"
													v-bind="attrs"
													v-on="on"
												>
													<v-icon small dark>mdi-delete-outline</v-icon>
												</v-btn>
											</template>
											<span>Eliminar</span>
										</v-tooltip>
									</div>
								</td>
							</tr>
						</draggable>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
  </v-container>
</template>

<script>
import {deleteContractSection, updateContractSectionOrders, deleteContractSectionCategory, updateContractSectionCategoryOrders} from '@/helpers/api/contracts'
import draggable from "vuedraggable"
import _debounce from 'lodash/debounce'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      search: '',
			selectedCategory: null,
      headersCat: [
        { text: 'Título', value: 'title' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      headers: [
        { text: 'Título', value: 'title' },
        { text: 'Contenido', value: 'content' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
			placeholderMapping: {
				'{contract_start_date}': 'Fecha Inicio',
				'{contract_date}': 'Fecha del Contrato',
				'{address}': 'Dirección del Proyecto',
				'{district}': 'Municipio del Proyecto',
				'{client_name}': 'Nombre Cliente',
				'{client_dni}': 'Rut Cliente',
				'{client_address}': 'Dirección Cliente',
				'{client_district}': 'Comuna Cliente',
				'{company_name}': 'Nombre Empresa',
				'{company_rut}': 'Rut Empresa',
				'{company_responsible}': 'Nombre Representante',
				'{company_responsible_rut}': 'Rut Representante',
				'{company_address}': 'Dirección Empresa',
				'{client_company_name}': 'Nombre Empresa (Cliente)',
				'{client_company_dni}': 'Rut Empresa (Cliente)',
				'{client_company_address}': 'Dirección Empresa (Cliente)',
				'{client_company_district}': 'Comuna Empresa (Cliente)',
				'{client_rep_name}': 'Nombre Representante (Cliente)',
				'{client_rep_dni}': 'Rut Representante (Cliente)',
				'{client_rep_address}': 'Dirección Representante (Cliente)',
				'{client_rep_district}': 'Comuna Representante (Cliente)',
			},
    };
  },
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCategory(newValue){
			if(newValue){
				this.$store.dispatch('contracts/getCompanyContractsConfig', {category_id: this.selectedCategory.id, start: 0, limit: 100})
			}
		},
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
		}
	},
  methods: {
		transformPlaceholdersToFriendlyView(content) {
			let result = content;
			for (const [placeholder, label] of Object.entries(this.placeholderMapping)) {
				const regex = new RegExp(placeholder, 'g');
				result = result.replace(regex, `<span class="friendly-placeholder" style="font-size: 12px;padding: 1px 6px;border: 1px solid grey;border-radius: 5px;background: white;color:orange;" contenteditable="false">${label}</span>`);
			}
			return result;
		},
		newContractCategory(){
			const order = this.$store.state.contracts.companyContractCategories.length + 1
			this.$store.commit('contracts/setModalCategoryEditing', { order })
			this.$store.commit('contracts/setModalCategory', true)
		},
		newContractSection(){
			if(this.selectedCategory){
				const data = {
					title: null,
					code: null,
					type: 1,
					order: this.selectedCategory.sections_count + 1,
					content: null,
					category_id: this.selectedCategory.id,
				}
				this.$store.commit('contracts/setModalSectionEditing', data)
				this.$store.commit('contracts/setModalSection', true)
			}
			else{
				const messageData = {
					message: 'Primero selecciona una categoría',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		},
		startEndDragEventCategory: _debounce(function(ev){ this.endDragEventCategory(ev) }, 800),
		async endDragEventCategory(ev){
			if (typeof ev.newIndex === 'number') {
				const categories = this.$store.state.contracts.companyContractCategories.map((category, index) => {
					return { order: index + 1, id: category.id}
				})
				await updateContractSectionCategoryOrders({categories})
				this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
			}
		},
		startEndDragEvent: _debounce(function(ev){ this.endDragEvent(ev) }, 800),
		async endDragEvent(ev){
			if (typeof ev.newIndex === 'number') {
				const sections = this.$store.state.contracts.companyContracts.map((section, index) => {
					return { order: index + 1, id: section.id}
				})
				await updateContractSectionOrders({sections})
				this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
			}
		},
    editSection(section) {
      const data = {
				...section,
				...{section_id: section.id}
			}
			this.$store.commit('contracts/setModalSectionEditing', data)
			this.$store.commit('contracts/setModalSection', true)
    },
    editCategory(category) {
      const data = {
				...category,
				...{category_id: category.id}
			}
			this.$store.commit('contracts/setModalCategoryEditing', data)
      this.$store.commit('contracts/setModalCategory', true)
    },
    async deleteSectionCategory(category_id) {
      await deleteContractSectionCategory({category_id})
			this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
    },
    async deleteSection(section_id) {
      await deleteContractSection({section_id})
			this.selectedCategory && this.$store.dispatch('contracts/getCompanyContractsConfig', {category_id: this.selectedCategory.id, start: 0, limit: 100})
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    }
  },
};
</script>

<style scoped>
	.custom-html-content {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	/deep/ .custom-html-content p {
		margin-bottom: 0;
	}
</style>