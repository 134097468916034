import {endpoints} from './endpoints'
import request from '../request'

export function createCostCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCostSubcategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostSubcategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCostItem (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostItem,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCostCategories (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCostCategories,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCostCategory (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCostCategory,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function saveAllCostCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.saveAllCostCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostCategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostSubcategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostSubcategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostItem (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostItem,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}


// Config

export function getCostCategoriesCompany (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCostCategoriesCompany,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCostCategoryCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostCategoryCompany,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateCostCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateCostCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostCategoryCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostCategoryCompany,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCostSubCategoryCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostSubCategoryCompany,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateCostSubCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateCostSubCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostSubCategoryCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostSubCategoryCompany,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCostItemCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostItemCompany,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateCostItem (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateCostItem,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostItemCompany (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostItemCompany,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCostCenter (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostCenter,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateCostCenter (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateCostCenter,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostCenter (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostCenter,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCostTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostTemplate,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyCostTemplates (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyCostTemplates,
			method: 'get',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostTemplate,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}