const endpoints = {
	login: '/auth/login',
	register: '/auth/signup',
	resetPassRequest: '/auth/resetpassrequest',
	resetPass: '/auth/resetpass',
	userInfo: '/user/info',
	update: '/user/update',
	setUserBillingInfo: '/user/setUserBillingInfo',
	getUserBillingInfo: '/user/getUserBillingInfo',
	setSimpleSignature: '/user/setSimpleSignature',
	setAccountCompanyProfile: '/user/setAccountCompanyProfile',
	setCompanyAccreditationUserStatus: '/user/setCompanyAccreditationUserStatus',
	loginWithGoogleToken: '/social-auth/loginWithGoogleToken',
	resendVerificationEmail: '/auth/resendVerificationEmail'
}
export {endpoints}