<template>
	<div class="fill-height pa-2 d-flex flex-column" v-if="loading">
		<p class="text-center caption">Cargando Presupuesto</p>
		<v-skeleton-loader
			type="heading"
			class="my-2 mx-6"
		></v-skeleton-loader>
		<v-skeleton-loader
			type="paragraph"
			class="my-2 mx-6"
		></v-skeleton-loader>
		<v-skeleton-loader
			type="text"
			class="my-2 mx-6"
		></v-skeleton-loader>
		<v-skeleton-loader
			type="paragraph"
			class="my-2 mx-6"
		></v-skeleton-loader>
	</div>
	<div ref="clientBudgetContainer" class="fill-height pa-2 d-flex flex-column" v-else-if="budget">
		<div class="mx-4 custom-warning-status px-2 py-1 mb-2">
			<div class="d-flex align-center">
				<p class="mb-0 mr-2 font-weight-bold caption">Estado del presupuesto:</p>
				<v-chip
					:color="budget.last_status?.status | budgetStatusColor"
					label
					outlined
					x-small
					class="px-1"
				>
					<b>{{ budget.last_status?.status | budgetStatus }}</b>
				</v-chip>
			</div>
			<p class="mb-0 caption">{{ budget.last_status?.status | budgetEstatusNoticeClient }}</p>
		</div>
		<div style="position: relative">
			<div v-if="budget.last_status?.status == budgetStatuses.OBSERVADO_CLIENTE" class="px-4 pb-0 mb-2 d-flex flex-column align-center justify-space-between">
				<p class="mb-0 caption">El cliente ha hecho una observación al presupuesto, por favor revísala y envía nuevamente el presupuesto al cliente.</p>
				<p class="mt-2 mb-0 caption">Observación Cliente: "<span class="font-weight-bold">{{ budget.last_observation?.observation }}</span>"</p>
			</div>
			<div v-else-if="budget.last_status?.status == budgetStatuses.ACEPTADO" class="px-4 py-0 mb-2 d-flex align-center justify-space-between">
				<div class="d-flex justify-end flex-grow-1">
					<v-btn
						depressed
						color="green"
						dark
						class="mb-0 mr-2"
						@click="createContract"
					>
						<v-icon small>mdi-briefcase-outline</v-icon>
						<p class="mb-0 ml-2 caption">Pasar a Contrato</p>
					</v-btn>
				</div>
			</div>
			<iframe v-if="budgetPdf" :src="budgetPdf" width="100%" class="fill-height d-flex flex-grow-1" style="min-height: 650px;"></iframe>
			<div v-else class="pa-2">
				<p class="caption mb-0 text-center">Lo sentimos, no se encuentra el PDF de este presupuesto</p>
			</div>
		</div>
	</div>
</template>

<script>
import { getBudgetById } from '@/helpers/api/budgets'
import { createContract } from '@/helpers/api/contracts'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'
import budgetStatuses from '@/components/Helpers/Constants/budget.js'

export default {
	name: 'CompanyBudget',
	props: {
		budget_id: {
			type: [Number, String],
			default: null
		},
		handleOpenCrmDetail: {
      type: Function
    },
		condensed: {
			type: Boolean,
			default: false
		}
	},
	watch:{
		budget_id(newData){
			newData && this.getBudgetInfo()
		}
	},
	computed: {
		budgetPdf(){
			return this.budget?.pdf?.file.file_link
		},
	},
	data: () => ({
		budgetStatuses,
		loading: false,
		budget: null
	}),
	mounted(){
		this.budget = this.budget_info
		if(!this.budget){
			this.getBudgetInfo()
		}
    eventBus.$on('reloadBudget', (data) => {
			if(this.budget && data.budget_id == this.budget.id){
				this.getBudgetInfo()
			}
    })
		this.$nextTick(() => {
      const element = this.$refs.clientBudgetContainer
      if (element) {
        this.componentWidth = element.offsetWidth
      }
		})
	},
	methods: {
		async getBudgetInfo(){
			if(this.budget_id){
				this.loading = true
				const budget = await getBudgetById({budget_id: this.budget_id})
				if(budget.code == 200){
					this.budget = null
					this.$nextTick(() => {
						this.budget = budget.budget
					})
				}
				this.loading = false
			}
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
		getType(type){
			switch (type) {
				case "App\\Models\\BotConversation":
					return 'crm'
				case "App\\Models\\PreProject":
					return 'preproject'
				case "App\\Models\\Project":
					return 'project'
				default:
					break;
			}
			return 'Otro'
		},
		openNewProjectModal(preproject){
			const pre_project = {...preproject, ...{doc_type: 1}}
			this.$store.commit('projects/setProjectModalInfo', pre_project)
			this.$store.commit('projects/setProjectModal', true)
		},
    async createContract(){
      if(this.budget.morphable_id){
				if(this.getType(this.budget.morphable_type) == 'preproject'){
					this.openNewProjectModal(this.budget.morphable)
					return null
				}
				const data = {
					item_type: this.getType(this.budget.morphable_type),
					item_id: this.budget.morphable_id
				}
        const contract = await createContract(data)
        if(contract.code === 200){
          const messageData = {
            message: 'Se ha creado correctamente el contrato',
            title: 'Contrato creado',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
					this.$router.push({ name: 'ContractDetail', query: { id: contract.contract.id } })
          this.setMessage(messageData)
        }
        else{
          const messageData = {
            message: 'Hubo un error en la operación, por favor intenta nuevamente',
            title: 'Ha ocurrido un error',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#FF1209',
          }
          this.setMessage(messageData)
        }
      }
    }
	}
}
</script>

<style>

.custom-warning-status{
	border: 1px solid #fb8c00;
	border-radius: 6px;
}

</style>