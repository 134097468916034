<template>
	<div class="fill-height pa-2 d-flex flex-column" v-if="loading">
		<p class="text-center caption">Cargando Presupuesto</p>
		<v-skeleton-loader
			type="heading"
			class="my-2 mx-6"
		></v-skeleton-loader>
		<v-skeleton-loader
			type="paragraph"
			class="my-2 mx-6"
		></v-skeleton-loader>
		<v-skeleton-loader
			type="text"
			class="my-2 mx-6"
		></v-skeleton-loader>
		<v-skeleton-loader
			type="paragraph"
			class="my-2 mx-6"
		></v-skeleton-loader>
	</div>
	<div ref="clientBudgetContainer" class="fill-height pa-2 d-flex flex-column" v-else-if="budget">
		<div class="mx-4 custom-warning-status px-2 py-1 mb-2">
			<div class="d-flex align-center">
				<p class="mb-0 mr-2 font-weight-bold caption">Estado del presupuesto:</p>
				<v-chip
					:color="budget.last_status?.status | budgetStatusColor"
					label
					outlined
					x-small
					class="px-1"
				>
					<b>{{ budget.last_status?.status | budgetStatus }}</b>
				</v-chip>
			</div>
			<p class="mb-0 caption">{{ budget.last_status?.status | budgetEstatusNoticeClient }}</p>
		</div>
		<!-- <div class="mx-4 mb-2">
			<v-alert
				outlined
				type="warning"
				dense
				min-width="100%"
				border="left"
			>
				<p class="mb-0 caption">{{ budget.last_status?.status | budgetEstatusNoticeClient }}</p>
			</v-alert>
			<div class="d-flex align-center">
				<p class="mb-0 mr-2 font-weight-bold caption">Estado del presupuesto:</p>
				<v-chip
					:color="budget.last_status?.status | budgetStatusColor"
					label
					outlined
					x-small
					class="px-1"
				>
					<b>{{ budget.last_status?.status | budgetStatus }}</b>
				</v-chip>
				<p class="ml-2 mb-0 caption">{{ budget.last_status?.description }}</p>
			</div>
		</div> -->
		<div style="position: relative">
			<div v-if="budget.last_status?.status == budgetStatuses.OBSERVADO_CLIENTE" class="px-4 py-0 mb-2 d-flex flex-column justify-space-between">
				<p class="mb-0 caption">Has enviado una observación para este presupuesto, el encargado está revisándola para enviarte un nuevo presupuesto con la información modificada si así lo dispone la empresa.</p>
				<p class="mt-2 mb-0 caption">Tu Observación: "<span class="font-weight-bold">{{ budget.last_observation?.observation }}</span>"</p>
			</div>
			<div v-else-if="budget.last_status?.status == budgetStatuses.ENVIADO_CLIENTE" class="px-4 py-0 mb-2 d-flex flex-column align-end justify-space-between">
				<div class="d-flex align-center mb-1">
					<v-checkbox class="my-0 py-0" v-model="readed" color="#959595" hide-details dense></v-checkbox>
					<p class="mb-0 caption">Confirmo haber leído y revisado el presupuesto</p>
				</div>
				<div class="d-flex justify-end flex-grow-1 w-100">
					<v-btn
						depressed
						color="green"
						class="mb-0 mr-2"
						small
						:disabled="!readed"
						:dark="readed"
						@click="changeStatus(budgetStatuses.ACEPTADO)"
					>
						<v-icon small>mdi-check</v-icon>
						<p class="mb-0 ml-2 caption">Aceptar</p>
					</v-btn>
					<v-btn
						depressed
						color="error"
						outlined
						class="mb-0 mr-2"
						small
						:disabled="!readed"
						:dark="readed"
						@click="changeStatus(budgetStatuses.RECHAZADO)"
					>
						<v-icon small>mdi-cancel</v-icon>
						<p class="mb-0 ml-2 caption">Rechazar</p>
					</v-btn>
					<v-btn
						depressed
						color="warning"
						:outlined="!showObservation"
						class="mb-0"
						small
						dark
						@click="showObservation = !showObservation"
					>
						<v-icon small>mdi-comment-plus-outline</v-icon>
						<p class="mb-0 ml-2 caption">Observación</p>
					</v-btn>
				</div>
				<div class="d-flex flex-column justify-space-between flex-grow-1 w-100" v-if="showObservation">
					<v-divider class="mt-4 mb-2"></v-divider>
					<div class="px-4">
						<p class="mb-0 caption">Ingresa una observación solicitando a la empresa que corrija o modifique el presupuesto.</p>
						<v-textarea
							v-model="budgetObservation"
							label="Ingresar Observación a Presupuesto"
							color="#524D4D"
							outlined
							rows="2"
							class="mt-2 w-100"
						></v-textarea>
						<v-btn
							color="c#524D4D'"
							raised
							dark
							small
							block
							:loading="loadingSendObservation"
							@click="sendBudgetObservation"
						>
							<p class="mb-0">Enviar Observación</p>
						</v-btn>
					</div>
				</div>
			</div>
			<iframe v-if="budgetPdf" :src="budgetPdf" width="100%" class="fill-height d-flex flex-grow-1" style="min-height: 650px;"></iframe>
			<div v-else class="pa-2">
				<p class="caption mb-0 text-center">Lo sentimos, no se encuentra el PDF de este presupuesto</p>
			</div>
		</div>
	</div>
</template>

<script>
import { getBudgetById, addNewStatus, newBudgetObservation } from '@/helpers/api/budgets'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'
import budgetStatuses from '@/components/Helpers/Constants/budget.js'

export default {
	name: 'ClientBudget',
	props: {
		budget_id: {
			type: [Number, String],
			default: null
		},
		handleOpenCrmDetail: {
      type: Function
    },
		condensed: {
			type: Boolean,
			default: false
		}
	},
	watch:{
		budget_id(newData){
			newData && this.getBudgetInfo()
		}
	},
	computed: {
		budgetPdf(){
			return this.budget?.pdf?.file.file_link
		},
	},
	data: () => ({
		budgetStatuses,
		readed: false,
		budgetObservation: null,
		loading: false,
		isSticky: false,
		topOffset: 0,
		showObservation: false,
		budget: null,
		loadingSendObservation: false
	}),
	mounted(){
		this.budget = this.budget_info
		if(!this.budget){
			this.getBudgetInfo()
		}
    eventBus.$on('reloadBudget', (data) => {
			if(this.budget && data.budget_id == this.budget.id){
				this.getBudgetInfo()
			}
    })
		this.$nextTick(() => {
      const element = this.$refs.clientBudgetContainer
      if (element) {
        this.componentWidth = element.offsetWidth
      }
		})
	},
	methods: {
		async getBudgetInfo(){
			if(this.budget_id){
				this.loading = true
				const budget = await getBudgetById({budget_id: this.budget_id})
				if(budget.code == 200){
					this.budget = null
					this.$nextTick(() => {
						this.budget = budget.budget
					})
				}
				this.loading = false
			}
		},
		async changeStatus(status){
			const newStatus = await addNewStatus({budget_id: this.budget_id, status})
			if(newStatus.code == 200){
				this.getBudgetInfo()
				let messageData = {
					message: 'Genial! Haz aceptado el presupuesto para tu proyecto.',
					title: 'Presupuesto Aceptado',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				if(status == budgetStatuses.RECHAZADO){
					messageData = {
						message: 'Lamentamos que hayas tenido que rechazar el presupuesto. Ya informamos a la empresa tu decisión.',
						title: 'Presupuesto Rechazado',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#FF1209',
					}
				}
				this.setMessage(messageData)
			}
			else{
				const messageData = {
					message: 'Hubo un error en la operación, por favor intenta nuevamente',
					title: 'Ha ocurrido un error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
		async sendBudgetObservation(){
			this.loadingSendObservation = true
			const observation = await newBudgetObservation({
				budget_id: this.budget_id,
				observation: this.budgetObservation
			})
			if(observation.code == 200){
				this.budgetObservation = null
				this.getBudgetInfo()
			}
			this.loadingSendObservation = false
		},
	}
}
</script>

<style scoped>

.sticky-header {
  position: absolute;
	left: 0; 
	right: 0;
	max-width: inherit;
  background: white;
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
.custom-warning-status{
	border: 1px solid #fb8c00;
	border-radius: 6px;
}

</style>