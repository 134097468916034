const endpoints = {
	createContractSectionCategory: '/contracts/config/createContractSectionCategory',
	updateContractSectionCategory: '/contracts/config/updateContractSectionCategory',
	updateContractSectionCategoryOrders: '/contracts/config/updateContractSectionCategoryOrders',
	getCompanyContractSectionCategories: '/contracts/config/getCompanyContractSectionCategories',
	deleteContractSectionCategory: '/contracts/config/deleteContractSectionCategory',
	createContractSection: '/contracts/config/createContractSection',
	updateContractSection: '/contracts/config/updateContractSection',
	updateContractSectionOrders: '/contracts/config/updateContractSectionOrders',
	deleteContractSection: '/contracts/config/deleteContractSection',
	getCompanyContractSections: '/contracts/config/getCompanyContractSections',
	createContractTitle: '/contracts/config/createContractTitle',
	updateContractTitle: '/contracts/config/updateContractTitle',
	getContractTitles: '/contracts/config/getContractTitles',
	deleteContractTitle: '/contracts/config/deleteContractTitle',
	createContract: '/contracts/createContract',
	updateContract: '/contracts/updateContract',
	createContractSectionProject: '/contracts/createContractSection',
	createContractSectionContent: '/contracts/createContractSectionContent',
	getContract: '/contracts/getContract',
	getCompanyContracts: '/contracts/getCompanyContracts',
	updateContractSectionProject: '/contracts/updateContractSection',
	updateContractSectionContent: '/contracts/updateContractSectionContent',
	updateContractSectionProjectOrders: '/contracts/updateContractSectionOrders',
	updateContractSectionContentOrders: '/contracts/updateContractSectionContentOrders',
	deleteContractSectionProject: '/contracts/deleteContractSection',
	deleteContractSectionContent: '/contracts/deleteContractSectionContent',
	createContractTemplate: '/contracts/createContractTemplate',
	setTemplateToContract: '/contracts/setTemplateToContract',
	getContractTemplates: '/contracts/getContractTemplates',
	deleteContractTemplate: '/contracts/deleteContractTemplate',
	createContractContentComment: '/contracts/createContractContentComment',
	updateContractContentComment: '/contracts/updateContractContentComment',
	deleteContractContentComment: '/contracts/deleteContractContentComment',
	createContractPDF: '/contracts/createContractPDF',
	createContractPdfFromPreview: '/contracts/createContractPdfFromPreview',
	sendContractPdfEmail: '/contracts/sendContractPdfEmail',
	addNewStatus: '/contracts/addNewStatus',
	signContract: '/contracts/signContract',
	companySignContract: '/contracts/companySignContract',
	sendContractPaymentRequest: '/contracts/sendContractPaymentRequest',
}
export {endpoints}