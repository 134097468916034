<template>
	<v-dialog
		v-model="$store.state.crm.openBudgetModal"
		max-width="800"
		scrollable
	>
		<v-card>
			<v-card-text class="py-2">
				<div class="d-flex align-center justify-space-between">
					<p class="font-weight-bold body-2 mb-0 mr-2">Informe</p>
					<div class="d-flex">
						<v-btn
							depressed
							color="#524D4D"
							x-small
							outlined
							:loading="loading"
							@click="openBudgetModal"
						>
							<v-icon small>mdi-table-cog</v-icon>
							<p class="mb-0 ml-2 caption">Gestionar Presupuesto</p>
						</v-btn>
						<v-btn
							depressed
							color="#524D4D"
							x-small
							outlined
							icon
							class="ml-2"
							@click="$store.commit('crm/setOpenBudgetModal', false)"
						>
							<v-icon small>mdi-close</v-icon>
						</v-btn>
					</div>
				</div>
				<v-divider class="my-2"></v-divider>
				<ReportsHandler :item="{...conversation, item_type: 'crm'}"/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>


<script>
import ReportsHandler from '@/components/Helpers/Reports/ReportsHandler'

export default {
	name: 'PreProjectModule',
	components: {ReportsHandler},
	props: {},
  data() {
    return {
			loading: false,
    };
  },
  computed: {
    conversation() {
      return this.$store.state.crm.conversation
    },
  },
	watch: {
	},
	mounted(){
	},
  methods: {
		openBudgetModal(){
			localStorage.setItem('last_working_budget', JSON.stringify(this.conversation.budget))
			this.$router.push({ name: 'BudgetDetail', query: { id: this.conversation.budget.id } })
		}
  },
};
</script>