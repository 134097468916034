<template>
	<v-container fluid class="crm-index">
		<div class="d-flex align-center justify-end">
			<div class="d-flex align-center mb-2" style="flex: 1;max-width: 400px;">
				<v-icon color="#524D4D" @click="searchForString">mdi-magnify</v-icon>
				<v-text-field
					v-model="searchInput"
					:loading="$store.state.schedule.loadingConversations"
					outlined
					dense
					hide-details
					item-text="name"
					item-value="id"
					return-object
					clearable
					label="Búsqueda"
					class="mb-0 mr-2 custom-height-search custom-height-calendar"
					color="#524D4D"
					@input="sendToSearch"
				></v-text-field>
			</div>
		</div>
		<v-data-table
			ref="table"
			:headers="headers"
			:items="$store.state.crm.archivedConversations"
			item-key="id"
			:loading="$store.state.crm.loadingConversations"
			:server-items-length="this.$store.state.crm.arch_conversations_count"
			:options.sync="options"
			loading-text="Cargando datos..."
			class="crm-table"
			:class="$vuetify.breakpoint.mobile ? '':'resizable'"
			disable-sort
      :hide-default-header="!$vuetify.breakpoint.mobile"
			:footer-props="{
        itemsPerPageText: 'Filas por página',
				'items-per-page-options': [10, 30, 50, 100]
			}"
		>
			<template v-slot:header="{ props }" v-if="!$vuetify.breakpoint.mobile">
				<thead class="v-data-table-header">
					<tr>
						<th role="columnheader" scope="col" aria-label="#" class="header-cell"
							style="max-height: 30px; height: 25px;"
							:style="{ width: head.width ? head.width + 'px' : 'auto' }"
							:class="head.align == 'center' ? 'text-center':''"
							v-for="(head,index) in props.headers" :key="index"
							@mouseup="saveColumnWidth(index)"
						>
							<span>{{ head.text.toUpperCase() }}</span>
						</th>
					</tr>
				</thead>
			</template>
			<template v-slot:item.user="{ item }">
				<div><b>{{ item.user && item.user.name ? item.user.name:'No identificado'}}</b></div>
				<div v-if="item.user">{{ item.user ? item.user.email:'No identificado'}}</div>
			</template>
			<template v-slot:item.address="{ item }">
				<div class="d-flex justify-space-between align-center">
					<div>
						<div><b>{{ item.project_info ? item.project_info.address?.address:'Sin registro'}}</b> - {{ item.project_info ? item.project_info?.address?.district?.name:'Sin registro Comuna'}}</div>
						<div v-if="item.project_info?.address?.rol">Rol: <b>{{ item.project_info?.address?.rol}}</b></div>
					</div>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-1"
								icon
								dark
								x-small
								color="#524D4D"
								@click="openAddressModal(item)"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon small dark>mdi-home-edit-outline</v-icon>
							</v-btn>
						</template>
						<span>Modificar Dirección</span>
					</v-tooltip>
				</div>
			</template>
			<template v-slot:item.project_detail="{ item }">
				<div><b>{{ item.project_info && item.project_info.project_detail ? item.project_info.project_detail:'Sin registro'}}</b></div>
			</template>
			<template v-slot:item.message="{ item }">
				<div v-if="item.last_message">
					<b><small>{{ item.last_message.role == 1 ? 'Sistema':'Usuario' }}</small></b>
					<div style="max-width: 150px;">
						<div class="message-container" v-html="$options.filters.addTargetsToLinksMessages(item.last_message.message)"></div>
					</div>
				</div>
				<div v-else>
					<p class="mb-0 caption">No hay mensajes</p>
				</div>
			</template>
			<template v-slot:item.schedule="{ item }">
				<div class="py-2" v-if="item.schedule">
					<div>
						<v-icon small class="mr-2">mdi-calendar</v-icon>
						<b>{{ item.schedule.vt_date | toDateTime }}</b>
					</div>
					<div>
						<div
							class="mt-1"
						>
							<v-icon
								x-small
								:color="item.schedule.status == 1 ? 'orange':'green'"
								class="mr-1">{{ item.schedule.status == 1 ? 'mdi-alert':'mdi-check' }}</v-icon>
							<small :style="'color:' + (item.schedule.status == 1 ? 'orange':'green')">{{ item.schedule.status == 1 ? 'Esperando confirmación':'Confirmada' }}</small>
						</div>
						<!-- <small><b>{{ item.schedule.status == 1 ? 'Esperando confirmación':'Confirmada' }}</b></small> -->
					</div>
				</div>
				<div v-else>
					No hay agenda
				</div>
			</template>
			<template v-slot:item.schedule_user="{ item }">
				<!-- <div><small>Tipo Proyecto:</small></div> -->
				<div v-if="item.schedule && item.schedule.user_schedule && item.schedule.user_schedule.responsible && item.schedule.user_schedule.responsible.profile">
					<v-icon small class="mr-2">mdi-account-check-outline</v-icon>
					<b>{{ item.schedule.user_schedule.responsible.profile.name + ' ' + item.schedule.user_schedule.responsible.profile.lastname }}</b>
				</div>
			</template>
			<template v-slot:item.details="{ item }">
				<!-- <div><small>Tipo Proyecto:</small></div> -->
				<div>
					<b class="mr-2">{{ item.project_info?.project_type?.code }}</b>

					<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;" v-if="$checkPermissions(['edit_crm'])">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								dark
								small
								icon
								color="#524D4D"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
							</v-btn>
						</template>
						<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
							<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
								<p class="caption mb-0 font-weight-bold white--text">Cambiar Tipo de Proyecto</p>
							</v-list-item>
							<v-list-item-group color="#524D4D">
								<v-list-item
									v-for="(project_type, index) in $store.state.companies.companiesProjectTypes"
									:key="index"
									dense
									@click="changeProjectType(item.id, project_type.id)"
								>
									<v-list-item-title dense>
										{{ project_type.name }}
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</div>
			</template>
			<template v-slot:item.type="{ item }">
				<b>{{ item.mode == 1 ? 'Manual':'Automático' }}</b>
			</template>
			<template v-slot:item.created_at="{ item }">
				<!-- <div><small>Tipo Proyecto:</small></div> -->
				<div><b>{{ item.created_at | toDateTime }}</b></div>
				<v-chip
					color="#524D4D"
					label
					outlined
					x-small
					class="px-1"
				>
					<b>{{ item.mode == 1 ? 'Manual':'Automático' }}</b>
				</v-chip>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="toConversationDetail(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
				<!-- <v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							:dark="!(sentEmailDiff(item) != -1 && sentEmailDiff(item) < 24)"
							x-small
							color="secondary"
							:disabled="sentEmailDiff(item) != -1 && sentEmailDiff(item) < 24"
							v-if="item.schedule"
							@click="resendPaymentRequest(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-email</v-icon>
						</v-btn>
					</template>
					<span>Enviar Correo Solicitud de Pago</span>
				</v-tooltip> -->
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							icon
							dark
							x-small
							color="#524D4D"
							class="mx-1"
							v-bind="attrs"
							v-on="on"
							@click="restoreConversation(item.id)"
						>
							<v-icon small dark>mdi-archive-check-outline</v-icon>
						</v-btn>
					</template>
					<span>Restaurar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import moment from 'moment'
import _debounce from 'lodash/debounce'
import {sendScheduleEmail} from '@/helpers/api/conversations'
import {deleteCrmEntry, restoreCrmEntry, editCrmProjectType} from '@/helpers/api/crm'
import {resizableGrid} from '@/helpers/resize-table'

export default {
	data() {
		return {
			search: '',
			searchInput: null,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
			fixedHeaders: [
				{ text: '#', value: 'id', width: '55px', align: 'center', sortable: false },
				{ text: 'Nombre', value: 'user', sortable: false },
				{ text: 'Dirección', value: 'address', sortable: false },
				{ text: 'Proyecto', value: 'project_detail', sortable: false },
				{ text: 'Tipo Proyecto', value: 'details', sortable: false },
				{ text: 'Estado Agenda VT', value: 'schedule', sortable: false },
				{ text: 'Usuario Responsable VT', value: 'schedule_user', sortable: false },
				{ text: 'Fecha Creación', value: 'created_at', sortable: false },
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
			columnWidths: {}
		};
	},
	computed: {
		headers(){
			let headers = [
				{ text: '#', value: 'id', width: '55px', align: 'center', sortable: false },
				{ text: 'Nombre', align: 'start', value: 'user', sortable: false },
				{ text: 'Dirección', align: 'start', value: 'address', sortable: false },
				{ text: 'Proyecto', align: 'start', value: 'project_detail', sortable: false },
				{ text: 'Tipo Proyecto', value: 'details', sortable: false },
				{ text: 'Estado Agenda VT', align: 'start', value: 'schedule', sortable: false },
				{ text: 'Usuario Responsable VT', align: 'start', value: 'schedule_user', sortable: false },
				{ text: 'Fecha Creación', align: 'start', value: 'created_at', sortable: false },
				{ text: 'Acciones', align: 'start', value: 'actions', sortable: false },
			]
			if(!this.$vuetify.breakpoint.mobile){
				const storedWidths = JSON.parse(localStorage.getItem('columnWidths')) || {};
				headers = headers.map((header) => {
					return {
						...header,
						width: storedWidths[header.value] || 'auto'
					}
				})
			}
			return headers.filter((header) => {
				return this.$store.state.crm.listingColumns[header.value]
			})
		},
		filteredUsers() {
			return this.$store.state.users.usersList.filter(user => {
				return (
					user.name.toLowerCase().includes(this.search.toLowerCase()) ||
					user.email.toLowerCase().includes(this.search.toLowerCase())
				);
			});
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		loadingConversations(){
			return this.$store.state.crm.loadingConversations
		}
	},
	watch: {
    options: {
      handler() {
				this.reloadInfo()
      },
      deep: true,
    },
		selectedCompany(newValue){
			if(newValue){
				this.reloadInfo()
				this.$store.dispatch('companies/getUserCompanyConnectedStatus')
			}
		},
		loadingConversations(newValue){
			if(!newValue){
				this.$nextTick(() => {
					resizableGrid(this.$refs.table.$el)
				})
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.reloadInfo()
			this.$store.dispatch('companies/getUserCompanyConnectedStatus')
		}
		const savedWidths = JSON.parse(localStorage.getItem('columnWidths'));
		if (savedWidths) {
			this.columnWidths = savedWidths;
		}
	},
	methods: {
		openAddressModal(crm){
			const address = crm.project_info?.address
			const data = {
				status: true,
				edit: {...address, ...{crm_id: crm.id, address_id: address?.id, coordinates: null}}
			}
      this.$store.commit('general/setAddressModal', data)
		},
		openBudgetModal(crm){
			localStorage.setItem('last_working_budget', JSON.stringify(crm.budget))
			this.$router.push({ name: 'BudgetDetail', query: { id: crm.budget.id } })
		},
		sendToSearch: _debounce(function() { this.searchForString() }, 800),
		searchForString(){
			this.reloadInfo(this.searchInput)
		},
		toPreProjectsStart(crm){
			const preproject_info = {
				crm_id: crm.id,
				client_id: crm.user.user_id,
				name: crm.project_info?.project_detail
			}
			localStorage.setItem('new_preproject_info', JSON.stringify(preproject_info));
			this.$store.commit('preprojects/setNewPreProjectModal', true)
		},
		saveColumnWidth(index) {
			const header_value = this.headers[index].value
			const headerCell = this.$refs.table.$el.querySelectorAll('.header-cell')[index];
			const newWidth = headerCell.offsetWidth;
			this.$set(this.columnWidths, header_value, newWidth);
			localStorage.setItem('columnWidths', JSON.stringify(this.columnWidths));
		},
		async changeProjectType(conversation_id, project_type_id){
			await editCrmProjectType({conversation_id, project_type_id})
			this.reloadInfo()
		},
		async removeCrm(crm_id){
			await deleteCrmEntry({crm_id})
			this.reloadInfo()
		},
		async restoreConversation(crm_id){
			await restoreCrmEntry({crm_id})
			this.reloadInfo()
		},
		async resendPaymentRequest(conversation){
			try {
				await sendScheduleEmail({schedule_id: conversation.schedule.id})
				const data = {
					message: 'El correo con la solicitud de pago ha sido enviado al usuario.',
					title: 'Correo enviado correctamente',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.dispatch('general/addMessage', data)
				this.reloadInfo()
			} catch {
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Error al enviar el correo',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.$store.dispatch('general/addMessage', data)
			}
		},
		sentEmailDiff(conversation){
			if(conversation.schedule && conversation.schedule.request_email.length > 0){
				const current = moment()
				const startTime = conversation.schedule.request_email[0].created_at
				var duration = moment.duration(current.diff(startTime))
				var hours = duration.asHours()
				return hours
			}
			else if(conversation.schedule.request_email.length == 0){
				return -1
			}
			return null
		},
		addUser() {
			this.$store.commit('users/setNewUserModal', true)
		},
		toConversationDetail(conversation) {
			this.$router.push({ name: 'Conversation', params: { id: conversation.id } })
		},
		editUser(user) {
			alert(`Editar usuario: ${user.name}`);
		},
		deleteUser(user) {
			const index = this.users.indexOf(user);
			if (index > -1) {
				this.users.splice(index, 1);
			}
		},
		reloadInfo(search = null){
			let data = {
				...this.options,
				start: (this.options.page - 1) * this.options.itemsPerPage,
				limit: this.options.itemsPerPage,
			}
			if(search){
				data = {search}
			}
			this.$store.dispatch('crm/getArchivedConversations', data)
		}
	},
};
</script>

<style>
	.crm-index .message-container{
		margin-bottom: 0;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.crm-index .message-container p{
		margin-bottom: 0;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.crm-table table tr td{
		font-size: 11px!important;
	}
	.crm-table.resizable .v-data-table__wrapper th,
	.crm-table.resizable .v-data-table__wrapper td {
		border-right: 1px solid #cacaca;
	}
</style>
<style scoped>
	.menu-bg-white{background: white;}
</style>