<template>
<v-container fluid>
  <div>
    <vue-file-toolbar-menu :content="menu" class="bar" />
    <vue-document-editor
			id="budget-editor"
			ref="budgetTextEditor"
			:content.sync="budgetTextContent"
      :zoom="zoom"
      :page_format_mm="page_format_mm"
      :page_margins="page_margins"
			:overlay="overlay"
      :display="display"/>
  </div>
</v-container>
</template>

<script>
import VueFileToolbarMenu from 'vue-file-toolbar-menu';
import VueDocumentEditor from 'vue-document-editor'

export default {
	name: 'BudgetTextEditor',
	components: { VueDocumentEditor, VueFileToolbarMenu },
	filters: {
	},
	props: {
		budget: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		budgetTextContent: [],
		zoom: 1,
		zoom_min: 0.10,
		zoom_max: 5.0,
		page_format_mm: [215.9, 279.4],
		page_margins: "18mm 18mm 25mm 30mm",
		display: "grid",
		font: "Archivo Narrow",
		font_size: '10pt',
		mounted: false
	}),
	computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		ufValue(){
			return this.$store.state.general.dayUfValue
		},
		menu () {
      return [
        { icon: "format_align_left", title: "Align left", active: this.isLeftAligned, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+l" : "ctrl+shift+l", click: () => document.execCommand("justifyLeft") },
        { icon: "format_align_center", title: "Align center", active: this.isCentered, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+e" : "ctrl+shift+e", click: () => document.execCommand("justifyCenter") },
        { icon: "format_align_right", title: "Align right", active: this.isRightAligned, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+r" : "ctrl+shift+r", click: () => document.execCommand("justifyRight") },
        { icon: "format_align_justify", title: "Justify content", active: this.isJustified, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+j" : "ctrl+shift+j", click: () => document.execCommand("justifyFull") },
        { is: "separator" },
        { icon: "format_bold", title: "Bold", active: this.isBold, disabled: !this.current_text_style, hotkey: this.isMacLike ? "command+b" : "ctrl+b", click: () => document.execCommand("bold") },
        { icon: "format_italic", title: "Italic", active: this.isItalic, disabled: !this.current_text_style, hotkey: this.isMacLike ? "command+i" : "ctrl+i", click: () => document.execCommand("italic") },
        { icon: "format_underline", title: "Underline", active: this.isUnderline, disabled: !this.current_text_style, hotkey: this.isMacLike ? "command+u" : "ctrl+u", click: () => document.execCommand("underline") },
        { icon: "format_strikethrough", title: "Strike through", active: this.isStrikeThrough, disabled: !this.current_text_style, click: () => document.execCommand("strikethrough") },
        { is: "button-color", type: "compact", menu_class: "align-center", disabled: !this.current_text_style, color: this.curColor, update_color: (new_color) => document.execCommand('foreColor', false, new_color.hex8) },
        { is: "button-color", type: "compact", menu_id: "menu-background-color-text", menu_class: "align-center", disabled: !this.current_text_style, color: this.backColor,
					update_color: (new_color) => {
						document.execCommand('hiliteColor', false, new_color.hex8)
						// const icono = document.getElementById('background-text-color-icon');
						// if (icono) {
						// 	icono.style.color = new_color.hex8;
						// }
					}
				},
				{
					html: '<div style="width: 160px; font-size: 80%;">'+this.font+'</div>',
					title: "Fuente",
					chevron: true,
					menu: this.font_menu,
					menu_height: 200
				},
				{
					html: '<div style="width: 40px; font-size: 80%;">'+this.font_size+'</div>',
					title: "Font Size",
					chevron: true,
					menu: this.font_size_menu,
          menu_width: 80,
					menu_height: 200
				},
        { is: "spacer" },
        { icon: "format_list_numbered", title: "Numbered list", active: this.isNumberedList, disabled: !this.current_text_style, click: () => document.execCommand("insertOrderedList") },
        { icon: "format_list_bulleted", title: "Bulleted list", active: this.isBulletedList, disabled: !this.current_text_style, click: () => document.execCommand("insertUnorderedList") },
        { html: "<b>H1</b>", title: "Header 1", active: this.isH1, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h1>') },
        { html: "<b>H2</b>", title: "Header 2", active: this.isH2, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h2>') },
        { html: "<b>H3</b>", title: "Header 3", active: this.isH3, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h3>') },
        { icon: "format_clear", title: "Clear format", disabled: !this.current_text_style, click () { document.execCommand('removeFormat'); document.execCommand('formatBlock', false, '<div>'); } },
        
        { is: "spacer" },

        { // Format menu
          text: this.current_format_name,
          title: "Format",
          icon: "crop_free",
          chevron: true,
          menu: this.formats.map(([text, w, h]) => {
            return {
              text,
              active: (this.page_format_mm[0] == w && this.page_format_mm[1] == h),
              click: () => { this.page_format_mm = [w, h]; }
            }
          }),
          menu_width: 80,
          menu_height: 280
        },
        // { // Margins menu
        //   text: this.current_margins_name,
        //   title: "Margins",
        //   icon: "select_all",
        //   chevron: true,
        //   menu: this.margins.map(([text, value]) => {
        //     return {
        //       text: text+" ("+value+")",
        //       active: (this.page_margins == value),
        //       click: () => { this.page_margins = value; }
        //     }
        //   }),
        //   menu_width: 200,
        //   menu_class: "align-center"
        // },
        { // Zoom menu
          text: Math.floor(this.zoom * 100) + "%",
          title: "Zoom",
          icon: "zoom_in",
          chevron: true,
          menu: [
            ["200%", 2.0],
            ["150%", 1.5],
            ["125%", 1.25],
            ["100%", 1.0],
            ["75%", 0.75],
            ["50%", 0.5],
            ["25%", 0.25]
          ].map(([text, zoom]) => {
            return {
              text,
              active: this.zoom == zoom,
              click: () => { this.zoom = zoom; }
            }
          }),
          menu_width: 80,
          menu_height: 280,
          menu_class: "align-center"
        },
        // { // Display mode menu
        //   title: "Display",
        //   icon: this.display == "horizontal" ? "view_column" : (this.display == "vertical" ? "view_stream" : "view_module"),
        //   chevron: true,
        //   menu: [{
        //     icon: "view_module",
        //     active: this.display == "grid",
        //     click: () => { this.display = "grid"; }
        //   }, {
        //     icon: "view_column",
        //     active: this.display == "horizontal",
        //     click: () => { this.display = "horizontal"; }
        //   }, {
        //     icon: "view_stream",
        //     active: this.display == "vertical",
        //     click: () => { this.display = "vertical"; }
        //   }],
        //   menu_width: 55,
        //   menu_class: "align-right"
        // }
      ]
    },
		current_format_name () {
			const format = this.formats.find(([, width_mm, height_mm]) => (this.page_format_mm[0] == width_mm && this.page_format_mm[1] == height_mm));
			return format ? format[0] : (this.page_format_mm[0]+"mm x "+this.page_format_mm[1]+"mm");
		},
		formats: () => [
			["Carta", 215.9, 279.4]
		],
		current_margins_name () {
			const margins = this.margins.find(([, margins]) => (this.page_margins == margins));
			return margins ? margins[0] : this.page_margins;
		},
		margins: () => [
			["Margen Pequeño", "5mm"],
			["Margen Normal", "15mm"],
			["Margen Medio", "20mm"],
			["Margen Angosto", "10mm 15mm"]
		],
		current_text_style () { return this.mounted ? this.$refs.budgetTextEditor.current_text_style : false; },
		isLeftAligned () { return ["start", "left", "-moz-left"].includes(this.current_text_style.textAlign); },
		isRightAligned () { return ["end", "right", "-moz-right"].includes(this.current_text_style.textAlign); },
		isCentered () { return ["center", "-moz-center"].includes(this.current_text_style.textAlign); },
		isJustified () { return ["justify", "justify-all"].includes(this.current_text_style.textAlign); },
		isBold () {
			const fontWeight = this.current_text_style.fontWeight;
			return fontWeight && (parseInt(fontWeight) > 400 || fontWeight.indexOf("bold") == 0);
		},
		isItalic () { return this.current_text_style.fontStyle == "italic"; },
		isUnderline () { // text-decoration is not overridden by children, so we query the parent stack
			const stack = this.current_text_style.textDecorationStack;
			return stack && stack.some(d => (d.indexOf("underline") == 0));
		},
		isStrikeThrough () { // text-decoration is not overridden by children, so we query the parent stack
			const stack = this.current_text_style.textDecorationStack;
			return stack && stack.some(d => (d.indexOf("line-through") == 0));
		},
		isNumberedList () { return this.current_text_style.isList && this.current_text_style.listStyleType == "decimal"; },
		isBulletedList () { return this.current_text_style.isList && ["disc", "circle"].includes(this.current_text_style.listStyleType); },
		isH1 () { return this.current_text_style.headerLevel == 1; },
		isH2 () { return this.current_text_style.headerLevel == 2; },
		isH3 () { return this.current_text_style.headerLevel == 3; },
		curColor () { return this.current_text_style.color || "transparent"; },
		backColor () { return this.current_text_style.backgroundColor || "transparent"; },
		isMacLike: () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
    font_menu () {
      return this.font_list.map(font => {
        return {
          html: '<div style="font-size:12px;padding:0;' + (font == this.font ? 'color:green;font-weight:bold;':'') + '"">'+font+'</div>',
          // icon: (this.theme != "default" && this.font == font) ? 'check' : false,
          active: (this.font == font),
					padding: 0,
          click: () => {
            document.execCommand("fontName", false, font);
            this.font = font;
          }
        };
      });
    },
    font_list: () => [
			"Archivo Narrow",
			"Andale Mono",
			"Arial",
			"Arial Black",
			"Book Antiqua",
			"Comic Sans MS",
			"Courier New",
			"Georgia",
			"Helvetica",
			"Impact",
			"Symbol",
			"Tahoma",
			"Terminal",
			"Times New Roman",
			"Trebuchet MS",
			"Verdana",
			"Webdings",
			"Wingdings"
		],
    font_size_menu () {
			let fontSizes = []
			for (let fontSize = 1; fontSize <= 40; fontSize++) {
				const size_str = fontSize + 'pt'
				fontSizes.push({
          html: '<div style="font-size:12px;padding:0;' + (size_str == this.font_size ? 'color:green;font-weight:bold;':'') + '"">'+size_str+'</div>',
          icon: (this.theme != "default" && this.font == size_str) ? 'check' : false,
          active: (this.font_size == size_str),
          click: () => {
            this.setSelectionFontSize(size_str)
            this.font_size = size_str;
          }
        })
			}
			return fontSizes
    }

	},
	mounted(){
		this.setContent()
	},
	methods: {
    overlay () {
      let html = `
					<div class="budget-footer" style="margin: 0 18mm 0 30mm">
						<div class="company-footer-brand">
							<span style="color: #FE0000">TOLEDO</span><span style="color: #939393">ESTUDIO</span><span style="color: #504F4D">ARQUITECTOS</span>
						</div>
						<div class="company-contact">
							<p class="company-first-line"><b>CONTACTO:</b> ${this.selectedCompany.company.phone} - ${this.selectedCompany.company.email} - ${this.selectedCompany.company.address}, ${this.selectedCompany.company.city.name}, ${this.selectedCompany.company.city.country.name}</p>
							<a class="company-second-line" href="${this.selectedCompany.company.url}" target="_blank">${this.selectedCompany.company.url.replace('https://', '').replace('http://', '')}</a>
						</div>
					</div>`
      return html;
    },
		editHighlightButton(){
			const highlightElement = document.getElementById('menu-background-color-text');

			if (highlightElement && highlightElement.parentNode) {
				const parent = highlightElement.parentNode;

				// Eliminar el div hermano con clase "color-square"
				const colorSquare = parent.querySelector('.color-square');
				if (colorSquare) {
					colorSquare.remove();
				}

				// Crear e insertar el nuevo icono después de color-highlight
				const newElement = document.createElement('span');
				newElement.classList.add('material-icons');
				newElement.id = 'background-text-color-icon'
				newElement.textContent = 'format_color_fill';

				parent.insertBefore(newElement, highlightElement.nextSibling);
			}
		},
		setContent(){
			let content_final = `<div class="d-flex justify-space-between">
					<div class="d-flex justify-center align-items-center flex-grow-1"
						style="background-image: url(${this.selectedCompany.company.logo});background-size: contain;background-repeat: no-repeat;">
					</div>
					<div class="text-end">
						<p class="font-weight-bold my-0" style="font-size: 14pt;">
							${this.selectedCompany.company.name}
						</p>
						<p class="my-0" style="font-size: 10pt;line-height: 11pt;white-space: nowrap;">${this.selectedCompany.company.description}</p>
						<p class="my-0" style="font-size: 10pt;line-height: 11pt;white-space: nowrap;">Rut: ${this.$options.filters.formatRut(this.selectedCompany.company.rut)}</p>
						<p class="my-0" style="font-size: 10pt;line-height: 11pt;white-space: nowrap;">${this.selectedCompany.company.phone}</p>
						<p class="mt-0 mb-2" style="font-size: 10pt;line-height: 11pt;white-space: nowrap;">${this.selectedCompany.company.email}</p>
						`

			if(this.budget.morphable_type == "App\\Models\\Project"){
				content_final += `<p class="my-0" style="font-size: 10pt;line-height: 11pt;white-space: nowrap;">Presupuesto ${ this.budget.prefix?.prefix } Nº ${ this.budget.serial_id } - ${ this.$options.filters.toYear(this.budget.created_at) }</p>`
			}
			else if(this.budget.morphable_type == "App\\Models\\PreProject"){
				content_final += `<p class="my-0" style="font-size: 10pt;line-height: 11pt;white-space: nowrap;">Presupuesto Desarchivo</p>`
			}
			else if(this.budget.morphable_type == "App\\Models\\BotConversation"){
				content_final += `<p class="my-0" style="font-size: 10pt;line-height: 11pt;white-space: nowrap;">Presupuesto Visita Técnica</p>`
			}

			content_final += `
						<p class="my-0" style="font-size: 10pt;line-height: 11pt;white-space: nowrap;">Fecha Cotización: ${ this.$options.filters.toDate(this.budget.created_at) }</p>
					</div>
				</div>
				<br/>
				<p class="text-center font-weight-bold mb-6" style="font-size: 14pt">${ this.budget.title ? this.budget.title:'Presupuesto'}</p>
				<p class="text-left mb-0"><b>Proyecto</b>: ${ this.budget.morphable?.name }</p>
				<p class="text-left mb-4"><b>Dirección</b>: ${ this.budget.morphable?.info?.address?.address }</p>
				<table class="mt-4 mb-4" style="width: 100%;padding: 0px 0;background-color: white;" cellspacing="0" cellpadding="0">
					<tr>
						<td class="pa-2" style="border:1px solid #cecece;width:50%;vertical-align: top;">
							<p class="font-weight-bold text-left mb-1">Cliente</p>
							<p class="text-left mb-0">Nombre: ${ this.budget.morphable?.client?.profile?.name + ' ' + this.budget.morphable?.client?.profile?.lastname}</p>
							<p class="text-left mb-0">Teléfono: ${ this.budget.morphable?.client?.profile?.phone ?? '' }</p>
							<p class="text-left mb-0">Email: ${ this.budget.morphable?.client?.email }</p>
						</td>
						<td class="pa-2" style="border:1px solid #cecece;width:50%;vertical-align: top;">
							<p class="font-weight-bold text-left mb-1">Profesional Encargado</p>
							<p class="text-left mb-0">Nombre: ${ this.budget.morphable?.responsible?.profile?.name + ' ' + this.budget.morphable?.responsible?.profile?.lastname}</p>
							<p class="text-left mb-0">Teléfono: ${ this.budget.morphable?.responsible?.profile.phone }</p>
							<p class="text-left mb-0">Email: ${ this.budget.morphable?.responsible?.email }</p>
						</td>
					</tr>
				</table>
				<p class="mt-4 mb-2 font-weight-bold" style="font-size: 18px;">Presupuesto</p>
				<table class="mb-4" style="width: 100%;padding: 0px 0;background-color: white;" cellspacing="0" cellpadding="0">
					<tr>
						<td style="width:36%">
							<p class="font-weight-bold text-left">Concepto</p>
						</td>
						<td style="width:26%">
							<p class="font-weight-bold text-left">Detalle</p>
						</td>
						<td style="width:19%">
							<p class="font-weight-bold text-left">UF</p>
						</td>
						<td style="width:19%">
							<p class="font-weight-bold text-left">Pesos</p>
						</td>
					</tr>
				`
			let total = 0
			this.budget.categories.forEach((category, index) => {
				content_final += `
						<tr>
							<td colspan="4" style="background-color: #959595;">
								<p class="font-weight-bold text-left" style="color: white;">${ (index + 1) + 'º ETAPA - ' + category.name }</p>
							</td>
						</tr>
						`
				category.parts.forEach((part) => {
					total += part.value
					content_final += `
							<tr>
								<td>
									<p class="text-left" style="font-size: 13px;">${ part.concept }</p>
								</td>
								<td>
									<p class="text-left" style="font-size: 13px;">${ part.detail ?? '--' }</p>
								</td>
								<td>
									<p class="text-left">${ this.$options.filters.toThousandFilter(part.value) } + IVA</p>
								</td>
								<td>
									<p class="font-weight-bold text-left">${ this.$options.filters.toThousandFilter(part.value * this.ufValue)} + IVA</p>
								</td>
							</tr>
							`
				})
			})

			content_final += `
				<tr>
					<td colspan="2">
						<p class="font-weight-bold text-left">Total Presupuesto</p>
					</td>
					<td>
						<p class="font-weight-bold text-left">${ this.$options.filters.toThousandFilter(total) } + IVA</p>
					</td>
					<td>
						<p class="font-weight-bold text-left">${ this.$options.filters.toThousandFilter(total * this.ufValue) } + IVA</p>
					</td>
				</tr>
			</table>`
			if(this.budget.notes.length > 0){
				content_final += `
					<p class="mt-4 mb-2 font-weight-bold" style="font-size: 18px;">Notas</p>
					<ol>
					`
				this.budget.notes.forEach((note) => {
					content_final += `<li>${ note.note }</li>`
				})
				content_final += `</ol>`
			}

			this.budgetTextContent = [content_final]
			this.$nextTick(() => {
				this.$store.commit('budget/setBudgetEditorRef', this.$refs.budgetTextEditor)
				this.mounted = true
				this.editHighlightButton()
			})
		},
		sectionIndex(index){
			return '' +  this.$store.state.general.romanNumerals[index] + '. ' + this.$store.state.general.ordinalsNumbers[index] + ':'
		},
		setSelectionFontSize(size) {
			const selection = window.getSelection()
			if (!selection.rangeCount) return
			const range = selection.getRangeAt(0)

			const fragment = range.cloneContents()
			const selectedNodes = fragment.childNodes

			selectedNodes.forEach(node => {
				if (node.nodeType === 1) {
					node.style.fontSize = size
				} 
				else if (node.nodeType === 3) {
					const span = document.createElement("span")
					span.style.fontSize = size
					span.appendChild(node.cloneNode(true))
					node.parentNode.replaceChild(span, node)
				}
			});
			range.deleteContents()
			range.insertNode(fragment)
		},
		mergeAdjacentSpans(span) {
			const prev = span.previousSibling;
			const next = span.nextSibling;

			if (prev && prev.nodeType === 1 && prev.tagName === "SPAN" && prev.style.fontSize === span.style.fontSize) {
				prev.innerHTML += span.innerHTML;
				span.remove();
			}
			
			if (next && next.nodeType === 1 && next.tagName === "SPAN" && next.style.fontSize === span.style.fontSize) {
				span.innerHTML += next.innerHTML;
				next.remove();
			}
		},
		justifySelection(align){
			console.log('justifySelection')
			const selection = window.getSelection();
			if (!selection.rangeCount) return;
			console.log('justifySelection 2')

			const range = selection.getRangeAt(0);
			const span = document.createElement("span");
			span.style.display = "inline-block"; // Permite aplicar text-align
			span.style.textAlign = align;
			span.appendChild(range.extractContents()); // Extrae el contenido seleccionado
			range.insertNode(span); // Inserta el nuevo nodo con alineación
		}
	}
};
</script>

<style>
	#budget-editor{
		font-size: 10pt;
		text-transform: none;
	}
	#budget-editor div,
	#budget-editor p,
	#budget-editor span{text-transform: none;}
	#budget-editor img{
		max-width: 100%;
		object-fit: contain;
	}
	.budget-footer{
		position: absolute;
		bottom: 7mm;
		left: 0;
		right: 0;
	}
	.company-footer-brand{
		background-color: #BFBFBF;
		font-weight: bold;
		text-align: center;
	}
	.budget-footer .company-contact{
			text-align: center;
		}
	.budget-footer .company-first-line{
			margin: 0;
			padding-top: 2pt;
			font-size: 10pt;
			line-height: 10pt;
			text-align:center;
		}
	.budget-footer .company-second-line{
			margin: 0;
			text-align:center;
			font-size: 10pt;
			line-height: 10pt;
			width: 100%;
			font-weight: bold;
			color: #504F4D;
			text-decoration: none;
		}
	#budget-editor table {
		border-collapse: collapse;
		vertical-align: middle;
	}
	#budget-editor th {
		background: #ccc;
	}

	#budget-editor th, #budget-editor td {
		border: 1px solid #ccc;
		padding: 4px 8px;
		vertical-align: middle;
	}

	#budget-editor tr:nth-child(even) {
		background: #efefef;
	}

	#budget-editor tr:hover {
		background: #d1d1d1;
	}

	#budget-editor p {
		margin-bottom: 0;
	}
	/deep/.font-selector-custom .bar-menu-item{
		font-size: 12px;
	}
</style>