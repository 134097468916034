<template>
	<table class="costbreakdown-detail-table">
		<tr>
			<td style="vertical-align: middle;" width="20%"><p class="mb-0" style="font-size: 10px;"></p></td>
			<td style="vertical-align: middle;" width="25%"><p class="mb-0" style="font-size: 10px;"></p></td>
			<td style="vertical-align: middle;" width="10%"><p class="text-center mb-0" style="font-size: 10px;">Valor U. (UF)</p></td>
			<td style="vertical-align: middle;" width="10%"><p class="text-center mb-0" style="font-size: 10px;">Valor U. (CLP)</p></td>
			<td style="vertical-align: middle;" width="6%"><p class="text-center mb-0" style="font-size: 10px;">Cantidad</p></td>
			<td style="vertical-align: middle;" width="10%"><p class="text-center mb-0" style="font-size: 10px;">Total UF</p></td>
			<td style="vertical-align: middle;" width="10%"><p class="text-center mb-0" style="font-size: 10px;">TOTAL CLP</p></td>
			<td width="9%">
				<div class="d-flex justify-center">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="green"
								small
								text
								v-bind="attrs"
								v-on="on"
								@click="changeVisualization(subcategory.id)"
							>
								<p class="text-center mb-0" style="font-size: 10px;">Detalle</p>
							</v-btn>
						</template>
						<span>Expande el bloque y ve el detalle de esta sección</span>
					</v-tooltip>
				</div>
			</td>
		</tr>
		<tr>
			<td width="20%" style="max-width:20%;min-width: 20%;">
				<div class="d-flex align-center">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="#FF120B"
								small
								icon
								class="mr-2"
								v-bind="attrs"
								v-on="on"
								@click="deleteSubcategory(subcategory.id, i1, i2)"
							>
								<v-icon small>mdi-delete-outline</v-icon>
							</v-btn>
						</template>
						<span>Eliminar Detalle</span>
					</v-tooltip>
					<p class="font-weight-bold caption mb-0">{{ subcategory.parent.name }}</p>
				</div>
			</td>
			<td width="25%">
			</td>
			<td width="10%">
				<p class="font-weight-bold caption mb-0 text-center">${{ subcategoryTotals.unit_cost | toThousandFilter }}</p>
			</td>
			<td width="10%">
				<p class="font-weight-bold caption mb-0 text-center">${{ subcategoryTotals.unit_cost * ufValue | toThousandFilter }}</p>
			</td>
			<td width="6%">
				<p class="font-weight-bold caption mb-0 text-center">{{ subcategoryTotals.quantity }}</p>
			</td>
			<td width="10%">
				<p class="font-weight-bold caption mb-0 text-center">{{ subcategoryTotals.total_uf | toThousandFilter }}</p>
			</td>
			<td width="10%">
				<p class="font-weight-bold caption mb-0 text-center">${{ subcategoryTotals.total_clp | toThousandFilter }}</p>
			</td>
			<td width="9%">
			</td>
		</tr>
	</table>
</template>

<script>
export default {
	name: 'SubcategorySummaryTable',
	props: {
		category: Object,
		subcategory: Object,
		changeVisualization: Function
	},
	computed: {
		ufValue(){
			return this.$store.state.general.dayUfValue
		},
		subcategoryTotals() {
			if(this.category.specialty){
				return this.subcategory.specialties.reduce((acc, item) => {
					acc.unit_cost += item.unit_cost;
					acc.quantity += item.quantity;
					acc.total_uf += item.total_uf;
					acc.total_clp += item.total_clp;
					return acc;
				}, { unit_cost: 0, quantity: 0, total_uf: 0, total_clp: 0 });
			}
			return this.subcategory.items.reduce((acc, item) => {
				acc.unit_cost += item.unit_cost;
				acc.quantity += item.quantity;
				acc.total_uf += item.total_uf;
				acc.total_clp += item.total_clp;
				return acc;
			}, { unit_cost: 0, quantity: 0, total_uf: 0, total_clp: 0 });
		}
	},
	mounted(){
		console.log('subcategory', this.subcategory)
	},
	nethods: {
		
	}
}
</script>

<style scoped>
.costbreakdown-detail-table{
	table-layout: fixed;
	width: 100%;
}
</style>