<template>
	<v-container fluid style="background-color: #959595;" fill-height class="d-flex flex-column justify-center">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0">{{message}}</p>
		</v-snackbar>
		<div v-if="loginFromSocial">
			<img :src="require('../../assets/logoarquimarket.png')" style="max-width: 150px; width: 100%;" />
			<p class="mb-0 text-center white--text">Cargando datos de tu cuenta</p>
		</div>
		<v-card light min-width="500" class="pa-8" v-else>
			<div class="login-form-container d-flex flex-column justify-center align-center">
				<img :src="require('../../assets/logoarquimarket.png')" style="max-width: 150px; width: 100%;"/>
				<v-form
					ref="form"
					v-model="valid"
					lazy-validation
					class="d-flex flex-column flex-grow-1 mt-0"
					style="max-width: 400px;width: 100%;"
				>
					<p class="text-center mb-4 caption px-2 mt-2">PLATAFORMA DIGITAL COLABORATIVA DE COORDINACIÓN DE PROYECTOS Y SERVICIOS DE ARQUITECTURA, DISEÑO, GESTIÓN INMOBILIARIA Y CONSTRUCCIÓN</p>
					<v-text-field
						v-model="email"
						:rules="emailRules"
						label="Email"
						color="#959595"
						required
						name="username"
						autocomplete="username"
					></v-text-field>
					<v-text-field
						v-model="userPassword"
						:rules="passwordRules"
						label="Contraseña"
						color="#959595"
						required
						:append-icon="passType ? 'mdi-eye-off' : 'mdi-eye'"
						@click:append="() => (passType = !passType)"
						:type="passType ? 'password' : 'text'"
						name="password"
						autocomplete="current-password"
					></v-text-field>
					<v-btn
						:disabled="!valid"
						color="#524D4D"
						dark
						:loading="loadingLogin"
						@click="loginUser"
					>
						Ingresar
					</v-btn>
					<v-btn
						depressed
						color="#DD4F40"
						dark
						block
						class="mt-4"
						:loading="loadingLogin"
						@click="toSocialLogin('google')"
					>
						<v-icon>mdi-google</v-icon>
						<p class="mb-0 ml-2 subtitle-2"
						v-show="!$store.state.menu.sidebarMini">Ingresa con Google</p>
					</v-btn>
					<v-divider class="my-5"></v-divider>
					<v-btn
						:disabled="!valid || loadingLogin"
						color="#524D4D"
						small
						text
						@click="$router.push({name: 'Register'})"
					>
						Registra tu Cuenta
					</v-btn>
					<v-btn
						:disabled="!valid || loadingLogin"
						color="#959595"
						class="mt-2"
						small
						text
						@click="$router.push({name: 'ResetPassRequest'})"
					>
						Restablecer Contraseña
					</v-btn>
					<a class="caption text-center mt-5" href="https://panel.toledoarquitectos.cl/policies" target="_blank">Ver Políticas de Privacidad</a>
					<v-btn
						:disabled="!email"
						color="#959595"
						class="mt-2"
						small
						text
						@click="sendVerificationEmail"
					>
						Reenviar email para validación de correo
					</v-btn>
				</v-form>
			</div>
		</v-card>
	</v-container>
</template>

<script>
import {login, loginWithGoogleToken, resendVerificationEmail} from '@/helpers/api/user'

export default {
	name: 'Login',
	data: () => ({
		valid: true,
		showMessage: false,
		messageColor: 'error',
		message: '',
		email: '',
		emailRules: [
			v => !!v || 'Email es obligatorio',
			v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
		],
		userPassword: '',
		passwordRules: [
			v => !!v || 'Contraseña es requerida'
		],
		passType: true,
		loadingLogin: false,
		loginFromSocial: false
	}),
	mounted(){
		this.$store.commit('user/setIsAuthenticated', false)
		this.socialLoginFromToken()
		if(this.$route.query.email_validation_status){
			this.checkEmailValidation()
		}
	},
	methods: {
		checkEmailValidation(){
			let messageData = {
				message: 'No se pudo verificar la dirección de correo, por favor intenta nuevamente.',
				title: 'Error',
				type: 'danger',
				icon: 'mdi-close-octagon',
				color: '#FF1209',
			}
			if(this.$route.query.email_validation_status == 'success' || this.$route.query.email_validation_status == 'already_verified'){
				messageData = {
					message: 'Correo confirmado, ahora puedes ingresar normalmente.',
					title: 'Correo enviado',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
			}
			this.setMessage(messageData)
		},
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		toSocialLogin(provider = 'google'){
			this.loadingLogin = true
			window.location.href = `https://apiarq.insomnia.cl/social-oauth?provider=${provider}`;
		},
		socialLoginFromToken(){
			const access_token = this.$route.query.social_access_token;
			if (access_token) {
				this.loginFromSocial = true
				this.loadingLogin = true
				loginWithGoogleToken(access_token)
					.then(() => {
						this.loadingLogin = false
						this.loginFromSocial = false
						this.$store.commit('user/setIsAuthenticated', true)
						this.$router.push({name: 'Dashboard'})
					})
					.catch((error) => {
						this.loadingLogin = false
						this.loginFromSocial = false
						this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
						if(error.response && error.response.status == 401){
							this.message = 'Email o contraseña incorrectos. Por favor verifica los datos ingresados.'
						}
						this.messageColor = 'warning'
						this.showMessage = true
					});
			}
		},
		loginUser () {
			this.loadingLogin = true
			login(this.email, this.userPassword)
				.then(async () => {
					this.loadingLogin = false
					this.$store.commit('user/setIsAuthenticated', true)
					this.$router.push({name: 'Dashboard'})
				})
				.catch((error) => {
					this.loadingLogin = false
					this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
					if(error && error.data && error.data.code == 401){
						this.message = 'Email o contraseña incorrectos. Por favor verifica los datos ingresados.'
					}
					if(error && error.data && error.data.code == 403){
						this.message = 'Debes validar tu correo antes de poder ingresar al panel. Hemos enviado un correo de validación. Por favor revisa su casilla.'
					}
					this.messageColor = 'warning'
					this.showMessage = true
				})
		},
		async sendVerificationEmail(){
			const verification = await resendVerificationEmail({email: this.email})
			if(verification.code == 200){
				const messageData = {
					message: 'Correo de verificación enviado. Por favor revisa tu bandeja de correo.',
					title: 'Correo enviado',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			}
			else{
				const messageData = {
					message: 'No se pudo enviar el correo de verificación, por favor valida que tu correo esté bien escrito.',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    }
	},
};
</script>
<style scoped>
</style>
