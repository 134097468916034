<template>
	<div v-if="fileToUpload || previewFile" class="d-flex flex-column flex-grow-1">
		<div class="d-flex align-center justify-space-between mb-2">
			<div class="d-flex align-center">
				<p class="mb-0 caption font-weight-bold">Previsualización Archivo</p>
				<v-btn
					depressed
					color="#524D4D"
					outlined
					dark
					small
					class="ml-2"
					v-if="$checkPermissions(['edit_preprojects']) && previewFile"
					@click="fileToItem(previewFile.id)"
				>
					<v-icon small dark>mdi-file-export-outline</v-icon>
					<p class="caption mb-0">Pasar a Antecedentes</p>
				</v-btn>
			</div>
			<v-btn
				depressed
				color="#524D4D"
				icon
				class="ml-2"
				@click="closeFilePreview"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</div>
		<FileViewer :selectedFile="fileToUpload || previewFile" />
		<div class="user-input-container" v-if="fileToUpload">
			<v-progress-linear indeterminate v-if="loadingAddChat"></v-progress-linear>
			<div class="user-input-inner-container">
				<v-text-field
					ref="messageInput"
					v-model="new_message"
					placeholder="Escribe un mensaje"
					hide-details
					dense
					outlined
					required
					:disabled="loadingAddChat"
					@keydown.enter="sendMessage"
				>
					<template v-slot:append-outer>
						<v-btn x-large fab color="green" dark @click="sendMessage">
							<v-icon>mdi-send</v-icon>
						</v-btn>
					</template>
				</v-text-field>
			</div>
		</div>
	</div>
	<div v-else class="d-flex flex-column flex-grow-1">
		<div class="chat-container d-flex flex-column flex-grow-1">
			<div class="messages-container" ref="messagesContainer" v-if="conversationMessages.length > 0">
				<div
					v-for="(message, index) in conversationMessages"
					:key="index"
					class="single-message" :class="isMyMessage(message) ? 'self-user':''">
					<p class="mb-0" style="font-size: 11px; line-height: 10px;">{{message?.user?.name}}
					
					<div
						v-if="message.file && getIconFile(message.file).icon != 'mdi-file-image'"
						class="chat-file-container rounded d-flex justify-center align-center pa-1"
						@click="previewFile = message.file"
					>
						<v-icon :color="getIconFile(message.file).color" large>{{getIconFile(message.file).icon}}</v-icon>
						<p class="mb-0 mt-2 caption text-center truncate-text">{{message.file.name}}</p>
					</div>
					<div v-else-if="message.file" @click="previewFile = message.file">
						<div class="chat-image-container mt-1">
							<v-img
								:src="message.file.file_link"
								contain
								width="200px"></v-img>
						</div>
					</div>

					<p class="mb-0 mr-4">{{message?.message}}</p>
					<div class="text-right caption text--secondary">
						<small>{{message.created_at | toDateTime}}</small>
					</div>
				</div>
			</div>
			<div class="messages-container nomessages" v-else>
				Escribe un mensaje para iniciar la conversación
			</div>
		</div>
		<div class="user-input-container">
			<v-progress-linear indeterminate v-if="loadingAddChat"></v-progress-linear>
			<div class="user-input-inner-container">
				<v-text-field
					ref="messageInput"
					v-model="new_message"
					placeholder="Escribe un mensaje"
					hide-details
					dense
					outlined
					required
					:disabled="loadingAddChat"
					@keydown.enter="sendMessage"
				>
					<template v-slot:append-outer>
						<div class="px-2 py-0">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon
										:loading="loadingAddChat"
										@click="showFilePicker"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon>mdi-paperclip</v-icon>
									</v-btn>
								</template>
								<span>Agregar Archivo</span>
							</v-tooltip>
							<input 
								type="file" 
								ref="fileInput"
								style="display: none;"
								:accept="acceptedFileTypes"
							/>
						</div>
						<v-btn icon @click="sendMessage">
							<v-icon>mdi-send</v-icon>
						</v-btn>
					</template>
				</v-text-field>
			</div>
		</div>
	</div>
</template>

<script>
import {getChatRoomMessages, addMessageToChatRoom, chatFileToItem} from '@/helpers/api/chats'
import FileViewer from '@/components/Views/PreProjects/Sections/FileViewer'
export default {
	components: {FileViewer},
	props: {
		selectedChatRoomId: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			search: '',
			new_message: null,
			loadingAddChat: false,
			conversationMessages: [],
			requestInterval: null,
			fileToUpload: null,
			previewFile: null
		};
	},
  computed: {
    preproject() {
      return this.$store.state.preprojects.preProject
    },
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		acceptedFileTypes() {
			return [
				'application/pdf', 
				'application/msword', 
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
				'application/vnd.ms-excel', 
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
				'image/jpeg', 
				'image/png', 
				'image/gif', 
				'image/bmp', 
				'image/svg+xml'
			].join(',');
		}
  },
	watch: {
		chatsRooms(newValue){
			if(newValue && newValue.length > 0 && parseInt(this.$route.query.user_id)){
				this.selectRoomFromSearch({id: parseInt(this.$route.query.user_id)})
			}
		},
		conversationMessages(){
			setTimeout(() => {
				this.scrollToBottom()
			}, 300);
		},
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('chats/getUserChats')
				this.$store.dispatch('users/getUserFromCompany', {start: 0, limit: 300})
			}
		},
		selectedChatRoomId(newValue, oldValue){
			if(newValue && newValue != oldValue){
				this.getSelectedChatRoomMessages()
			}
		}
	},
	beforeDestroy(){
		clearInterval(this.requestInterval)
		this.requestInterval = null
	},
	mounted(){
		this.scrollToBottom()
		if(this.selectedCompany){
			this.$store.dispatch('chats/getUserChats')
			this.$store.dispatch('users/getUserFromCompany', {start: 0, limit: 300})
		}
		
		if(this.selectedChatRoomId){
			this.getSelectedChatRoomMessages()
		}
		this.requestInterval = setInterval(() => {
			if(this.selectedChatRoomId){
				this.getSelectedChatRoomMessages()
			}
		}, 4000);
	},
	methods: {
		closeFilePreview(){
			this.fileToUpload = null
			this.previewFile = null
		},
		getIconFile(file){
			const fileExtension = file.path.split('.').pop().toLowerCase();
			if (fileExtension === 'pdf') {
					return { icon: 'mdi-file-pdf-box', color: '#FF0000' };
			} else if (fileExtension === 'doc' || fileExtension === 'docx') {
					return { icon: 'mdi-file-word', color: '#1E88E5' };
			} else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
					return { icon: 'mdi-file-excel', color: '#4CAF50' };
			} else if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'].includes(fileExtension)) {
					return { icon: 'mdi-file-image', color: '#FFD700' };
			} else {
					return { icon: 'mdi-file', color: '#9E9E9E' };
			}
		},
		showFilePicker() {
			const input = this.$refs.fileInput;
			input.onchange = async (event) => {
				const file = event.target.files[0]
				this.fileToUpload = file
				input.value = null
			};
			input.click();
		},
    scrollToBottom() {
      const container = this.$refs.messagesContainer;
			if(container){
				container.scrollTop = container.scrollHeight;
			}
    },
		isMyMessage(message){
			return message.user_id == this.$store.state.user?.userInfo?.id
		},
		async getSelectedChatRoomMessages(){
			const chat_room_id = this.selectedChatRoomId
			const messages = await getChatRoomMessages({chat_room_id})
			if(messages.code == 200){
				if (this.conversationMessages.length != messages.messages.length) {
					this.conversationMessages = messages.messages;
				}
			}
		},
		async sendMessage(){
			if(this.new_message || this.fileToUpload){
				this.appendNewMessageTemp(this.new_message)
				const data = {
					chat_room_id: this.selectedChatRoomId,
					message: this.new_message,
					file: this.fileToUpload
				}
        const formData = new FormData()
        Object.keys(data).forEach((key) => {
          if(data[key]) {
            formData.append(key, data[key])
          }
        })
				this.new_message = null
				const new_message = await addMessageToChatRoom(formData)
				if(new_message.code == 200){
					this.conversationMessages = new_message.chat_room_messages
					this.$store.dispatch('chats/getUserChats')
					this.closeFilePreview()
				}
			}
		},
		appendNewMessageTemp(message){
			const add_message = {
				message,
				user_id: this.$store.state.user?.userInfo?.id
			}
			this.conversationMessages.push(add_message)
		},
		async fileToItem(file_id){
			const data = {
				chat_room_id: this.selectedChatRoomId,
				file_id
			}
			try {
				await chatFileToItem(data)
				this.$store.dispatch('preprojects/getPreProject', {preproject_id: this.preproject.id, showLoader: false})
				const messageData = {
					message: 'Se ha asociado el archivo al preproyecto',
					title: 'Archivo guardado',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			} catch (error) {
				const messageData = {
					message: 'Hubo un error en la operación, por favor intenta nuevamente',
					title: 'Ha ocurrido un error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
	},
};
</script>

<style scoped>
	/* .chat-container{
		flex: 1;
	} */
	.chat-list-item{
		cursor: pointer;
	}
	.chat-list-item:hover{
		background: #FCFBFE;
	}

	.v-text-field {
		align-items: center;
	}
	.chatbox-container{
		display: flex;
		flex-direction: column;
		background: white;
	}
	.messages-container{
		display: flex;
		flex-direction: column;
		padding: 10px;
		flex: 1;
		background-color: #FCFBFE;
		overflow-y: scroll;
		text-transform: none;
	}
	.user-input-container{
		flex: 0;
	}
	.user-input-inner-container{
		padding: 10px;
	}
	.single-message{
		background-color: rgb(213, 211, 211);
		border: 1px solid rgb(193, 191, 191);
		padding: 4px 8px;
		max-width: 80%;
		border-radius: 10px;
		font-size: 14px;
		margin-bottom: 8px;
		text-transform: none;
	}
	.single-message.self-user{
		background-color: rgb(217, 239, 217);
		border: 1px solid rgb(197, 232, 197);
		align-self: flex-end;
	}
	.nomessages{
		text-align: center;
		font-size: 14px;
		padding-top: 30px;
	}
	.chat-file-container{
		cursor: pointer;
		background: rgba(0,0,0,0.1);
	}
	.chat-image-container{
		cursor: pointer;
	}
</style>
<style>
	.chatbox-container .single-message p{
		margin-bottom: 0;
	}
</style>