<template>
	<div>
    <v-data-table
      :headers="headers"
      :items="client_crms"
      item-key="id"
      :loading="loadingList"
			height="200px"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
			:item-class="getRowClass"
    >
      <template v-slot:item.date="{ item }">
				<p class="mb-0 caption">{{ item.created_at | toDateTime }}</p>
      </template>
      <template v-slot:item.vt_date="{ item }">
				<p class="mb-0 caption">{{ item.schedule?.vt_date | toDateTime }}</p>
      </template>
      <template v-slot:item.responsible="{ item }">
				<p class="mb-0 caption">{{ item.schedule?.user_schedule?.responsible?.profile?.name }} {{ item.schedule?.user_schedule?.responsible?.profile?.lastname }}</p>
      </template>
      <template v-slot:item.status="{ item }">
				<v-chip
					class="mt-1"
					:color="vtStatus(item).color"
					x-small
					label
					dark
					v-if="vtStatus(item)"
				>
					<v-icon class="mr-1" x-small>mdi-email-outline</v-icon>
					{{vtStatus(item).text}}
				</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="openCrmDetail(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							icon
							dark
							x-small
							color="#524D4D"
							class="mx-1"
							v-bind="attrs"
							v-on="on"
							@click="toPreProjectsStart(item)"
						>
							<v-icon small dark>mdi-send</v-icon>
						</v-btn>
					</template>
					<span>Crear Cliente</span>
				</v-tooltip>
      </template>
    </v-data-table>
	</div>
</template>

<script>
export default {
	name: 'ClientsCrmList',
	props: {
		clientId: {
      type: Number,
      required: true
    },
		openCrmDetail: {
      type: Function,
      required: true
    }
	},
	computed: {
		loadingList() {
			return this.$store.state.crm.loadingClientCrm
		},
		client_crms() {
			return this.$store.state.crm.clientCrms
		},
		selected_crm() {
			return this.$route.query?.crm
		}
	},
	data: () => ({
		headers: [
        { text: '#', value: 'id', align: 'center', sortable: false },
        { text: 'Fecha de Creación', value: 'date', width: '120px', align: 'center', sortable: false },
        { text: 'Visita Técnica', value: 'vt_date', width: '120px', align: 'center', sortable: false },
        { text: 'Profesional Encargado', value: 'responsible', align: 'center', sortable: false },
        { text: 'Estado', value: 'status', align: 'center', sortable: false },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false },
      ],
	}),
	mounted(){
		this.getClientCRM()
	},
	methods: {
		getRowClass(item) {
			return item.id == this.selected_crm ? 'custom-client-highlight-row' : '';
		},
		vtStatus(conversation){
			if(conversation.schedule && conversation.schedule.status == 2 && conversation.schedule.request_email.length > 0){
				return {
					text: 'Informe Enviado',
					color: 'success'
				}
			}
			else if(conversation.schedule){
				return {
					text: 'Informe Pendiente',
					color: 'warning'
				}
			}
			return null
		},
		async getClientCRM(){
			this.$store.dispatch('crm/getClientCrms', {
				client_id: this.clientId,
				start: 1,
				limit: 100
			})
			// this.loadingList = true
			// try {
			// 	const client_crm = await getClientCRM({
			// 		client_id: this.clientId,
			// 		start: 1,
			// 		limit: 100
			// 	})
			// 	if(client_crm.code == 200){
			// 		this.client_crms = client_crm.client_crm
			// 	}
			// } catch (error) {
			// 	console.log(error)
			// }
			// this.loadingList = false
		},
		toPreProjectsStart(crm){
			const preproject_info = {
				crm_id: crm.id,
				client_id: crm.user.user_id,
				name: crm.project_info?.project_detail
			}
			localStorage.setItem('new_preproject_info', JSON.stringify(preproject_info));
			this.$store.commit('preprojects/setNewPreProjectModal', true)
		},
	}
}
</script>

<style>
.custom-client-highlight-row {
  background-color: #e8e8e8 !important;
}
</style>