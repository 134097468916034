<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.user.editMyUserDataModal"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-account</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Mis Datos</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#FF1209" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4">
                <v-col cols="12">
									<v-btn-toggle
										v-model="selectedWorkAs"
										dense
										color="green"
										class="d-flex flex-row"
									>
										<v-btn :value="1" class="flex-grow-1">
											<p class="mb-0 caption font-weight-bold">Datos Personales</p>
										</v-btn>
										<v-btn :value="2" class="flex-grow-1">
											<p class="mb-0 caption font-weight-bold">Datos de mi Empresa</p>
										</v-btn>
									</v-btn-toggle>
								</v-col>
							</v-row>
							<div class="pa-4" v-if="selectedWorkAs == 1">
								<v-row class="mb-4">
									<v-col cols="12" md="2" class="py-0">
										<v-avatar
											size="80"
											class="profile-avatar"
											@click="triggerFileInput"
										>
											<img :src="avatarUrl" class="user-avatar" v-if="avatarUrl"/>
											<v-icon v-else class="user-avatar icon" color="#524D4D" x-large>mdi-account</v-icon>
											<div class="logo-text">Modificar Imagen</div>
										</v-avatar>
										<v-file-input
											ref="fileInput"
											v-model="newUserData.avatar"
											show-size
											outlined
											dense
											accept=".png,.jpg,.jpeg"
											placeholder="Seleccione imagen de perfil"
											prepend-inner-icon="mdi-image"
											prepend-icon=""
											style="display: none;"
											@change="onFileSelected"
										></v-file-input>
									</v-col>
									<v-col cols="12" md="10" class="py-0">
										<v-row>
											<v-col cols="12" class="py-0">
												<v-text-field
													v-model="newUserData.name"
													label="Nombre"
													placeholder="Ingrese nombre del usuario"
													outlined
													required
													dense
													:rules="requiredRule"
												></v-text-field>
											</v-col>
											<v-col cols="6" class="py-0">
												<v-text-field
													v-model="firstLastname"
													label="Primer Apellido"
													placeholder="Ingrese primer apellido del usuario"
													outlined
													required
													dense
													:rules="requiredRule"
												></v-text-field>
											</v-col>
											<v-col cols="6" class="py-0">
												<v-text-field
													v-model="secondLastname"
													label="Segundo Apellido"
													placeholder="Ingrese segundo apellido del usuario"
													outlined
													required
													dense
													:rules="requiredRule"
												></v-text-field>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" class="py-0">
										<v-text-field
											v-model="newUserData.rut"
											label="Rut"
											placeholder="Ingrese el rut del usuario"
											outlined
											required
											dense
											hint="Ingresa rut sin puntos ni guión"
											:rules="rutRule"
											@blur="onBlurRut"
											@focus="onFocusRut"
											maxlength="9"
										></v-text-field>
										<v-text-field
											v-model="newUserData.email"
											label="Email"
											placeholder="Ingrese email del usuario"
											outlined
											required
											dense
											:rules="emailRules"
										></v-text-field>
										<v-text-field
											v-if="!newUserData.user_id"
											v-model="newUserData.password"
											label="Contraseña"
											placeholder="Ingrese contraseña del usuario"
											outlined
											required
											dense
											:rules="requiredRule"
										></v-text-field>
										<v-text-field
											v-model="newUserData.phone"
											label="Teléfono"
											placeholder="Ingrese teléfono del usuario (Ej: 56912345678)"
											outlined
											required
											dense
											:rules="phoneRule"
											maxlength="11"
											@keypress="isNumberKey"
										></v-text-field>
										<v-text-field
											v-model="newUserData.address"
											label="Dirección"
											placeholder="Ingrese dirección del usuario"
											outlined
											required
											dense
											:rules="requiredRule"
										></v-text-field>
										<v-select
											v-model="newUserData.state_id"
											:items="$store.state.general.states"
											:loading="$store.state.general.loadingStates"
											:menu-props="{ top: true, offsetY: true }"
											item-text="name"
											item-value="id"
											label="Seleccione Región"
											outlined
											dense
											required
											:rules="requiredRule"
											@change="onChangeState"
										></v-select>
										<v-select
											v-model="newUserData.city_id"
											:items="$store.state.general.cities"
											:loading="$store.state.general.loadingCities"
											item-text="name"
											item-value="id"
											label="Seleccione Comuna"
											outlined
											dense
											required
											:disabled="!newUserData.state_id"
											:rules="requiredRule"
										></v-select>
									</v-col>
								</v-row>

								<v-row align="center">
									<v-col cols="12">
										<div class="d-flex flex-row justify-end">
											<v-btn
												class="mx-2 my-1"
												color="white"
												raised
												@click="closeModal"
											>
												<p class="mb-0 text-primary">Cancelar</p>
											</v-btn>
											<v-btn
												class="mx-2 my-1"
												color="#524D4D"
												raised
												dark
												:loading="loadingData"
												@click="sendForm"
											>
												<p class="mb-0">Confirmar</p>
											</v-btn>
										</div>
									</v-col>
								</v-row>
							</div>
							<div class="pa-4" v-if="selectedWorkAs == 2">
								<v-row>
									<v-col cols="12" md="6" class="py-0">
										<v-text-field
											v-model="companyProfile.rep_name"
											label="Nombre Representante Legal"
											placeholder="Ingrese nombre"
											outlined
											required
											dense
											:rules="requiredRule"
										></v-text-field>
										<v-text-field
											v-model="companyProfile.rep_first_lastname"
											label="Primer Apellido Representante Legal"
											placeholder="Ingrese primer apellido"
											outlined
											required
											dense
											:rules="requiredRule"
										></v-text-field>
										<v-text-field
											v-model="companyProfile.rep_second_lastname"
											label="Segundo Apellido Representante Legal"
											placeholder="Ingrese segundo apellido"
											outlined
											required
											dense
											:rules="requiredRule"
										></v-text-field>
										<v-text-field
											v-model="companyProfile.rep_rut"
											label="Rut Representante Legal"
											placeholder="Ingrese el rut del representante legal de la empresa"
											outlined
											required
											dense
											hint="Ingresa rut sin puntos ni guión"
											:rules="rutRule"
											@blur="onBlurRut"
											@focus="onFocusRut"
											maxlength="9"
										></v-text-field>
										<v-text-field
											v-model="companyProfile.rep_address"
											label="Dirección Representante Legal"
											placeholder="Ingrese dirección del representante legal"
											outlined
											required
											dense
											:rules="requiredRule"
										></v-text-field>
										<v-select
											v-model="companyProfile.rep_state_id"
											:items="$store.state.general.states"
											:loading="$store.state.general.loadingStates"
											:menu-props="{ top: true, offsetY: true }"
											item-text="name"
											item-value="id"
											label="Seleccione Región"
											outlined
											dense
											required
											:rules="requiredRule"
											@change="onChangeStateRP"
										></v-select>
										<v-select
											v-model="companyProfile.rep_city_id"
											:items="rp_cities"
											:loading="$store.state.general.loadingCities"
											item-text="name"
											item-value="id"
											label="Seleccione Comuna"
											outlined
											dense
											required
											:disabled="!newUserData.state_id"
											:rules="requiredRule"
										></v-select>
									</v-col>
									<v-col cols="12" md="6" class="py-0">
										<v-text-field
											v-model="companyProfile.name"
											label="Nombre Empresa"
											placeholder="Ingrese nombre de la empresa"
											outlined
											required
											dense
											:rules="requiredRule"
										></v-text-field>
										<v-text-field
											v-model="companyProfile.rut"
											label="Rut Empresa"
											placeholder="Ingrese el rut de la empresa"
											outlined
											required
											dense
											hint="Ingresa rut sin puntos ni guión"
											:rules="rutRule"
											@blur="onBlurRut"
											@focus="onFocusRut"
											maxlength="9"
										></v-text-field>
										<v-text-field
											v-model="companyProfile.email"
											label="Email"
											placeholder="Ingrese email de contacto de la empresa"
											outlined
											required
											dense
											:rules="emailRules"
										></v-text-field>
										<v-text-field
											v-model="companyProfile.phone"
											label="Teléfono"
											placeholder="Ingrese teléfono de contacto de la empresa (Ej: 56912345678)"
											outlined
											required
											dense
											:rules="phoneRule"
											maxlength="11"
											@keypress="isNumberKey"
										></v-text-field>
										<v-text-field
											v-model="companyProfile.address"
											label="Dirección Comercial"
											placeholder="Ingrese dirección de la empresas"
											outlined
											required
											dense
											:rules="requiredRule"
										></v-text-field>
										<v-select
											v-model="companyProfile.state_id"
											:items="$store.state.general.states"
											:loading="$store.state.general.loadingStates"
											:menu-props="{ top: true, offsetY: true }"
											item-text="name"
											item-value="id"
											label="Seleccione Región"
											outlined
											dense
											required
											:rules="requiredRule"
											@change="onChangeStateC"
										></v-select>
										<v-select
											v-model="companyProfile.city_id"
											:items="c_cities"
											:loading="$store.state.general.loadingCities"
											item-text="name"
											item-value="id"
											label="Seleccione Comuna"
											outlined
											dense
											required
											:disabled="!companyProfile.state_id"
											:rules="requiredRule"
										></v-select>
									</v-col>
								</v-row>
								<v-row align="center">
									<v-col cols="12">
										<p class="mb-1 caption font-weight-bold">Documentos para Acreditar Datos</p>
										<p class="mb-0 caption">Los siguientes documentos son necesarios para acreditar los datos ingresados (Por seguridad sólo se aceptan archivos PDF)</p>
									</v-col>
									<v-col cols="6">
										<p class="mb-1 caption font-weight-bold">Certificado de Vigencia</p>
										<div class="d-flex align-center justify-space-between mb-3">
											<v-btn
												depressed
												:color="companyProfile.document_2 ? 'green':'#524D4D'"
												small
												outlined
												@click="showFilePicker(2)"
											>
												<v-icon small>mdi-upload</v-icon>
												<p class="mb-0 ml-2 caption">Subir Documento</p>
											</v-btn>
										</div>
										<p class="mb-1 caption font-weight-bold">Estatuto Actualizado</p>
										<div class="d-flex align-center justify-space-between mb-3">
											<v-btn
												depressed
												:color="companyProfile.document_3 ? 'green':'#524D4D'"
												small
												outlined
												@click="showFilePicker(3)"
											>
												<v-icon small>mdi-upload</v-icon>
												<p class="mb-0 ml-2 caption">Subir Documento</p>
											</v-btn>
										</div>
									</v-col>
									<v-col cols="6">
										<p class="mb-1 caption font-weight-bold">Escritura Constitución de Sociedad</p>
										<div class="d-flex align-center justify-space-between mb-3">
											<v-btn
												depressed
												:color="companyProfile.document_4 ? 'green':'#524D4D'"
												small
												outlined
												@click="showFilePicker(4)"
											>
												<v-icon small>mdi-upload</v-icon>
												<p class="mb-0 ml-2 caption">Subir Documento</p>
											</v-btn>
										</div>
										<p class="mb-1 caption font-weight-bold">Comprobante Dirección Representante Legal</p>
										<div class="d-flex align-center justify-space-between">
											<v-btn
												depressed
												:color="companyProfile.document_5 ? 'green':'#524D4D'"
												small
												outlined
												@click="showFilePicker(5)"
											>
												<v-icon small>mdi-upload</v-icon>
												<p class="mb-0 ml-2 caption">Subir Documento</p>
											</v-btn>
										</div>
										<input 
											type="file" 
											ref="fileInput"
											style="display: none;"
											accept="application/pdf"
										/>
									</v-col>
								</v-row>

								<v-row align="center">
									<v-col cols="12">
										<v-checkbox v-model="companyProfile.work_as_company" color="black" label="Quiero Usar el Sistema Como Empresa" hide-details dense></v-checkbox>
									</v-col>
									<v-col cols="12">
										<div class="d-flex flex-row justify-end">
											<v-btn
												class="mx-2 my-1"
												color="white"
												raised
												@click="closeModal"
											>
												<p class="mb-0 text-primary">Cancelar</p>
											</v-btn>
											<v-btn
												class="mx-2 my-1"
												color="c#524D4D'"
												raised
												dark
												:loading="loadingData"
												@click="sendCompanyForm"
											>
												<p class="mb-0">Confirmar Datos Empresa</p>
											</v-btn>
										</div>
									</v-col>
								</v-row>
							</div>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {update, setAccountCompanyProfile} from '@/helpers/api/user'
import {getCities} from '@/helpers/api/general'
import {validate, format, clean} from '@/helpers/rut.js'

export default {
  name: 'EditMyUserData',
  components: { },
  data: () => ({
    validform: true,
		selectedWorkAs: 1,
		firstLastname: null,
		secondLastname: null,
    newUserData: {
			user_id: null,
			city_id: null,
      email: null,
      name: null,
      lastname: null,
			rut: null,
      phone: null,
      address: null,
      role_id: 1,
      avatar: null,
      company_id: null,
			password: null
    },
		companyProfile: {
			rep_name: null,
			rep_first_lastname: null,
			rep_second_lastname: null,
			rep_rut: null,
			rep_address: null,
			rep_state_id: null,
			rep_city_id: null,
			name: null,
			rut: null,
			email: null,
			phone: null,
			address: null,
			state_id: null,
			city_id: null,
			work_as_company: false,
			document_2: null,
			document_3: null,
			document_4: null,
			document_5: null
		},
		rp_cities: [],
		c_cities: [],
		userCreated: null,
    acceptRejectModal: false,
    acceptRejectModalData: null,
    loadingData: false,
		avatarUrl: null,
		rutRule: [
			v => validate(v) || 'Rut no válido'
		],
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    phoneRule: [
			v => !!v || 'Campo requerido',
			v => /^569\d{8}$/.test(v) || 'Debe tener 11 dígitos y empezar con 569',
    ],
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ],
  }),
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
  mounted(){
		if(this.$store.state.user.myUserDataModal){
			this.newUserData = {
				...this.$store.state.user.myUserDataModal,
				...this.$store.state.user.myUserDataModal.profile,
				...this.$store.state.user.myUserDataModal.company_rol
			}
			this.newUserData.avatar = null
			this.avatarUrl = this.$store.state.user.myUserDataModal.profile.avatar
			if(this.newUserData.state_id){
				this.onChangeState(this.newUserData.state_id)
			}
			if(this.newUserData.lastname){
				const parts = this.newUserData.lastname.split(' ')
				this.firstLastname = parts[0] || ''
				this.secondLastname = parts[1] || ''
			}

			this.companyProfile = {
				...this.companyProfile,
				...this.$store.state.user.myUserDataModal.company_profile
			}
			if(this.companyProfile?.city?.state_id){
				this.companyProfile.state_id = this.companyProfile?.city?.state_id
				this.onChangeStateC(this.companyProfile.city.state_id)
			}
			if(this.companyProfile?.rep_city?.state_id){
				this.companyProfile.rep_state_id = this.companyProfile.rep_city.state_id
				this.onChangeStateRP(this.companyProfile.rep_city.state_id)
			}
		}
  },
  methods: {
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
    addRole() {
			this.$store.commit('companies/setNewRoleModalEditing', null)
			this.$store.commit('companies/setNewRoleModal', true)
    },
		onBlurRut(){
			if(validate(this.newUserData.rut)){
				this.newUserData.rut = format(this.newUserData.rut)
			}
		},
		onFocusRut(){
			if(validate(this.newUserData.rut)){
				this.newUserData.rut = clean(this.newUserData.rut)
			}
		},
		onChangeState(state){
			this.$store.dispatch('general/getCities', state)
		},
		async onChangeStateRP(state_id){
      const cities = await getCities({state_id})
      if (cities.code === 200) {
        this.rp_cities = cities.cities
      }
		},
		async onChangeStateC(state_id){
      const cities = await getCities({state_id})
      if (cities.code === 200) {
        this.c_cities = cities.cities
      }
		},
    triggerFileInput() {
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    onFileSelected(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.avatarUrl = e.target.result
        };
        reader.readAsDataURL(file)
      }
    },
    closeModal(){
			this.$store.commit('user/setEditMyUserDataModal', false)
			this.$store.commit('user/setMyUserDataModal', null)
      this.newUserData = {
				user_id: null,
				city_id: null,
				email: null,
				name: null,
				lastname: null,
				rut: null,
				phone: null,
				address: null,
				role_id: 1,
				avatar: null,
				company_id: null,
				password: null
      }
      this.validform = true
      this.loadingData = false
    },
		showFilePicker(docNumber) {
			const docKey = `document_${docNumber}`;
			const input = this.$refs.fileInput;
			input.onchange = async (event) => {
				const file = event.target.files[0]
				if (Object.prototype.hasOwnProperty.call(this.companyProfile, docKey)) {
            this.companyProfile[docKey] = file;
        }
				input.value = null
			};
			input.click();
		},
		async sendCompanyForm(){
			this.loadingData = true
			const formData = new FormData()
			let companyProfile = {...this.companyProfile,
				...{
					rut: clean(this.companyProfile.rut),
					rep_rut: clean(this.companyProfile.rep_rut)
				}
			}
			Object.keys(companyProfile).forEach((key) => {
				if(companyProfile[key]) {
					formData.append(key, companyProfile[key])
				}
			})
			const account_cp = await setAccountCompanyProfile(formData)
			if(account_cp.code === 200){
				const messageData = {
					message: 'Se han guardado correctamente los datos',
					title: 'Datos actualizados',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
					this.$store.dispatch('users/getUserFromCompany')
					this.$store.dispatch('user/getUserInfo')
          this.setMessage(messageData)
          this.closeModal()
			}
			else{
				const messageData = {
					message: 'Hubo un error en la operación, por favor intenta nuevamente',
					title: 'Ha ocurrido un error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
			this.loadingData = false
		},
    async sendForm(){
      if(this.$refs.form.validate()){
        this.loadingData = true
        const formData = new FormData()
        let newUserData = {...this.newUserData, ...{password_confirmation: this.newUserData.password }}
				newUserData.rut = clean(newUserData.rut)
				newUserData.lastname = this.firstLastname + ' ' + this.secondLastname
        Object.keys(newUserData).forEach((key) => {
          if(newUserData[key]) {
            formData.append(key, newUserData[key])
          }
        })
        const user = await update(formData)
        if(user.code === 200){
					this.userCreated = user.user
          const messageData = {
            message: 'Se han guardado correctamente los datos',
            title: 'Usuario actualizados',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
					this.$store.dispatch('users/getUserFromCompany')
					this.$store.dispatch('user/getUserInfo')
          this.setMessage(messageData)
          this.closeModal()
        }
        else if(user.data){
					this.userCreated = user.user
          user.data.forEach(message => {
            const messageData = {
              message,
              title: 'Error',
              type: 'danger',
              icon: 'mdi-close-octagon',
              color: '#FF1209',
            }
            this.setMessage(messageData)
          })
        }
        this.loadingData = false
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
		position: relative;
	}
	.logo-text{
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		color: white;
		font-size: 12px;
		padding-bottom: 10px;
		padding-top: 3px;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>