<template>
  <v-container fluid>
		<div class="d-flex align-center justify-end mb-2">
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						fab
						dark
						x-small
						color="green"
						class="mx-2"
						v-if="lastWorkingBudget"
						@click="toBudget(lastWorkingBudget)"

						v-bind="attrs"
						v-on="on"
					>
						<v-icon small dark>mdi-book-edit-outline</v-icon>
					</v-btn>
				</template>
				<span>Continuar Trabajando en Último Presupuesto</span>
			</v-tooltip>
			<div class="d-flex align-center" style="flex: 1;max-width: 400px;">
				<v-icon color="#524D4D" @click="searchForString">mdi-magnify</v-icon>
				<v-text-field
					v-model="searchInput"
					:loading="$store.state.budget.loadingCompanyBudgets"
					outlined
					dense
					hide-details
					item-text="name"
					item-value="id"
					return-object
					clearable
					label="Búsqueda"
					class="mb-0 mr-2 custom-height-search custom-height-calendar"
					color="#524D4D"
					@input="sendToSearch"
				></v-text-field>
			</div>
		</div>
    <v-data-table
      :headers="headers"
      :items="budgetsList"
			:server-items-length="$store.state.budget.companyBudgetsCount"
      item-key="id"
			class="budget-table"
      :loading="$store.state.budget.loadingCompanyBudgets"
			:options.sync="options"
      loading-text="Cargando datos..."
			:footer-props="{
        itemsPerPageText: 'Filas por página',
				'items-per-page-options': [10, 30, 50, 100]
			}"
    >
			<template v-slot:item.serial="{ item }">
				<div class="d-flex align-center">
					<div style="width:75px;"><b>{{ item.prefix?.prefix }}  {{ item.serial?.serial }} - {{ item.created_at | toYear}}</b></div>
					<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
						<template v-slot:activator="{ on, attrs }">
							<v-tooltip bottom>
								<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
									<v-btn
										icon
										color="warning"
										x-small
										v-bind="{ ...attrs, ...tooltipAttrs }"
										v-on="{ ...on, ...tooltipOn }"
									>
										<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
									</v-btn>
								</template>
								<span>Cambiar Prefijo</span>
							</v-tooltip>
						</template>
						<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
							<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
								<p class="caption mb-0 font-weight-bold white--text">Cambiar Prefijo Presupuesto</p>
							</v-list-item>
							<v-list-item-group color="#524D4D">
								<v-list-item
									v-for="(prefix, index) in $store.state.budget.companiesBudgetPrefixes"
									:key="index"
									dense
									@click="changeBudgetPrefix(item.id, prefix.id)"
								>
									<v-list-item-title dense>
										{{ prefix.prefix }} - {{ prefix.name }}
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</div>
			</template>
			<template v-slot:item.client="{ item }">
				<b>{{ getUserName(item.morphable) }}</b>
				<div v-if="item.morphable">{{ item.morphable.client ? item.morphable.client.email:'No identificado'}}</div>
			</template>
      <template v-slot:item.details="{ item }">
				<div>
					<div class="d-flex">
						<div class="d-flex align-center" style="width: 130px;">
							<v-icon small class="mr-2">{{getTypeIcon(item.morphable_type)}}</v-icon> <p class="mb-0 font-weight-bold">{{ getTypeName(item.morphable_type)}}</p>
						</div>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									color="warning"
									x-small
									v-bind="attrs"
									v-on="on"
									@click="gotToAssociation(item)"
								>
									<v-icon dark small>mdi-eye-outline</v-icon>
								</v-btn>
							</template>
							<span>Ir al detalle</span>
						</v-tooltip>
					</div>
					<div class="d-flex align-center">
						<div v-if="item.morphable">
							<div class="d-flex align-center" v-if="item.morphable.responsible" style="width: 130px;">
								<v-icon small class="mr-2" color="green">mdi-account-check-outline</v-icon>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<p
											class="mb-0 text-truncate font-weight-bold"
											v-bind="attrs"
											v-on="on"
										>
											{{ item.morphable.responsible.profile.name + ' ' + item.morphable.responsible.profile.lastname }}
										</p>
									</template>
									<span>{{ item.morphable.responsible.profile.name + ' ' + item.morphable.responsible.profile.lastname }}</span>
								</v-tooltip>
							</div>
							<div v-else style="width: 130px;">
								<v-icon small class="mr-2" color="warning">mdi-account-cancel-outline</v-icon>
								<b>Sin Responsable</b>
							</div>
						</div>
						<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
							<template v-slot:activator="{ on, attrs }">
								<v-tooltip bottom>
									<template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
										<v-btn
											icon
											color="warning"
											x-small
											v-bind="{ ...attrs, ...tooltipAttrs }"
											v-on="{ ...on, ...tooltipOn }"
										>
											<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
										</v-btn>
									</template>
									<span>Cambiar Responsable</span>
								</v-tooltip>
							</template>
							<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
								<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
									<p class="caption mb-0 font-weight-bold white--text">Cambiar Responsable</p>
								</v-list-item>
								<v-list-item-group color="#524D4D">
									<v-list-item
										v-for="(responsible, index) in $store.state.users.usersList"
										:key="index"
										dense
										@click="changeResponsible(item, responsible.id)"
									>
										<v-list-item-title dense>
											{{ responsible.profile?.name }} {{ responsible.profile?.lastname }}
										</v-list-item-title>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
					</div>
				</div>
      </template>
      <template v-slot:item.project="{ item }">
				<div class="d-flex flex-column">
					<p class="ml-2 mb-0 font-weight-bold">{{ item.morphable?.info?.address?.address }}</p>
					<p class="ml-2 mb-0">{{ item.morphable?.info?.project_type?.name }}</p>
				</div>
      </template>
      <template v-slot:item.status="{ item }">
				<div>
					<v-chip
						color="green"
						label
						x-small
						dark
						v-if="lastWorkingBudget && lastWorkingBudget.id == item.id"
						class="px-1"
					>
						<b>Lo trabajaste hace poco</b>
					</v-chip>
					<div class="d-flex align-center">
						<div>
							<v-chip
								:color="item.last_status?.status | budgetStatusColor"
								label
								outlined
								x-small
								class="px-1"
							>
								<b>{{ item.last_status?.status | budgetStatus }}</b>
							</v-chip>
							<p class="ml-2 mb-0 caption">{{ item.last_status?.description }}</p>
						</div>

						<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									color="warning"
									x-small
									v-bind="attrs"
									v-on="on"
								>
									<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
								</v-btn>
							</template>
							<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
								<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
									<p class="caption mb-0 font-weight-bold white--text">Cambiar Estado</p>
								</v-list-item>
								<v-list-item-group color="#524D4D">
									<v-list-item
										v-for="(status, index) in budgetStatuses"
										:key="index"
										dense
										@click="changeBudgetStatus(item.id, status.value)"
									>
										<v-list-item-title dense>
											{{ status.title }}
										</v-list-item-title>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
					</div>
				</div>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							icon
							dark
							x-small
							color="#959595"
							class="mx-1"
							@click="toBudget(item)"

							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-book-cog-outline</v-icon>
						</v-btn>
					</template>
					<span>Ver Presupuesto</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							icon
							dark
							x-small
							color="#959595"
							class="mx-1"
							@click="sendEmailBudget(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-email-plus-outline</v-icon>
						</v-btn>
					</template>
					<span>Enviar Correo a Cliente</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							icon
							dark
							x-small
							color="#959595"
							class="mx-1"
							@click="createContract(item)"

							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-briefcase-outline</v-icon>
						</v-btn>
					</template>
					<span>Crear Contrato</span>
				</v-tooltip>
				<!-- 
				<v-tooltip bottom v-if="$checkPermissions(['edit_users'])">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="warning"
							class="mx-1"
							@click="editUser(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-pencil-outline</v-icon>
						</v-btn>
					</template>
					<span>Editar</span>
				</v-tooltip> -->
				<!-- <v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="#31D56A"
							class="mx-1"
							@click="$router.push({ name: 'Chat', query: { user_id: item.id } })"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-chat-outline</v-icon>
						</v-btn>
					</template>
					<span>Chatear</span>
				</v-tooltip> -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'
import {addNewStatus, sendBudgetPdfEmail, setBudgetInfo} from '@/helpers/api/budgets'
import {createContract} from '@/helpers/api/contracts'

import {setResponsible as setResponsiblePreProject} from '@/helpers/api/preprojects'
import {setResponsible as setResponsibleProject} from '@/helpers/api/projects'
import {assignScheduleToUser} from '@/helpers/api/crm'

export default {
  data() {
    return {
      search: '',
			prefixMenu: false,
			searchInput: null,
			lastWorkingBudget: null,
			budgetStatuses: [
				{value: 1, title: 'Creado'},
				{value: 2, title: 'Enviado'},
				{value: 3, title: 'Observado'},
				{value: 4, title: 'Aceptado'},
				{value: 5, title: 'Rechazado'},
			],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      headers: [
				{ text: 'Nº', value: 'serial', class: 'with-divider', cellClass: 'with-divider', sortable: false },
				{ text: 'Cliente', value: 'client', class: 'with-divider', cellClass: 'with-divider', sortable: false },
        { text: 'Seguimiento', value: 'details', class: 'with-divider', cellClass: 'with-divider', sortable: false },
        { text: 'Proyecto', value: 'project', class: 'with-divider', cellClass: 'with-divider', sortable: false },
        { text: 'Estado', value: 'status', class: 'with-divider', cellClass: 'with-divider', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
		budgetsList(){
			return this.$store.state.budget.companyBudgets
		},
    filteredUsers() {
      return this.$store.state.users.usersList.filter(user => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		queryRoute(){
			return this.$route.query
		},
		lastBudgetId(){
			const budget = localStorage.getItem('last_working_budget')
			if(budget){
				const budget_data = JSON.parse(budget)
				return budget_data
			}
			return null
		}
  },
	watch: {
    options: {
      handler() {
				this.reloadInfo()
      },
      deep: true,
    },
		selectedCompany(newValue){
			if(newValue){
				this.reloadInfo()
				this.$store.dispatch('users/getUserFromCompany')
			}
		},
		budgetsList(newValue){
			if(newValue){
				this.initialCheck()
			}
		},
		queryRoute(){
			const budget_id = this.$route.query.b
			if(budget_id){
				const data = {
					status: true,
					budget_id,
					force_get: true
				}
				this.$store.commit('general/setNewBudgetModal', data)
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.reloadInfo()
			this.$store.dispatch('users/getUserFromCompany')
		}
		this.setLastWorkingBudget()
		if(this.budgetsList?.length){
			this.initialCheck()
		}
    eventBus.$on('reloadBudget', () => {
			this.reloadInfo()
    })
	},
  methods: {
		initialCheck(){
			const budget_id = this.$route.query.b
			if(budget_id){
				const budget = this.budgetsList.find(b => b.id == budget_id)
				if(budget){
					this.toBudget(budget)
				}
			}
		},
		setLastWorkingBudget(){
			const budget = localStorage.getItem('last_working_budget')
			if(budget){
				const budget_data = JSON.parse(budget)
				this.lastWorkingBudget = budget_data
			}
		},
		async changeBudgetStatus(budget_id, status){
			const resp = await addNewStatus({budget_id, status})
			if(resp.code == 200){
				this.reloadInfo()
			}
		},
		async changeBudgetPrefix(budget_id, budget_prefix_id){
			const resp = await setBudgetInfo({budget_id, budget_prefix_id})
			if(resp.code == 200){
				this.reloadInfo()
			}
		},
		getUserName(item){
			let name = ''
			if(item.client && item.client.profile){
				name = item.client.profile.name + ' ' + item.client.profile.lastname
			}
			else{
				name = 'No identificado'
			}
			return name
		},
		getTypeIcon(type){
			switch (type) {
				case "App\\Models\\BotConversation":
					return 'mdi-contacts-outline'
				case "App\\Models\\PreProject":
					return 'mdi-information-variant-circle-outline'
				case "App\\Models\\Project":
					return 'mdi-ruler-square'
				default:
					break;
			}
			return 'Otro'
		},
		getTypeName(type){
			switch (type) {
				case "App\\Models\\BotConversation":
					return 'CRM'
				case "App\\Models\\PreProject":
					return 'PreProyecto'
				case "App\\Models\\Project":
					return 'Proyecto'
				default:
					break;
			}
			return 'Otro'
		},
		getType(type){
			switch (type) {
				case "App\\Models\\BotConversation":
					return 'crm'
				case "App\\Models\\PreProject":
					return 'preproject'
				case "App\\Models\\Project":
					return 'project'
				default:
					break;
			}
			return 'Otro'
		},
		sendToSearch: _debounce(function() { this.searchForString() }, 800),
		searchForString(){
			this.reloadInfo(this.searchInput)
		},
		reloadInfo(search = null){
			let data = {
				...this.options,
				start: (this.options.page - 1) * this.options.itemsPerPage,
				limit: this.options.itemsPerPage,
			}
			if(search){
				data = {search}
			}
			this.$store.dispatch('budget/getCompanyBudgets', data)
		},
		toBudget(item){
			localStorage.setItem('last_working_budget', JSON.stringify(item))
			this.lastWorkingBudget = item
			this.$router.push({ name: 'BudgetDetail', query: { id: item.id } })
		},
    editUser(user) {
			const data = {...user, ...{user_id: user.id, client: true}}
			this.$store.commit('users/setNewUserModalEditing', data)
			this.$store.commit('users/setNewUserModal', true)
    },
		openNewProjectModal(budget, preproject){
			const pre_project = {...preproject, ...{budget}, ...{doc_type: 1}}
			this.$store.commit('projects/setProjectModalInfo', pre_project)
			this.$store.commit('projects/setProjectModal', true)
		},
    async createContract(item){
      if(item.morphable_id){
				if(this.getType(item.morphable_type) == 'preproject'){
					this.openNewProjectModal(item, item.morphable)
					return null
				}
				const data = {
					item_type: this.getType(item.morphable_type),
					item_id: item.morphable_id,
					contract_prefix_id: item.prefix?.id,
					serial_id: item.serial_id,
					parent_id: item.id
				}
        const contract = await createContract(data)
        if(contract.code === 200){
          const messageData = {
            message: 'Se ha creado correctamente el contrato',
            title: 'Contrato creado',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
					this.$router.push({ name: 'ContractDetail', query: { id: contract.contract.id } })
          this.setMessage(messageData)
        }
        else{
          const messageData = {
            message: 'Hubo un error en la operación, por favor intenta nuevamente',
            title: 'Ha ocurrido un error',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#FF1209',
          }
          this.setMessage(messageData)
        }
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
		async sendEmailBudget(budget){
			const budget_r = await sendBudgetPdfEmail({budget_id: budget.id})
			if(budget_r.code === 200){
				this.reloadInfo(this.searchInput)
				const messageData = {
					message: 'Se ha enviado correctamente el presupuesto por correo',
					title: 'Presupuesto enviado',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			}
			else{
				const messageData = {
					message: 'Hubo un error en la operación, por favor intenta nuevamente',
					title: 'Ha ocurrido un error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		},
		async changeResponsible(item, responsible_id){
			const item_type = this.getType(item.morphable_type)
			if(item_type == 'crm'){
				await assignScheduleToUser({schedule_id: this.conversation.schedule.user_schedule.id, new_user_id: responsible_id})
			}
			else if(item_type == 'preproject'){
				await setResponsiblePreProject({preproject_id: item.morphable_id, responsible_id})
			}
			else if(item_type == 'project'){
				await setResponsibleProject({project_id: item.morphable_id, responsible_id})
			}
			this.reloadInfo()
		},
		gotToAssociation(item){
			const item_type = this.getType(item.morphable_type)
			if(item_type == 'crm'){
				this.$router.push({ name: 'Conversation', params: { id: item.morphable_id } })
			}
			else if(item_type == 'preproject'){
				this.$router.push({ name: 'PreProject', params: { id: item.morphable_id } })
			}
			else if(item_type == 'project'){
				const messageData = {
					message: 'Detalle de proyectos en futuro módulo Proyectos',
					title: 'Sección en construcción',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		}
  },
};
</script>

<style scoped>
</style>
<style>
.budget-index .message-container{
	margin-bottom: 0;
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.budget-index .message-container p{
	margin-bottom: 0;
	font-size: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.budget-table table tr td{
	font-size: 11px!important;
}
.budget-table.resizable .v-data-table__wrapper th,
.budget-table.resizable .v-data-table__wrapper td {
	border-right: 1px solid #cacaca;
}
.with-divider {
  border-right: 1px solid grey;
}
</style>