import { getCostCategoriesCompany, getCompanyCostTemplates } from "@/helpers/api/costs"
import {getSpecialties} from '@/helpers/api/company'

const DashboardStore = {
  namespaced: true,
  state: {
		loadingCompaniesSpecialties: false,
		companiesSpecialties: [],
		loadingCompaniesCostCategories: false,
		companiesCostCategories: [],
		companiesCostGeneralSubCategories: [],
		loadingCompaniesCostTemplates: false,
		companiesCostTemplates: [],
		newCostBreakdownCategoryModal: false,
		newCostBreakdownCategoryModalEditing: null,
		newCostBreakdownSubCategoryModal: false,
		newCostBreakdownSubCategoryModalEditing: null,
		newCostBreakdownItemModal: false,
		newCostBreakdownItemModalEditing: null
  },
  mutations: {
    setLoadingCompaniesSpecialties(state, status) {
      state.loadingCompaniesSpecialties = status
    },
    setCompaniesSpecialties(state, specialties) {
      state.companiesSpecialties = specialties
    },
    setLoadingCompaniesCostCategories(state, status) {
      state.loadingCompaniesCostCategories = status
    },
    setCompaniesCostCategories(state, categories) {
      state.companiesCostCategories = categories
    },
    setCompaniesCostGeneralSubCategories(state, subcategories) {
      state.companiesCostGeneralSubCategories = subcategories
    },
    setLoadingCompaniesCostTemplates(state, status) {
      state.loadingCompaniesCostTemplates = status
    },
    setCompaniesCostTemplates(state, templates) {
      state.companiesCostTemplates = templates
    },
    setNewCostBreakdownCategoryModal(state, modal) {
      state.newCostBreakdownCategoryModal = modal
    },
    setNewCostBreakdownCategoryModalEditing(state, info) {
      state.newCostBreakdownCategoryModalEditing = info
    },
    setNewCostBreakdownSubCategoryModal(state, modal) {
      state.newCostBreakdownSubCategoryModal = modal
    },
    setNewCostBreakdownSubCategoryModalEditing(state, info) {
      state.newCostBreakdownSubCategoryModalEditing = info
    },
    setNewCostBreakdownItemModal(state, modal) {
      state.newCostBreakdownItemModal = modal
    },
    setNewCostBreakdownItemModalEditing(state, info) {
      state.newCostBreakdownItemModalEditing = info
    }
  },
  actions: {
    async getCompanySpecialties({ commit }) {
      commit('setLoadingCompaniesSpecialties', true)
      const specialties = await getSpecialties()
      if (specialties.code === 200) {
        commit('setCompaniesSpecialties', specialties.specialties)
      }
      commit('setLoadingCompaniesSpecialties', false)
    },
    async getCompanyCostCategories({ commit }) {
      commit('setLoadingCompaniesCostCategories', true)
      const categories = await getCostCategoriesCompany()
      if (categories.code === 200) {
        commit('setCompaniesCostCategories', categories.cost_categories)
        commit('setCompaniesCostGeneralSubCategories', categories.company_subcategories)
      }
      commit('setLoadingCompaniesCostCategories', false)
    },
    async getCompanyCostTemplates({ commit }) {
      commit('setLoadingCompaniesCostTemplates', true)
      const categories = await getCompanyCostTemplates()
      if (categories.code === 200) {
        commit('setCompaniesCostTemplates', categories.cost_template)
      }
      commit('setLoadingCompaniesCostTemplates', false)
    },
  }
}

export default DashboardStore
