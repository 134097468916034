const endpoints = {
	createBudgetPrefix: '/budgets/config/createBudgetPrefix',
	updateBudgetPrefix: '/budgets/config/updateBudgetPrefix',
	getBudgetPrefixes: '/budgets/config/getBudgetPrefixes',
	removeBudgetPrefix: '/budgets/config/removeBudgetPrefix',
	createBudgetConcept: '/budgets/config/createBudgetConcept',
	updateBudgetConcept: '/budgets/config/updateBudgetConcept',
	getBudgetConcepts: '/budgets/config/getBudgetConcepts',
	removeBudgetConcept: '/budgets/config/removeBudgetConcept',
	createBudgetCompanyCategory: '/budgets/config/createBudgetCompanyCategory',
	updateBudgetCompanyCategory: '/budgets/config/updateBudgetCompanyCategory',
	getBudgetCompanyCategories: '/budgets/config/getBudgetCompanyCategories',
	removeBudgetCompanyCategory: '/budgets/config/removeBudgetCompanyCategory',
	createBudgetCompanyDetail: '/budgets/config/createBudgetCompanyDetail',
	updateBudgetCompanyDetail: '/budgets/config/updateBudgetCompanyDetail',
	getBudgetCompanyDetails: '/budgets/config/getBudgetCompanyDetails',
	removeBudgetCompanyDetail: '/budgets/config/removeBudgetCompanyDetail',
	updateOrderBudgetDetail: '/budgets/config/updateOrderBudgetDetail',
	saveUpdateBudgetNoteTemplate: '/budgets/config/saveUpdateBudgetNoteTemplate',
	getCompanyBudgetNoteTemplates: '/budgets/config/getCompanyBudgetNoteTemplates',
	deleteBudgetNoteTemplate: '/budgets/config/deleteBudgetNoteTemplate',
	updateOrderBudgetNoteTemplate: '/budgets/config/updateOrderBudgetNoteTemplate',
	createBudgetTitle: '/budgets/config/createBudgetTitle',
	updateBudgetTitle: '/budgets/config/updateBudgetTitle',
	getBudgetTitles: '/budgets/config/getBudgetTitles',
	deleteBudgetTitle: '/budgets/config/deleteBudgetTitle',

	createBudget: '/budgets/createBudget',
	updateBudget: '/budgets/updateBudget',
	setBudgetInfo: '/budgets/setBudgetInfo',
	getCompanyBudgets: '/budgets/getCompanyBudgets',
	getBudgetById: '/budgets/getBudgetById',
	setBudgetsPartCategory: '/budgets/setBudgetsPartCategory',
	removeBudgetPartCategory: '/budgets/removeBudgetPartCategory',
	setItemBudgetPart: '/budgets/setItemBudgetPart',
	removeItemBudgetPart: '/budgets/removeItemBudgetPart',
	addPreProjectBudgetsPart: '/budgets/config/addPreProjectBudgetsPart', //NO SE USA?
	createBudgetNote: '/budgets/createBudgetNote',
	updateBudgetNote: '/budgets/updateBudgetNote',
	updateOrderBudgetNote: '/budgets/updateOrderBudgetNote',
	deleteBudgetNote: '/budgets/deleteBudgetNote',
	addNewStatus: '/budgets/addNewStatus',
	sendBudgetPdfEmail: '/budgets/sendBudgetPdfEmail',
	createBudgetPdfFromPreview: '/budgets/createBudgetPdfFromPreview',
	getSerials: '/budgets/getSerials',
	newBudgetObservation: '/budgets/newBudgetObservation',
}
export {endpoints}