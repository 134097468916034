<template>
<div v-if="!contract && loading">
	<p class="text-center caption">Cargando Contrato</p>
	<v-skeleton-loader
		type="heading"
		class="my-2 mx-6"
	></v-skeleton-loader>
	<v-skeleton-loader
		type="paragraph"
		class="my-2 mx-6"
	></v-skeleton-loader>
	<v-skeleton-loader
		type="text"
		class="my-2 mx-6"
	></v-skeleton-loader>
	<v-skeleton-loader
		type="paragraph"
		class="my-2 mx-6"
	></v-skeleton-loader>
</div>
<div v-else-if="contract && selectedCompany">
	<div class="mx-4 mb-2" v-if="scrollTopBudgetcontainer == 0 && userInfo && userInfo.id == contract.morphable?.client_id">
		<div class="custom-warning-status px-2 py-1">
			<div class="d-flex align-center">
				<p class="mb-0 mr-2 font-weight-bold caption">Estado del contrato:</p>
				<v-chip
					:color="contract.last_status?.status | contractStatusColor"
					label
					outlined
					x-small
					class="px-1"
				>
					<b>{{ contract.last_status?.status | contractStatus }}</b>
				</v-chip>
			</div>
			<p class="mb-0 caption">{{ contract.last_status?.status | contractEstatusNoticeClient }}</p>
		</div>
	</div>
	<div ref="budgetStickyHeader" style="position: relative" >
		<div :class="{'sticky-header': isSticky}" :style="{ top: topOffset + 'px' }" class="pa-4 pb-0 d-flex align-center justify-space-between" v-if="contract.last_status?.status < contractStatuses.FIRMADO_CLIENTE">
			<div class="d-flex justify-end" v-if="showEdit">
				<v-btn
					depressed
					color="warning"
					dark
					outlined
					class="mb-2"
					x-small
					@click="toContractEdit"
				>
					<v-icon small>mdi-pencil-outline</v-icon>
					<p class="mb-0 ml-2 caption">Ir A Edición de Contrato</p>
				</v-btn>
			</div>
			<div class="d-flex flex-column justify-end w-100" v-if="contract.last_status?.status == contractStatuses.ENVIADO_CLIENTE && userInfo && userInfo.id == contract.morphable?.client_id">
				<v-chip
					color="red"
					label
					outlined
					x-small
					class="px-1 text-center d-flex justify-center"
					v-if="!scrolledToBottom"
				>
					<b>Debes leer el contrato antes de aceptar o rechazar</b>
				</v-chip>
				<div class="d-flex justify-end mt-2">
					<v-btn
						depressed
						color="green"
						class="mb-0 mr-2"
						:disabled="!scrolledToBottom"
						:dark="scrolledToBottom"
						@click="changeStatus(contractStatuses.ACEPTADO)"
					>
						<v-icon small>mdi-check</v-icon>
						<p class="mb-0 ml-2 caption">Aceptar Contrato</p>
					</v-btn>
					<v-btn
						depressed
						color="error"
						outlined
						class="mb-0"
						:disabled="!scrolledToBottom"
						:dark="scrolledToBottom"
						@click="changeStatus(contractStatuses.RECHAZADO)"
					>
						<v-icon small>mdi-cancel</v-icon>
						<p class="mb-0 ml-2 caption">Rechazar Contrato</p>
					</v-btn>
				</div>
			</div>
			<div class="d-flex align-center justify-end w-100" v-if="contract.last_status?.status == contractStatuses.ACEPTADO && userInfo && userInfo.id == contract.morphable?.client_id">
				<div class="mr-4">
					<div class="mb-1"
							v-if="!contract.start_date">
						<v-chip
							color="red"
							label
							outlined
							x-small
							class="px-1 mb-2"
						>
							<b>Para Firmar primero debes seleccionar la fecha de inicio del contrato</b>
						</v-chip>
					</div>
					<v-menu
						ref="startDateMenu"
						v-model="startDateMenu"
						:close-on-content-click="false"
						:return-value.sync="selectedStartDate"
						transition="scale-transition"
						offset-y
						min-width="auto"
						max-width="250"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								:value="selectedStartDate | toDate"
								label="Fecha Inicio Contrato"
								prepend-icon="mdi-calendar"
								readonly
								dense
								class="custom-height-search custom-height-calendar"
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="selectedStartDate"
							no-title
							scrollable
							:first-day-of-week="1"
							locale="es-cl"
							:events="startDateSelectionOptions.holidays"
							:allowed-dates="allowedDates"
							:max-date="startDateSelectionOptions.max_date"
							:min-date="startDateSelectionOptions.min_date"
							:picker-date.sync="pickerDate"
							width="100%"
							reactive
							dense
							event-color="red lighten-1"
							color="green"
							class="custom-calendar-sidemini pb-4"
						>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="#524D4D"
								@click="startDateMenu = false"
							>
								Cancelar
							</v-btn>
							<v-btn
								text
								color="#524D4D"
								@click="changeContratDate"
							>
								Confirmar
							</v-btn>
						</v-date-picker>
					</v-menu>
				</div>
				<div>
					<v-btn
						v-if="contract.start_date"
						depressed
						color="green"
						dark
						class="mb-0 mr-2"
						@click="startSignContract"
					>
						<v-icon small>mdi-check</v-icon>
						<p class="mb-0 ml-2 caption">Firmar Contrato</p>
					</v-btn>
				</div>
			</div>
		</div>
		<v-divider class="my-2"></v-divider>
	</div>
	<div
		ref="colContractContainer"
		class="d-flex flex-column contract-container pb-0 h-100"
		style="overflow-y: scroll;"
		:style="'height:'+(budgetAvailableHeight)+'px'"
    @scroll="handleScroll">
		<div class="d-flex flex-column flex-grow-1">
			<div v-if="contract.last_status?.status < contractStatuses.ACEPTADO">
				<!-- <div class="py-2 px-4 d-flex justify-space-between" style="zoom: 0.8" v-if="condensed && false">
					<div class="d-flex justify-space-between">
						<img :src="selectedCompany.company.logo" style="width: 120px;height: 120px;"/>
					</div>
					<div>
						<div class="px-2 mb-2">
							<p class="font-weight-bold mb-0" style="color: #606060;font-size: 19.6px !important">{{$store.state.companies.selectedCompany.company.name}}</p>
							<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.description}}</p>
							<p class="caption mb-0" style="color: #606060;">Rut: {{$store.state.companies.selectedCompany.company.rut | formatRut}}</p>
							<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.phone}}</p>
							<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.email}}</p>
						</div>
						<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
							<div style="width: 120px">Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">{{ contract.code }} - {{ contract.created_at | toYear }}</b>
						</div>
						<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
							<div style="width: 120px">Fecha Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">{{ contract.created_at | toDate }}</b>
						</div>
						<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
							<div style="width: 120px">Fecha Inicio</div><span>:</span>
							<b class="text-end ml-3" style="flex: 1;" v-if="contract.start_date">{{ contract.start_date | toDate }}</b>
							<b class="text-end ml-3" style="flex: 1;" v-else>A Convenir</b>
						</div>
					</div>
				</div> -->
				<div class="py-2 px-4" style="flex-wrap: wrap;">
					<div class="d-flex justify-space-between align-stretch">
						<div class="d-flex flex-column justify-center align-items-center flex-grow-1"
							:style="`background-image: url(${selectedCompany.company.logo});`"
							style="background-size: contain;background-repeat: no-repeat;">
						</div>
						<div class="text-end flex-grow-2" :style="condensed ? 'zoom: 0.8':''">
							<p class="font-weight-bold mb-0" style="color: #606060; font-size: 19.6px !important">
								{{$store.state.companies.selectedCompany.company.name}}
							</p>
							<p class="caption mb-0" style="color: #606060;line-height: 11pt;">{{$store.state.companies.selectedCompany.company.description}}</p>
							<p class="caption mb-0" style="color: #606060;line-height: 11pt;">Rut: {{$store.state.companies.selectedCompany.company.rut | formatRut}}</p>
							<p class="caption mb-0" style="color: #606060;line-height: 11pt;">{{$store.state.companies.selectedCompany.company.phone}}</p>
							<p class="caption mb-3" style="color: #606060;line-height: 11pt;">{{$store.state.companies.selectedCompany.company.email}}</p>
							<p class="caption mb-0" style="color: #606060;line-height: 11pt;">Cotización {{ contract.contract_info?.prefix?.prefix }} Nº {{ contract.serial?.serial }} - {{ contract.created_at | toYear }}</p>
							<p class="caption mb-0" style="color: #606060;line-height: 11pt;">{{ contract.created_at | toDate }}</p>
							<p class="caption mb-0" style="color: #606060;line-height: 11pt;">
								Inicio Proyecto:
								<b v-if="contract.start_date">{{ contract.start_date | toDate }}</b>
								<b v-else>A Convenir</b>
							</p>
						</div>
					</div>
				</div>

				<div class="py-2 px-4" v-for="(section, index) in contract.sections" :key="index">
					<div class="section-container rounded">
						<div class="pa-4">
							<div class="d-flex align-center mb-3 justify-space-between">
								<div class="d-flex flex-grow-1 justify-space-between">
									<p class="mb-0 font-weight-bold text-center flex-grow-1" v-if="section.section_type == 1">{{section.title}}</p>
									<p class="mb-0 font-weight-bold" v-else>{{ sectionIndex(index) }} {{section.title}}</p>
								</div>
							</div>
							<div v-for="(section_content, indexB) in section.contents" :key="'a'+indexB" :id="'section_content_id_' + section_content.id">
								<div class="mb-4">
									<v-hover v-slot="{ hover }">
										<div class="section-content-container">
											<div class="px-2">
												<div v-if="section_content.show_title" class="font-weight-bold">{{ (indexB + 1) + '.- ' }} {{ section_content.title }}</div>
												<div class="section-content pa-2" v-html="transformPlaceholdersToContentView(section_content.content)"></div>
											</div>
											<div class="d-flex justify-end py-2">
												<v-tooltip left>
													<template v-slot:activator="{ on, attrs }">
														<v-badge
															:value="countContractsByContentId(section_content.id)"
															:content="countContractsByContentId(section_content.id)"
															color="red"
															overlap
														>
															<v-btn
																:ref="'commentActivatorButton_' + section_content.id"
																depressed
																color="warning"
																outlined
																small
																rounded
																@click="openCommentSection(section_content.id)"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon class="mr-4" :class="{'pulse-animation': section_content.observation && !hover}">mdi-comment-plus-outline</v-icon>
																<p class="mb-0" v-if="section_content.observation">Hay Observaciones / Comentarios en esta sección</p>
																<p class="mb-0" v-else>Agregar un comentario a esta sección</p>
															</v-btn>
														</v-badge>
													</template>
													<span>Ver / Agregar Comentarios en Sección</span>
												</v-tooltip>
											</div>
											<!-- <v-tooltip left>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														v-show="hover"
														depressed
														color="warning"
														class="my-2 mx-2 add-comment-icon"
														large
														icon
														@click="openCommentSection(section_content.id)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon>mdi-comment-plus-outline</v-icon>
													</v-btn>
												</template>
												<span>Ver / Agregar Comentarios en Sección</span>
											</v-tooltip> -->
											<v-overlay
												:opacity="0.2"
												:value="menuComment == section_content.id"
												z-index="9"
												@click="menuComment = null"
											>
												<v-card
													v-if="menuComment == section_content.id"
													class="card-comment-container"
													light
													color="white"
													width="400"
													:style="{
														position: 'fixed',
														top: `${commentPosition.top}px`,
														left: `${commentPosition.left}px`
													}"
													@click.stop
													:ripple="false"
													>
													<v-card-text class="pa-0" style="background: white;width:400px;">
														<div class="py-1 px-4" style="background: #959595;">
															<p class="caption mb-0 font-weight-bold white--text">Observaciones en Sección</p>
														</div>
														<v-list dense class="py-0" max-height="300" style="overflow-y: auto;" ref="scrollListComment">
															<v-list-item-group color="#524D4D" v-if="section_content.observation">
																<v-list-item
																	v-for="(comment, index) in section_content.observation.comments"
																	:key="index"
																	dense
																>
																	<v-list-item-title dense>
																		<div class="d-flex justify-space-between item-background-hover py-2">
																			<div class="d-flex">
																				<v-icon :color="comment.side == 1 ? 'warning':''">mdi-comment-plus-outline</v-icon>
																				<div class="ml-2">
																					<div class="font-weight-bold caption">{{ comment.user.profile.name }} {{ comment.user.profile.lastname }} <small>({{ comment.side == 1 ? 'Cliente':'Empresa' }})</small></div>
																					<div class="caption">{{ comment.comment }}</div>
																				</div>
																			</div>
																			<v-tooltip bottom>
																				<template v-slot:activator="{ on, attrs }">
																					<v-btn
																						fab
																						dark
																						x-small
																						icon
																						color="error"
																						@click="deleteComment(comment)"
																						v-bind="attrs"
																						v-on="on"
																						v-if="userInfo && userInfo.id == comment.user_id"
																					>
																						<v-icon small dark>mdi-delete-outline</v-icon>
																					</v-btn>
																				</template>
																				<span>Eliminar Comentario</span>
																			</v-tooltip>
																		</div>
																	</v-list-item-title>
																</v-list-item>
															</v-list-item-group>
														</v-list>
														<v-divider class="my-2"></v-divider>
														<div class="px-4 pb-4">
															<v-text-field
																label="Ingrese Comentario"
																v-model="sectionComment"
																class="custom-height-search custom-height-calendar mb-2"
																hide-details
																dense
																outlined
																validate-on-blur
																:rules="requiredRule"
																@keyup.enter="saveComment"
															></v-text-field>
															<v-btn
																depressed
																color="green"
																:dark="!!sectionComment"
																small
																block
																:disabled="!sectionComment"
																:loading="loadingComment"
																@click="saveComment"
															>
																<v-icon small>mdi-check</v-icon>
																<p class="mb-0 ml-2 caption">Confirmar</p>
															</v-btn>
															<v-btn
																depressed
																color="#959595"
																text
																x-small
																block
																class="mt-2"
																@click="closeCommentForm"
															>
																<p class="mb-0">Cerrar</p>
															</v-btn>
														</div>
													</v-card-text>
												</v-card>
											</v-overlay>
										</div>
									</v-hover>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="contract && contract.pdf && contract.pdf.file" class="d-flex flex-grow-1" style="min-height: 900px">
				<iframe :src="contract.pdf.file.file_link" width="100%" class="fill-height d-flex flex-grow-1"></iframe>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { getContract, updateContract, createContractContentComment, deleteContractContentComment, addNewStatus } from '@/helpers/api/contracts'
import {markAsReaded} from '@/helpers/api/notifications'
import contractStatuses from '@/components/Helpers/Constants/contract.js'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'

export default {
  data() {
    return {
			contractStatuses,
			startDateMenu: false,
			budgetStickyHeader: null,
      search: '',
			selectedStartDate: null,
			loadingComment: false,
			sectionComment: null,
			commentPosition: { top: 0, left: 0, width: 400 },
      isSticky: false,
			menuComment: null,
			topOffset: 0,
			contract: null,
			loading: true,
			pickerDate: null,
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			resizeObserver: null,
			scrolledToBottom: false,
			scrollTopBudgetcontainer: 0,
			placeholderMapping: {
				'{contract_start_date}': 'Fecha Inicio',
				'{contract_date}': 'Fecha del Contrato',
				'{address}': 'Dirección del Proyecto',
				'{district}': 'Municipio del Proyecto',
				'{client_name}': 'Nombre Cliente',
				'{client_dni}': 'Rut Cliente',
				'{client_address}': 'Dirección Cliente',
				'{client_district}': 'Comuna Cliente',
				'{company_name}': 'Nombre Empresa',
				'{company_rut}': 'Rut Empresa',
				'{company_responsible}': 'Nombre Representante',
				'{company_responsible_rut}': 'Rut Representante',
				'{company_address}': 'Dirección Empresa',
				'{client_company_name}': 'Nombre Empresa (Cliente)',
				'{client_company_dni}': 'Rut Empresa (Cliente)',
				'{client_company_address}': 'Dirección Empresa (Cliente)',
				'{client_company_district}': 'Comuna Empresa (Cliente)',
				'{client_rep_name}': 'Nombre Representante (Cliente)',
				'{client_rep_dni}': 'Rut Representante (Cliente)',
				'{client_rep_address}': 'Dirección Representante (Cliente)',
				'{client_rep_district}': 'Comuna Representante (Cliente)',
			}
    };
  },
	props: {
		contract_info: {
			type: Object,
			default: null
		},
		contract_id: {
			type: [Number, String],
			default: null
		},
		showEdit: {
			type: Boolean,
			default: false
		},
		fromCompany: {
			type: Boolean,
			default: false
		},
		condensed: {
			type: Boolean,
			default: false
		}
	},
  computed: {
		budgetAvailableHeight(){
			if(this.budgetStickyHeader){
				return this.$store.getters['general/availableHeight']-24-this.budgetStickyHeader
			}
			return this.$store.getters['general/availableHeight']-24
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		userInfo(){
			return this.$store.state.user.userInfo
		},
		hashRoute(){
			return this.$route.hash
		},
		allowedDatesArray(){
			return this.contract.starting_day_allowed
		},
		startDateSelectionOptions(){
			return this.contract?.start_date_selection_options
		},
		contractVariablesValue(){
			if(this.contract){
				const content = {
					'{contract_date}': this.$options.filters.toDate(this.contract.created_at),
					'{contract_start_date}': this.contract.start_date ? this.$options.filters.toDate(this.contract.start_date):'A Convenir',
					'{client_name}': `${this.contract.morphable?.client?.profile.name} ${this.contract.morphable?.client?.profile.lastname}`,
					'{client_dni}': this.$options.filters.formatRut(this.contract.morphable?.client?.profile.rut),
					'{client_address}': this.contract.morphable?.client?.profile.address,
					'{client_district}': this.contract.morphable?.client?.profile.city?.name ?? 'N/A',
					'{address}': this.contract.morphable?.info.address.address,
					'{district}': this.contract.morphable?.info.address.district.name,
					'{company_name}': this.contract.morphable?.company.name,
					'{company_rut}': this.$options.filters.formatRut(this.contract.morphable?.company.rut),
					'{company_responsible}': `${this.contract.morphable?.company.user_responsible.profile.name} ${this.contract.morphable?.company.user_responsible.profile.lastname}`,
					'{company_responsible_rut}': this.$options.filters.formatRut(this.contract.morphable?.company.user_responsible.profile.rut),
					'{company_address}': this.contract.morphable?.company.address,
					'{client_company_name}': this.contract.morphable?.client?.company_profile?.name ?? 'N/A',
					'{client_company_dni}': this.$options.filters.formatRut(this.contract.morphable?.client?.company_profile?.rut ?? ''),
					'{client_company_address}': this.contract.morphable?.client?.company_profile?.address ?? 'N/A',
					'{client_company_district}': this.contract.morphable?.client?.company_profile?.city?.name ?? 'N/A',
					'{client_rep_name}': `${this.contract.morphable?.client?.company_profile?.rep_name} ${this.contract.morphable?.client?.company_profile?.rep_first_lastname} ${this.contract.morphable?.client?.company_profile?.rep_second_lastname}`,
					'{client_rep_dni}': this.$options.filters.formatRut(this.contract.morphable?.client?.company_profile?.rep_rut),
					'{client_rep_address}': this.contract.morphable?.client?.company_profile?.rep_address,
					'{client_rep_district}': this.contract.morphable?.client?.company_profile?.rep_city?.name ?? 'N/A',
				}
				return content
			}
			return null
		},
  },
	watch: {
		startDateSelectionOptions(newValue){
			if(newValue){
				this.pickerDate = newValue.min_date
				const c_date = this.contract.start_date ? this.contract.start_date : this.startDateSelectionOptions.min_date
				this.selectedStartDate = this.$options.filters.toDate(c_date, null, 'YYYY-MM-DD')
			}
		},
		hashRoute: function () {
			this.scrollToSection()
		},
		contract_id(){
			this.contract = null
			this.getContractInfo()
		},
		contract_info(newValue){
			if(newValue){
				this.contract = this.contract_info
				this.scrollToSection()
				this.$nextTick(() => {
					if(this.$refs.scrollListComment && this.$refs.scrollListComment.length > 0){
						const container = this.$refs.scrollListComment[0]?.$el
						if(container){
							container.scrollTop = container.scrollHeight
						}
					}
				})
			}
		},
		contract(newValue){
			if(newValue){
				this.setBudgetHeader()
			}
		}
	},
	mounted(){
		this.scrollToSection()
		this.contract = this.contract_info
		if(!this.contract){
			this.getContractInfo()
		}
    eventBus.$on('reloadContract', (data) => {
			if(this.contract && data.contract_id == this.contract.id && this.menuComment && data.contract_content_id){
				this.markAsReadNotification(data.contract_content_id)
			}
    })
		if(this.startDateSelectionOptions){
			this.pickerDate = this.startDateSelectionOptions.min_date
			const c_date = this.contract.start_date ? this.contract.start_date : this.startDateSelectionOptions.min_date
			this.selectedStartDate = this.$options.filters.toDate(c_date, null, 'YYYY-MM-DD')
		}
    // this.$refs.colContractContainer.addEventListener("scroll", this.handleScroll)
	},
  beforeDestroy() {
    // this.$refs.colContractContainer.removeEventListener("scroll", this.handleScroll)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
  },
  methods: {
		setBudgetHeader(){
			this.$nextTick(() => {
				const header = this.$refs.budgetStickyHeader;
				if (!header) return;
				this.resizeObserver = new ResizeObserver(entries => {
					for (let entry of entries) {
						const newHeight = entry.target.offsetHeight;
						this.budgetStickyHeader = newHeight
						console.log('budgetStickyHeader: ', this.budgetStickyHeader)
					}
				});
				this.resizeObserver.observe(header);
			});
		},
    handleScroll(event) {
      const element = event.target;
      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight;
      const clientHeight = element.clientHeight;

			this.scrollTopBudgetcontainer = scrollTop

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        this.scrolledToBottom = true
      }
    },
		allowedDates(date){
			return this.startDateSelectionOptions?.starting_day_allowed.includes(date)
		},
		transformPlaceholdersToContentView(content) {
			let result = content;
			for (const [placeholder, label] of Object.entries(this.contractVariablesValue)) {
				const regex = new RegExp(placeholder, 'g');
				result = result.replace(regex, `<span id="${placeholder}" style="font-weight:bold;text-transform: uppercase;">${label}</span>`);
			}
			return result;
		},
		countContractsByContentId(contractContentId) {
			const obj = this.$store.state.notifications.rtdbNotifications
			if (!obj.contracts) return 0;

			return Object.values(obj.contracts).reduce((count, contract) => {
					return contract.data?.contract_content_id == contractContentId ? count + 1 : count;
			}, 0);
		},
		getContractKeysByContentId(contractContentId) {
			const obj = this.$store.state.notifications.rtdbNotifications
			if (!obj.contracts) return [];

			return Object.keys(obj.contracts).filter(key => 
					obj.contracts[key].data?.contract_content_id == contractContentId
			);
		},
		openCommentSection(section_content_id){
			this.menuComment = section_content_id
			this.$nextTick(() => {
        const buttonRef = this.$refs[`commentActivatorButton_${section_content_id}`]
        if (buttonRef) {
					const buttonEl = Array.isArray(buttonRef) ? buttonRef[0] : buttonRef
					const rect = buttonEl.$el.getBoundingClientRect()
					const cardWidth = 400;
					const cardHeight = 500;
					let top = rect.bottom + window.scrollY + 5;
					let left = rect.left + window.scrollX;

					if (left + cardWidth > window.innerWidth) {
						left = window.innerWidth - cardWidth - 10;
					}
					
					if (top + cardHeight > window.innerHeight + window.scrollY) {
						top = window.innerHeight + window.scrollY - cardHeight - 10;
					}
					
					this.commentPosition = {
						top,
						left,
						width: rect.width
					}
        }
				if(this.$refs.scrollListComment && this.$refs.scrollListComment.length > 0){
					const container = this.$refs.scrollListComment[0]?.$el
					if(container){
						container.scrollTop = container.scrollHeight
					}
				}
			})
			this.markAsReadNotification(section_content_id)
		},
		markAsReadNotification(section_content_id){
			this.markAsReadRTDBNotifications(section_content_id)

			const ids = this.$store.state.notifications.contractNotifications
        .filter((item) => {
					return !item.readed && item.data?.contract_content_id == section_content_id
				})
        .map(item => item.id)
			ids.forEach(notification_id => {
				this.markNotificationAsRead(notification_id)
			});
		},
		markAsReadRTDBNotifications(section_content_id){
			const keys_to_remove = this.getContractKeysByContentId(section_content_id)
      eventBus.$emit('deleteRTDBNotifications', { type: 'contracts', keys_to_remove});
		},
		async markNotificationAsRead(notification_id){
				const tasks = await markAsReaded({id: notification_id})
				if(tasks.code == 200){
					this.$store.dispatch('notifications/getNotifications', {take: 10, types: [4]})
				}
		},
		async startSignContract(){
			this.$store.commit('contracts/setContractSignModal', this.contract)
			// if(!this.$store.state.user.userInfo?.signature){
			// 	this.$store.commit('users/setUserSignatureModal', true)
			// 	let messageData = {
			// 		message: 'Debes definir tu firma simple antes de firmar el contrato.',
			// 		title: 'Firma no definida',
			// 		type: 'warning',
			// 		icon: 'mdi-information',
			// 		color: '#3EBFA3',
			// 	}
			// 	this.setMessage(messageData)
			// }
			// else{
			// 	const signed = await signContract({contract_id: this.contract_id})
			// 	if(signed.code == 200){
			// 		let messageData = {
			// 			message: 'Genial! Tu contrato ha sido firmado correctamente.',
			// 			title: 'Contrado Firmado',
			// 			type: 'warning',
			// 			icon: 'mdi-information',
			// 			color: '#3EBFA3',
			// 		}
			// 		this.setMessage(messageData)
			// 		setTimeout(() => {
			// 			window.open(signed.url)
			// 		}, 1000)
			// 	}
			// }
		},
    // handleScroll() {
    //   this.isSticky = window.scrollY > 100
    //   const scrollY = window.scrollY || window.pageYOffset;
    //   this.topOffset = scrollY
    // },
		checkForHash(){
			if (this.$route.hash.startsWith('#section_content_id_')) {
				const section_content_id = this.$route.hash.replace('#section_content_id_', '');
				if (section_content_id) {
					this.openCommentSection(section_content_id);
				}
			}
		},
    scrollToSection() {
      this.$nextTick(() => {
        if (this.$route.hash) {
          const section = document.querySelector(this.$route.hash)
          const scrollContainer = this.$refs.colContractContainer

          if (section && scrollContainer) {
            const offset = 100
            const sectionTop = section.offsetTop - scrollContainer.offsetTop
            scrollContainer.scrollTo({
              top: sectionTop - offset,
              behavior: 'smooth'
            })
						setTimeout(() => {
							this.checkForHash()
							!this.fromCompany && this.$router.replace({  query: {ct: this.contract_id} })
						}, 500)
          }
        }
      });
    },
		async changeContratDate(){
			if(!this.selectedStartDate){
				const messageData = {
					message: 'Por favor primero selecciona una fecha.',
					title: 'Fecha no Seleccionada',
					type: 'warning',
					icon: 'mdi-alert-outline',
					color: 'orange',
				}
				this.setMessage(messageData)
				return null
			}
			const data = {
				contract_id: this.contract_id,
				start_date: this.selectedStartDate
			}
			const ctUpdate = await updateContract(data)
			if(ctUpdate.code == 200){
				this.getContractInfo()
			}
			this.$refs.startDateMenu.save(this.selectedStartDate)
		},
		async changeStatus(status){
			const newStatus = await addNewStatus({contract_id: this.contract_id, status})
			if(newStatus.code == 200){
				let messageData = {
					message: 'Genial! Haz aceptado el contrato para tu proyecto. Ahora debes firmar este contrato.',
					title: 'Contrato Aceptado',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				if(status == contractStatuses.RECHAZADO){
					messageData = {
						message: 'Lamentamos que hayas tenido que rechazar el contrato. Ya informamos a la empresa tu decisión.',
						title: 'Contrato Rechazado',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#FF1209',
					}
				}
				this.setMessage(messageData)
				await this.getContractInfo()
				if(status == contractStatuses.ACEPTADO){
					this.startSignContract()
				}
			}
			else{
				const messageData = {
					message: 'Hubo un error en la operación, por favor intenta nuevamente',
					title: 'Ha ocurrido un error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#FF1209',
				}
				this.setMessage(messageData)
			}
		},
		async deleteComment(comment){
			await deleteContractContentComment({contract_content_comment_id: comment.id})
			this.getContractInfo()
			this.sectionComment = null
		},
		closeCommentForm(){
			this.menuComment = null
		},
		setNewCommentBeforeInsert(data) {
			const newContract = JSON.parse(JSON.stringify(this.contract));

			for (const section of newContract.sections) {
				for (const content of section.contents) {
					if (content.id == data.contract_content_id) {
						if(!content.observation){
							content.observation = {contract_content_id: data.contract_content_id}
						}
						if (!Array.isArray(content.observation.comments)) {
							this.$set(content.observation, 'comments', []);
						}

						content.observation.comments.push({
							user: this.userInfo,
							comment: data.comment,
							side: data.side
						});
					}
				}
			}
			this.$set(this, 'contract', newContract);
			this.$nextTick(() => {
				if(this.$refs.scrollListComment && this.$refs.scrollListComment.length > 0){
					const container = this.$refs.scrollListComment[0]?.$el
					if(container){
						container.scrollTop = container.scrollHeight
					}
				}
			})
		},
		async saveComment(){
			if(!this.sectionComment || this.sectionComment.length == 0 || this.sectionComment == '') return
			this.loadingComment = false
			const data = {contract_id: this.contract.id, contract_content_id: this.menuComment, comment: this.sectionComment, side: 1}
			this.setNewCommentBeforeInsert(data)
			this.sectionComment = null
			await createContractContentComment(data)
			this.getContractInfo()
			this.loadingComment = false
		},
		toContractEdit(){
			this.$router.push({ name: 'ContractDetail', query: { id: this.contract.id } })
		},
		getContractInfo() {
			return new Promise((resolve, reject) => {
				if (!this.contract_id) {
					reject(new Error("No se ha especificado contract_id"));
					return;
				}

				this.loading = true;

				getContract({ contract_id: this.contract_id })
					.then(contract => {
						if (contract.code == 200) {
							this.$set(this, 'contract', { ...contract.contract });

							this.$nextTick(() => {
								this.scrollToSection();
								if (this.$refs.scrollListComment && this.$refs.scrollListComment.length > 0) {
									const container = this.$refs.scrollListComment[0]?.$el;
									if (container) {
										container.scrollTop = container.scrollHeight;
									}
								}
							});

							resolve(contract.contract);
						} else {
							reject(new Error(`Error en la respuesta del contrato: ${contract.code}`));
						}
					})
					.catch(error => reject(error))
					.finally(() => {
						this.loading = false;
					});
			});
		},
		// async getContractInfo(){
		// 	if(this.contract_id){
		// 		return new Promise(async (resolve, reject) => {
		// 			this.loading = true
		// 			const contract = await getContract({contract_id: this.contract_id})
		// 			if(contract.code == 200){
		// 				this.$set(this, 'contract', { ...contract.contract })
		// 				// this.selectedStartDate = contract.contract.start_date
		// 				// this.$set(this, 'contract', contract.contract);
		// 				this.$nextTick(() => {
		// 					this.scrollToSection()
		// 					if(this.$refs.scrollListComment && this.$refs.scrollListComment.length > 0){
		// 						const container = this.$refs.scrollListComment[0]?.$el
		// 						if(container){
		// 							container.scrollTop = container.scrollHeight
		// 						}
		// 					}
		// 				})
		// 			}
		// 			this.loading = false
		// 			resolve()
		// 		})
		// 	}
		// },
		sectionIndex(index){
			return '' +  this.$store.state.general.romanNumerals[index] + '. ' + this.$store.state.general.ordinalsNumbers[index] + ':'
		},
		transformPlaceholdersToFriendlyView(content) {
			let result = content;
			for (const [placeholder, label] of Object.entries(this.placeholderMapping)) {
				const regex = new RegExp(placeholder, 'g');
				result = result.replace(regex, `<span class="friendly-placeholder" style="font-size: 12px;padding: 1px 6px;border: 1px solid grey;border-radius: 5px;background: white;color:orange;" contenteditable="false">${label}</span>`);
			}
			return result;
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  },
};
</script>

<style scoped>
.contract-container{
	background-color: white;
	text-transform: none;
}
.scrollable-contract-container{
	overflow-y: scroll;
}
.section-content-container{
	position: relative;
	text-transform: none;
}
.add-comment-icon{
	position: absolute;
	bottom: 0;
	right: 0;
	margin: auto;
	background: white;
}
.card-comment-container{
	position: absolute;
	top: 0;
	right: 60px;
	background: white;
	z-index: 9999;
}
.sticky-header {
  position: absolute;
	left: 0; 
	right: 0;
	max-width: inherit;
  background: white;
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
.custom-warning-status{
	border: 1px solid #fb8c00;
	border-radius: 6px;
}
/deep/ .section-content-container p,
/deep/ .section-content-container div,
/deep/ .section-content-container ul,
/deep/ .section-content-container span,
/deep/ .section-content-container{
	text-transform: none;
}
</style>