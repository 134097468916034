<template>
  <div class="text-center">
    <v-dialog
      v-model="modalSignStatus"
      max-width="500px"
			persistent
      scrollable
    >
      <v-card>
        <v-card-title class="pa-0">
            <div class="d-flex flex-row flex-grow-1 align-center justify-space-between py-2 px-8">
							<div class="d-flex flex-row align-center justify-start">
								<v-icon color="#959595" class="mr-2">mdi-file-pdf-box</v-icon>
								<p class="mb-0 font-weight-bold text-primary body-1">Firmar Contrato</p>
							</div>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon
										dark
										small
										color="#959595"
										@click="$store.commit('contracts/setContractSignModal', null)"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon dark>mdi-close</v-icon>
									</v-btn>
								</template>
								<span>Cerrar</span>
							</v-tooltip>
						</div>
				</v-card-title>
				<v-card-text class="py-2 px-8">
					<p>¿Deseas firmar el contrato del proyecto <b>{{contract.morphable?.name}}</b>?</p>
					<div v-if="!this.contract.companySign">
						<p class="mb-3 caption font-weight-bold">Antes de continuar por favor selecciona una fecha de inicio para tu contrato</p>
						<div class="mb-4 mr-4">
							<v-menu
								ref="startDateMenu"
								v-model="startDateMenu"
								:close-on-content-click="false"
								:return-value.sync="selectedStartDate"
								transition="scale-transition"
								offset-y
								min-width="auto"
								max-width="250"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										:value="selectedStartDate | toDate"
										label="Fecha Inicio Contrato"
										prepend-icon="mdi-calendar"
										readonly
										dense
										class="custom-height-search custom-height-calendar"
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="selectedStartDate"
									no-title
									scrollable
									:first-day-of-week="1"
									locale="es-cl"
									for
									:events="startDateSelectionOptions?.holidays"
									:allowed-dates="allowedDates"
									:max-date="startDateSelectionOptions?.max_date"
									:min-date="startDateSelectionOptions?.min_date"
									:picker-date.sync="pickerDate"
									width="100%"
									reactive
									dense
									event-color="red lighten-1"
									color="green"
									class="custom-calendar-sidemini pb-4"
								>
									<v-spacer></v-spacer>
									<v-btn
										text
										color="#524D4D"
										@click="startDateMenu = false"
									>
										Cancelar
									</v-btn>
									<v-btn
										text
										color="#524D4D"
										@click="changeContratDate"
									>
										Confirmar
									</v-btn>
								</v-date-picker>
							</v-menu>
						</div>
					</div>
        </v-card-text>
        <v-card-actions class="pa-0">
					<div class="d-flex flex-grow-1 justify-end py-2 px-8">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="green"
									class="mb-0"
									small
									v-bind="attrs"
									v-on="on"
									dark
									@click="startSignContract"
								>
									<v-icon small>mdi-signature-freehand</v-icon>
									<p class="mb-0 ml-2 caption" v-if="contract.companySign">Firmar el Contrato como Representante Legal</p>
									<p class="mb-0 ml-2 caption" v-else>Firmar el Contrato</p>
								</v-btn>
							</template>
							<span>{{ signButtonDescription }}</span>
						</v-tooltip>
					</div>
				</v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { signContract, companySignContract, updateContract } from '@/helpers/api/contracts'
import { eventBus } from '@/components/Helpers/Events/NotificationsEventBus'

export default {
  name: 'ContractSignModal',
  data: () => ({
		startDateMenu: false,
		selectedStartDate: null,
		pickerDate: null,
  }),
  computed: {
		startDateSelectionOptions(){
			return this.$store.state.contracts.contractSignModal?.start_date_selection_options
		},
		signButtonDescription(){
			if(this.contract.companySign){
				return 'Firmarás el contrato como representante legal y se notificará al cliente'
			}
			return 'Se firmará el contrato y se informará a la empresa para que esta termine el proceso'
		},
		modalSignStatus(){
			return !!this.$store.state.contracts.contractSignModal
		},
		contract(){
			return this.$store.state.contracts.contractSignModal
		}
	},
	watch: {
		startDateSelectionOptions(newValue){
			if(newValue){
				this.pickerDate = newValue.min_date
			}
		},
	},
  mounted(){
		if(this.startDateSelectionOptions){
			this.pickerDate = this.startDateSelectionOptions.min_date
			const c_date = this.contract.start_date ? this.contract.start_date : this.startDateSelectionOptions.min_date
			this.selectedStartDate = this.$options.filters.toDate(c_date, null, 'YYYY-MM-DD')
		}
  },
  methods: {
		allowedDates(date){
			return this.startDateSelectionOptions?.starting_day_allowed.includes(date)
		},
		async startSignContract(){
			if(!this.$store.state.user.userInfo?.signature){
				this.$store.commit('users/setUserSignatureModal', true)
				let messageData = {
					message: 'Debes definir tu firma simple antes de firmar el contrato.',
					title: 'Firma no definida',
					type: 'warning',
					icon: 'mdi-information',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			}
			else{
				if(this.contract.companySign){
					this.signAsCompany()
				}
				else{
					this.signAsClient()
				}
			}
		},
		async changeContratDate(){
			if(!this.selectedStartDate){
				const messageData = {
					message: 'Por favor primero selecciona una fecha.',
					title: 'Fecha no Seleccionada',
					type: 'warning',
					icon: 'mdi-alert-outline',
					color: 'orange',
				}
				this.setMessage(messageData)
				return null
			}
			const data = {
				contract_id: this.contract.id,
				start_date: this.selectedStartDate
			}
			await updateContract(data)
			this.$refs.startDateMenu.save(this.selectedStartDate)
		},
		async signAsClient(){
			const signed = await signContract({contract_id: this.contract.id})
			const data = {contract_id: this.contract.id}
			eventBus.$emit('reloadContract', data)
			this.$store.commit('contracts/setContractSignModal', null)
			if(signed.code == 200){
				let messageData = {
					message: 'Genial! Tu contrato ha sido firmado correctamente.',
					title: 'Contrado Firmado',
					type: 'warning',
					icon: 'mdi-information',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
				setTimeout(() => {
					window.open(signed.url)
				}, 1000)
			}
		},
		async signAsCompany(){
			try {
				const sign = await companySignContract({contract_id: this.contract.id})
				if(sign.code == 200){
					const messageData = {
						message: 'Se ha firmado correctamente el contrato. Se ha notificado al cliente.',
						title: 'Contrato Firmado',
						type: 'success',
						icon: 'mdi-check-circle',
						color: '#3EBFA3',
					}
					this.setMessage(messageData)
					const data = {contract_id: this.contract.id}
					eventBus.$emit('reloadContract', data)
					this.$store.commit('contracts/setContractSignModal', null)
				}
			} catch (error) {
				if(error.data){
					error.data.forEach(message => {
						const messageData = {
							message: message.message,
							title: 'Error',
							type: 'danger',
							icon: 'mdi-close-octagon',
							color: '#FF1209',
						}
						this.setMessage(messageData)
					})
				}
			}
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>